import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Video360LayoutItem } from 'src/app/core/models/layout-item-video360';

@Component({
  selector: 'video-vr',
  templateUrl: './video-vr.component.html',
  styleUrls: ['./video-vr.component.scss'],
})
export class VideoVrComponent implements OnInit, AfterViewInit {

  @Input() url: string;

  @Input()
  set taskItem(taskItem: Video360LayoutItem) {
    this._taskItem = taskItem;
    this.url = taskItem.url;
  }
  get taskItem(): Video360LayoutItem {
    return this._taskItem;
  }
  private _taskItem: Video360LayoutItem;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if ((window as any).AFRAME.components['play-on-window-click']) {
      return;
    }
    (window as any).AFRAME.registerComponent('play-on-window-click', {
      init2: function () {
        // console.log('play-on-window-click init');
        this.onClick = this.onClick.bind(this);
        this.el.components.material.material.map.image.addEventListener('click', function () {
          console.log('click');
        });
        console.log(this.el);
      },
      init: function () {
        console.log('start init');
        var self = this;
        var isDragging = false;
        let canvases = document.getElementsByClassName('a-canvas');
        console.log(canvases, this);
        for (let canvasIndex = 0; canvasIndex < canvases.length; canvasIndex++) {
          const canvasElement = canvases[canvasIndex];

          console.log("canvasElement", canvasElement);

          let mouseMove = function () {
            isDragging = true;
            window.removeEventListener("mousemove", mouseMove);
          };

          canvasElement.addEventListener('mousedown', function () {
            window.addEventListener('mousemove', mouseMove)
          })

          canvasElement.addEventListener('mouseup', function () {
            var wasDragging = isDragging;
            isDragging = false;
            window.removeEventListener("mousemove", mouseMove);
            if (!wasDragging) {
              console.log("me clicked");

              var video = self.el.components.material.material.map.image;
              if (!video) { return; }
              if (video.paused) {
                video.play();
              } else {
                video.pause();
              }
              // $(this).selection();
            }
          })
        }
        // this.onClick = this.onClick.bind(this);
      },
      play: function () {
        console.log('play-on-window-click play');
        // window.addEventListener('click', this.onClick);
      },
      pause: function () {
        console.log('play-on-window-click pause');
        // window.removeEventListener('click', this.onClick);
      },
      onClick: function (evt) {
        console.log('play-on-window-click click');
        var video = this.el.components.material.material.map.image;
        if (!video) { return; }
        video.play();
      }
    });
  }

}
