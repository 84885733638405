import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ModelsModule } from 'src/app/core/models/models.module';
import { ScriptContextMenuComponent } from './script-context-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsModule } from 'src/app/core/analytics/analytics.module';
import { ContextMenuModule } from 'src/app/core/context-menu/context-menu.module';
import { ClipboardModule } from 'ngx-clipboard';
import { PublishModalModule } from '../publish-modal/publish-modal.module';
import { ScriptDeleteConfirmationDialogModule } from '../list/script-card/script-delete-confirmation-dialog/script-delete-confirmation-dialog.module';
import { WorkspaceModule } from 'src/app/core/workspace/workspace.module';
import { PlayModalModule } from '../play-modal/play-modal.module';



@NgModule({
  declarations: [
    ScriptContextMenuComponent
  ],
  imports: [
    IonicModule,
    ModelsModule,
    CommonModule,
    TranslateModule.forChild(),
    AnalyticsModule,
    ContextMenuModule,
    ClipboardModule,
    PublishModalModule,
    ScriptDeleteConfirmationDialogModule,
    WorkspaceModule,//.forChild()
    PlayModalModule
  ],
  exports: [
    ScriptContextMenuComponent
  ]
})
export class ScriptContextMenuModule { }
