import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutOrderPipe } from './layout-order.pipe';
import { TaskViewComponent } from './task-view.component';
import { LayoutImageComponent } from './layout-image/layout-image';
import { CameraDesktopComponent } from './camera-desktop/camera-desktop';
import { VideoRecordDesktopComponent } from './video-record-desktop/video-record-desktop';
import { ImageVrComponent } from './image-vr/image-vr.component';
import { QuoteComponent } from './quote/quote.component';
import { VideoVrComponent } from './video-vr/video-vr.component';
import { IonicModule } from '@ionic/angular';
import { NgPipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeModule } from 'src/app/core/localize/localize.module';
import { VideoComponent } from './video/video.component';
import { CameraMobileComponent } from './camera-mobile/camera-mobile';
import { VideoRecordMobileComponent } from './video-record-mobile/video-record-mobile';
import { TaskViewComponentAnalyticsService } from './task-view.analytics';
import { MatIconModule } from '@angular/material/icon';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { SafePipeModule } from 'safe-pipe';
import { TaskTextComponent } from './text/text.component';
import { FilePath } from '@ionic-native/file-path/ngx';
import { ContextMenuModule } from 'src/app/core/context-menu/context-menu.module';
import { InputTextComponent } from './input-text/input-text.component';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { MomentModule, TimeAgoPipe } from 'ngx-moment';

@NgModule({
  declarations: [
    LayoutOrderPipe,
    TaskViewComponent,
    LayoutImageComponent,
    CameraDesktopComponent,
    CameraMobileComponent,
    VideoRecordDesktopComponent,
    VideoRecordMobileComponent,
    QuoteComponent,
    ImageVrComponent,
    VideoComponent,
    VideoVrComponent,
    TaskTextComponent,
    InputTextComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgPipesModule,
    TranslateModule.forChild(),
    LocalizeModule,
    MatIconModule,
    Ng2ImgMaxModule,
    SafePipeModule,
    ContextMenuModule,
    MomentModule,
  ],
  exports: [
    TaskViewComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    TaskViewComponentAnalyticsService,
    FilePath,
    PhotoViewer,
    TimeAgoPipe,
    DatePipe,
  ]
})
export class TaskViewModule { }
