import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ManageTeamAnalyticsService } from '../manage-teams/manage-team-analytics-service';
import { WorkspaceLevel, Workspace } from '../../core/models/workspace.model';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';

@Component({
             selector: 'app-invite-new-member-dialog',
             templateUrl: './invite-new-member-dialog.component.html',
             styleUrls: ['./invite-new-member-dialog.component.scss']
           })
export class InviteNewMemberDialogComponent implements OnInit {

  @Input()
  workspace: Workspace;

  @Input()
  levels: Array<WorkspaceLevel>;

  email: string;
  memberRoleLevel: WorkspaceLevel;

  processing = false;

  constructor(
    private modalCtrl: ModalController,
    public analytics: ManageTeamAnalyticsService,
    private workspaceService: WorkspaceService
    ) {
  }

  ngOnInit() {
    this.analytics.createInviteMemberDialogOpenEvent();
    this.memberRoleLevel = this.levels[0];
  }

  onCancel(closeType: string) {
    this.analytics.createInviteMemberDialogOnCancelClickedEvent(closeType);
    this.modalCtrl.dismiss({action: 'cancel'});
  }

  async onSubmit(inviteForm: NgForm) {
    if (inviteForm.valid) {
      this.processing = true;
      this.analytics.createInviteMemberDialogOnSubmitClickedEvent();
      const result = await this.workspace.inviteMember(this.email, this.memberRoleLevel).pipe(first()).toPromise();
      this.processing = false;
      // console.log(result);
      this.modalCtrl.dismiss({
                               action: 'submit',
                               mail: this.email,
                               level: this.memberRoleLevel
                             });
    }
  }
}
