import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Task } from 'src/app/core/models/task.model';
import { Player } from 'src/app/core/models/player.model';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { Response } from 'src/app/core/models/response';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Role } from 'src/app/core/models/role.model';


@Component({
  selector: 'task-preview',
  templateUrl: './task-preview.component.html',
  styleUrls: ['./task-preview.component.scss'],
})
export class TaskPreviewComponent implements OnInit {

  private _task: Task;
  @Input()
  set task(task: Task) {
    this._task = task;
    this.response = new Response({}, null, null, this.modelProvider);
  }
  get task(): Task {
    return this._task;
  }

  public response: Response;
  @Input() player: Player;

  @Input() tasks: Task[];
  @Input() players: Player[];
  @Input() roles: Role[];

  @ViewChild('contentContainer') contentContainer: ElementRef;

  constructor(
    private ref: ChangeDetectorRef,
    private modelProvider: ModelProvider
  ) {
  }


  public onChoiceSelected(choice: CustomEvent) {
    this.response.choice = choice.detail.value;
  }

  public onTextTyped(choice: CustomEvent) {
    this.response.text = choice.detail.value;
  }

  public setResponseImage(file: File | Blob) {
    if (file) {
      this.response.photo = {};
      this.response.photo.original = {
        ref: '',
        url: URL.createObjectURL(file),
        mimeType: file.type
      };
    } else {
      delete this.response['photo'];
    }
  }

  public setResponseVideo(file: File) {
    if (file) {
      this.response.video = {};
      this.response.video.transcoded = {
        ref: '',
        url: URL.createObjectURL(file),
        mimeType: file.type
      };
      this.ref.detach();
      this.isSendDisabled();
      this.ref.detectChanges();
      this.ref.markForCheck();
      this.ref.reattach();
    } else {
      delete this.response['video'];
    }

    // this.ref.detectChanges();
    // console.log("setResponseVideo", file, JSON.stringify(file));
  }

  public onSubmit() {
    if (this.player && this.response) {
      this.response.done = true;
      this.player.addResponse(this.task, this.response);
      this.contentContainer.nativeElement.scroll(0, 0);
    }
  }

  public isSendDisabled() {
    switch (this.task.inputType) {
      case LayoutItem.TYPE_INPUT_TEXT:
        if (!this.response.text) {
          return true;
        }
        break;
      case LayoutItem.TYPE_INPUT_PHOTO:
        if (!this.response.photo || !this.response.photo.original) {
          return true;
        }
        break;
      case LayoutItem.TYPE_INPUT_VIDEO:
        if (!this.response.video || !this.response.video.transcoded) {
          return true;
        }
        break;
      case LayoutItem.TYPE_INPUT_CHOICE:
        if (!this.response.choice) {
          return true;
        }
        break;

      default:
        break;
    }

    return false;
  }

  ngOnInit() {
    console.log(this.task);
  }

}
