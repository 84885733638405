import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ModelProvider } from "src/app/core/models/general/model.provider";
import { OrganizationService } from "src/app/organization/organization.service";
import { AuthService } from "../../auth/auth.service";
import { filter, first, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-organization-not-found",
  templateUrl: "./organization-not-found.component.html",
  styleUrls: ["./organization-not-found.component.scss"],
})
export class OrganizationNotFoundComponent implements OnInit {
  processing: string = null;

  @ViewChild("form") form: NgForm;
  joinCode: string = null;

  constructor(
    public organizationService: OrganizationService,
    public modelProvider: ModelProvider,
    public router: Router,
    public auth: AuthService
  ) {}

  ngOnInit() {}

  async logout() {
    await this.auth.signOut();
    await this.router.navigateByUrl('/');
  }

  public showProfile() {
    this.router.navigate(['/playing/profile']);
  }


  async joinWithCode($event: any) {
    // console.log($event);
    // console.log(this.joinCode);
    try {
      this.processing = "code";
      if (this.joinCode.trim().length > 0) {
        const response = await this.organizationService.getOrganizationByCode(
          this.joinCode
        );
      }
    } catch (error) {
      this.form.controls.joinCode.setErrors({[error?.message ? error.message : error] : error})
    } finally {
      this.processing = null;
    }
  }

  async joinGamoteca() {
    try {
      this.processing = "gamoteca";
      const organizationPath = this.modelProvider.fsDB
          .collection("organizations")
          .doc(environment.gamotecaOrgID).ref.path;
      const result = await this.organizationService.getOrganizationByPath(organizationPath);
      // const result = await this.organizationService.joinToOriganization(
      //   this.modelProvider.fsDB
      //     .collection("organizations")
      //     .doc("FpBM0aALYD9Tn7fu6ohC").ref
      // );
      // const user = await this.auth.user$.pipe(first()).toPromise();
      // await user.organizationMembers$
      //   .pipe(
      //     filter((members) => members.length > 0),
      //     first()
      //   )
      //   .toPromise();
      // await this.auth.navigateToHome();
    } catch (error) {
      throw error;
    } finally {
      this.processing = null;
    }
  }
}
