<div class="modal-content ion-padding">
  <form #deleteForm="ngForm" (ngSubmit)="onSubmit(deleteForm)" autocomplete="off">
    <h2 class="modal-title">{{'user-profile/delete-account/page-title' | translate}}</h2>
    <div class="alert alert-warning" role="alert">
      <p class="my-0">
        <b>{{'user-profile/delete-account/alert/title' | translate}}</b>
        <br />{{'user-profile/delete-account/alert/paragraph-1' | translate}}</p>
      <p>{{'user-profile/delete-account/alert/paragraph-2' | translate}}</p>
    </div>
    <div class="form-group my-4">
      <label for="confirm-input" [innerHTML]="'user-profile/delete-account/confirm-input/label' | translate">
      </label>
      <input name="confirm" [(ngModel)]="confirm" type="text" required id="confirm-input">
    </div>
    <div class="mt-5 text-right">
      <ion-button (click)="onCancel()"
                  class="mr-3 action"
                  color="secondary"
                  shape="round">
        {{'general/cancel' | translate}}
      </ion-button>
      <ion-button type="submit"
                  color="warning"
                  class="action"
                  shape="round"
                  [disabled]="processing || !deleteForm.valid || deleteForm.value.confirm !== 'DELETE'">
        <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
        {{'user-profile/delete-account/submit-button/label' | translate}}</ion-button>
    </div>
  </form>
</div>