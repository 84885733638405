import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, NgControl, NgForm, ValidationErrors } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { url } from 'inspector';
import { ClipboardService } from 'ngx-clipboard';
import { delay, first } from 'rxjs/operators';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Organization } from 'src/app/core/models/organization.model';
import { Plan } from 'src/app/core/models/plan';


type integrationsType = {
  providerID: string,
  sso: boolean,
  lti: boolean,
}

type ssoType = {
  clientID: string,
  clientSecret: string,
  host: string,
  providerID: string,
  scope: string,
  tokenPath: string,
  type: string,
  userInfoPath: string,
};

type ltiType = {
  providerID: string,
  token: string,
  type: string,
  url: string,
  wsFunction: string,
  encryptionKey?: string,
};

@Component({
  selector: 'organization-integrations',
  templateUrl: './organization-integrations.component.html',
  styleUrls: ['./organization-integrations.component.scss'],
})
export class OrganizationIntegrationsComponent implements OnInit {

  @ViewChild('form') form: NgForm;
  @ViewChildren('domainControl') domainControllers: QueryList<NgControl>;

  formTemplate: 'simple' | 'advanced' = 'simple';

  processing: string;

  @Input()
  organization: Organization;

  @Input()
  plan: Plan;

  integrations: integrationsType;
  sso: ssoType;
  lti: ltiType;

  constructor(
    private modelProvider: ModelProvider,
    private clipboard: ClipboardService,
    private toastController: ToastController
  ) { }

  async ngOnInit() {
    this.integrations = this.organization.integrations || {
      lti: true,
      sso: false,
      providerID: this.organization.ref.id,
    };
    const ssoSnapshot = await this.organization.ref.collection('integrations').doc('sso').get();
    if (ssoSnapshot.exists) {
      this.sso = ssoSnapshot.data() as ssoType;
    } else {
      this.sso = {
        clientID: 'gamoteca',
        clientSecret: '',
        host: 'https://',
        providerID: this.organization.ref.id,
        scope: 'user_info',
        tokenPath: '/local/oauth/token.php',
        type: 'moodle',
        userInfoPath: '/local/oauth/user_info.php',
      };
    }

    const ltiSnapshot = await this.organization.ref.collection('integrations').doc('lti').get();
    if (ltiSnapshot.exists) {
      this.lti = ltiSnapshot.data() as ltiType;
    } else {
      this.lti = {
        providerID: this.organization.ref.id,
        token: '',
        type: 'moodle',
        url: 'https://[...]/webservice/rest/server.php',
        wsFunction: 'gamoteca',
      };
    }

    try {
      const urlParsed = this.parseURL(this.lti.url);
      this.domain = urlParsed.host;      
    } catch (error) {
      console.error(error);
    }
    

    if(!this.lti.encryptionKey) {
      const response = await this.modelProvider.functions.httpsCallable('organizationGetEncryptionKey')({
        organizationPath: this.organization.ref.path
      }).pipe(first()).toPromise();
      
      if (response.key) {
        this.lti = { ...this.lti, encryptionKey: response.key }
      }
    }

    this.domainControllers.changes.pipe(first(), delay(500)).subscribe(o => {
      this.form.controls.domain.setValidators(this.domainValidator());
    });
  }

  domain = '';
  onDomainUpdate($event: any) {
    this.lti.url = `https://${$event}/webservice/rest/server.php`;
  }

  private domainValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/g.test(control.value);
      return forbidden ? null : {domainError: {value: control.value}};
    };
  }

  private parseURL(url) {
    let parser: any = document.createElement('a'),
      searchObject: any = {},
      queries, split, i;
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for (i = 0; i < queries.length; i++) {
      split = queries[i].split('=');
      searchObject[split[0]] = split[1];
    }
    return {
      protocol: parser.protocol,
      host: parser.host,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      searchObject: searchObject,
      hash: parser.hash
    };
  }

  async copyEncryptionKey() {
    this.clipboard.copyFromContent(this.lti.encryptionKey);

    const toast = await this.toastController.create({
      message: 'Encryption key copied to clipboard',
      duration: 2000
    });
    toast.present();
  }

  async save() {
    this.processing = 'save';
    this.sso.providerID = this.integrations.providerID;
    this.lti.providerID = this.integrations.providerID;
    try {
      this.organization.integrations = this.integrations;
      await this.organization.save();
      // await this.organization.ref.collection('integrations').doc('sso').set(this.sso, { merge: true });
      await this.organization.ref.collection('integrations').doc('lti').set(this.lti, { merge: true });
      const toast = await this.toastController.create({
        message: 'Successfully saved',
        duration: 1000
      });
      toast.present();
    } catch (error) {
      console.error(error);
    } finally {
      this.processing = null;
    }
  }

}
