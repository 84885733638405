import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandingService } from './branding.service';
import { ModelsModule } from '../models/models.module';
import { ColorModule } from '../color/color.module';
import { IonicStorageModule } from '@ionic/storage-angular';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ModelsModule,
    ColorModule,
    IonicStorageModule,
  ],
  // providers: [
  //   BrandingService
  // ]
})
export class BrandingModule {

  static forChild(): ModuleWithProviders<BrandingModule> {
    return { ngModule: BrandingModule
        // , providers: [WorkspaceService]
    };
}

  static forRoot(
      service?: BrandingService
    ): ModuleWithProviders<BrandingModule> {
    return {
      ngModule: BrandingModule,
      providers: [
        BrandingService,
        // PrivilegeService,
        // { provide: WorkspaceService, useClass: WorkspaceService }
      ]
    };
  }

}
