import { Component, OnInit, Input, ChangeDetectorRef, Optional, ViewChild, SimpleChanges } from '@angular/core';
import { Task } from 'src/app/core/models/task.model';
import { Player } from 'src/app/core/models/player.model';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { Response } from 'src/app/core/models/response';
import { Platform } from '@ionic/angular';
import { TaskViewComponentAnalyticsService } from './task-view.analytics';
import { CameraDesktopComponent } from './camera-desktop/camera-desktop';
import { VideoRecordDesktopComponent } from './video-record-desktop/video-record-desktop';
import { VideoLayoutItem } from 'src/app/core/models/layout-item-video';
import { InputVideoLayoutItem } from 'src/app/core/models/layout-item-input-video';
import { Role } from 'src/app/core/models/role.model';

@Component({
  selector: 'player-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
})
export class TaskViewComponent implements OnInit {

  @ViewChild('cameraDesktop') cameraDesktop: CameraDesktopComponent;
  @ViewChild('videoRecordDesktop') videoRecordDesktop: VideoRecordDesktopComponent;

  // @Input()
  // uploadPercent: number;

  public isSubmiting = false;

  // tslint:disable-next-line: member-ordering
  private _task: Task;
  @Input()
  set task(task: Task) {
    // console.log('set task', task);
    this._task = task;
    this.isSubmiting = false;
    // this._reloadResponse();
    // Stop all videos
    document.querySelectorAll('video').forEach(video => video.pause());
  }
  get task(): Task {
    return this._task;
  }

  // tslint:disable-next-line: member-ordering
  public response: Response;
  // tslint:disable-next-line: member-ordering
  private _player: Player;
  @Input()
  set player(player: Player) {
    this._player = player;
    // this._reloadResponse();
  }
  get player(): Player {
    return this._player;
  }

  // tslint:disable-next-line: member-ordering
  @Input() tasks: Task[];
  // tslint:disable-next-line: member-ordering
  @Input() players: Player[];

  @Input() roles: Role[];

  // @Input() player: Player;

  constructor(
    private ref: ChangeDetectorRef,
    public platform: Platform,
    public analytics: TaskViewComponentAnalyticsService
  ) { }

  ngOnInit(): void {
    // Quill.register('modules/smart-breaker', (window as any).SmartBreaker);
    // throw new Error("Method not implemented.");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.player || changes.task) {
      this._reloadResponse();
    }
  }

  private _reloadResponse() {
    // console.log('_reloadResponse', new Date(), this.response && this.response.toData());
    if (this.cameraDesktop) { this.cameraDesktop.stopCamera(); }
    if (this.videoRecordDesktop) { this.videoRecordDesktop.stopCamera() }

    if (this.player && this.player.responses && this.player.responses.getByID(this.task.id)) {
      this.response = this.player.responses.getByID(this.task.id);
    } else {
      if (this.task) {
        if (this.response && this.response.isNew) {
          return;
        }

        this.response = this.task.getNewResponse(this.player);
      }
    }

    // console.log(this.response.toData());
  }

  public onChoiceSelected(choice: CustomEvent) {
    this.response.choice = choice.detail.value;
  }

  public onTextTyped(text: string) {
    this.response.text = text;
    if(!text || text.length === 0) { 
      delete this.response.text;
    }
  }

  public setResponseImage(file: File) {
    if (file) {
      this.response.setPhoto(file);
      // this.response.photo = {};
      // this.response.photo.original = {
      //   ref: '',
      //   url: URL.createObjectURL(file),
      //   mimeType: file.type
      // };
    } else {
      delete this.response['photo'];
    }
  }

  public setResponseVideo(file: File) {
    if (file) {
      this.response.setVideo(file);
      console.error('set response video');
      // this.response.video = {};
      // this.response.video.transcoded = {
      //   ref: '',
      //   url: URL.createObjectURL(file),
      //   mimeType: file.type
      // };
      this.ref.detach();
      this.isSendDisabled();
      this.ref.detectChanges();
      this.ref.markForCheck();
      this.ref.reattach();
    } else {
      this.response.removeVideo();
      // delete this.response['video'];
    }

    // this.ref.detectChanges();
    // console.log("setResponseVideo", file, JSON.stringify(file));
  }

  public onSubmit() {
    this.analytics.onSubmitClick(this.player);
    this.isSubmiting = true;
    if (this.player && this.response) {
      this.response.done = true;
      this.response.point = this.task.point || 0;
      this.player.addResponse(this.task, this.response);
      // this.contentContainer.nativeElement.scroll(0, 0);
    }
  }

  public isSendDisabled() {
    if (this.response && this.response.createdAt) {
      return true;
    }

    switch (this.task.inputType) {
      case LayoutItem.TYPE_INPUT_TEXT:
        if (!this.response.text) {
          return true;
        }
        break;
      case LayoutItem.TYPE_INPUT_PHOTO:
        if (!this.response.photoURL) {
          // if (!this.response.photo || !this.response.photo.original) {
          return true;
        }
        break;
      case LayoutItem.TYPE_INPUT_VIDEO:
        if (
          this.task.layout.inputVideo?.properties?.alternate?.text?.active
          && this.response.text?.length > 0
        ) {
          return false;
        }
        if (!this.response.videoURL) {
          // if (!this.response.video || !this.response.video.transcoded) {
          return true;
        }
        break;
      case LayoutItem.TYPE_INPUT_CHOICE:
        if (!this.response.choice) {
          return true;
        }
        break;

      default:
        break;
    }

    return false;
  }
}
