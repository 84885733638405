import { Directive, ElementRef, HostBinding, Input, Renderer2, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[headerTitleSize]'
})
export class TitleSizeDirective {

  @Input() headerTitleSize: string;

  private sizes = {
    lg: 15,
    xlg: 20,
    xxlg: 30,
  }

  @HostBinding('class.title-lg')
  public get isLg(): boolean {
    return this.headerTitleSize && this.headerTitleSize.length >= this.sizes.lg && this.headerTitleSize.length < this.sizes.xlg
  }
  @HostBinding('class.title-xlg')
  public get isXLg(): boolean {
    return this.headerTitleSize && this.headerTitleSize.length >= this.sizes.xlg && this.headerTitleSize.length < this.sizes.xxlg
  }
  @HostBinding('class.title-xxxlg')
  public get isXXLg(): boolean {
    return this.headerTitleSize && this.headerTitleSize.length >= this.sizes.xxlg
  }

  constructor(
    // private renderer: Renderer2,
    private el: ElementRef,
    private platform: Platform
    ) {
      // for (const platform of this.platform.platforms()) {
      //   this.renderer.addClass(this.el.nativeElement, platform);  
      // }
     }

}
