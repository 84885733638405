<app-header active="superadmin"></app-header>

<ion-content>
  <content-container class="py-5" [back-button]="'/superadmin/dashboard'" pageTitle="Generate Player URL">
    <div class="row">
      <form (submit)="submit()" #form="ngForm" class="col-sm-8" autocomplete="off">
        <div class="form-group">
          <!-- <label for="userID">User ID
            <a target="_blank" href="https://console.firebase.google.com/project/gamoteca-161414/authentication/users">Find user ID</a>
          </label> -->
          <label for="rawURL" class="label-with-popper">
            <span>Original URL</span>
          </label>

          <!-- <input #formUserID="ngModel" [(ngModel)]="rawURL" required type="text" name="userID" id="userID"> -->
          <ion-textarea [(ngModel)]="rawURL" required type="text" name="rawURL" id="rawURL" [rows]="4" [autoGrow]="true"></ion-textarea>
        </div>
        <div class="form-group">
          <label for="utmCampaign" class="label-with-popper"><span>UTM Campaign</span></label>
          <input #formUtmCampaign="ngModel" [(ngModel)]="utmCampaign" type="text" name="utmCampaign"
            id="utmCampaign">
        </div>
        <div class="form-group">
          <label for="utmMedium" class="label-with-popper"><span>UTM Medium</span></label>
          <input #formUtmMedium="ngModel" [(ngModel)]="utmMedium" type="text" name="utmMedium" id="utmMedium">
        </div>
        <div class="form-group">
          <label for="utmSource" class="label-with-popper"><span>UTM Source</span></label>
          <input #formUtmSource="ngModel" [(ngModel)]="utmSource" type="text" name="utmSource" id="utmSource">
        </div>
        <div class="form-group">
          <ion-button shape="round" color="primary" type="submit" [disabled]="processing">
            <ion-spinner *ngIf="processing"></ion-spinner>
            Generate URL
          </ion-button>
        </div>
      </form>
    </div>
    <form>
      <div *ngIf="resultLink" class="form-group mt-5">
        <label for="resultLink" class="label-with-popper"><span>Player Link</span>
        <ion-icon name="copy-outline" (click)="copyURL()" title="Copy link" class="ml-2"></ion-icon>
      </label>
        <div class="well">
          {{resultLink}}
        </div>
        <!-- <texarea [(ngModel)]="resultLink" type="text" name="resultLink" id="resultLink" readonly></texarea> -->
      </div>
    </form>

  </content-container>
</ion-content>