<mat-icon (click)="onCancel('icon')" class="modal-close-icon" slot="start">close</mat-icon>

<div class="modal-content ion-padding">
  <h2 class="modal-title">{{'play-edit/team-box/delete-confirmation-dialog/title' | translate}}</h2>
  <p class="confirmation-description"
    [innerHTML]="'play-edit/team-box/delete-confirmation-dialog/confirm-question' | translate: {title: team.title}">
  </p>
  <ion-footer mode="ios">
    <div class="text-right">
      <ion-button (click)="onCancel('button')" class="mr-2" color="secondary" shape="round">
        {{'general/cancel' | translate}}
      </ion-button>

      <ion-button (click)="onDelete()" type="submit" color="primary" shape="round">
        {{'play-edit/team-box/delete-confirmation-dialog/delete' | translate}}</ion-button>
    </div>
  </ion-footer>
</div>