import { FirestoreModel } from '../firebase/firestore-model';
import { DocumentReference } from '@firebase/firestore-types';
import { Player } from './player.model';
import { ScriptVersion } from './script-version.model';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Role } from './role.model';
import { Model } from './general/model';
import { TaskLayout } from './task-layout';
import { LayoutItem } from './task-layout-item';
import { FirebaseFileUploaderEntity } from '../firebase/firebase-file-uploader-entity';
import { Dependency } from './dependency';
import { LocalizedText } from './general/localized-text';
import { Play } from './play.model';
import { Response } from './response';
import firebase from "firebase/app"
import { uniq, remove, maxBy } from 'lodash';
import { Script } from './script.model';
import { QueryFn } from '@angular/fire/firestore';
import { FirestoreArrayField } from '../firebase/firestore-array-field';
import { Rule } from './rule';
import { TextLayoutItem } from './layout-item-text';
import { QuoteLayoutItem } from './layout-item-quote';

export class Task extends FirestoreModel<Task> implements FirebaseFileUploaderEntity {
  public COLLECTION_NAME = 'tasks';
  public BASE_MODEL = 'tasks';

  public _title: LocalizedText;
  public get title(): string {
    return this._title.getText();
  }
  public set title(text: string) {
    this._title.setText(text);
  }
  public layout: TaskLayout;
  public dependency: Dependency;
  public point: number;
  public order: number;
  public terminate: boolean;
  public roleRef: DocumentReference;

  protected rules() {
    return {
      _title: {
        [Model.RULE_ALIAS]: 'title',
        [Model.RULE_CLASS]: LocalizedText,
        // [Model.RULE_DEFAULT]: new LocalizedText({ this.modelProvider.translate.instant('task-edit/form/title/default')
      },
      layout: {
        [Model.RULE_CLASS]: TaskLayout,
        [Model.RULE_DEFAULT]: () => new TaskLayout({ [this.getNewLayoutID()]: new TextLayoutItem({}) }, this, {}, this.modelProvider)
      },
      dependency: {
        [Model.RULE_CLASS]: Dependency,
        [Model.RULE_DEFAULT]: () => new Dependency({}, this, {}, this.modelProvider)
      },
      point: {
        [Model.RULE_DEFAULT]: 0
      },
      order: {},
      terminate: {
        [Model.RULE_DEFAULT]: false
      },
      roleRef: {}
    };
  }

  public setFields(data: any) {
    if (data && data.dependency && data.dependency.ruleSet && !data.dependency.rules) {
      data.dependency.rules = data.dependency.ruleSet;
    }

    super.setFields(data);

    if(data && data.dependency && data.dependency.constructor === Object && Object.keys(data.dependency).length === 0) {
      this.dependency = Dependency.instantiate({}, this, { skipSetFields: false }, this.modelProvider);
      this.dependency.setDefaultValues();
    }
  }

  public async copy(version: ScriptVersion, roleRef: DocumentReference, tasks: Task[]) {
    const task = this.modelProvider.task.createByVersion(version);
    task.roleRef = roleRef;
    task.dependency = this.dependency;
    task._title = this._title;
    task.layout = this.layout;
    task.terminate = this.terminate;
    task.point = this.point;

    const roleTasks = tasks.filter(_task => _task.roleRef.isEqual(roleRef));
    const orderMaxTask = maxBy(roleTasks, (_task => _task.order ? Number(_task.order) : 0));
    task.order = orderMaxTask ? Number(orderMaxTask.order) + 1 : 1;
    await task.save();
  }

  public async save() {
    await super.save();
    // Update script version update time
    await this.getReference().parent.parent.update('updatedAt', firebase.firestore.Timestamp.now());
  }

  public async remove(tasks?: Task[]) {
    for (const task of tasks) {
      // Remove from other tasks' dependency list
      const foundRuleWithTask = task.dependency.ruleSet.find(rule => rule.taskRef.isEqual(this.ref));
      if (foundRuleWithTask) {
        task.dependency.ruleSet.removeBy((rule) => {
          return rule.taskRef.isEqual(this.ref);
        });
      }
      // Remove from other tasks' quote
      const quote = task.layout.getItemByType(LayoutItem.TYPE_QUOTE, false) as QuoteLayoutItem;
      let quoteFound = false;
      if (quote && quote.properties && quote.properties.taskRef && quote.properties.taskRef.path === this.ref.path) {
        task.layout.removeItemByType(LayoutItem.TYPE_QUOTE);
        quoteFound = true;
      }

      if (foundRuleWithTask || quoteFound) {
        task.save()
      }
    };

    await super.remove();
  }

  public findAllByScriptVersion(scriptVersion: ScriptVersion, queryFn?: QueryFn): Observable<Task[]> {
    return this.findAllBy(null, scriptVersion.getReference().collection('tasks'));
  }

  public findAllByRoleRef(roleRef: DocumentReference, scriptVersion: ScriptVersion) {
    return this.findAllByScriptVersion(scriptVersion).pipe(map(tasks => tasks.filter(task => task.roleRef.isEqual(roleRef))));
  }

  public findByID(id: string, scriptID?: string, versionID?: string) {
    if (scriptID && versionID) {
      return this.findByRef('scripts/' + scriptID + '/versions/' + versionID + '/tasks/' + id);
    } else {
      return super.findByID(id);
    }
  }

  /**
   * Role
   */
  private _role$: Observable<Role>;
  public get role$(): Observable<Role> {
    if (this._role$ !== undefined) { return this._role$; }

    this._role$ = this.modelProvider.role.findByRef(this.roleRef);
    return this._role$;
  }

  private _role: Role;
  public get role(): Role {
    if (this._role !== undefined) { return this._role; }
    this._role = null;

    this.role$.subscribe(role => {
      this._role = role;
    });
    return this._role;
  }

  public isActual(players: Array<Player>) {
    return this.isResolved(players) && !this.isDone(players);
  }

  public isResolved(players: Array<Player>): Boolean {
    if (!this.dependency || !this.dependency.ruleSet) {
      return true;
    } else {
      let resolved = (this.dependency.ruleSet.length === 0 || this.dependency.condition === 'AND') ? true : false;
      this.dependency.ruleSet.forEach(rule => {

        let ruleResolved = false;
        // Check if any player resolved the rule
        players.forEach(player => {
          if (rule.operator === 'done') {
            if (player.responses && player.responses.getByID(rule.taskRef.id) && player.responses.getByID(rule.taskRef.id).done === true) {
              ruleResolved = true;
            }
          }
          if (rule.operator === 'chosen') {
            if (rule.value === false || rule.value === 'false') {
              if (player.responses && player.responses.getByID(rule.taskRef.id) && player.responses.getByID(rule.taskRef.id).done === true) {
                ruleResolved = true;
              }
            } else {
              if (!player.responses.isEmpty &&
                player.responses.getByID(rule.taskRef.id) &&
                player.responses.getByID(rule.taskRef.id).done === true &&
                player.responses.getByID(rule.taskRef.id).choice === rule.value
              ) {
                ruleResolved = true;
              }
            }
          }
        });

        resolved = (this.dependency.condition === 'AND') ? resolved && ruleResolved : resolved || ruleResolved;
      });

      return resolved;
    }
  }

  /**
   * Make order number lower
   */
  public async moveUp(allTask: Task[]) {
    const level = this.getDependencyLevel(allTask);
    const sameLevelTasks = allTask.filter(task => {
      return task.getDependencyLevel(allTask) === level && task.roleRef.path == this.roleRef.path;;
    }).sort((a, b) => { return Number(a.order) > Number(b.order) ? 1 : -1; });

    const myIndex = sameLevelTasks.findIndex((task) => task.ref.path === this.ref.path);

    if (myIndex === 0) { return; }

    const taskBefore = sameLevelTasks[myIndex - 1];
    this.order = (taskBefore.order || 0);
    taskBefore.order = this.order;

    if (this.order === taskBefore.order) {
      this.order = taskBefore.order - 1;
    }

    await this.save();
    await taskBefore.save();
  }

  /**
  * Make order number lower
  */
  public async moveDown(allTask: Task[]) {
    const level = this.getDependencyLevel(allTask);
    const sameLevelTasks = allTask.filter(task => {
      return task.getDependencyLevel(allTask) === level && task.roleRef.path == this.roleRef.path;
    }).sort((a, b) => { return Number(a.order) > Number(b.order) ? 1 : -1; });

    const myIndex = sameLevelTasks.findIndex((task) => task.ref.path === this.ref.path);

    if (myIndex === sameLevelTasks.length - 1) { return; }

    const taskAfter = sameLevelTasks[myIndex + 1];
    this.order = taskAfter.order || 0;
    taskAfter.order = this.order;

    if (this.order === taskAfter.order) {
      this.order = taskAfter.order + 1;
    }

    await this.save();
    await taskAfter.save();
  }

  public isDependent(otherTask: Task) {
    if (!this.dependency.ruleSet) {
      return false;
    }

    return this.dependency.ruleSet.find(
      (rule) => rule.taskRef.isEqual(otherTask.getReference())
    ) ? true : false;
  }

  // public isDependentOf(otherTask: Task) {
  //   return otherTask.dependency.ruleSet.find(
  //     (rule) => rule.taskRef.isEqual(this.getReference())
  //   ) ? true : false;
  // }

  public getDependentTasksChain(allTask: Task[], level = 1): Array<Task> {
    if (level > 100) {
      return [];
    }
    let dependentTasks: Array<Task> = [];
    if (this.dependency && this.dependency.ruleSet) {
      dependentTasks = dependentTasks.concat(this.dependency.ruleSet.map(rule =>
        allTask.find(_task => _task.getReference().isEqual(rule.taskRef))
      ));
    }

    dependentTasks.forEach(task => {
      dependentTasks = dependentTasks.concat(task.getDependentTasksChain(allTask, ++level));
    });

    return uniq(dependentTasks);
  }

  // public getDependencyLevel(allTask: Task[], level = {level: 1}) {
  //   if (level.level > 100) {
  //     return level.level;
  //   }
  //   let dependentTasks: Array<Task> = [];
  //   // const level = {level: 1};
  //   if (this.dependency && this.dependency.rules) {
  //     dependentTasks = dependentTasks.concat(this.dependency.rules.map(rule =>
  //       allTask.find(_task => _task.getReference().isEqual(rule.taskRef))
  //     ));
  //   }

  //   dependentTasks.forEach(task => {
  //     task.getDependencyLevel(allTask, {level: level.level + 1}));
  //   });

  //   return level.level;
  // }

  public isDependentOf(otherTask: Task, allTask: Task[], cache?: Map<Task, boolean>/*, searchTask?: Task*/) {
    // if (!searchTask) { searchTask = this }
    // console.log('isDependentOf', this.title, otherTask.title)

    cache = cache || new Map<Task, boolean>();
    if (cache.has(this)) {
      return cache.get(this);
    }

    // Dependent 
    let dependentTasks: Array<Task> = [];
    if (otherTask.dependency && otherTask.dependency.ruleSet) {
      dependentTasks = dependentTasks.concat(otherTask.dependency.ruleSet.map(rule =>
        allTask.find(_task => _task.getReference().isEqual(rule.taskRef))
      )).filter((task) => task);
    }

    // console.log(JSON.stringify(dependentTasks.map(task => task.title)));

    if (dependentTasks.length === 0) {
      return false;
    }

    let isDependent = false;
    for (const dependentTask of dependentTasks) {
      if (dependentTask.ref.isEqual(this.ref)) {
        cache.set(otherTask, true);
        return true;
      } else {
        const _isDependent =
          (cache.has(dependentTask))
            ? cache.get(dependentTask)
            : this.isDependentOf(dependentTask, allTask, cache);
        cache.set(dependentTask, _isDependent);

        isDependent = isDependent || _isDependent;
      }
    }

    cache.set(otherTask, isDependent);
    return isDependent;
  }

  // public isDependentOf(otherTask: Task, allTask: Task[], searchTask?: Task) {
  //   if (!searchTask) { searchTask = this }
  //   console.log('isDependentOf', this.title, searchTask)

  //   let dependentTasks: Array<Task> = [];
  //   if (otherTask.dependency && otherTask.dependency.ruleSet) {
  //     dependentTasks = dependentTasks.concat(otherTask.dependency.ruleSet.map(rule =>
  //       allTask.find(_task => _task.getReference().isEqual(rule.taskRef))
  //     )).filter((task) => task);
  //   }

  //   if (dependentTasks.length === 0) {
  //     return false;
  //   }

  //   let isDependent = false;
  //   for (const dependentTask of dependentTasks) {
  //     if (dependentTask.ref.isEqual(searchTask.ref)) {
  //       return true;
  //     } else {
  //       isDependent = isDependent || this.isDependentOf(dependentTask, allTask, searchTask);
  //     }
  //   }

  //   return isDependent;
  // }

  public getDependencyLevel(allTask: Task[], level = 1, cache?: Map<Task, number>, dependencyChain?: Task[]) {
    dependencyChain = (dependencyChain) ? [...dependencyChain, this] : [this];
    cache = cache || new Map<Task, number>();
    if (cache.has(this)) {
      return cache.get(this);
    }

    const hasRepetition = (new Set(dependencyChain)).size !== dependencyChain.length;

    const debugIDs = [];

    if (level > 100 || hasRepetition) {
      console.log('circular depencendy detected: ', this.title, dependencyChain);
      return -999;
    }
    let dependentTasks: Array<Task> = [];
    // const level = {level: 1};
    if (this.dependency && this.dependency.ruleSet) {
      dependentTasks = dependentTasks.concat(this.dependency.ruleSet.map(rule =>
        allTask.find(_task => _task.getReference().isEqual(rule.taskRef))
      )).filter((task) => task);
    }

    if (dependentTasks.length === 0) {
      return level;
    } else {
      const levels: Array<number> = [];
      if (debugIDs.includes(this.id) && debugIDs.includes(dependencyChain[0].id)) {
        console.log(this.title, '===========', dependencyChain.map(task => task.title));
      }
      for (const task of dependentTasks) {
        const _level = (cache.has(task))
          ? cache.get(task)
          : task.getDependencyLevel(allTask, level, cache, dependencyChain);
        levels.push(_level);
        cache.set(task, _level);

        if (debugIDs.includes(this.id) && debugIDs.includes(dependencyChain[0].id)) {
          console.log(dependencyChain[0].title, this.title, 'dep: ', task.title, _level);
        }
      }

      if (debugIDs.includes(this.id) && debugIDs.includes(dependencyChain[0].id)) {
        console.log(this.title, 'LEVEL: ' + Math.max(...levels) + '\n===========');
      }
      // cache.set(this, Math.max(...levels));
      return (Math.max.apply(null, levels) + 1) as number;
      // return Math.max(...levels) + 1;
    }
  }

  public getAllByLevel(allTask: Task[]) {
    const tasksByLevel = new Map<number, Task[]>();
    const cache = new Map<Task, number>();
    allTask.forEach(task => {
      const level = task.getDependencyLevel(allTask, 1, cache);
      const value = [task, ...(tasksByLevel.get(level) || [])];
      // Sort by order number
      value.sort((a, b) => { return Number(a.order) > Number(b.order) ? 1 : -1; });
      tasksByLevel.set(level, value);
    });

    return tasksByLevel;
  }


  public get mediaType() {
    // Find media element in layout
    let media = 'none';
    for (const layoutID in this.layout.items) {
      if (this.layout.items.hasOwnProperty(layoutID)) {
        if ([
          LayoutItem.TYPE_IMAGE,
          LayoutItem.TYPE_IMAGE_360,
          LayoutItem.TYPE_VIDEO,
          LayoutItem.TYPE_VIDEO_360
        ].indexOf(this.layout.items[layoutID].type) >= 0) {
          media = this.layout.items[layoutID].type;
        }
      }
    }

    return media;
  }

  public get inputType(): string {
    let input = 'complete';
    for (const layoutID in this.layout.items) {
      if (this.layout.items.hasOwnProperty(layoutID)) {
        if ([
          LayoutItem.TYPE_INPUT_CHOICE,
          LayoutItem.TYPE_INPUT_TEXT,
          LayoutItem.TYPE_INPUT_PHOTO,
          LayoutItem.TYPE_INPUT_VIDEO
        ].indexOf(this.layout.items[layoutID].type) >= 0) {
          input = this.layout.items[layoutID].type;
        }
      }
    }

    return input;
  }

  public get hasInput() {
    return this.inputType !== 'complete';
  }

  public get hasQuote() {
    return this.layout.getItemByType(LayoutItem.TYPE_QUOTE, false);
  }

  public hasMultiChoiceLayout() {
    return this.layout.getLayoutIDByType(LayoutItem.TYPE_INPUT_CHOICE);
  }

  public hasTextLayout() {
    return this.layout.getLayoutIDByType(LayoutItem.TYPE_TEXT);
  }

  public isDone(players: Array<Player>) {
    let isDone = false;
    players.forEach(player => {
      if (player.responses && player.responses.getByID(this.id) && player.responses.getByID(this.id).done === true) {
        isDone = true;
      }
    });

    return isDone;
  }

  public getArrivalTime(players: Array<Player>, play: Play) {
    let arrival: Date;
    if (!this.dependency || !this.dependency.ruleSet || this.dependency.ruleSet.length === 0) {
      return (play.startedAt) ? play.startedAt.toDate() : play.createdAt.toDate();
    } else {
      if (this.dependency.ruleSet) {
        this.dependency.ruleSet.forEach(rule => {
          players.forEach(player => {
            if (player.responses && player.responses.getByID(rule.taskRef.id) && player.responses.getByID(rule.taskRef.id).done === true) {
              if (!arrival || arrival.getTime() < player.responses.getByID(rule.taskRef.id).createdAt.toDate().getTime()) {
                arrival = player.responses.getByID(rule.taskRef.id).createdAt.toDate();
              }
            }
          });
        });
      }
    }
    return arrival;
  }

  public getResponseTime(player: Player) {
    return player.responses.getByID(this.id).createdAt.toDate();
  }

  public getNewResponse(player: Player, data = {}, options = {}) {
    const response = new Response(data, player, options, this.modelProvider);
    response.id = this.id;
    return response;
  }


  public getStorageReferencePath(category: string, file: File) {
    return this.getDocument().ref.path + '/' + category + '/' + file.name;
  }

  public uploadFile(category: string, file: File, metadata?: any) {
    const reference = this.modelProvider.fsStorage.ref(this.getStorageReferencePath(category, file));
    return { ref: reference, uploadTask: reference.put(file, metadata) };
  }

  public getNewLayoutID() {
    return this.modelProvider.fsDB.createId();
  }

  public get inputTypeIcon(): string {
    switch (this.inputType) {
      case LayoutItem.TYPE_INPUT_TEXT:
        return 'subject';
      case LayoutItem.TYPE_INPUT_PHOTO:
        return 'camera_alt';
      case LayoutItem.TYPE_INPUT_VIDEO:
        return 'videocam';
      case LayoutItem.TYPE_INPUT_CHOICE:
        return 'assignment';
      default:
        return 'play_circle';
        return 'check_circle';
    }
  }

  protected instantiate(path, data, options?: any) {
    // const defaultData = {
    //   title: LocalizedText.empty(this, this.modelProvider)
    // };
    // data = Object.assign(defaultData, data);
    const task = new Task(path, data, options, this.modelProvider);
    // task.dependency = new Dependency(task.dependency);
    return task;
  }

  validate() {
    return ['title', 'roleRef'].every((field) => {
      return this[field] !== "" && this[field] !== null && this[field] !== undefined;
    });
  }

  // public async loadDependentTasks() {
  //   if (this.dependency && this.dependency.rules) {
  //     for (const rule of this.dependency.rules) {
  //       rule.task = await this.findByRef((rule.taskRef as any).path).pipe(take(1)).toPromise();
  //     }
  //     this.dependency.allRulesLoaded.next(true);
  //   }
  // }

  public createByVersion(version: ScriptVersion, data = {}) {
    return this.create(version.getDocument().collection('tasks').ref.path, data);
  }
}
