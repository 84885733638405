import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Play } from 'src/app/core/models/play.model';

@Component({
  selector: 'play-view',
  templateUrl: './play-view-page.component.html',
  styleUrls: ['./play-view-page.component.scss'],
})
export class PlayViewPageComponent implements OnInit {

  public play: Play;

  constructor(
    private route: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this.route.data.subscribe((data: { play: Play }) => {
      this.play = data.play;
    });
  }

}
