<div class="container">
  <div *ngIf="user && post" class="container-fluid">
    <!-- <div class="col-12"> -->
    <div class="row top pb-0 align-items-center">
      <div class="col-auto pl-0 avatar-container">
        <img class="profile" [src]="user?.photoURL" [alt]="user.displayName" />
      </div>
      <div class="col pl-2 pr-0">
        <div>
          <p class="font-bold" class="title my-0">{{title}}</p>
          <p class="text-muted time my-0">
            <small>{{timeString}}</small>
          </p>
        </div>
      </div>
    </div>
    <div class="row message mt-5 mb-3 align-items-center">
      <div class="col col-auto px-0 icon">
        <span>
          <mat-icon>chat</mat-icon>
          <!-- <ion-icon name="checkmark-circle" color="primary"></ion-icon> -->
        </span>
      </div>
      <div class="col pl-0 mt-1 text">
        <p class="my-0 pre">{{message}}</p>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>