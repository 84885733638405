import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GenerateUrlPageRoutingModule } from './generate-url-routing.module';

import { SuperadminGenerateUrlPage } from './generate-url.page';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GenerateUrlPageRoutingModule,
    AppComponentsModule,
    TranslateModule.forChild(),
    // PipesModule,
    // MatIconModule,
    // NgxPopperModule,
    ClipboardModule,
  ],
  declarations: [SuperadminGenerateUrlPage]
})
export class GenerateUrlPageModule {}
