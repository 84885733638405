import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { Workspace } from 'src/app/core/models/workspace.model';
import { Subscription } from 'rxjs';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Play } from 'src/app/core/models/play.model';
import { QueryFn } from '@angular/fire/firestore';

@Component({
  selector: 'app-workspace-analytics',
  templateUrl: './workspace-analytics.component.html',
  styleUrls: ['./workspace-analytics.component.scss'],
})
export class WorkspaceAnalyticsComponent implements OnInit {

  workspace: Workspace;
  plays: Play[];

  subscriptions: Array<Subscription> = [];
  
  constructor(
    public workspaceService: WorkspaceService,
    public modelProvider: ModelProvider
  ) { }

  ngOnInit() {
    const sub = this.workspaceService.activatedWorkspace.subscribe((workspace) => {
      this.workspace = workspace;

      const queryFn:QueryFn = ref => ref.where('workspaceRef', '==', workspace.ref);

      const playSub = this.modelProvider.play.findAllBy(queryFn).subscribe(plays => {
        this.plays = plays;
      });
      this.subscriptions.push(playSub);
    });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
