<div class="preview-container">

  <div class="header">
    <mat-icon class="preview-back-arrow">arrow_back</mat-icon>
  </div>
  <ion-item *ngIf="!task"
            lines="none"
            class="no-task-left">
    <mat-icon>cafe</mat-icon>
    <ion-label><strong>Stay tuned for your next challenge.</strong><br/>Your game partner or facilitator is drafting a response.</ion-label>
  </ion-item>
  <div #contentContainer
       *ngIf="task"
       class="preview-image">
    <player-task-view [task]="task"
                      [player]="player"
                      [tasks]="tasks"
                      [players]="players"
                      [roles]="roles"></player-task-view>
  </div>
</div>
