import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleResolver } from '../core/models/resolvers/role.resolver';
import { TaskResolver } from '../core/models/resolvers/task.resolver';
import { ScriptResolver } from '../script/script.resolver';
import { AuthGuard } from '../auth/auth.guard';
import { VersionResolver } from '../core/models/resolvers/version.resolver';
import { ScriptVersionResolver } from '../script/script-version.resolver';
import { PrivilegeGuard } from '../core/privilege/privilege.guard';
import { PRIVILEGES } from '../core/privilege/privilege-roles';

const routes: Routes = [
  {
    path: 'design/:scriptID/task',
    loadChildren: './task/list/task-list-page.module#TaskListPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.TASK_EDIT},
    resolve: { script: ScriptResolver }
  },
  {
    path: 'design/:scriptID/task-view',
    loadChildren: './task/list/task-list-page.module#TaskListPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.TASK_VIEW},
    resolve: { script: ScriptResolver }
  },
  {
    path: 'design/:scriptID/:versionID/task/:taskID/edit',
    loadChildren: './task/edit/task-edit-page.module#TaskEditPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.TASK_EDIT},
    resolve: {
      version: VersionResolver,
      script: ScriptResolver,
      task: TaskResolver
    }
  },
  {
    path: 'design/:scriptID/:versionID/task/create/role/:roleID',
    loadChildren: './task/edit/task-edit-page.module#TaskEditPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.TASK_EDIT},
    resolve: {
      script: ScriptResolver,
      version: ScriptVersionResolver,
      role: RoleResolver
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [
    ScriptResolver
  ]
})
export class DesignerRoutingModule { }
