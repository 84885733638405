import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OrganizationService } from '../organization.service';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-organization-join',
  templateUrl: './organization-join.component.html',
  styleUrls: ['./organization-join.component.scss'],
})
export class OrganizationJoinComponent{

  processing: string = null;

  @ViewChild("form") form: NgForm;

  @Input()
  organization: {
    title: string,
    imageURL?: string,
    path: string,
    id: string,
  }

  terms: boolean = false;
  @ViewChild('label') label: ElementRef;


  constructor(
    private organizationService: OrganizationService,
    private modelProvider: ModelProvider,
    private modalController: ModalController,
    private platform: Platform
  ) { }

  private linkFunction = (event: Event) => {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    window.open((event.target as any).getAttribute('href'), this.platform.is('android') ? '_system' : '_blank');
  }

  private _links: Array<Element> = [];
  ngAfterViewInit(): void {
    if (this.platform.is('cordova')) {
      this._links = this.label.nativeElement.querySelectorAll('a');
    }

    for (const link of this._links) {
      link.addEventListener('click', this.linkFunction)
    }
  }


  async submit() {
    try {
      this.processing = 'join';

      await this.organizationService.joinToOriganization(
        this.modelProvider.fsDB
          .collection("organizations")
          .doc(this.organization.id).ref
      );
      await this.modalController.dismiss(true);
    } catch (error) {
      
    } finally {
      this.processing = null;
    }
  }
}
