import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'angular2-moment';
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { AnalyticsModule } from 'src/app/core/analytics/analytics.module';
import { ModelsModule } from 'src/app/core/models/models.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { PlayStatusComponent } from './play-status.component';
import { PlayDetailsAnalyticsService } from '../play-details/play-details-analytics';
import { PlayStatusAnalyticsService } from './play-status-analytics.service';
import { RoleSelectModule } from '../role-select/role-select.module';
import { CloudMessagingModule } from 'src/app/core/cloud-messaging/cloud-messaging.module';



@NgModule({
  declarations: [
    PlayStatusComponent
  ],
  exports: [
    PlayStatusComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    AnalyticsModule,
    ModelsModule,
    PipesModule,
    MomentModule,
    NgPipesModule,
    MatIconModule,
    NgxMaskModule.forChild(),
    RoleSelectModule,
    CloudMessagingModule
  ],
  providers: [
    PlayDetailsAnalyticsService,
    PlayStatusAnalyticsService
  ]
})
export class PlayStatusModule { }
