import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { Play } from 'src/app/core/models/play.model';

@Injectable()
export class PlayListPageAnalyticsService {
  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
  }

  setScreen() {
    this.analyticsService.setCurrentScreen('Play list');
  }

  onPlayClick(play: Play) {
    this.analyticsService.event(
      ['Play card clicked'],
      play.title,
      {
          'play-id': play.id,
          'play-title': play.title,
          'play-status': play.status
      }
    );
  }
}
