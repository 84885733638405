import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayLandingComponent } from './play-landing.component';
import { AppComponentsModule } from '../../core/app-components.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PlayLandingAnalyticsService } from './play-landing.analytics';
import { WorkspaceModule } from 'src/app/core/workspace/workspace.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
            declarations: [
              PlayLandingComponent
            ],
            imports: [
              CommonModule,
              AppComponentsModule,
              IonicModule,
              TranslateModule.forChild(),
              FormsModule,
              RouterModule,
              WorkspaceModule,
              MatIconModule
            ],
            exports: [],
            providers: [
              PlayLandingAnalyticsService
            ]
          })
export class PlayLandingModule {}
