import { Workspace, WorkspaceLevel } from './workspace.model';
import { DocumentReference, QueryFn, QueryGroupFn } from '@angular/fire/firestore';
import firebase from "firebase/app"
import { FirestoreField } from '../firebase/firestore-field';
import { Observable } from 'rxjs';
import { User } from './user.model';
import { ModelProvider } from './general/model.provider';
import { FirestoreModel } from '../firebase/firestore-model';
import { first } from 'rxjs/operators';

export class WorkspaceMember extends FirestoreModel<WorkspaceMember> {
  active: boolean;
  level: WorkspaceLevel;
  userRef: DocumentReference;
  joinedAt: firebase.firestore.Timestamp;

  // public documentInstance: FirestoreModel<Workspace>;

  protected rules() {
    return {
      active: {},
      level: {},
      userRef: {},
      joinedAt: {}
    };
  }

  // // tslint:disable-next-line: member-ordering
  // static instantiate(data, documentInstance, options = {}, modelProvider: ModelProvider) {
  //   return new WorkspaceMember(data, documentInstance, options, modelProvider);
  // }

  get workspaceRef() {
    return this.ref.parent.parent;
  }

  private _workspace$: Observable<Workspace>;
  public get workspace$() {
    if(this._workspace$) return this._workspace$;

    this._workspace$ = this.modelProvider.workspace.findByRef(this.workspaceRef);
    return this._workspace$;
  }

  public findAllByWorkspace(workspace: Workspace, query?: QueryFn) {
    if (!query) { query = ref => ref; }

    return this.findAllBy(ref => query(ref), workspace.ref.collection('wsMembers'));
  }

  public findAllByUser$(user: User | DocumentReference, queryFn?: QueryGroupFn): Observable<Array<WorkspaceMember>> {
    if (!queryFn) { queryFn = ref => ref; }

    const userRef = (user instanceof User) ? user.ref : user;

    const queryRaw = queryFn(
      firebase.firestore().collectionGroup('wsMembers').where('active', '==', true).where('userRef', '==', userRef)
    );

    return this.findAllBy(ref => queryRaw);
  }

  /**
   * User
   */
  private _user$: Observable<User>;
  public get user$(): Observable<User> {
    if (this._user$ !== undefined) { return this._user$; }

    this._user$ = this.modelProvider.user.findByRef(this.userRef);
    return this._user$;
  }

  async changeLevel(level: WorkspaceLevel) {
    // const workspace = (this.documentInstance as FirestoreModel<Workspace>);

    return await this.modelProvider.functions.httpsCallable('wsChangeMemberLevel')({
      level: level,
      memberPath: this.ref.path
    }).pipe().toPromise();

    // this.level = level;

    // const user = await this.user$.pipe(first()).toPromise();
    // user.workspaces[this.workspaceRef.id].level = level;
    // await user.save();
    // await this.save();
    // await workspace.save();
  }

  async deactivateUserAsMember() {
    // this.active = false;

    return await this.modelProvider.functions.httpsCallable('wsActivateDeactivateMember')({
      isActive: false,
      memberPath: this.ref.path
    }).pipe().toPromise();

    // // const workspace = (this.documentInstance as FirestoreModel<Workspace>);
    // const user = await this.user$.pipe(first()).toPromise();
    // user.workspaces[this.workspaceRef.id].active = false;
    // await user.save();
    // await this.save();
  }

  async reactivateUserAsMember() {
    // this.active = true;

    return await this.modelProvider.functions.httpsCallable('wsActivateDeactivateMember')({
      isActive: true,
      memberPath: this.ref.path
    }).pipe().toPromise();

    // const workspace = (this.documentInstance as FirestoreModel<Workspace>);
    // const user = await this.user$.pipe(first()).toPromise();
    // user.workspaces[this.workspaceRef.id].active = true;
    // await user.save();
    // await this.save();
  }

  protected instantiate(path, data, options?: any) {
    return new WorkspaceMember(path, data, options, this.modelProvider);
  }

}
