<ion-content>
  <div class="container">
    <form #form="ngForm" (submit)="submit()">
      <ion-list>
        <div class="form-group">
          <div class="row">
            <div class="col-10">
              <ion-item>
                <ion-label>Topic</ion-label>
                <ion-input [(ngModel)]="topic" name="topic"></ion-input>
                <!-- <input type="text" ([ngModel])="topic" name="topic"> -->
              </ion-item>
            </div>
            <div class="col">
              <ion-button (click)="loadDemo()">Demo</ion-button>
            </div>
          </div>
        </div>
        <ion-item [lines]="'none'">
          <ion-label position="stacked">Learning objectives</ion-label>
          <ion-textarea [(ngModel)]="learningObjectives" name="learningObjectives" [autoGrow]="true"></ion-textarea>
        </ion-item>
        <ion-item [lines]="'none'">
          <ion-label position="stacked">Learning structure</ion-label>
          <ion-textarea [(ngModel)]="learningStructure" name="learningStructure" [autoGrow]="true"></ion-textarea>
        </ion-item>
        <ion-item [lines]="'none'">
          <ion-label position="stacked">Methodology</ion-label>
          <ion-textarea [(ngModel)]="methodology" name="methodology" [autoGrow]="true"></ion-textarea>
        </ion-item>
        <ion-item [lines]="'none'">
          <ion-label position="stacked">About Roles</ion-label>
          <ion-textarea [(ngModel)]="roleInfo" name="roleInfo" [autoGrow]="true"></ion-textarea>
        </ion-item>
        <div class="form-group text-center mt-3">
          <ion-button type="submit" [disabled]="form.invalid || processing">
            <ion-spinner *ngIf="processing"></ion-spinner>
            Generate game
          </ion-button>
        </div>
      </ion-list>
    </form>
    <hr />
    <ng-container *ngIf="taskMap && roleMap">
      <h2>{{version.title}}</h2>
      <p>{{version.description}}</p>
      <h2>Roles</h2>
      <ng-container *ngFor="let roleItem of roleMap | keyvalue">
        {{roleItem.value.title}} ({{roleItem.value.ref.id}})<br />
      </ng-container>
      <h2>Tasks</h2>
      <ng-container *ngFor="let taskItem of taskMap | keyvalue">
        <h4>{{taskItem.value.roleRef.id}}: {{taskItem.value.title}}</h4>
        <p>
          {{taskItem.value.layout.text.text}}<br /><br />
          <i>[{{taskItem.value.inputType}}]</i>
        </p>
      </ng-container>

      <script-simulator #simulator [scriptVersion]="version" [roles]="roles" [tasks]="tasks"></script-simulator>
      <ul *ngIf="roles" class="roles row row-eq-height">
        <div class="d-flex flex-nowrap">
        <li
          *ngFor="let role of roles | facilitatorOrder: false; trackBy: trackById"
          [ngClass]="getColumnSizeByNumOfRoles()"
        >
          <div class="role-column">
            <div class="title rtl-text">
              <span
                class="circle mr-1"
                [style.backgroundColor]="role.color"
              ></span
              >{{ role.title }}
            </div>
            <div
              *ngIf="simulator.isActive && simulator.tasksByRoles[role.id]"
              class="simulator"
            >
            <!-- {{role.id}} -->
            <!-- {{simulator.tasksByRoles[role.id].activeTask.title}} -->
              <task-preview
                class="simulator"
                [task]="simulator.tasksByRoles[role.id].activeTask"
                [player]="simulator.tasksByRoles[role.id].player"
                [tasks]="tasks"
                [roles]="roles"
                [players]="simulator.players"
              >
              </task-preview>
            </div>
          </div>
        </li>
        </div>
      </ul>
    </ng-container>
  </div>
</ion-content>