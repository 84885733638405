import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
// import { Camera, CameraOptions, DestinationType, EncodingType, MediaType } from '@ionic-native/camera';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { Platform } from '@ionic/angular';
import { CameraMobileAnalyticsService } from './camera-mobile.analytics';

/**
 * Generated class for the CameraMobileComponent component.
 *
 * TODO:
 * - Resize camera image
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'camera-mobile',
  templateUrl: './camera-mobile.html',
  styleUrls: ['./camera-mobile.scss'],
  providers: [
    Camera,
    CameraMobileAnalyticsService
  ]
})
export class CameraMobileComponent {
  @ViewChild('captureCanvas') captureCanvas;
  // tslint:disable-next-line: no-output-rename
  @Output('file') file: EventEmitter<File | Blob> = new EventEmitter<File | Blob>();

  public status = 'inactive';

  public previewURL: string;

  constructor(
    private camera: Camera,
    private platform: Platform,
    private analytics: CameraMobileAnalyticsService
  ) {
  }

  public cancel() {
    this.status = 'inactive';
    this.file.emit(null);
  }

  public retake() {
    this.analytics.onRetakeClick();
    this.cancel();
    this.capture();
  }

  public capture(isAlbum = false) {
    this.analytics.onCaptureClick();
    let self = this;

    function dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(',')[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    }

    const options: CameraOptions = {
      quality: 85,
      sourceType: isAlbum ? this.camera.PictureSourceType.PHOTOLIBRARY : this.camera.PictureSourceType.CAMERA,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      targetWidth: 1600,
      targetHeight: 1600,
      correctOrientation: true
    }

    this.camera.getPicture(options).then((imageData) => {
      // this.analytics.event("Photo capture - Capture clicked");
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = 'data:image/jpeg;base64,' + imageData;
      self.previewURL = base64Image;
      let blob = dataURItoBlob(base64Image);
      (blob as any).name = 'capture.jpg';
      // let file = new File([blob], 'capture.jpg');

      // var img = new Image();
      // img.onload = function onload() {
      //   let canvas: any = self.captureCanvas.nativeElement
      //   let context = self.captureCanvas.nativeElement.getContext('2d');
      //   canvas.width = img.width;
      //   canvas.height = img.height;

      //   // console.log(img.width, img.height);

      //   // var ctx = canvas.getContext('2d');
      //   context.drawImage(img, 0, 0, canvas.width, canvas.height);

      //   self.status = 'success';

      //   // canvas.toBlob(onsuccess);
      // };

      // img.src = base64Image;

      self.status = 'success';
      // const file = new File([blob], 'capture.jpg', { type: 'image/jpeg', lastModified: Date.now() });
      self.file.emit(blob);
      // let file = new File([blob], 'capture.jpg', { type: 'image/jpeg', lastModified: Date.now() });
      // var url = window.URL.createObjectURL(blob);
      // self.blobImageUrl = self._sanitizer.bypassSecurityTrustUrl(url);
      // self.setResponseImage(blob);
    }, (err) => {
      // Handle error
    });
  }

}
