import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { PlayResolver } from 'src/app/core/models/resolvers/play.resolver';
import { PrivilegeGuard } from '../core/privilege/privilege.guard';
import { PRIVILEGES } from '../core/privilege/privilege-roles';
import { ScriptResolver } from '../script/script.resolver';

const routes: Routes = [
  {
    path: 'organize',
    loadChildren: './list/play-list-page.module#PlayListPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'}
  },
  {
    path: 'organize/:playID/edit',
    loadChildren: './edit/play-edit-page.module#PlayEditPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { play: PlayResolver }
  },
  {
    path: 'organize/create/:scriptID',
    loadChildren: './edit/play-edit-page.module#PlayEditPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { script: ScriptResolver }
  },
  {
    path: 'organize/:playID',
    loadChildren: './view/play-page-view.module#PlayViewPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { play: PlayResolver }
  },
  {
    path: 'organize/:playID/live',
    loadChildren: './monitoring/monitoring.module#MonitoringModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.ORGANIZE_PAGE_VIEW, redirectTo: 'play-landing'},
    resolve: { play: PlayResolver }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [
    PlayResolver
  ]
})
export class PlayRoutingModule { }
