import { Pipe, PipeTransform } from '@angular/core';
import { Player } from '../../core/models/player.model';
import { Task } from '../../core/models/task.model';

/**
 * Generated class for the TaskStatusPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'taskStatus',
  pure: false
})
export class TaskStatusPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(tasks: Array<Task>, members: Array<Player>, member: Player, filter: string, ...args): Task[] {
    if (!tasks || !members || !member || !filter) {
      return tasks;
    }

    const newTasks = [];

    switch (filter) {
      case 'active':
        tasks.forEach(task => {
          if (task.isResolved(members) && !task.isDone(members) && task.roleRef.id === member.roleRef.id) {
            newTasks.push(task);
          }
        });
        break;
      case 'completed':
        // var newTasks = [];
        tasks.forEach(task => {
          const response = member.getResponse(task);
          if (response && response.done && task.roleRef.id === member.roleRef.id) {
            newTasks.push(task);
          }
        });
        return newTasks;
        break;
      default:
        tasks.forEach(task => {
          if (task.role.id === member.role.id) {
            newTasks.push(task);
          }
        });
        break;
    }

    return newTasks;
  }
}
