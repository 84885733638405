<mat-icon (click)="onCancel('icon')" class="modal-close-icon" slot="start">close</mat-icon>

<div class="modal-content ion-padding">
  <h2 class="modal-title">{{'manage-teams/invite/title' | translate}}</h2>

  <form #inviteForm="ngForm" (ngSubmit)="onSubmit(inviteForm)">

    <div class="form-group">
      <div class="input-text-wrapper">
        <input type="email" name="mail" #mail="ngModel" [(ngModel)]="email"
          [placeholder]="'manage-teams/invite/email' | translate" email required />
        <div *ngIf="mail.touched" class="alert invalid-field pl-0">
          <span *ngIf="mail.errors?.required">
            {{'task-edit/form/global/required' | translate}}
          </span>
          <span *ngIf="mail.errors?.email">
            {{'task-edit/form/global/invalid-email' | translate}}
          </span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="level" class="label-with-popper">
        <span>{{'manage-teams/invite/invite-as' | translate}}</span>

        <ion-icon name="help-circle" class="ml-2" [popper]="organizerInviteAsInfoTextPopper" [popperTrigger]="'hover'"
          [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'" popperAppendTo="body"></ion-icon>
        <popper-content popperApplyClass="popover-color" #organizerInviteAsInfoTextPopper>
          <p class="mt-0">
            <b>{{'organization/members/role/owner' | translate }}</b><br />
            <span [innerHTML]="'organization/members/role/owner/info' | translate"></span>
          </p>
          <p class="mt-0">
            <b>{{'organization/members/role/admin' | translate }}</b><br />
            <span [innerHTML]="'organization/members/role/admin/info' | translate"></span>
          </p>
          <p class="mt-0">
            <b>{{'organization/members/role/billing' | translate }}</b><br />
            <span [innerHTML]="'organization/members/role/billing/info' | translate"></span>
          </p>
        </popper-content>
      </label>

      <select class="form-control" id="level" name="level" [(ngModel)]="memberRoleLevel">
        <option [value]="'owner'">{{'organization/members/role/owner' | translate }}</option>
        <option [value]="'admin'">{{'organization/members/role/admin' | translate }}</option>
        <option [value]="'billing'">{{'organization/members/role/billing' | translate }}</option>
      </select>
      <!-- <input [(ngModel)]="organizationName" name="organizationName" type="text" class="col-sm-8 px-0" /> -->
    </div>

    <div *ngIf="isSuperadmin" class="form-check ml-0 mb-3">
      <input [(ngModel)]="isSendEmail" name="sendEmail" type="checkbox" class="form-check-input" id="sendEmail">
      <label class="note form-check-label"
        for="sendEmail">{{'manage-teams/invite/sendEmail/label' | translate}}</label>
    </div>

    <ion-footer mode="ios">
      <div class="text-right">
        <ion-button (click)="onCancel('button')" class="mr-2" color="secondary" shape="round">
          {{'general/cancel' | translate}}
        </ion-button>

        <ion-button type="submit" color="primary" [disabled]="processing || inviteForm.invalid" shape="round">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          {{'manage-teams/invite/send-btn' | translate}}
        </ion-button>
      </div>
    </ion-footer>
  </form>

</div>