import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { Play } from 'src/app/core/models/play.model';
import { Player } from 'src/app/core/models/player.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Task } from 'src/app/core/models/task.model';
import { DocumentReference } from '@angular/fire/firestore';

@Injectable()
export class TaskViewComponentAnalyticsService {
  protected context: 'simulator' | 'task-view' | 'task-editor';

  constructor(
    @SkipSelf() @Optional() protected analyticsService: AnalyticsProvider,
    protected translateService: TranslateService
  ) {
    try {
      switch (this.analyticsService.currentScreen) {
        case 'Task list':
          this.context = 'simulator';
          break;
        case 'Task editor':
          this.context = 'task-editor';
          break;
        default:
          this.context = 'task-view';
          break;
      }      
    } catch (error) {
      
    }

  }

  onSubmitClick(player: Player) {
    if (this.context !== 'task-view') { return; }

    const additionalParams: any = {};
    const isFirst = (player.responses && player.responses.items && Object.keys(player.responses.items).length === 1);
    if (isFirst) {
      additionalParams.isFirst = true;
    }

    this.analyticsService.event('Submit clicked', null, { isFirst: isFirst, 'player-progress': Math.round(player.progress * 100) });
  }

}
