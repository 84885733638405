import { Component, OnInit, Input } from '@angular/core';
import { Script } from 'src/app/core/models/script.model';
import { ModalController } from '@ionic/angular';
import firebase from "firebase/app"
import { TranslateService } from '@ngx-translate/core';
import { PublishModalAnalyticsService } from './publish-modal-analytics.service';
import { OrganizationService } from 'src/app/organization/organization.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-publish-modal',
  templateUrl: './publish-modal.component.html',
  styleUrls: ['./publish-modal.component.scss'],
})
export class PublishModalComponent implements OnInit {

  @Input() script: Script;
  public isPublic: boolean;

  public isPublishing = false;

  autoJoinEnabled: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private translateService: TranslateService,
    private analytics: PublishModalAnalyticsService,
    public organizationService: OrganizationService
  ) { }

  ngOnInit() {
    this.isPublic = this.script.isPublished;

    this.autoJoinEnabled = (this.script.public && this.script.public.autoJoinEnabled) || false;
  }

  public onSelect(event: CustomEvent<{ value: boolean }>) {
    this.analytics.createPublishTypeSelectedEvent(event.detail.value);
  }

  public async onSave() {
    const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();

    if (!['active', 'trialing'].includes(organization.plan.status)) {
      await this.modalCtrl.dismiss({ action: 'canceled' });
      await this.organizationService.showWarning(this.translateService.instant('publish-settings/page-title'));
      return false;
    }
    

    this.analytics.createSaveButtonClickedEvent(this.isPublic);
    this.isPublishing = true;
    if (this.isPublic) {
      // console.log(!this.script.isPublished, await this.script.isPublicDiffers());
      if (!this.script.isPublished || await this.script.isPublicDiffers()) {
        await this.script.publish();
      }
    } else {
      if (this.script.isPublished) {
        await this.script.unpublish();
      }
    }

    if (this.script.public && this.script.public.autoJoinEnabled !== this.autoJoinEnabled) {
      this.script.public.autoJoinEnabled = this.autoJoinEnabled;
      await this.script.save();
    }

    this.isPublishing = false;
    this.modalCtrl.dismiss({ action: 'saved' });
  }

  public onCancel() {
    this.analytics.createCancelButtonClickedEvent();
    this.script.resetToStored();
    this.modalCtrl.dismiss({ action: 'canceled' });
  }

}
