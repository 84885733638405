import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ContentGeneratorDemoComponent } from './content-generator-demo/content-generator-demo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from '../auth/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { SimulatorModule } from '../designer/simulator/simulator.module';
import { TaskPreviewModule } from '../designer/task/preview/task-preview.module';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from '../core/pipes/pipes.module';
import { AnalyticsModule } from '../core/analytics/analytics.module';
import { TaskViewModule } from '../playing/play/task-view/task-view/task-view.module';

const routes: Routes = [
  {
    path: 'ai/create-game',
    component: ContentGeneratorDemoComponent,
    canActivate: [AuthGuard]
  },
]


@NgModule({
  declarations: [
    ContentGeneratorDemoComponent
  ],
  exports: [
    ContentGeneratorDemoComponent,
    // RouterModule
  ],
  imports: [
    // AnalyticsModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TaskPreviewModule,
    SimulatorModule,
    NgPipesModule,
    PipesModule,
    TaskViewModule
  ],
})
export class AiModule { }
