import {
  Component,
  OnInit,
  AfterContentInit,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  HostBinding,
  ElementRef,
} from "@angular/core";
import { Play } from "src/app/core/models/play.model";
import {
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { ScriptVersion } from "src/app/core/models/script-version.model";
import { Role } from "src/app/core/models/role.model";
import { Team } from "src/app/core/models/team.model";
import { Task } from "src/app/core/models/task.model";
import { first, take } from "rxjs/operators";
import { Player } from "src/app/core/models/player.model";
import { TranslateService } from "@ngx-translate/core";
import { ModelProvider } from "src/app/core/models/general/model.provider";
import { Subject, ReplaySubject, Observable, combineLatest } from "rxjs";
import { Script } from "src/app/core/models/script.model";
import { AnalyticsProvider } from "src/app/core/analytics/analytics";
import {
  AlertController,
  ActionSheetController,
  IonTabs,
  IonHeader,
  IonTabBar,
  IonContent,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Location, NgTemplateOutlet } from "@angular/common";
import { PlayService } from "./play.service";
import { PlayStatusComponent } from "./play-status/play-status.component";
import { TeaserModalPage } from "../browse/script-details/teaser-modal/teaser-modal.page";

@Component({
  selector: "app-play",
  templateUrl: "./play.page.html",
  styleUrls: ["./play.page.scss"],
})
export class PlayPage implements OnInit, AfterViewInit, AfterViewChecked {
  // ngAfterViewChecked(): void {
  //   // throw new Error("Method not implemented.");
  // }
  @ViewChild("tabs") tabs: IonTabs;
  @ViewChild("tabBar", { read: ElementRef }) tabBar: ElementRef; //IonTabBar;
  @ViewChild("header") header: NgTemplateOutlet = null;
  @ViewChild("content") content: IonContent;
  @ViewChild("aboveTab") aboveTab: NgTemplateOutlet = null;
  @ViewChild("playStatus") playStatus: PlayStatusComponent;

  public script: Script;
  @HostBinding("attr.dir") direction;

  public title: string;

  public scriptVersion: ScriptVersion;
  public play: Play;
  public player: Player;
  public myRole: Role;
  public myTeam: Team;
  public players: Array<Player> = [];
  public myTeamPlayers: Array<Player> = [];
  public teams: Array<Team>;

  public tasks: Array<Task>;
  public myTasksDone: Array<Task>;
  public myTasksActual: Array<Task>;

  public afterOnInit$ = new ReplaySubject<any>(1);
  public afterViewInit$ = new ReplaySubject<any>(1);
  public afterViewChecked$ = new ReplaySubject<any>(1);

  public showTabbar = true;
  // public scrollTop = 0;
  public sticky = false;
  public stickyCheckEnabled = true;
  public hasTeaser: boolean;
  public screenWidth;

  public hasFeed = false;

  onWindowScroll(e: CustomEvent) {
    if (!this.stickyCheckEnabled) {
      return;
    }
    // this.scrollTop = e.detail.scrollTop;
    const posterHeight = window.outerWidth <= 576 ? 233 : 350;
    this.sticky = e.detail.scrollTop > posterHeight;
  }

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    // private routeSnpapshot: ActivatedRouteSnapshot,
    public translate: TranslateService,
    public modelProvider: ModelProvider,
    // private analytics: AnalyticsProvider,
    public alertController: AlertController,
    public actionSheetController: ActionSheetController,
    private router: Router,
    public playService: PlayService,
    private modalCtrl: ModalController,
    private platform: Platform
  ) {
    this.screenWidth = this.platform.width();
    this.platform.resize.subscribe(async () => {
      this.screenWidth = this.platform.width();
    });
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    // this.afterViewInit$.next(true);
    const posterHeight = window.outerWidth <= 576 ? 233 : 350;

    this.tabs.outlet.activateEvents.subscribe(async (component) => {
      this.afterViewChecked$.subscribe(async () => {
        this.header = component.header;
        this.aboveTab = component.aboveTab;

        if (component.scrollTop !== undefined) {
          // this.tabBar.nativeElement.style.top = (component.scrollTop < posterHeight) ? posterHeight - component.scrollTop + 'px' : 0;
        }
      });
    });
  }

  ngAfterViewChecked(): void {
    this.afterViewChecked$.next(true);
  }

  backClicked() {
    this.location.back();
  }

  public async scrollToTop() {
    await this.content.scrollToTop();
  }

  private initOpenTab() {
    combineLatest([this.playService.play$, this.playService.myPlayer$])
      .pipe(first())
      .subscribe(([play, myPlayer]) => {
        // this.playService.myPlayer$.pipe(first()).subscribe(player => {
        if (
          play.status === Play.STATUS_ONGOING &&
          myPlayer.status === Player.STATUS_ACTIVE
        ) {
          this.router.navigate(["task/list"], { relativeTo: this.route });
        }
        // });
      });
  }

  async ionViewWillEnter() {
    // console.log("ION VIEW Will ENTER", this.route.snapshot.params['playID']);
    const id = this.route.snapshot.params["playID"]; //this.route.paramMap.get('playID');
    if (id) {
      await this.playService.init(id);
      this.direction = (
        await this.playService.scriptVersion$.pipe(first()).toPromise()
      ).direction;
      this.initOpenTab();
      this.hasTeaser = this.playService.scriptVersion.hasTeaser();
      this.hasFeed = this.playService.play?.feed?.postEnabled || false;
    }
  }

  ngOnDestroy() {
    if (this.route.snapshot.params["playID"]) {
      this.playService.destroy();
    }
  }

  async ngOnInit2() {
    this.route.root.firstChild.data.subscribe(
      async (data: { play$: Observable<Play>; play: Play }) => {
        if (!data.play) {
          return;
        }
        this.play = data.play;

        this.initOpenTab();

        this.play.teams$.subscribe((teams) => {
          this.teams = teams;
        });

        this.scriptVersion = await this.play.scriptVersion$
          .pipe(first())
          .toPromise();
        this.tasks = await this.scriptVersion.tasks$.pipe(first()).toPromise();

        this.play.players$.subscribe(async (players) => {
          this.players = players;
          this.player = players
            .filter((player) => player.userRef)
            .find((player) =>
              player.userRef.isEqual(this.modelProvider.user.meReference)
            );

          if (this.player && this.player.teamRef) {
            const teams = await this.play.teams$.pipe(take(1)).toPromise();
            this.myTeam = teams.find((team) =>
              this.player.teamRef.isEqual(team.getReference())
            );
            this.myTeamPlayers = players.filter((player) =>
              this.player.teamRef.isEqual(player.teamRef)
            );
          }

          if (this.player && this.player.roleRef) {
            this.myRole = (
              await this.scriptVersion.roles$.pipe(first()).toPromise()
            ).find((role) => this.player.roleRef.isEqual(role.getReference()));
          }

          if (this.myTeamPlayers && this.player.roleRef) {
            this.myTasksActual = this.tasks
              .filter(
                (task) =>
                  task.isResolved(this.myTeamPlayers) &&
                  !task.isDone(this.myTeamPlayers) &&
                  task.roleRef.isEqual(this.player.roleRef)
              )
              .sort((a: Task, b: Task) => (a.order > b.order ? 1 : -1));

            this.myTasksDone = this.tasks
              .filter(
                (task) =>
                  task.isDone(this.myTeamPlayers) &&
                  task.roleRef.isEqual(this.player.roleRef)
              )
              .sort((a: Task, b: Task) =>
                this.player.responses.getByID(a.id).createdAt.toMillis() <
                this.player.responses.getByID(b.id).createdAt.toMillis()
                  ? 1
                  : -1
              );
          }
          this.afterOnInit$.next(true);
        });
      }
    );
  }

  public async leaveEvent() {
    this.player.status = Player.STATUS_INVITED;
    await this.player.save();
    this.router.navigate(["../../"]);
  }

  async btnPlayTeaser_onClick() {
    if (!this.hasTeaser) return;

    const modal = await this.modalCtrl.create({
      component: TeaserModalPage,
      componentProps: {
        externalVideoUrl: this.playService.scriptVersion.teaser.url,
      },
      cssClass: "join-code-popover auto-height",
    });

    await modal.present();
  }
}
