import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.page.html',
  styleUrls: ['./email-verification.page.scss'],
})
export class EmailVerificationPage implements OnInit {

  userID: string;

  processing = false;

  result: any;

  projectId = environment.firebaseConfig.projectId;

  constructor(
    private fns: AngularFireFunctions,
    public toastController: ToastController
  ) { }

  ngOnInit() {
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000
    });
    await toast.present();
  }

  submit() {
    this.processing = true;

    const callable = this.fns.httpsCallable('verifyUser');
    callable({ userID: this.userID }).subscribe(data => {
      console.log(data);
      if (data.error) {
        this.presentToast('Error in verification');
      } else {
        this.presentToast('Successfully verified: ' + this.userID)
      }
      this.userID = '';
      this.processing = false;
    })
  }

}
