import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { VideoLayoutItem } from 'src/app/core/models/layout-item-video';
import { Response } from 'src/app/core/models/response';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'layout-item-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnDestroy {

  @ViewChild('video') video: ElementRef;

  public sanitizedURL: SafeUrl;
  private _url: string;
  public get url(): string {
    return this._url;
  }
  @Input()
  public set url(value: string) {
    this._url = value;
    this.sanitizedURL = this.sanitize(this.url);
  }

  public isTranscoding: boolean;

  @Input()
  set response(response: Response) {
    this._response = response;
    if (response && response.videoURL) {

      if (response && response.video && response.video.transcoded || this.isLocalFile()) {
        this.url = response.videoURL;
      }

      if (response && response.video && response.video.original && !response.video.transcoded && !this.isLocalFile()) {
        this.isTranscoding = true;
      } else {
        this.isTranscoding = false;
      }
    }
  }
  get response(): Response {
    return this._response;
  }
  private _response: Response;

  @Input()
  set taskItem(taskItem: VideoLayoutItem) {
    this._taskItem = taskItem;
    this.isTranscoding = this._taskItem.isTranscoding;
    this.url = taskItem.url;
  }
  get taskItem(): VideoLayoutItem {
    return this._taskItem;
  }
  private _taskItem: VideoLayoutItem;

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private _urlCache: SafeResourceUrl;
  private _urlCached: string;
  sanitizeResourceUrl(url: string) {
    if (this._urlCache && this._urlCached === url) {
      return this._urlCache;
    }
    // console.log(url);
    this._urlCached = url;
    this._urlCache = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this._urlCache;// this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private isLocalFile() {
    return (this.response && this.response.videoURL && this.response.videoURL.startsWith('blob:'));
  }

  ngOnDestroy(): void {
    // console.log('video - ngOnDestroy');
    // this.stopCamera();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // console.info(this.video.nativeElement);
    // // console.error('onFirstFrameLoaded 0');
    // this.video.nativeElement.addEventListener('dataloaded', (e) => {
    //   console.info('koa1');
    //   console.error('onFirstFrameLoaded 1');
    // })
  }

  loaded = false;

  constructor(
    private sanitizer: DomSanitizer,
    private platform: Platform
  ) {

  }

  onFirstFrameLoaded($event?: any) {
    this.loaded = true;
    // console.info('onFirstFrameLoaded');
    // console.info($event.target);
    // console.error($event.target);
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.video.nativeElement.autoplay = true;
      const playPromise = this.video.nativeElement.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
          this.video.nativeElement.pause();
        }).catch(error => {
          // Auto-play was prevented
          // Show paused UI.
        });
      } else {
        this.video.nativeElement.pause();
      }
    }
  }

}
