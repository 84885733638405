<div *ngIf="response?.mediaType; let mediaType" class="row media position-relative">
  <ng-container *ngIf="response.mediaType === 'image'">
    <img [src]="response.photoURL" alt="" />
  </ng-container>
  <ng-container *ngIf="response.mediaType === 'video'">
    <video [src]="response.videoURL+'#t=0.1'"></video>
    <mat-icon>play_circle_outline</mat-icon>
  </ng-container>
  <ng-container *ngIf="response.mediaType === 'choice'">
    <div class="col">
      <div class="row message mb-3 align-items-center">
        <div class="col col-auto px-0 icon">
          <span>
            <mat-icon>quiz</mat-icon>
          </span>
        </div>
        <div class="col pl-0 mt-1 text">
          <ion-radio-group *ngIf="this.task?.layout?.getItemByType(LayoutItem.TYPE_INPUT_CHOICE, false); let item"
          class="choice mb-0 py-1 px-3" [value]="response.choice">
          <ion-list-header class="p-0">
            <ion-label class="rtl-text mt-0"><i>{{item.question}}</i></ion-label>
          </ion-list-header>
          <ion-item *ngFor="let choice of item.choices | keyvalue | orderBy: 'value.order'" class="p-0 m-0 py-2" mode="md"
            lines="none">
            <ion-label class="m-0 p-0 ion-text-wrap rtl-text">{{choice.value.text}}</ion-label>
            <ion-radio [value]="choice.key" [disabled]="true" checked mode="md" slot="start" color="dark" class="m-0 mr-3 p-0"
              [value]="choice.key" slot="start"></ion-radio>
          </ion-item>
        </ion-radio-group>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="response.mediaType === 'text'">
    <div class="col">
      <div class="row message mb-3 align-items-center">
        <div class="col col-auto px-0 icon">
          <span>
            <mat-icon>chat</mat-icon>
          </span>
        </div>
        <div class="col pl-0 mt-1 text">
          <p class="my-0 pre">{{response.text}}</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>