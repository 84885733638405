import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Task } from '../task.model';
import { ModelProvider } from '../general/model.provider';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TaskResolver implements Resolve<Task> {
    constructor(
        private modelProvider: ModelProvider,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Task> | Observable<never> {
        const id = route.paramMap.get('taskID');
        const scriptID = route.paramMap.get('scriptID');
        const versionID = route.paramMap.get('versionID');

        return Observable.create((observer) => {
            this.modelProvider.task.findByID(id, scriptID, versionID).pipe(first()).subscribe(task => {
                observer.next(task);
                observer.complete();
            }, error => {
                console.error('Task Resolver error', error);
                this.router.navigate(['design']);
            });
        });
    }
}
