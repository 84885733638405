import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { Role } from 'src/app/core/models/role.model';

@Injectable({
  providedIn: 'root'
})
export class PlayStatusAnalyticsService {

  constructor(
    private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) { }

  onSelectRoleOpened() {
    this.analyticsService.event(['Role selection', 'opened'], null, null, 'Play details');
  }

  onRoleDismissed() {
    this.analyticsService.event(
      ['Role selection', 'cancel button clicked'],
      this.translateService.instant('playing/play-details/role-select/cancel')
      , null
      , 'Play details'
    );
  }

  onRoleSelected(role: Role) {
    this.analyticsService.event(
      ['Role selection', 'selected'],
      this.translateService.instant('playing/play-details/role-select/ok'),
      {
        'role-title': role.title,
        'role-id': role.id
      }
      , 'Play details'
    );
  }

  onJoinGameButtonClicked(): void {
    this.analyticsService.event(
      ['join game button clicked'],
      this.translateService.instant('playing/game-details/start-button/label')
      , null
      , 'Play details'
    );
  }

  onInviteClicked() {
    this.analyticsService.event(['invite button clicked'], null, null, 'Play details');
  }
}
