import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { map, mergeMap, tap, first } from 'rxjs/operators';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { ModelProvider } from '../core/models/general/model.provider';
import { Script } from '../core/models/script.model';
import { WorkspaceService } from '../core/workspace/workspace.service';
import { OrganizationService } from '../organization/organization.service';


@Injectable({
    providedIn: 'root',
})
export class ScriptResolver implements Resolve<Script> {
    constructor(
        private modelProvider: ModelProvider,
        private workspaceService: WorkspaceService,
        private organizationService: OrganizationService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Script> | Observable<never> {
        const id = route.paramMap.get('scriptID');

        return Observable.create((observer) => {
            this.modelProvider.script.findByID(id).pipe(first()).subscribe(async script => {
                this.workspaceService.setActiveWorkspaceByRef(script.workspaceRef);
                this.organizationService.setByScript(script);
                observer.next(script);
                observer.complete();
            }, error => {
                console.error(error);
                this.router.navigate(['design']);
            });
        });
    }
}
