import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScriptVersion } from 'src/app/core/models/script-version.model';

export class ScriptForm extends FormGroup {
  constructor(public script: ScriptVersion) {
    super({
            title: new FormControl(script.title, Validators.required),
            description: new FormControl(script.description)
          });
  }

  getControl(control: string): FormControl {
    return this.get(control) as FormControl;
  }
}
