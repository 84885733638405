import { Injectable } from '@angular/core';
import { AnalyticsProvider } from './core/analytics/analytics';
import { AuthService } from './auth/auth.service';
import { Platform } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable()
export class AppAnalyticsService {

  private version: string;

  constructor(
    protected analytics: AnalyticsProvider,
    private auth: AuthService,
    private platform: Platform
  ) {
    // Analytics
    this.analytics.startTracking();

    (async () => {
      this.version = await this.auth.getAppVersion();

      this.auth.afAuth.authState.pipe(first()).subscribe(async user => {
        this.onAppOpened();
      });

      // window.addEventListener('beforeunload', async (e) => {
      //   // this.version = await this.auth.getAppVersion();
      //   console.log('beforeunload');
      //   this.onAppClosed();
      //   // Cancel the event as stated by the standard.
      //   e.preventDefault();
      //   // Chrome requires returnValue to be set.
      //   e.returnValue = '';
      //   return null;
      // }, false);
    })();
  }

  onAppOpened() {
    this.analytics.event('loaded', environment.app, {
      app: environment.app,
      environment: environment.environment,
      version: this.version,
      platforms: this.platform.platforms().join(', ')
    }, 'App');
  }

  onAppClosed() {
    this.analytics.event('closed', environment.app, {
      app: environment.app,
      environment: environment.environment,
      version: this.version,
      platforms: this.platform.platforms().join(', ')
    }, 'App');
  }


}
