import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { AppUpdateComponent } from './app-update.component';
import { ModelsModule } from '../models/models.module';
import { Market } from '@ionic-native/market/ngx';
import { AppUpdateAnalyticsService } from './app-update.analytics';

@NgModule({
            imports: [
              IonicModule,
              CommonModule,
              ModelsModule,
            ],
            exports: [AppUpdateComponent],
            declarations: [
              AppUpdateComponent,
            ],
            providers: [
              Market,
              AppUpdateAnalyticsService
            ],
            entryComponents: [
              AppUpdateComponent
            ]
          })
export class AppUpdateModule {}
