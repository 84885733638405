import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from '../core/analytics/analytics';
import { OrganizationService } from './organization.service';

@Injectable()
export class OrganizationAnalyticsService {

  public prefix = 'Team';

  constructor(
    public analyticsService: AnalyticsProvider,
    private translateService: TranslateService,
    private organizationService: OrganizationService
  ) {

  }

  async openPage(page: string, params = {}) {
    await this.analyticsService.setCurrentScreen(this.prefix + ' / ' + page, {
      ...await this.organizationService.getAnalyticsParams(),
      ...params
    });
  }

  async createWorkspace() {

  }

}
