import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Angulartics2Intercom } from 'angulartics2/intercom';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
// import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import firebase from "firebase/app"
import { Title } from '@angular/platform-browser';
import { User } from '../models/user.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
// import { FirebaseAnalyticsOriginal } from '@ionic-native/firebase-analytics';
// import { ENV } from '@env';

/*
  Generated class for the AnalyticsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable(
  { providedIn: 'root' }
)
export class AnalyticsProvider {

  public currentScreen: string;
  public screenProperties: object;

  private validIntercomEvents = {
    'Game list / page opened': {},
    'Game list / new design button clicked': {
      updateAfter: true
    },
    'Task list / new task icon clicked': {
      updateAfter: true
    },
    'Task list / Simulator / start button clicked': {
      updateAfter: true
    },
    // 'Game list / Publish popup / publish type selected': {
    //   filterParams: {
    //     label: 'Published'
    //   },
    //   actionToLog: 'publishing games'
    // },
    'Game list / Publish popup / save button clicked': {
      filterParams: {
        label: 'Published'
      },
    },
    'Game edit / Publish popup / save button clicked': {
      filterParams: {
        label: 'Published'
      },
    },
    'Game list / Game card / Invite URL button clicked': {},
    'Play list / page opened': {},
    'Game list overview / page opened': {},
    'Game details / page opened': {},
    'Game details / start game button clicked': {},
    'Game details / Role selection / selected': {},
    'App / loaded': {},

    'Sign in / Partner / page opened': {},
    'Sign in / Partner / login button clicked': {},
    'Play details / invite button clicked': {},
    'Join with code / submit button clicked': {},

    'Task list / page opened': {},
    'Task view / page opened': {},
    'Task view / Submit clicked': {},
    'Task view / upload finished': {},
    'Task view / Player finished': {},
    'My profile / logout button clicked': {},

    'Task editor / Quote answer / Input changed': {
      updateAfter: true
    },
    'Task editor / Preconditions / Add button clicked': {
      updateAfter: true
    },

    'Organise play / page opened': {
      updateAfter: true
    },
    'Organise play / Start Game button clicked': {},
    'Play monitoring / page opened': {},

    'Analytics / page opened': {},

    'Team / Pricing / page opened': {},
    'Team / Purchase / page opened': {},
    'Team / Purchase confirmation / page opened': {},
    'Team / Manage / Profile / page opened': {},
    'Team / Manage / Members / page opened': {},
    'Team / Manage / Plan details / page opened': {},
    'Team / Manage / Billing / page opened': {},
    'Team / Create Workspace button clicked': {},

    'Play options / page opened': {
      updateAfter: true
    },
    'Play options / Organized session / create button': {},
    'Play options / Instant session / copy url button clicked': {
      updateAfter: true
    },
    'Play options / Publish / first update button clicked': {},
    'Play options / Publish / update button clicked': {},
    // 'Play options / Random player mode / toggle': {},
  };

  private validFirebaseAnalyticsEvents = {
    'Game list / page opened': {
      actionToLog: 'game_list_view'
    },
    'Game list / new design button clicked': {
      actionToLog: 'game_create'
    },
    'Task list / new task icon clicked': {
      actionToLog: 'task_create'
    },
    'Task list / Simulator / start button clicked': {
      actionToLog: 'simulator_start'
    },
    // 'Game list / Publish popup / publish type selected': {
    //   filterParams: {
    //     label: 'Published'
    //   },
    //   actionToLog: 'publishing games'
    // },
    'Game list / Publish popup / save button clicked': {
      actionToLog: 'game_published',
      filterParams: {
        label: 'Published'
      },
    },
    'Game edit / Publish popup / save button clicked': {
      actionToLog: 'game_published',
      filterParams: {
        label: 'Published'
      },
    },
    'Game list / Game card / Invite URL button clicked': {
      actionToLog: 'game_invite_url_copied'
    },
    'Play list / page opened': {
      actionToLog: 'play_list_view'
    },
    'Game list overview / page opened': {
      actionToLog: 'game_list_view'
    },
    'Game details / page opened': {
      actionToLog: 'game_view'
    },
    'Game details / start game button clicked': {
      actionToLog: 'game_join_init'
    },
    'Game details / Role selection / selected': {
      actionToLog: 'game_join_role_selected'
    },
    'App / loaded': {
      actionToLog: 'app_loaded'
    },
    'Task list / page opened': {
      actionToLog: 'task_list_view'
    },
    'Task view / page opened': {
      actionToLog: 'task_view'
    },
    'Task view / Submit clicked': {
      actionToLog: 'task_submit'
    },
    'Task view / upload finished': {
      actionToLog: 'task_uploaded'
    },
    'Task view / Player finished': {
      actionToLog: 'play_finished'
    },
    'My profile / logout button clicked': {
      actionToLog: 'logout'
    }
  };

  constructor(
    public firebaseAnalytics: FirebaseAnalytics,
    private angulartics2: Angulartics2,
    private mixpanel: Angulartics2Mixpanel,
    private googleAnalytics: Angulartics2GoogleAnalytics,
    private intercom: Angulartics2Intercom,
    // private gtag: Angulartics2GoogleTagManager,
    private platform: Platform,
    private ngFireAnalytics: AngularFireAnalytics,
    private titleService: Title,
    private fsDB: AngularFirestore,
  ) {
    // firebase.analytics()
    // this.ngFireAnalytics.setAnalyticsCollectionEnabled(true);
    // console.log('analytics - constructed', this.currentScreen);
    // this.mixpanel.startTracking();
    // this.googleAnalytics.startTracking();
    // this.intercom.startTracking();
    // angulartics2GoogleAnalytics.startTracking();
    // this.googleAnalytics;
    // this.angulartics2.eventTrack.next({
    //   action: 'test'
    // });
    // this.angulartics2.
  }

  public startTracking() {
    console.debug('analytics - startTracking');
    this.mixpanel.startTracking();
    this.googleAnalytics.startTracking();
    this.intercom.startTracking();
    if (this.platform.is('cordova')) {
      this.firebaseAnalytics.setEnabled(true);
    }
    // this.gtag.startTracking();
    // firebase.analytics();
  }

  public async setCurrentScreen(name: string, properties?: object) {
    this.titleService.setTitle('Gamoteca ' + environment.title + ' - ' + name);
    console.debug('analytics - setCurrentScreen', name);
    this.currentScreen = name;
    this.screenProperties = properties;

    // this.angulartics2.pageTrack.next({
    //   path: name
    // });
    // this.mixpanel.eventTrack(this.currentScreen + 'page opened');

    try {
      if (this.platform.is('cordova')) {
        await this.firebaseAnalytics.setCurrentScreen(name);
      } else {
        await this.ngFireAnalytics.setCurrentScreen(name);
      }
      await this.event(['page opened'], null, null, null, { setScreen: true });
    } catch (error) {
      console.error(error);
    }

  }

  public async error(error: string, code?: number) {
    this.firebaseAnalytics.logEvent('error', {
      message: error,
      code: code
    });
    this.angulartics2.exceptionTrack.next({
      message: error,
      code: code
    });
  }

  public async event(action: string | string[], label?: string, _properties?: Object, category?: string, options?: { setScreen: boolean }) {
    try {
      // this.mixpanel.eventTrack(action, properties);
      action = this.formatAction(action);
      const properties: any = { ...this.screenProperties, ..._properties };
      if (category) {
        properties.category = category;
      } else {
        if (this.currentScreen) {
          properties.category = this.currentScreen;
        }
      }
      if (label) {
        properties.label = label;
      }

      const appNameUppercase = environment.app.charAt(0).toUpperCase() + environment.app.slice(1);
      const actionToLog = appNameUppercase + ' / ' + properties.category + ' / ' + action;

      this.mixpanel.eventTrack(actionToLog, properties);
      this.googleAnalytics.eventTrack(actionToLog, properties);

      // console.log('event ', properties.category + ' / ' + action, properties);
      const intercomParam = this.validIntercomEvents[properties.category + ' / ' + action];
      if (
        intercomParam
        // options && options.setScreen
        // Object.keys(this.validIntercomEvents).find( eventName => eventName === properties.category + ' / ' + action )
      ) {
        const filterParams = intercomParam.filterParams;
        if (filterParams && !Object.entries(filterParams).every((([key, value]) => properties[key] === value))) {
          return;
        }
        // console.log('Intercom ANALYTICS:', intercomParam.actionToLog ? intercomParam.actionToLog : actionToLog, properties);

        this.intercom.eventTrack(intercomParam.actionToLog ? intercomParam.actionToLog : actionToLog, properties);

        if (intercomParam.updateAfter === true) {
          setTimeout(() => {
            (window as any).Intercom('update');
          }, 400);
        }
      }

      // this.angulartics2.eventTrack.next({
      //   action: actionToLog,
      //   properties: properties,
      // });

      const fbParam = this.validFirebaseAnalyticsEvents[properties.category + ' / ' + action];
      if (fbParam) {
        const filterParams = fbParam.filterParams;
        if (filterParams && !Object.entries(filterParams).every((([key, value]) => properties[key] === value))) {
          return;
        }
        // this.ngFireAnalytics.logEvent(fbParam.actionToLog ? fbParam.actionToLog : actionToLog, properties);
        console.debug('firebaseParam.actionToLog:' + environment.app + '_' + (fbParam.actionToLog ? fbParam.actionToLog : actionToLog));

        if (this.platform.is('cordova')) {
          await this.firebaseAnalytics.logEvent(environment.app + '_' + (fbParam.actionToLog ? fbParam.actionToLog : actionToLog), this.objectToFirebaseValue(properties));
        } else {
          await this.ngFireAnalytics.logEvent(environment.app + '_' + (fbParam.actionToLog ? fbParam.actionToLog : actionToLog), this.objectToFirebaseValue(properties));
        }
      }

      // if (this.platform.is('cordova')) {

      //   // this.firebaseAnalytics.logEvent(this.stringToFirebaseValue(action), this.objectToFirebaseValue(properties));
      // } else {

      //   const fbParam = this.validFirebaseAnalyticsEvents[properties.category + ' / ' + action];
      //   // console.debug(properties.category + ' / ' + action);
      //   // console.debug(fbParam);
      //   if (fbParam) {
      //     const filterParams = fbParam.filterParams;
      //     if (filterParams && !Object.entries(filterParams).every((([key, value]) => properties[key] === value))) {
      //       return;
      //     }
      //     this.ngFireAnalytics.logEvent(environment.app + '_' + (fbParam.actionToLog ? fbParam.actionToLog : actionToLog), properties);
      //     // this.ngFireAnalytics.logEvent(fbParam.actionToLog ? fbParam.actionToLog : actionToLog, properties);
      //     console.debug('fbParam.actionToLog:' + fbParam.actionToLog);
      //   }
      //   // await this.ngFireAnalytics.logEvent(this.stringToFirebaseValue(action), this.objectToFirebaseValue(properties));
      // }      
    } catch (error) {
      console.debug('analytics error');
      console.error(error);
    }

  }

  // Event name is too long. The maximum supported length is 40
  // Event name must contain only letters, numbers, or underscores
  private stringToFirebaseValue(name: string) {
    return name.replace(/-/g, '_').replace(/\//g, '').replace(/\s/g, '_').replace(/__/g, '_').replace(/___/g, '_');
  }
  private objectToFirebaseValue(object: Object) {
    const returnObject = {};
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        returnObject[this.stringToFirebaseValue(key)] = value;
      }
    }

    return returnObject;
  }

  public systemEvent(action: string, category: string) {
    const properties: any = { ...this.screenProperties };

    properties.category = 'system/';
    if (category) {
      properties.category = 'system/' + category;
    }
    if (this.currentScreen) {
      properties.screen = this.currentScreen;
    }

    this.angulartics2.eventTrack.next({
      action: action,
      properties: properties,
    });

    this.firebaseAnalytics.logEvent(action, properties);
  }

  // public systemError(action: string, category?: string) {
  //   let properties: any = { ...this.screenProperties };

  //   properties.category = 'system/';
  //   if (category) {
  //     properties.category = 'system/' + category;
  //   }
  //   if (this.currentScreen) {
  //     properties.screen = this.currentScreen;
  //   }

  //   this.angulartics2.eventTrack.next({
  //     action: action,
  //     properties: properties,
  //   });

  //   this.firebaseAnalytics.logEvent(action, properties);
  // }

  public async setUser(firebaseUser: firebase.User) {
    console.log('analytics - setUser', firebaseUser.uid);
    // this.angulartics2.setAlias(user.id);
    // this.mixpanel.setUsername(user.displayName);
    // this.angulartics2.setAlias.next(user.uid);
    const user: User = (await this.fsDB.collection('users').doc(firebaseUser.uid).get().pipe(first()).toPromise()).data() as User;

    this.angulartics2.setUsername.next(firebaseUser.uid);

    this.intercom.setUserProperties({
      name: firebaseUser.displayName,
      user_id: firebaseUser.uid,
      created_at: new Date(firebaseUser.metadata.creationTime).getTime() / 1000,
    });

    this.mixpanel.setUserProperties({
      '$name': user.displayName,
      '$created': firebaseUser.metadata.creationTime
      // '$avatar': user.photoURL
    });

    const capitalize = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (user.roles) {
      for (const role in user.roles) {
        if (Object.prototype.hasOwnProperty.call(user.roles, role)) {
          const roleItem = user.roles[role];
          if((window as any)?.mixpanel?.add_group) {
            (window as any)?.mixpanel?.add_group('Role', capitalize(role));
          }
        }
      }
    }

    // this.angulartics2.setUsername.next(user.uid);
    // firebase.analytics().setUserId(user.uid);
    // await this.ngFireAnalytics.setUserId(user.uid);
    if (this.platform.is('cordova')) {
      await this.firebaseAnalytics.setUserId(firebaseUser.uid);
    } else {
      await this.ngFireAnalytics.setUserId(firebaseUser.uid);
    }

    // (<any>window).mixpanel.identify(user.uid);
    // (<any>window).mixpanel.people.set({
    //   'name': user.name,
    //   '$email': user.email,    // only special properties need the $
    //   '$last_login': new Date(),         // properties can be dates...
    //   // 'gender': 'Male'                    // feel free to define your own properties
    // });
  }

  public async setUserProperties(properties: Object) {
    console.log('analytics - setUserProperties', properties);
    try {
      firebase.analytics().setUserProperties(properties);
      await this.ngFireAnalytics.setUserProperties(properties);
      if (this.platform.is('cordova')) {
        for (const name in properties) {
          if (properties.hasOwnProperty(name)) {
            const value = properties[name];

            await this.firebaseAnalytics.setUserProperty(this.stringToFirebaseValue(name), JSON.stringify(value));
          }
        }
      } else {
        await this.ngFireAnalytics.setUserProperties(this.objectToFirebaseValue(properties));
      }
    } catch (error) {
      console.error(error);
    }
    this.angulartics2.setUserProperties.next(properties);
    if ((properties as any).email) {
      console.log('analytics - setEmail', (properties as any).email);
      this.mixpanel.setUserProperties({
        $email: (properties as any).email
      });
    }
  }

  // public login(user: any) {
  //   this.angulartics2.setUsername.next(user.id);
  // }

  // public register(user: any) {
  //   this.angulartics2.setAlias.next(user.id);
  // }

  public logout() {
    if (this.platform.is('cordova')) {
      this.firebaseAnalytics.setUserId(null);
    } else {
      this.ngFireAnalytics.setUserId(null);
    }

    (<any>window).mixpanel.reset();
    (<any>window).ga('send', 'pageview', { 'sessionControl': 'start' });
    localStorage.removeItem('GA_LOCAL_STORAGE_KEY');
    // (<any>window).mixpanel.cookie.clear();
    // (<any>window).mixpanel.init('GARBAGE');
  }

  formatAction(action: string | string[]) {
    return typeof action === "string" ? action : (action as string[]).join(' / ')
  }

}
