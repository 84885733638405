<ion-searchbar [disabled]="isDataFetching" showClearButton="focus" debounce="700" (ionChange)="handleSearch($event)"></ion-searchbar>
<div *ngIf="isDataFetching; else finalData">
  <div class="spinnerCenter">
    <ion-spinner color="primary"></ion-spinner>
  </div>
</div>
<ng-template #finalData>
  <div *ngIf="activeTab !== 'players'" class="actions text-right mb-3">
    <ion-button shape="round" (click)="openInviteMember()">
      <mat-icon slot="start">add</mat-icon>
      {{'organization/invite-member/invite-button/label' | translate}}
    </ion-button>
  </div>
  <div class="clearfix"></div>
  <div class="no-data" *ngIf="members.length === 0">No records found</div>
  <div *ngIf="members.length > 0">
    <div class="row member-row mt-5" *ngFor="let data of members; let i = index">
      <ng-container *ngIf="data.user">
        <div class="col-md-2 col-lg-1 vcenter member-picture" [ngStyle]="{'opacity': data.member.active ? 1 : 0.4}">
          <img [src]="data.user.photoURL ? data.user.photoURL : '/assets/images/img-placeholder.png'" alt="Member profile picture">
        </div>
        <div class="col-md-4 col-lg-5 vcenter name" [ngStyle]="{'opacity': data.member.active ? 1 : 0.4}">
          <div>{{ data.user.displayName }}</div>
          <div class="user-email" *ngIf="data.user.email">{{data.user.email}}</div>
          <span *ngIf="!data.member.active">
            &nbsp;({{ 'organization-members/remove-from-team/removed' | translate }})
          </span>
        </div>
        <div class="col-md-5 vcenter role" [ngStyle]="{'opacity': data.member.active ? 1 : 0.4}">
          <div class="progress-loader-wrapper">
            <ion-spinner *ngIf="processingMembers.includes(data.member)" name="lines-small"></ion-spinner>
          </div>
          <form *ngIf="true || activeTab !== 'players'">
            <select [ngModel]="data.member.level" [name]="data.member"
              [disabled]="processingMembers.includes(data.member) || !data.member.active"
              (change)="changeLevel(data.member, $event.target.value)" class="form-control">
              <option [value]="'owner'">{{'organization/members/role/owner' | translate }}</option>
              <option [value]="'admin'">{{'organization/members/role/admin' | translate }}</option>
              <option [value]="'billing'">{{'organization/members/role/billing' | translate }}</option>
              <option [value]="'player'">{{'organization/members/role/player' | translate }}</option>
            </select>
          </form>
        </div>
    
        <div class="col-md-1 vcenter member-actions">
          <mat-icon *ngIf="data.member.active" [title]="'organization/members/remove/menu-item' | translate"
          popperAppendTo="body" (click)="showRemoveFromTeamConfirmationDialog(member)">delete</mat-icon>
    
          <!-- <mat-icon *ngIf="member.active" [title]="'manage-teams/remove-from-team/menu-item' | translate"
            popperAppendTo="body" (click)="showRemoveFromTeamConfirmationDialog(member.userRef.id)">checkbox</mat-icon> -->
    
          <mat-icon *ngIf="!data.member.active" [title]="'manage-teams/add-to-team-again/menu-item' | translate"
            (click)="showAddUserToTeamConfirmationDialog(member)">check_box_outline_blank</mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<div class="pagination-controls">
  <span>Rows per page</span>
  <select [(value)]="pageSize" [formControl]="paginationForm.get('rowsPerPage')">
    <option *ngFor="let option of pageSizeOptions" [value]="option">{{ option }}</option>
  </select>
  <span>{{ startRecord }} - {{ endRecord }} of {{ totalRecords }}</span>
  <button [disabled]="isDataFetching || currentPage === 1" (click)="goToPreviousPage()">
    &lt;
  </button>
  <button [disabled]="isDataFetching || currentPage * pageSize >= totalRecords" (click)="goToNextPage()">
    &gt;
  </button>
</div>
