import { ModelProvider } from './general/model.provider';
import { FirestoreField } from '../firebase/firestore-field';
import { LayoutItem, mediatype } from './task-layout-item';
import { Task } from './task.model';
import { TaskLayoutItemFactory } from './task-layout-item-factory';
import { TextLayoutItem } from './layout-item-text';
import { QuoteLayoutItem } from './layout-item-quote';
import { VideoLayoutItem } from './layout-item-video';
import { ImageLayoutItem } from './layout-item-image';
import { InputChoiceLayoutItem } from './layout-item-input-choice';
import { InputTextLayoutItem } from './layout-item-input-text';
import { InputVideoLayoutItem } from './layout-item-input-video';

export class TaskLayout extends FirestoreField {
  public items: {
    [itemID: string]: LayoutItem;
  };

  public task: Task;

  public static instantiate(data: any, model: Task, options: any, modelProvider: ModelProvider): TaskLayout {
    return new TaskLayout(data, model, options, modelProvider);
  }

  protected rules() {
    return {
      // items: {},
    };
  }

  public toData() {
    const data = {};
    // tslint:disable-next-line: forin
    for (const itemID in this.items) {
      data[itemID] = this.items[itemID].toData();
    }

    return data;
  }

  public setFields(data: any) {
    if (!this.items) {
      this.items = {};
    }

    for (const layoutID in data) {
      if (data.hasOwnProperty(layoutID)) {
        // tslint:disable-next-line: no-use-before-declare

        this.items[layoutID] = TaskLayoutItemFactory.createItemByType(data[layoutID],
                                                                      this.documentInstance,
                                                                      this.modelProvider);
      }
    }
  }

  public getItemByType(type: mediatype, createNew?: boolean): LayoutItem {
    return this.items[this.getLayoutIDByType(type, createNew)];
  }

  public get text(): TextLayoutItem {
    return this.getItemByType(LayoutItem.TYPE_TEXT, true) as TextLayoutItem;
  }

  public get image(): ImageLayoutItem {
    return this.getItemByType(LayoutItem.TYPE_IMAGE, true) as ImageLayoutItem;
  }

  public set image(layoutItem: ImageLayoutItem) {
    this.setLayoutByType(LayoutItem.TYPE_IMAGE, layoutItem);
  }

  public get video(): VideoLayoutItem {
    return this.getItemByType(LayoutItem.TYPE_VIDEO, true) as VideoLayoutItem;
  }

  public set video(layoutItem: VideoLayoutItem) {
    this.setLayoutByType(LayoutItem.TYPE_VIDEO, layoutItem);
  }

  public set inputText(layoutItem: InputTextLayoutItem) {
    this.setLayoutByType(LayoutItem.TYPE_INPUT_TEXT, layoutItem);
  }

  public get inputText(): InputTextLayoutItem {
    return this.getItemByType(LayoutItem.TYPE_INPUT_TEXT, true) as InputTextLayoutItem;
  }

  public set inputVideo(layoutItem: InputVideoLayoutItem) {
    this.setLayoutByType(LayoutItem.TYPE_INPUT_VIDEO, layoutItem);
  }

  public get inputVideo(): InputVideoLayoutItem {
    return this.getItemByType(LayoutItem.TYPE_INPUT_VIDEO, true) as InputVideoLayoutItem;
  }


  public get choice(): InputChoiceLayoutItem {
    return this.getItemByType(LayoutItem.TYPE_INPUT_CHOICE, true) as InputChoiceLayoutItem;
  }

  public set choice(layoutItem: InputChoiceLayoutItem) {
    this.setLayoutByType(LayoutItem.TYPE_INPUT_CHOICE, layoutItem);
  }

  public get quote(): QuoteLayoutItem {
    return this.getItemByType(LayoutItem.TYPE_QUOTE, true) as QuoteLayoutItem;
  }

  public set quote(layoutItem: QuoteLayoutItem) {
    this.setLayoutByType(LayoutItem.TYPE_QUOTE, layoutItem);
  }

  public removeItemByType(type: mediatype | mediatype[]) {
    if (Array.isArray(type)) {
      type.forEach(type => {
        delete this.items[this.getLayoutIDByType(type)];
      });
    }

    if (typeof type === 'string') {
      delete this.items[this.getLayoutIDByType(type)];
    }
  }

  public setLayoutByType(type: mediatype, item: LayoutItem) {
    this.items[this.getLayoutIDByType(type, true)] = item;
  }

  public getLayoutIDByType(type: mediatype, createNew?: boolean): string {
    for (const layoutID in this.items) {
      if (this.items.hasOwnProperty(layoutID)) {
        const item = this.items[layoutID];
        if (item.type === type) {
          return layoutID;
        }
      }
    }
    if (createNew) {
      const id = this.modelProvider.fsDB.createId();
      const item =TaskLayoutItemFactory.createItemByType({type: type, properties: {}}, this.documentInstance, this.modelProvider);
      this.items[id] = item;
      return this.getLayoutIDByType(type);
    }
    {
      return null;
    }
  }
}
