import { Component, OnInit, Input } from '@angular/core';
import { Player } from 'src/app/core/models/player.model';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'member-chip',
  templateUrl: './member-chip.component.html',
  styleUrls: ['./member-chip.component.scss'],
})
export class MemberChipComponent implements OnInit {

  @Input() player: Player;

  @Input() user: User;

  // public defaultImageURL: string = 'https://firebasestorage.googleapis.com/v0/b/gamoteca-161414.appspot.com/o/users%2Ffacilitator%2Favatar.png?alt=media&token=5265ea4d-525a-4212-873c-96aa13c9b649';
  
  constructor() { }

  ngOnInit() {
    if (this.player) {
      this.player.user$.subscribe(user => {
        this.user = user;
      })
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }

}
