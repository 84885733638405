<app-header active="superadmin"></app-header>

<ion-content>
  <content-container class="py-5" [back-button]="'/superadmin/dashboard'" pageTitle="Email verification">
    <div class="row">
      <form (submit)="submit()" #form="ngForm" class="col-sm-4" autocomplete="off">
        <div class="form-group">
          <!-- <label for="userID">User ID
            <a target="_blank" href="https://console.firebase.google.com/project/gamoteca-161414/authentication/users">Find user ID</a>
          </label> -->
          <label for="userID" class="label-with-popper">
            <span>User ID</span>
            <ion-icon name="help-circle" class="ml-1" [popper]="userIDPopper" [popperTrigger]="'hover'"
              [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
            <popper-content popperApplyClass="popover-color" #userIDPopper>
              You can find user ID by email address <a target="_blank" href="https://console.firebase.google.com/project/{{projectId}}/authentication/users">here</a>
              <!-- <ion-button shape="round" target="_blank" href="https://console.firebase.google.com/project/gamoteca-161414/authentication/users" color="secondary" >Find User ID</ion-button> -->
            </popper-content>
          </label>

          <input #formUserID="ngModel" [(ngModel)]="userID" required type="text" name="userID" id="userID">
          <div *ngIf="formUserID.touched" class="alert invalid-field">
            <span *ngIf="formUserID.errors?.required">
              User ID is required
            </span>
            <span *ngIf="formUserID.errors?.email">
              
            </span>
          </div>
        </div>
        <div class="form-group">
          
          <ion-button shape="round" color="primary" type="submit" [disabled]="processing">
            <ion-spinner *ngIf="processing"></ion-spinner>
            Verify User
          </ion-button>
        </div>
      </form>
    </div>

  </content-container>
</ion-content>