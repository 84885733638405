import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { PrivilegeService } from "./privilege.service";
import { environment } from "src/environments/environment";
import { WorkspaceService } from "../workspace/workspace.service";
import { first } from "rxjs/operators";
import { ModalController } from "@ionic/angular";
import { OrganizationCreateModalComponent } from "src/app/organization/organization-create-modal/organization-create-modal.component";
import { AuthService } from "src/app/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class PrivilegeGuard implements CanActivate {
  public redirectUrl: string = null;
  constructor(
    private privilegeService: PrivilegeService,
    private router: Router,
    private workspaceService: WorkspaceService,
    private modalController: ModalController,
    private auth: AuthService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.data.privilege) {
      throw new Error(
        `The property "privilege" is missing from the router data when the user try to navigate to URL:"${state.url}"`
      );
    }
    this.router.routerState.root.queryParams.subscribe(async (params) => {
      if (params && params.redirectUrl) {
        this.redirectUrl = params.redirectUrl;
      }
    });
    if (environment.app === "designer") {
      const hasWorkspace = await this.workspaceService
        .hasDesignerWorkspace$()
        .pipe(first())
        .toPromise();
      if (this.auth.authenticated && !hasWorkspace && !this.redirectUrl) {
        this.auth.user$.pipe(first()).subscribe(async (user) => {
          if (user) {
            const modal = await this.modalController.create({
              component: OrganizationCreateModalComponent,
              componentProps: {
                email: user.email,
              },
              cssClass: ["auto-height", "sm-modal"],
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (!data || data.action === "cancel" || data.action === "failure") {
              return false;
            }
          }
        });
      }
      const hasAccess = await this.privilegeService.hasAccess(
        route.data.privilege
      );
      if (!hasAccess) {
        const redirectURL = route.data.redirectTo
          ? route.data.redirectTo
          : environment.homeURL;
        this.router.navigateByUrl(redirectURL);
        return false;
      } else {
        return true;
      }
    }
  }
}
