import { LayoutItem } from './task-layout-item';

export class TextLayoutItem extends LayoutItem {
  public type: string = LayoutItem.TYPE_TEXT;
  public order = 5;

  public properties: {
    languages?: {
      [languageID: string]: string;
    },
    format: 'plain' | 'html';
  };

  public get format() {
    return (this.properties && this.properties.format) || (this.isHTML(this.text) ? 'html' : 'plain');
  }

  public get text() {
    return this.modelProvider.localize.getText(this.properties);
  }

  public set text(text: string) {
    this.properties = { ...this.properties, ...this.modelProvider.localize.setText(this.properties, text) };
    if (this.properties.format || this.isHTML(text)) {
      this.properties.format = this.isHTML(text) ? 'html' : 'plain';
    }
  }

  private isHTML(str: string) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }
}
