import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ContentContainerComponent } from './content-container/content-container.component';
import { HeaderComponent } from './header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { FireStoreUploadDirective } from './file-upload/fire-store-upload.directive';
import { ModifiedDateComponent } from './date/modified-date.component';
import { NgxPopperModule } from 'ngx-popper';
import { AnalyticsModule } from './analytics/analytics.module';
import { TeamSidebarModule } from './workspace/team-sidebar/team-sidebar.module';
import { ContextMenuModule } from './context-menu/context-menu.module';
import { PrivilegeModule } from './privilege/privilege.module';
import { MatIconModule } from '@angular/material/icon';
import { InlineEditorComponent } from './inline-editor/inline-editor.component';
import { FormsModule } from '@angular/forms';
import { IonIconTitleModule } from './ion-icon-title/ion-icon-title.module';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
            imports: [
              CommonModule,
              RouterModule,
              IonicModule,
              TranslateModule.forChild(),
              FormsModule,
              NgxPopperModule,
              AnalyticsModule,
              TeamSidebarModule,
              ContextMenuModule,
              PrivilegeModule,
              MatIconModule,
              IonIconTitleModule,
              PipesModule
            ],
            exports: [
              ContentContainerComponent,
              HeaderComponent,
              FireStoreUploadDirective,
              ModifiedDateComponent,
              TeamSidebarModule,
              ContextMenuModule,
              InlineEditorComponent
            ],
            declarations: [
              ContentContainerComponent,
              HeaderComponent,
              FireStoreUploadDirective,
              ModifiedDateComponent,
              InlineEditorComponent
            ]
          })
export class AppComponentsModule {}
