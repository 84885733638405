import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ScriptViewPageComponent } from './script-view-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { PlayComponentsModule } from '../../event/play-components.module';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { LocalizeModule } from 'src/app/core/localize/localize.module';
import { ScripViewAnalyticsService } from './script-view-analytics-service';
import { ScriptCardAnalyticsService } from '../list/script-card/script-card-analytics-service';
import { TeaserUrlEditorModule } from './script-designer-url-editor/teaser-url-editor.module';
import { ScriptContextMenuModule } from '../script-context-menu/script-context-menu.module';
import { WorkspaceModule } from 'src/app/core/workspace/workspace.module';
import { MatIconModule } from '@angular/material/icon';
import { PlayModalModule } from '../play-modal/play-modal.module';
import { SafePipeModule } from 'safe-pipe';
import { UseTemplateModalModule } from '../use-template-modal/use-template-modal.module';

const routes: Routes = [
  {
    path: '',
    component: ScriptViewPageComponent
  }
];

@NgModule({
            imports: [
              CommonModule,
              FormsModule,
              IonicModule,
              RouterModule.forChild(routes),
              TranslateModule.forChild(),
              PlayComponentsModule,
              AppComponentsModule,
              PipesModule,
              ReactiveFormsModule,
              LocalizeModule,
              TeaserUrlEditorModule,
              ScriptContextMenuModule,
              WorkspaceModule,//.forChild()
              MatIconModule,
              PlayModalModule,
              UseTemplateModalModule,
              SafePipeModule
            ],
            declarations: [
              ScriptViewPageComponent,
            ],
            providers: [
              ScripViewAnalyticsService,
              {
                provide: ScriptCardAnalyticsService,
                useExisting: ScripViewAnalyticsService
              }
            ]
          })
export class ScriptViewPageModule {}
