<app-header active="superadmin"></app-header>

<ion-content>
  <div class="container mt-5">
    <form>
      <div class="filter row mb-5">
        <div class="col-4 pr-0">
          <div class="row">
            <div class="col pr-0">
              <ion-item>
                <ion-datetime [value]="(dataFilter$ | async).start.toISOString()" (ionChange)="setStartDate($event)"
                  displayFormat="MM/DD/YY" placeholder="From"></ion-datetime>
              </ion-item>
            </div>
            <div class="col px-0">
              <ion-item>
                <ion-datetime [value]="(dataFilter$ | async).end.toISOString()" (ionChange)="setEndDate($event)"
                  displayFormat="MM/DD/YY" placeholder="To"></ion-datetime>
              </ion-item>
            </div>
          </div>
        </div>
        <div class="col-3 px-0">
          <ion-item>
            <ion-select [value]="(viewFilter$ | async).game" (ionChange)="setGameFilter($event)">
              <ion-select-option [value]="null">Any Game</ion-select-option>
              <ion-select-option *ngFor="let script of scripts" [value]="script">{{(script.draft$ | async)?.title}}</ion-select-option>
              <!-- <ion-select-option value="Single-player">Single-Player</ion-select-option> -->
            </ion-select>
          </ion-item>
        </div>
        <div class="col-2 px-0">
          <ion-item>
            <ion-select [value]="null" (ionChange)="setTypeFilter($event)">
              <ion-select-option [value]="null">Any type</ion-select-option>
              <ion-select-option value="Random">Random</ion-select-option>
              <ion-select-option value="Invite">Invite</ion-select-option>
              <!-- <ion-select-option value="Single-player">Single-Player</ion-select-option> -->
            </ion-select>
          </ion-item>
        </div>
        <div class="col-2 pl-0">
          <ion-item>
            <ion-select [value]="null" (ionChange)="setStatusFilter($event)">
              <ion-select-option [value]="null">Any status</ion-select-option>
              <ion-select-option value="pre-start">Pre Start</ion-select-option>
              <ion-select-option value="on-going">Running</ion-select-option>
              <ion-select-option value="finished">Finished</ion-select-option>
            </ion-select>
          </ion-item>
        </div>
      </div>
    </form>
    <div *ngIf="!plays || !scripts" class="text-center my-5">
      <ion-spinner name="lines-small"></ion-spinner>
    </div>
    <ng-container *ngIf="scripts">
      <ng-container *ngFor="let play of plays | slice:0:limit; trackBy: modelProvider.trackById">
        <ng-container *ngIf="getScript(play); let script">
          <superadmin-analytics-session-item [play]="play" [script]="script"></superadmin-analytics-session-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-container>
    <ion-infinite-scroll *ngIf="!scrollCompleted" threshold="1px" (ionInfinite)="onScroll($event)">
      <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ng-container>

</ion-content>