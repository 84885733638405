const topic = 'Preventing and reporting sexual harassment: an interactive learning experience';
const objective = `The objective of the interactive learning experience (or game) is to help UNDP staff:
- Increase awareness on sexual harassment in the workplace through practical examples of what is not acceptable 
- Develop positive behaviours to
- - Intervene as a bystander, therefore preventing incidents of sexual harassment from happenning in the first place
- - Learn how to practise a reporting conversation through a virtual role-play exercise, to make staff more aware and comfortable to report incidents when they do occur
- The goal of the learning experience is that it is NOT meant to replace existing detailed guidance on sexual harassment, but rather act as a tool to encourage positive behavioural change. 
`;


const structure = `A scenario outlining the interactions between a harasser, victim and bystander

Level 1: Defining sexual harassment
- Definition
- Four examples of sexual harassment
- Explanation of why each example is not acceptable
Level 2: Bystander intervention
- Learn some strategies to intervene as a bystander
- Reflect on which strategies could apply to the scenario
Level 3: Reporting 
- A bad example of reporting between the bystander and a manager, with opportunity to reflect on what went wrong
- Opportunity to practise the reporting conversation with the bystander as a manager
- Step-by-step guidance on correct responses (with feedback)  
- Practice and record the entire reporting conversation to self assess, and receive feedback
`;

const methodology = `
The learning experience will use:
- Engaging storytelling and a scenario 
- Bite size content to raise awareness on sexual harassment, bystander strategies and reporting. Links will be provided to more detailed guidance on the topics
- Short assessments to engage the learner
- Opportunity to practice a reporting conversation
- Option to share open reflections and receive feedback from a learning partner. 
`;

const role = `
Abel
- Abel is an international project manager working in a UNDP field office. He works full time hours in the office and supervises a team of project officers. Abel socializes outside of work with colleagues at the weekends. He seems to get on well with everyone.

Sara
- Sara is a project officer working on Abel’s team, she works with Abel on a daily basis and has undertaken numerous missions with him. She is well experienced and is well liked by her colleagues.

Sam
- Sam is an international project officer who recently joined Abel and Sara’s team. He also works full time  hours in the office and although new to the team, has developed good professional relationships with his colleagues. 
`;

export const demo = {
    topic: topic,
    objective: objective,
    structure: structure,
    methodology: methodology,
    role: role,
};