import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SimulatorComponent } from './simulator.component';
import { SimulatorAnalyticsService } from './simulator.analytics';
import { AnalyticsModule } from 'src/app/core/analytics/analytics.module';


@NgModule({
  imports: [
    AnalyticsModule.forChild()
  ],
  exports: [
    SimulatorComponent
  ],
  declarations: [
    SimulatorComponent
  ],
  providers: [
    SimulatorAnalyticsService
  ]
})
export class SimulatorModule { }