import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IntercomService } from 'src/app/core/intercom/intercom.service';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.page.html',
  styleUrls: ['./browse.page.scss'],
})
export class BrowsePage implements OnInit {

  constructor(
    private router: Router,
    public intercom: IntercomService
  ) { }

  public showProfile() {
    this.router.navigate(['/playing/profile']);
  }

  ngOnInit() {
  }

}
