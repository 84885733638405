import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Task } from '../task.model';
import { ModelProvider } from '../general/model.provider';
import { ScriptVersion } from '../script-version.model';


@Injectable({
    providedIn: 'root',
})
export class VersionResolver implements Resolve<ScriptVersion> {
    constructor(
        private modelProvider: ModelProvider,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ScriptVersion> | Observable<never> {
        // const id = route.paramMap.get('versionID');
        const scriptID = route.paramMap.get('scriptID');
        const versionID = route.paramMap.get('versionID');

        return Observable.create((observer) => {
            this.modelProvider.scriptVersion.findByID(versionID, scriptID).subscribe(version => {
                observer.next(version);
                observer.complete();
            }, error => {
                console.error('Version Resolver error', error);
                this.router.navigate(['design']);
            });
        });
    }
}
