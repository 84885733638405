<ng-template #loading>
  <div class="text-center py-3">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
<form #form="ngForm" *ngIf="customer && plan; else loading" class="form">
  <div *ngIf="!subscription" class="row">
    <div class="col-sm-12">
      <!-- <div class="alert alert-warning" role="alert"> -->
      <p class="mt-0">
        The subscription for your Gamoteca {{plan.title}} plan has been cancelled due to an overdue or unsuccessful
        payment.</p>
      <p>If you would like to continue benefiting from Gamoteca {{plan.title}}’s features, please create a new Team
        account and
        move your team’s shared Workspaces to the new team account.</p>
      <p><a href="" target="_blank">Click here</a> for an article for how to backup or migrate games and Workspaces.</p>
      <!-- </div> -->
    </div>
  </div>
  <div *ngIf="subscription && price" class="row">
    <div class="col-sm-6">
      <h2>Billing Information</h2>
      <div class="form-group">
        <label for="billingName">Company name</label>
        <input [(ngModel)]="customer.name" name="billingName" required id="billingName" type="text" />
      </div>
      <div class="form-group">
        <label for="address">Address</label>
        <input [(ngModel)]="customer.address.line1" name="address" required id="address" type="text" />
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="country">Country</label>
            <select [(ngModel)]="customer.address.country" (ngModelChange)="calculateTotal()" name="country" required
              name="country" class="form-control w-100">
              <ng-container *ngFor="let countryItem of countries | keyvalue | orderBy: 'value.label'">
                <ng-container *ngIf="countryItem.key; let localeCode">
                  <ng-container *ngIf="countryItem.value; let country">
                    <option [value]="localeCode">{{country.label}}</option>
                  </ng-container>
                </ng-container>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="city">City</label>
            <input [(ngModel)]="customer.address.city" name="city" required id="city" type="text" />
          </div>
        </div>
      </div>
      <div *ngIf="countries[customer.address.country].isEU" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="taxID">{{countries[customer.address.country].vatLabel}}</label>
            <input type="text" [(ngModel)]="taxID" [prefix]="countries[customer.address.country].vatPrefix"
              (ngModelChange)="calculateTotal()" [mask]="countries[customer.address.country].vatMask" name="taxID"
              [attr.required]="countries[customer.address.country].isEU && country !== sourceCountry"
              id="taxID" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="state">State/Province</label>
            <input [(ngModel)]="customer.address.state" name="state" required id="state" type="text" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="zip">Postal code</label>
            <input [(ngModel)]="customer.address.postal_code" name="zip" required id="zip" type="text" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="alert alert-warning" role="alert">
          Changes made to billing details (tax, payment method, address) will be reflected in the next invoice
        </div>
        <!-- <p>Changes made to billing details (tax, payment method, address) will be reflected in the next invoice</p> -->
      </div>
      <div class="form-group mt-4">
        <ion-button type="submit" [disabled]="!form.form.valid || processing || !cardValid" color="primary"
          shape="round" class="float-left" (click)="save()">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          Save
        </ion-button>
        <div class="price-summary float-left ml-4">
          <div class="total">Total: {{total | currency: (currency | uppercase)}}</div>
          <div class="details">
            {{this.price.currencies[this.currency].amount | currency: (currency | uppercase)}} +
            {{vatPercent}}% VAT</div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        <label for="organizationName">Payment method</label>
        <!-- <p *ngIf="paymentMethod === 'existing-card'" class="mt-0">
          <ng-container *ngIf="customer?.invoice_settings?.default_payment_method?.card?.last4; let last4">
            Credit card ({{last4}})
          </ng-container>
        </p> -->
        <!-- <p *ngIf="paymentMethod === 'invoice'" class="mt-0">
          Invoice
        </p> -->
        <ion-radio-group class="choice" [(ngModel)]="paymentMethodID" name="paymentMethodID"
          (ionChange)="onSelectPaymentMethod($event)">
          <ion-item *ngFor="let stripePayementMethod of stripePaymentMethods" class="p-0 m-0 py-2" mode="md"
            lines="none">
            <ion-label class="m-0 p-0 ion-text-wrap">
              Card *{{stripePayementMethod?.card?.last4}}
            </ion-label>
            <ion-radio [value]="stripePayementMethod.id" [checked]="paymentMethodID === stripePayementMethod.id"
              slot="start" color="dark" class="m-0 mr-3 p-0" slot="start">
            </ion-radio>
          </ion-item>
          <!-- <ion-item class="p-0 m-0 py-2" mode="md" lines="none">
            <ion-label class="m-0 p-0 ion-text-wrap">Existing credit card
              <ng-container *ngIf="customer?.invoice_settings?.default_payment_method?.card?.last4; let last4">
                ({{last4}})
              </ng-container>
            </ion-label>
            <ion-radio [value]="'existing-card'" [checked]="paymentMethod === 'existing-card'" slot="start" color="dark"
              class="m-0 mr-3 p-0" slot="start">
            </ion-radio>
          </ion-item> -->
          <ion-item class="p-0 m-0 py-2" mode="md" lines="none">
            <ion-label class="m-0 p-0 ion-text-wrap">New credit card
            </ion-label>
            <ion-radio [value]="'card'" [checked]="paymentMethodID === 'card'" slot="start" color="dark"
              class="m-0 mr-3 p-0" slot="start">
            </ion-radio>
          </ion-item>
          <div *ngIf="paymentMethodID === 'card'" class="form-group">
            <!-- <label>Credit card details</label> -->
            <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions" (change)="cardUpdated($event)">
            </ngx-stripe-card>
          </div>
          <ion-item class="p-0 m-0 py-2" mode="md" lines="none">
            <ion-label class="m-0 p-0 ion-text-wrap">
              Invoice
              <!-- <ion-icon name="help-circle" [popper]="invoiceInfoTextPopper" [popperTrigger]="'hover'"
            [popperPlacement]="'right'" popperAppendTo="body" [popperApplyClass]="'info-text-popper'"></ion-icon>
          <popper-content popperApplyClass="popover-color" #invoiceInfoTextPopper>
            {{ 'play-edit/form/role-selection/info-text' | translate }}
          </popper-content> -->
            </ion-label>
            <ion-radio [value]="'invoice'" slot="start" color="dark" class="m-0 mr-3 p-0" slot="start">
            </ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>
      <!-- <div *ngIf="paymentMethod === 'card'" class="form-group">
        <label>Credit card details</label>
        <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
      </div>
      <div *ngIf="paymentMethod === 'invoice'" class="form-group">
        <label>Send invoices to your accountant</label>
        <input type="email" class="col-sm-8 px-0" />
      </div> -->
    </div>
  </div>
</form>