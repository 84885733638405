import { Pipe, PipeTransform } from '@angular/core';
import { Workspace } from '../models/workspace.model';
import { AuthService } from 'src/app/auth/auth.service';

@Pipe({
        name: 'workspaceOrder'
      })
export class WorkspaceOrderPipe implements PipeTransform {

  constructor(private auth: AuthService) {
  }

  transform(workspaces: Workspace[], ...args: any[]): any {
    return !workspaces ? null : workspaces.sort((a, b) => {

      if (a.isGlobal && !b.isGlobal) {
        return -1;
      } else if (!a.isGlobal && b.isGlobal) {
        return 1;
      }

      if (a.isPersonal && !b.isPersonal) {
        return -1;
      } else if (!a.isPersonal && b.isPersonal) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
