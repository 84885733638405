import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudMessagingService } from './cloud-messaging.service';
import { ModelsModule } from '../models/models.module';
import { AuthModule } from 'src/app/auth/auth.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ModelsModule,
    IonicModule,
  ],
  providers: [
    CloudMessagingService
  ]
})
export class CloudMessagingModule { }
