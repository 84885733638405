<ul class="team">
  <label>
    <inline-editor (ok)="renameTeam(team.title)" [iconTitle]="'play-edit/team-box/rename/label' | translate">
      <input name="teamName" type="text" [(ngModel)]="team.title"
        #teamName="ngModel" />
    </inline-editor>
    <ion-icon name="trash-outline" (click)="showDeleteConfirmationDialog()" ionIconTitle [title]="'play-edit/team-box/delete-confirmation-dialog/delete' | translate" class="ml-2"></ion-icon>
    <!-- <input class="form-group" [value]="team.title"/> -->
    <!-- <ion-icon name="create" (click)="deleteTeam()"></ion-icon> -->
    <!-- {{team.title}} <ion-icon name="trash" (click)="deleteTeam()"></ion-icon> -->
  </label>
  <li *ngIf="false" class="role any">
    <div class="title">
      <span class="circle"></span>{{'play-edit/form/members/any-role/label' | translate}}
    </div>
    <ul class="members">
      <ng-container *ngFor="let player of players">
        <li *ngIf="!player.roleRef">
          <member-chip [player]="player">
            <ng-container *ngIf="!player?.responses || player?.responses?.isEmpty">
              <context-menu-icon icon-name="arrow-dropdown">
                <context-item [label]="'play-edit/form/members/context-menu/set-team/label' | translate">
                  <context-group>
                    <context-item *ngFor="let _team of teams" [label]="_team.title"
                      (click)="moveToTeam(player, _team, $event)"></context-item>
                  </context-group>
                </context-item>
                <context-item [label]="'play-edit/form/members/context-menu/set-role/label' | translate">
                  <context-group>
                    <context-item *ngFor="let role of roles" [label]="role.title"
                      (click)="moveToRole(player, role, $event)"></context-item>
                  </context-group>
                </context-item>
  
                <context-item class="divider highlighted" [label]="'play-edit/form/members/context-menu/delete-player/label' | translate" (click)="remove(player, $event)">
                </context-item>
              </context-menu-icon>
            </ng-container>
          </member-chip>
        </li>
      </ng-container>
    </ul>

  </li>
  <ng-container *ngFor="let role of roles | facilitatorOrder">
    <li *ngIf="role.type !== 'facilitator'" class="role">
      <div class="title">
        <span class="circle" [style.backgroundColor]="role.color"></span>{{role.title}}
      </div>
      <ul class="members">
        <ng-container *ngFor="let player of players">
          <li *ngIf="player.roleRef">
            <member-chip *ngIf="role.ref.isEqual(player.roleRef)" [player]="player">
              <context-menu-icon *ngIf="player?.responses?.isEmpty" icon-name="arrow_drop_down" class="player-context-menu">
                <context-item [label]="'play-edit/form/members/context-menu/set-team/label' | translate">
                  <context-group>
                    <ng-container *ngFor="let _team of teams">
                      <context-item *ngIf="_team.ref.path !== team.ref.path" [label]="_team.title"
                      (click)="moveToTeam(player, _team, $event)"></context-item>
                    </ng-container>
                  </context-group>
                </context-item>
                <context-item [label]="'play-edit/form/members/context-menu/set-role/label' | translate">
                  <context-group>
                    <ng-container *ngFor="let role of roles | skipFacilitator">
                      <context-item *ngIf="role.ref.path !== player?.role?.ref.path" [label]="role.title"
                      (click)="moveToRole(player, role, $event)"></context-item>
                    </ng-container>
                  </context-group>
                </context-item>
  
                <context-item class="divider highlighted" [label]="'play-edit/form/members/context-menu/delete-player/label' | translate" (click)="remove(player, $event)">
                </context-item>
              </context-menu-icon>
            </member-chip>
          </li>
        </ng-container>
  
        <!-- <ng-container
                      *ngFor="let member of play?.members | filterBy: { role: { id: role.id }, teamRef: { id: (team.id)?team.id:false } }">
                      <member-chip [member]="member"></member-chip>
                    </ng-container> -->
        <!-- <ng-container
        *ngFor="let member of play?.members | filterBy: { role: { id: role.id }, teamRef: { id: (team.id)?team.id:false } }">
        <member-chip [member]="member"></member-chip>
      </ng-container> -->
      </ul>
    </li>
  </ng-container>
  <div class="counter" [class.invalid]="(roles | skipFacilitator)?.length !== (players | team: team.ref | skipFacilitator)?.length">
    <ion-icon name="people"></ion-icon>
    <span>{{(players | team: team.ref | skipFacilitator)?.length}}/{{(roles | skipFacilitator)?.length}}</span>
  </div>
</ul>