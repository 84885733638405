<!-- <p>
  {{play?.id}} - {{script?.id}}
</p> -->

<div class="row event-card" [dir]="(play.scriptVersion$ | async)?.direction">

  <div class="col-3 event-image"
    [ngStyle]="{'background-image': ('url('+ (play?.scriptVersion?._image | size : 'sm' : 'sm' : '/assets/images/img-placeholder.png') +')')  }">
  </div>
  <div class="col-9 pr-0">
    <div class="row align-items-center">
      <div class="col-8 play-title rtl-text">
        <div>
          <div>
            {{ play.title }}
          </div>
          <ng-container *ngIf="play.organizeTypeForAnalytics; let organizeType">
            <ion-badge size="small" color="warning" class="align-middle">
              <ion-icon *ngIf="organizeType === 'random'" name="shuffle"></ion-icon>
              {{organizeType}}
            </ion-badge>
          </ng-container>

        </div>

      </div>

      <!-- <div class="col-2 created-on">
        <div class="avatars">
          <img [src]="defaultAvatarUrl"
          [alt]="'general/created-on' | translate"/>
        </div>
        <div>{{ 'general/created-on' | translate }}</div>
        <div>{{ play.createdAt?.toDate() | date: 'dd/MM/YY' }}</div>
      </div> -->


        <div class="d-none col player-slots flex-column v-align-center v-center vertical-center">
          <!-- <div class="d-flex align-items-center justify-content-center">
            <mat-icon>people</mat-icon>
            <span>{{ play.slots?.enrolled }}/{{ play.slots?.total }}</span>
          </div> -->
  
          <ion-badge *ngIf="play.accessLevel === 'private'" size="small" color="warning" class="align-middle">
            <ion-icon name="shuffle"></ion-icon>
            Random
          </ion-badge>
  
          <ion-badge *ngIf="play.accessLevel === 'private'" size="small" color="warning" class="align-middle">
            <ion-icon name="shuffle"></ion-icon>
            Random
          </ion-badge>
        </div>


      <div class="col status text-right">
        <div class="label">
          {{ 'play-list/table/header/status/' + play.status | translate }}
        </div>
      </div>
      <div class="context-menu col-1 px-0 text-right">
        <context-menu-icon>
          <context-item (click)="openMonitorSession()" label="Monitor" ></context-item>
          <context-item (click)="openMixpanelSession()" label="Mixpanel"></context-item>
          <context-item (click)="openFirebaseSession()" label="Firebase"></context-item>
        </context-menu-icon>
      </div>

    </div>
    <div *ngIf="tasks" class="row">
      <ng-container *ngFor="let player of players">
        <ng-container *ngIf="getUser(player); let user">
          <div class="col pr-0">
            <div class="player p-3 mt-3">

              <context-menu-icon icon-name="link">
                <context-item (click)="openMixpanelPlayer(player)" label="Mixpanel"></context-item>
                <context-item (click)="openFirebasePlayer(player)" label="Firebase"></context-item>
              </context-menu-icon>

              <h5 title="Joined at">
                <!-- <mat-icon class="float-left mr-2 ml-0">account_circle</mat-icon> -->
                <img class="avatar" [src]="user.photoURL">
                <!-- {{getLastLoginTime(player)}} -->
                {{player.createdAt.toDate() | date:'short'}}
                <!-- <mat-icon class="float-right text-muted">link</mat-icon> -->
                <!-- <mat-icon class="float-right text-muted">analytics</mat-icon>
                <mat-icon class="float-right text-muted">folder_open</mat-icon> -->
              </h5>
              <p title="Progress">
                <mat-icon class="float-left text-muted">flag</mat-icon> {{getProgress(player)}}
              </p>
              <p title="Last login at">
                <mat-icon class="float-left text-muted">login</mat-icon> 
                {{getLastLoginTime(player)}}
                <!-- {{user?.lastLoginAt?.toDate() | date:'short'}} -->
              </p>
              <p title="Device">
                <mat-icon class="float-left text-muted">devices</mat-icon> {{getDevice(user)}}
              </p>
            </div>
          </div>
        </ng-container>

      </ng-container>
    </div>
  </div>



</div>