import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivilegePipe } from './privilege.pipe';
import { PrivilegeService } from './privilege.service';
import { WorkspaceModule } from '../workspace/workspace.module';



@NgModule({
  declarations: [
    PrivilegePipe,
  ],
  imports: [
    CommonModule,
    WorkspaceModule,//.forChild()
  ],
  providers: [
    PrivilegeService,
  ],
  exports: [
    PrivilegePipe
  ]
})
export class PrivilegeModule { }
