
<ion-header>
  <ion-toolbar mode="ios">
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container *ngIf="!header">
      <ion-back-button defaultHref="playing/browse/play-list" slot="start" mode="ios"></ion-back-button>
      <ion-title><span class="rtl-text" [headerTitleSize]="playService.play?.title">{{playService.play?.title}}</span></ion-title>
    </ng-container>
    <ion-progress-bar [value]="(playService.myPlayer$ | async)?.progress"></ion-progress-bar>
    <!-- *ngIf="(playService.myPlayer$ | async)?.progress" -->
  </ion-toolbar>
</ion-header>
<ion-content #content [scrollEvents]="true" (ionScroll)="onWindowScroll($event)">
  <div *ngIf="this.playService?.scriptVersion" class="top-poster" [class.h-0]="!showTabbar || aboveTab"
    [style.background-image]="'url(' + (this.playService?.scriptVersion?._image | size : 'xlg' : 'md') + ')'">
    <div class="action text-center">
      <play-status #playStatus *ngIf="playService.play && (playService.play$ | async) && (playService.roles$ | async)"
        [play]="playService.play" [player]="playService.myPlayer$ | async"
        [roles]="(playService.roles$ | async)"></play-status>
    </div>

    <div *ngIf="hasTeaser && showTabbar">

      <!-- <ion-fab slot="fixed" horizontal="end">
        <div [hidden]="screenWidth <= 576">
          <ion-button color="light" size="small" shape="round" (click)="btnPlayTeaser_onClick()">
            <ion-icon slot="start" name="play-circle-outline" color="primary"></ion-icon> <span class="text-primary">Play Teaser</span>
          </ion-button>
        </div>
        <div [hidden]="screenWidth > 576">
          <ion-fab-button color="light" size="small" shape="round" (click)="btnPlayTeaser_onClick()">
            <ion-icon name="play-circle-outline" color="primary"></ion-icon>
          </ion-fab-button>
        </div>
      </ion-fab> -->

      <ion-fab slot="fixed" horizontal="end">
        <div class="d-none d-md-block">
          <ion-button color="light" size="small" shape="round" (click)="btnPlayTeaser_onClick()">
            <ion-icon slot="start" name="play-circle-outline" color="primary"></ion-icon> <span class="text-primary">Play Teaser</span>
          </ion-button>
        </div>
        <div class="d-md-none d-sm-block">
          <ion-fab-button color="light" size="small" shape="round" (click)="btnPlayTeaser_onClick()">
            <ion-icon name="play-circle-outline" color="primary"></ion-icon>
          </ion-fab-button>
        </div>
      </ion-fab>

    </div>
  </div>
  <ng-container *ngTemplateOutlet="aboveTab"></ng-container>
  <ion-tabs #tabs [class.sticky]="this.sticky">
    <ion-tab-bar #tabBar slot="top" [class.h-0]="!showTabbar" mode="md">
      <ion-tab-button tab="details" layout="icon-start" [selected]="true" mode="md">
        <mat-icon class="mr-2">info</mat-icon>
        <ion-label>{{"playing/play/tab/details/title" | translate}}</ion-label>
        <ion-badge *ngIf="playService?.play?.status === 'on-going' && playService?.myPlayer && playService?.play?.organizeType === 'invite-game' && playService?.play?.slots?.available > 0" color="warning">
          <ion-icon name="alert"></ion-icon>
        </ion-badge>
      </ion-tab-button>

      <ion-tab-button tab="task" layout="icon-start"
        [disabled]="!(playService.play$ | async) || (playService.play$ | async)?.status == 'pre-start' || !(playService.myPlayer$ | async)"
        mode="md">
        <mat-icon class="mr-2">subscriptions</mat-icon>
        <ion-label *ngIf="!hasFeed">{{"playing/play/tab/progress/title" | translate}}</ion-label>
        <ion-label *ngIf="hasFeed">{{"playing/play/tab/feed/title" | translate}}</ion-label>
        <ng-container *ngIf="playService.myPlayer$ | async">
          <ng-container *ngIf="playService.play$ | async; let play">
            <ion-badge *ngIf="(playService.myTasksActual$ | async )?.length > 0 && play?.status !== 'pre-start'"
              color="warning">
              {{(playService.myTasksActual$ | async )?.length}}
            </ion-badge>
          </ng-container>
        </ng-container>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>