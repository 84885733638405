import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { User } from 'src/app/core/models/user.model';
import { Script } from 'src/app/core/models/script.model';
import { take } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { PublishModalComponent } from '../../publish-modal/publish-modal.component';
import { ScriptCardAnalyticsService } from './script-card-analytics-service';
import { PlayModalComponent } from '../../play-modal/play-modal.component';
import { Router } from '@angular/router';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { PRIVILEGES } from 'src/app/core/privilege/privilege-roles';

@Component({
  selector: 'script-card',
  templateUrl: './script-card.component.html',
  styleUrls: ['./script-card.component.scss']
})
export class ScriptCardComponent implements OnInit {
  @Input() user: User;
  @Input() script: Script;
  @Input() version: ScriptVersion;

  @HostBinding('attr.dir') direction;
  
  public PRIVILEGES = PRIVILEGES;

  constructor(
    public analytics: ScriptCardAnalyticsService,
    public modalController: ModalController,
    // public workspaceService: WorkspaceService,
    public router: Router
  ) {

  }

  async ngOnInit() {
    if (!this.version) {
      this.version = await this.script.draft$.pipe(take(1)).toPromise();
      this.direction = this.version.direction;
    }
  }

  async showPublish() {
    // analytics.createPublishButtonClickedEvent(script, version);
    const modal = await this.modalController.create({
      component: PublishModalComponent,
      componentProps: {
        script: this.script
      },
      cssClass: 'auto-height'
    });
    return await modal.present();
  }

  async edit($event: CustomEvent<any>) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    this.router.navigateByUrl('/design/' + this.script.id + '/task');
  }

  async play($event: CustomEvent<any>) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    const modal = await this.modalController.create({
      component: PlayModalComponent,
      componentProps: {
        script: this.script,
        // version: this.version
      },
      cssClass: 'play-modal auto-height'
    });
    return await modal.present();
  }

}
