import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ScriptEditPageModule } from './edit/script-edit-page.module';
import { ScriptViewPageModule } from './view/script-view-page.module';
import { ScriptListPageModule } from './list/script-list-page.module';
import { ScriptRoutingModule } from './script-routing.module';

@NgModule({
  imports: [
    // CommonModule,
    // FormsModule,
    IonicModule,
    ScriptListPageModule,
    ScriptViewPageModule,
    ScriptEditPageModule,
    ScriptRoutingModule,
  ],
  exports: [
    ScriptViewPageModule,
    ScriptEditPageModule,
    ScriptListPageModule,
    ScriptRoutingModule
  ]
})
export class ScriptPageModule {}
