<div class="team-selector"
    [class.pro]="(this.organizationService.activeOrganization$ | async)?.plan.ref.id !== 'free'"
     *ngIf="user">
  <ul>
    <li *ngFor="let workspace of featuredWorkspaces">
      <div [title]="workspace.title"
           (click)="setActiveWorkspace(workspace);"
           [class.active]="(workspaceService.activatedWorkspace | async)?.ref.path === workspace.ref.path"
           class="featured team-logo">
        <mat-icon>public</mat-icon>
      </div>
    </li>
    <li *ngIf="featuredWorkspaces.length > 0" class="divider"></li>
    <li *ngFor="let workspace of workspaces">

      <div *ngIf="workspace.isPersonal"
           [title]="workspace.title"
           (click)="setActiveWorkspace(workspace);"
           [class.active]="(workspaceService.activatedWorkspace | async)?.ref.path === workspace.ref.path"
           class="personal team-logo">
        <mat-icon>person</mat-icon>
      </div>
      <!-- [style.background-image]="getWorkspaceLogo(workspace)" -->
      <div
        *ngIf="!workspace.isPersonal"
        [style.background-image]="'url('+ (workspace._image | size : 'xs' : 'xs' : workspace.photoURL) +')' | safe : 'resourceUrl' "
        [title]="workspace.title"
        [class.active]="(workspaceService.activatedWorkspace | async)?.ref.path === workspace.ref.path"
        (click)="setActiveWorkspace(workspace);"
        class="team-logo"
      >
      </div>

    </li>
  </ul>

  <ng-container *ngIf="((organizationService.activeOrganization$ | async)?.myMember$ | async); let organizationMember">
    <a
      (click)="$event.stopPropagation(); addNewWorkspace()"
      [class.disabled]="organizationMember?.level !== 'owner' && organizationMember?.level !== 'admin'"
    >
      <div class="add-new">
        <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
        <mat-icon *ngIf="!processing">add</mat-icon>
      </div>
    </a>
  </ng-container>

  <a [routerLink]="['/teams','settings']" class="settings">
    <mat-icon>settings</mat-icon>
  </a>
</div>
