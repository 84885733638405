import { Component, Input, OnInit, OnDestroy, SkipSelf } from '@angular/core';
import { User } from '../../models/user.model';
import { AuthService } from '../../../auth/auth.service';
import { WorkspaceOrderPipe } from '../../pipes/workspace-order.pipe';
import { WorkspaceService } from '../workspace.service';
import { Router } from '@angular/router';
import { Workspace } from '../../models/workspace.model';
import { skip, map, first, mergeMap } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { OrganizationPlansComponent } from 'src/app/organization/organization-plans/organization-plans.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { AnalyticsProvider } from '../../analytics/analytics';
import { TeamSidebarAnalyticsService } from './team-sidebar.analytics.service';
import { ModelProvider } from '../../models/general/model.provider';

@Component({
  selector: 'app-team-sidebar',
  templateUrl: './team-sidebar.component.html',
  styleUrls: ['./team-sidebar.component.scss'],
})
export class TeamSidebarComponent implements OnInit, OnDestroy {
  @Input() active: boolean;
  user: User;

  @Input() navigateOnChange: Array<any>;

  workspaces: Workspace[];
  featuredWorkspaces: Workspace[] = [];

  public processing = false;

  public subscriptions: Subscription[] = [];

  constructor(
    private auth: AuthService,
    private router: Router,
    private modelProvider: ModelProvider,
    public workspaceService: WorkspaceService,
    public organizationService: OrganizationService,
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    public analytics: TeamSidebarAnalyticsService
  ) {
  }

  async ngOnInit() {
    ;
    this.subscriptions.push(
      this.auth.user$.subscribe(user => this.user = user)
    );

    const templateWorkspace = await this.modelProvider.workspace.findByID('demo-games').pipe(first()).toPromise();
    this.featuredWorkspaces.push(templateWorkspace);

    const workspacesSubscription$ = this.workspaceService.workspaces$
    .pipe(mergeMap(async workspaces => {
      return await this.workspaceService.modelProvider.asyncFilter(workspaces, async workspace => 
        (await workspace.getMyMember()).level !== 'player'
      );
    }))
    .pipe(
      map(workspaces => workspaces.sort((a, b) => {
        if (a.isPersonal && !b.isPersonal) {
          return -1;
        } else if (!a.isPersonal && b.isPersonal) {
          return 1;
        }
        //  else {
        //   return 0;
        // }

        if (a.myMember.joinedAt && !b.myMember.joinedAt) {
          return -1;
        } else if (!a.myMember.joinedAt && b.myMember.joinedAt) {
          return 1;
        } else if (a.myMember.joinedAt && b.myMember.joinedAt) {
          return a.myMember.joinedAt.toMillis() > b.myMember.joinedAt.toMillis() ? 1 : -1;
        }

        return 0;
      }))
    )
      // .pipe(map(workspaces =>
      //   workspaces.filter(workspace => workspace.getMyMember().level !== 'player')
      // ))

    // const organizationSubscription = combineLatest(
    //   workspacesSubscription$,
    //   this.organizationService.activeOrganization$
    // );

    const organizationFilteredWorkspaces$ = combineLatest(
      workspacesSubscription$,
      this.organizationService.activeOrganization$
    )
      .pipe(
        map(([workspaces, activeOrganization]) => {
          return workspaces.filter(workspace => {
            // if(workspace.isPersonal) {
            //   return true;
            // }

            if (workspace.ownerOrganizationRef) {
              return workspace.ownerOrganizationRef.isEqual(activeOrganization.ref);
            } else {
              return activeOrganization.isOrphan();
            }
          });
        })
      )
      .subscribe(workspaces => this.workspaces = workspaces);

    // .subscribe(workspaces => {
    //   this.workspaces = workspaces.filter(workspace => workspace.getMyMember().level !== 'player');
    // });
    this.subscriptions.push(
      organizationFilteredWorkspaces$
    );

    // if (this.navigateOnChange) {
    //   const initWorkspace = 
    //   const subscription = this.workspaceService.activatedWorkspace
    //     .pipe(skip(1))
    //     .pipe(filter(workspace => work))
    //     .subscribe(workspace => {
    //       this.router.navigate(this.navigateOnChange);
    //     });
    //   this.subscriptions.push(subscription);
    // }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async setActiveWorkspace(workspace: Workspace) {
    // console.debug('setActiveWorkspace', workspace);
    this.workspaceService.setActiveWorkspace(workspace);

    if (this.navigateOnChange) {
      const activeWorkspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
      if (!workspace.ref.isEqual(activeWorkspace.ref)) {
        this.router.navigate(this.navigateOnChange);
      }
    }
  }

  async addNewWorkspace() {
    const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();

    const organizationMember = await organization.myMember$.pipe(first()).toPromise();

    if (!['owner', 'admin'].includes(organizationMember.level)) {
      return;
    }

    const isValidWorkspaceLimit = await organization.isValidWorkspaceLimit();
    if (!isValidWorkspaceLimit) {
      await this.organizationService.showWorkspaceLimitWarning();
      return;
    }

    if (this.processing) { return; }
    this.processing = true;
    const workspace = await this.workspaceService.addNew(organization);

    this.analytics.createWorkspace(await this.organizationService.getAnalyticsParams());

    if (workspace) {
      const workspaces = await this.workspaceService.workspaces$.pipe(first()).toPromise();
      const activeWorkspace = workspaces.find((_workspace => _workspace.ref.isEqual(workspace.ref)));

      await this.setActiveWorkspace(activeWorkspace);
      this.router.navigate(['/teams/settings']);
    }

    this.processing = false;
  }

  navigateToHome() {
    this.router.navigate([]);
  }

  getWorkspaceLogo(workspace: Workspace): SafeStyle {
    const url = workspace && workspace.imageUrl ? workspace.imageUrl : '../../../assets/images/img-placeholder.png';
    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    // return this.sanitizer.bypassSecurityTrustStyle(url);
    // return url;
  }

  // public async openPlansDialog() {
  //   const modal = await this.modalController.create({
  //     component: OrganizationPlansComponent,
  //     cssClass: 'organization-plan'
  //   });
  //   await modal.present();
  // }
}
