import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlayLandingAnalyticsService } from './play-landing.analytics';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { first } from 'rxjs/operators';

@Component({
             selector: 'app-play-landing',
             templateUrl: 'play-landing.component.html',
             styleUrls: ['play-landing.component.scss']
           })
export class PlayLandingComponent implements OnInit {
  dontShowAgain = false;

  constructor(
    public analytics: PlayLandingAnalyticsService,
    public workspaceService: WorkspaceService
  ) {
    
  }

  ionViewWillEnter() {
    this.analytics.setScreen();
  }

  async ngOnInit() {
    const workspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
    await workspace.getMyMember();
    if (!workspace || workspace.myMember.level === 'player') {
      this.workspaceService.setActiveWorkspace(this.workspaceService.getPersonalWorkspace());
    }
    this.dontShowAgain = localStorage.getItem('is-play-landing-page-visible') ?
                         localStorage.getItem('is-play-landing-page-visible') === 'true' :
                         false;
  }

  addDontShowAgainFlagToLocalStorage() {
    localStorage.setItem('is-play-landing-page-visible', JSON.stringify(this.dontShowAgain));
  }

  getPlayStoreUrl(): string {
    return `http://play.google.com/store/apps/details?id=${environment.android.playPackageName}`;
  }

  getAppStoreUrl(): string {
    return `https://apps.apple.com/us/app/gamoteca/id${environment.iOS.playAppId}?mt=8`;
  }

  getPlayInBrowserUrl(): string {
    return environment.web.playUrl;
  }
}
