import { Component, Input, OnInit } from '@angular/core';
import { Response } from 'src/app/core/models/response';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { Task } from 'src/app/core/models/task.model';

@Component({
  selector: 'post-response-media',
  templateUrl: './response-media.component.html',
  styleUrls: ['./response-media.component.scss'],
})
export class PostResponseMediaComponent implements OnInit {

  public LayoutItem = LayoutItem;

  @Input()
  response: Response;

  @Input()
  task: Task;

  constructor() { }

  ngOnInit() {
    
  }

}
