import { OnInit, Component, Input } from '@angular/core';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Player } from 'src/app/core/models/player.model';
import { Task } from 'src/app/core/models/task.model';
import { TaskStatusPipe } from 'src/app/designer/simulator/task-status.pipe';
import { take } from 'rxjs/operators';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Role } from 'src/app/core/models/role.model';
import { SimulatorAnalyticsService } from './simulator.analytics';

@Component({
  selector: 'script-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss'],
})
export class SimulatorComponent implements OnInit {
  @Input()
  public isActive = false;
  public players: Player[] = [];

  public tasksByRoles: {
    [roleID: string]: {
      role: Role
      player: Player
      tasks: Array<Task>,
      activeTask: Task
    }
  } = {};

  @Input() roles: Array<Role>;
  @Input() tasks: Array<Task>;
  @Input() scriptVersion: ScriptVersion;

  public constructor(
    private modelProvider: ModelProvider,
    public analytics: SimulatorAnalyticsService
  ) {

  }

  public async onTaskSubmit(task: Task) {
    // this.tasksByRoles[task.roleRef.id].member.responses[task.id] = {
    //     createdAt: new Date(),
    //     done: true,
    //     point: task.point
    // };

    this.refreshTasks();
  }

  public async refreshTasks() {
    const taskStatusPipe = new TaskStatusPipe();
    const tasks = this.tasks || await this.scriptVersion.tasks$.pipe(take(1)).toPromise();

    for (const roleID in this.tasksByRoles) {
      if (this.tasksByRoles.hasOwnProperty(roleID)) {
        const roleElement = this.tasksByRoles[roleID];

        roleElement.tasks = tasks
          .filter(
            task => (
              task.isResolved(this.players)
              && !task.isDone(this.players)
              && task.roleRef.isEqual(roleElement.role.getReference())
            )
          )
          .sort((a: Task, b: Task) => (a.order > b.order) ? 1 : -1);

        // roleElement.tasks =  taskStatusPipe.transform(tasks, this.players, roleElement.player, 'active');
        roleElement.activeTask = roleElement.tasks[0] || null;
      }
    }
  }

  public start() {
    this.analytics.onStart();
    this.fillPlayers();
    this.refreshTasks();
    this.isActive = true;
  }

  public stop() {
    this.analytics.onStop();
    this.isActive = false;
  }

  public async fillPlayers() {
    this.players = [];
    const roles = this.roles || await this.scriptVersion.roles$.pipe(take(1)).toPromise();
    for (let roleIndex = 0; roleIndex < roles.length; roleIndex++) {
      const role = roles[roleIndex];

      const player = this.modelProvider.player.create('player', { roleRef: role.ref });
      player.userRef = this.modelProvider.user.facilitatorRef;
      player.onAfterAddResponse$.subscribe(({ task, response }) => {
        this.refreshTasks();
      });
      this.players.push(player);

      this.tasksByRoles[role.id] = {
        player: player,
        role: role,
        tasks: [],
        activeTask: null
      };
    }
  }


  ngOnInit() {
    this.fillPlayers();
  }

}
