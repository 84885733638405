import { Component, ViewChild, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { MediaCapture, CaptureVideoOptions, MediaFile, CaptureError } from '@ionic-native/media-capture/ngx';
// import { File as FileService} from '@ionic-native/file';
// import { normalizeURL } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File as NativeFile } from '@ionic-native/file/ngx';
import { VideoEditor, TranscodeOptions } from '@ionic-native/video-editor/ngx';
import { Observable, Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { VideoRecordMobileAnalyticsService } from './video-record-mobile.analytics';
import { Storage } from '@ionic/storage';
import { FilePath } from '@ionic-native/file-path/ngx';
import { InputVideoLayoutItem } from 'src/app/core/models/layout-item-input-video';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the VideoMobileComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'video-record-mobile',
  templateUrl: './video-record-mobile.html',
  styleUrls: ['./video-record-mobile.scss'],
  providers: [
    MediaCapture,
    AndroidPermissions,
    VideoEditor,
    WebView,
    AndroidPermissions,
    NativeFile,
    VideoRecordMobileAnalyticsService
  ]
})
export class VideoRecordMobileComponent {
  @ViewChild('captureVideo') captureVideo;
  @ViewChild('previewVideo') previewVideo;

  @Output('file') file: EventEmitter<File | Blob> = new EventEmitter<File | Blob>();
  @Output('onTextTyped') onTextTyped: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public layoutItem: InputVideoLayoutItem;
  @Input()
  public language = 'en';

  public hasInfo = false;
  public alternateOptions: Array<string> = [];
  public showAlternates = true;
  public activeAlternate: string | boolean = false


  public status: string = 'inactive';
  public transcodeProgress = null;
  // public progressPercent: Subject<Number> = new Subject<Number>();
  public progressPercent: Subject<Number> = new Subject<Number>();
  // public progressPercent: Subject<Number> = new Subject<Number>();

  @Input()
  cacheKey: string;

  constructor(
    private mediaCapture: MediaCapture,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private nativeFile: NativeFile,
    private videoEditor: VideoEditor,
    private cdr: ChangeDetectorRef,
    private analytics: VideoRecordMobileAnalyticsService,
    private webview: WebView,
    public storage: Storage,
    private filePath: FilePath,
    public translate: TranslateService
    // private fileService: FileService
  ) {
    // console.log('Hello VideoMobileComponent Component');
    // this.platform.ready().then(ready => {
    //   console.log(JSON.stringify(this.platform.version()));
    // })
  }

  async ngOnInit() {
    this.hasInfo = this.layoutItem.getInstructionText()?.length > 0;
    this.alternateOptions = this.layoutItem.properties && this.layoutItem.properties.alternate &&
      Object.keys(this.layoutItem.properties.alternate).filter(key => this.layoutItem.properties.alternate[key].active);
  }

  async ngAfterViewInit() {
    if (this.cacheKey) {
      this.analytics.debug('cache exists', { key: this.cacheKey });
      const self = this;
      try {
        const cachedFilePath: string = await this.storage.get(this.cacheKey);
        this.analytics.debug('cache - path', { path: cachedFilePath });
        if (cachedFilePath) {
          const dir = cachedFilePath.split("/").slice(0, -1).join("/");//path2.substring(0,path2.lastIndexOf("\\")+1);
          const filename = cachedFilePath.replace(/^.*[\\\/]/, '');
          this.nativeFile.readAsArrayBuffer(dir, filename).then(arrayBuffer => {
            let blob = new Blob([arrayBuffer], { type: 'video/mp4' });

            (blob as any).name = filename;//filename; //'capture.' + extension;
            self.file.emit(blob);

            var url = URL.createObjectURL(blob);
            self.previewVideo.nativeElement.src = url;
            if (this.platform.is('ios')) {
              this.previewVideo.nativeElement.autoplay = true;
              const playPromise = this.previewVideo.nativeElement.play();
              if (playPromise !== undefined) {
                playPromise.then(_ => {
                  // Automatic playback started!
                  // Show playing UI.
                  // We can now safely pause video...
                  this.previewVideo.nativeElement.pause();
                }).catch(error => {
                  // Auto-play was prevented
                  // Show paused UI.
                });
              } else {
                this.previewVideo.nativeElement.pause();
              }
            }
            this.status = 'success';
          }).catch(error => {
            console.log(error);
          });


        }

      } catch (error) {

      }
    }
  }

  public cancel() {
    this.status = 'inactive';
    this.file.emit(null);
  }

  public retake() {
    this.analytics.onRetakeClick();
    this.cancel();
    this.capture();
  }

  private async afterAndroidPermission() {
    return new Promise((resolve, reject) => {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
        result => {
          this.analytics.debug('afterAndroidPermission - check', { result: JSON.stringify(result) });
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) {
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(result => {
              this.analytics.debug('afterAndroidPermission - request - Permission1', { result: JSON.stringify(result) });
              console.log(result);
              if (!result.hasPermission) {
                reject(false);
              } else {
                resolve(true);
              }
            })
          } else {
            resolve(true);
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(result => {
          this.analytics.debug('afterAndroidPermission - request - Permission2', { result: JSON.stringify(result) });
          console.log(result);
          if (!result.hasPermission) {
            reject(false);
          } else {
            resolve(true);
          }
        })
      );
    })

  }

  public onTranscodeProgress(info) {
    this.progressPercent.next(Math.round(info * 100));
    // this.transcodeProgress = Math.round(info * 100);
    // console.log("Transcode progress", this.transcodeProgress);
  }

  public async transcodeiOS(path) {
    const self = this;
    console.log("transcode", path);
    return this.videoEditor.transcodeVideo({
      fileUri: path,
      outputFileName: "capture-" + new Date().getTime() + "-" + this.platform.platforms().join("-") + "-transcoded",//'capture-' + new Date().getTime(),
      outputFileType: this.videoEditor.OutputFileType.M4V,
      optimizeForNetworkUse: this.videoEditor.OptimizeForNetworkUse.YES, // ios only
      saveToLibrary: false, // optional, defaults to true
      deleteInputFile: true, // optional (android only), defaults to false
      // progress: this.onTranscodeProgress // info will be a number from 0 to 100
      progress: (percent) => {
        self.progressPercent.next(Math.ceil(percent));
        self.cdr.detectChanges();
        // console.log(percent);
        // this.onTranscodeProgress // info will be a number from 0 to 100
      }
    })
      .then((fileUri: string) => {
        console.log('video transcode success', fileUri)
        return fileUri;
      })
      .catch((error: any) => {
        console.log('video transcode error', error)
        // throw error
        return error;
      });
  }

  public async transcodeAndroid(path) {
    const self = this;
    console.log("transcode", path);
    this.analytics.debug('transcodeAndroid - call', { path: path });
    const transcodePercents = [25, 50, 75, 100];
    const transcodePercentProgress = {
      25: false,
      50: false,
      75: false,
      100: false,
    };
    // return navigator.device.capture.captureVideo({

    return this.videoEditor.transcodeVideo({
      fileUri: path,
      outputFileName: "capture-" + new Date().getTime() + "-" + this.platform.platforms().join("-") + "-transcoded",//'capture-' + new Date().getTime(),
      // outputFileName: "capture" + this.platform.platforms().join("-") + "-transcoded",//'capture',
      outputFileType: this.videoEditor.OutputFileType.M4V,
      // fileUri: 'file-uri-here', // the path to the video on the device
      // outputFileName: 'output-name', // the file name for the transcoded video
      // outputFileType: VideoEditorOptions.OutputFileType.MPEG4, // android is always mp4
      optimizeForNetworkUse: this.videoEditor.OptimizeForNetworkUse.YES, // ios only
      saveToLibrary: false, // optional, defaults to true
      deleteInputFile: true, // optional (android only), defaults to false
      maintainAspectRatio: true, // optional (ios only), defaults to true
      width: 800, // optional, see note below on width and height
      height: 800, // optional, see notes below on width and height
      videoBitrate: 1000000, // optional, bitrate in bits, defaults to 1 megabit (1000000)

      // fps: 24, // optional (android only), defaults to 24
      audioChannels: 2, // optional (ios only), number of audio channels, defaults to 2
      audioSampleRate: 44100, // optional (ios only), sample rate for the audio, defaults to 44100
      audioBitrate: 128000, // optional (ios only), audio bitrate for the video in bits, defaults to 128 kilobits (128000)
      progress: (percent) => {
        const percentRound = Math.ceil(
          this.platform.is('android') ? percent * 100 : percent
        );

        console.log('transcode percent' + percent);

        for (const percent of transcodePercents) {
          if (percentRound >= percent && transcodePercentProgress[percent] === false) {
            transcodePercentProgress[percent] = true;
            this.analytics.debug('transcodeAndroid - progress - ' + percent + '%');
          }
        }

        self.progressPercent.next(percentRound);
        self.cdr.detectChanges();
        // console.log(percent);
        // this.onTranscodeProgress // info will be a number from 0 to 100
      }
    })
      .then((fileUri: string) => {
        this.analytics.debug('transcodeAndroid - success', { fileUri: fileUri });
        console.log('video transcode success', fileUri)
        return fileUri;
      })
      .catch((error: any) => {
        this.analytics.debug('transcodeAndroid - error', { error: JSON.stringify(error) });
        // console.log('video transcode error', error)
        throw new Error(error);
        // return error;
      });
  }

  public async capture() {
    this.analytics.onRecordClick();
    let self = this;
    // let options: CaptureVideoOptions = {

    // }
    this.mediaCapture.captureVideo(
      //   {
      //   limit: 1,
      // }
    )
      .then(
        async (data: MediaFile[]) => {
          this.analytics.onStopClick();
          self.status = 'success';
          let file = data[0];
          // console.log("data", data);
          // file.getFormatData(function(data){
          //   data.codecs.
          // })
          // file.
          // console.log("file", file.fullPath);

          // self.fileService.readAsArrayBuffer(file.fullPath, file)
          // window.resolveLocalFilesystemUrl(self.image).then((entry)=>{

          // })
          // File.resolveLocalFilesystemUrl(self.image).then((entry: any)=>{
          // let path = file.fullPath.replace(file.name, '');
          // console.log(path, file.name);

          // console.log("this.fileService.applicationDirectory",this.fileService.applicationDirectory);
          // console.log("this.fileService.applicationStorageDirectory",this.fileService.applicationStorageDirectory);
          // let path = "cdvfile:/"+file.fullPath.substring(0, file.fullPath.lastIndexOf('/') + 1);
          // let path = "file://" + file.fullPath.substring(0, file.fullPath.lastIndexOf('/') + 1);
          let path = file.fullPath.substring(0, file.fullPath.lastIndexOf('/') + 1);


          // let path = "file:///var/mobile/Containers/Data/Application/F07C6152-395C-4E5D-BD01-3468EA45EE7E/tmp/"
          let fileName = file.fullPath.substring(file.fullPath.lastIndexOf('/') + 1, file.fullPath.length);
          console.log(file.fullPath, path, fileName, this.webview.convertFileSrc(path));
          console.log(this.webview.convertFileSrc(file.fullPath));

          // var toDirectory = self.fileService.dataDirectory;

          // self.fileService.copyFile(path , fileName , toDirectory , fileName).then((res) => {
          //   console.log(JSON.stringify(res));
          //   self.fileService.readAsArrayBuffer(toDirectory, fileName).then(data => {
          //     console.log('success');
          //   }).catch(error => {
          //     console.log("error file", JSON.stringify(error));
          //   })
          //   // this.storeMediaFiles([{name: fileName, size: capturedFile.size}]);
          // },err => {
          //   console.log('err: ', JSON.stringify(err));
          // });

          // self.fileService.getFile(path, fileName).then(fileEntry => {
          //   fileEntry.
          // })

          // self.fileService.getFile()

          // self.fileService.readAsArrayBuffer(path, fileName).then(data => {
          //   console.log("success", data);
          //   let blob = new Blob([new Uint8Array(data)]);
          //   let extension = fileName.split('.').pop();;
          //   let fileObj = new File([blob], 'capture.'+extension, { type: file.type, lastModified: Date.now() });
          //   self.file.emit(fileObj);




          //   var url = URL.createObjectURL(blob);
          //   console.log(url, extension, file.type);
          //   self.previewVideo.nativeElement.src = url;
          //   // self.previewVideo.nativeElement.play();
          // }).catch(error => {
          //   console.log("error file", JSON.stringify(error, Object.getOwnPropertyNames(error)));
          // })





          // (window as any).resolveLocalFileSystemURL(file.fullPath,
          //   function (fileEntry) {
          //     console.log("file2", file);

          //     fileEntry.file(function (file) {
          //       var reader = new FileReader();

          //       reader.onloadend = function () {
          //         console.log("Successful file read: " + this.result);
          //         // displayFileData(fileEntry.fullPath + ": " + this.result);
          //       };

          //       reader.readAsText(file);

          //     }, function(error) {
          //       console.log("error",error);
          //     });
          //   }, function(evt) {
          //     console.log(JSON.stringify(evt));
          //     // console.log("error2", error);
          //   })

          // (window as any).resolveLocalFileSystemURL(path, function (dir) {
          //   console.log("got main dir", dir);
          //   dir.getFile(fileName, { create: true }, function (file) {
          //     console.log("got the file", file);
          //   });
          // });

          this.analytics.debug('fullPath', { path: file.fullPath });

          let path2 = self.platform.is("ios") ? 'file://' + file.fullPath : file.fullPath;

          if (self.platform.is('android') && file.fullPath.startsWith('content://')) {
            path2 = await this.filePath.resolveNativePath(path2)
          }

          this.analytics.debug('path2', { path: path2 });

          let dir = path2.split("/").slice(0, -1).join("/");//path2.substring(0,path2.lastIndexOf("\\")+1);
          let filename = path2.replace(/^.*[\\\/]/, '');



          // console.log(dir, filename);
          let transcodedPath: string;
          try {
            // this.progressPercent = new Subject<Number>();
            this.progressPercent.next(1);
            this.cdr.detectChanges();
            if (self.platform.is("android")) {
              await this.afterAndroidPermission();
              transcodedPath = await this.transcodeAndroid(path2);
            } else {
              transcodedPath = await this.transcodeiOS(path2);
            }
            this.progressPercent.next(null);
            // this.progressPercent = null;

            transcodedPath = self.platform.is("ios") ? "file://private" + transcodedPath : "file://" + transcodedPath;
            console.log(transcodedPath);
            this.analytics.debug('capture - transcodedPath', { transcodedPath: transcodedPath });

            dir = transcodedPath.split("/").slice(0, -1).join("/");//path2.substring(0,path2.lastIndexOf("\\")+1);
            filename = transcodedPath.replace(/^.*[\\\/]/, '');
          } catch (error) {
            this.analytics.debug('capture - transcodedPath - error', { error: JSON.stringify(error) });
            this.progressPercent.next(null);
            this.cdr.detectChanges();
          }


          // console.log("dir, filename", dir, filename);
          // readAsArrayBuffer
          // File.readAsArrayBuffer(dir, filename).then(arrayBuffer => {
          this.analytics.debug('capture - readAsArrayBuffer - init', { dir: dir, fileName: filename });
          this.nativeFile.readAsArrayBuffer(dir, filename).then(arrayBuffer => {
            // console.log("Reader finished init", arrayBuffer.byteLength);
            // let blob = new Blob([arrayBuffer], { type: file.type });
            let blob = new Blob([arrayBuffer], { type: 'video/mp4' });

            var filenameBase = filename.substring(0, filename.lastIndexOf('.'));
            var extension = filename.split('.').pop();

            // let extension = fileName.split('.').pop();
            // (blob as any).name = filenameBase + "-" + this.platform.platforms().join("-") + "-transcoded." + extension;//filename; //'capture.' + extension;
            (blob as any).name = filename;//filename; //'capture.' + extension;
            (blob as any).filePath = transcodedPath;
            self.file.emit(blob);

            this.analytics.debug('capture - readAsArrayBuffer - succeed', { filename: filename, size: blob.size });

            var url = URL.createObjectURL(blob);
            // console.log("Reader finished", url);
            // console.log(url, extension, file.type);
            this.previewVideo.nativeElement.src = url;
            // if (this.platform.is('ios')) {
            this.previewVideo.nativeElement.autoplay = true;
            const playPromise = this.previewVideo.nativeElement.play();
            if (playPromise !== undefined) {
              playPromise.then(_ => {
                // Automatic playback started!
                // Show playing UI.
                // We can now safely pause video...
                this.previewVideo.nativeElement.pause();
              }).catch(error => {
                // Auto-play was prevented
                // Show paused UI.
              });
            } else {
              this.previewVideo.nativeElement.pause();
            }
            // }
          }).catch(error => {
            this.analytics.debug('capture - error1', { error: JSON.stringify(error) });
            console.log(error);
          });




          // this.nativeFile.readAsDataURL(dir, filename).then(dataURL => {
          //   console.log("Data URL");
          // }).catch(error => {
          //   console.log(error);
          // });



          // this.nativeFile.getFile(dir, filename).then(arrayBuffer => {

          // }).catch(error => {
          //   console.log(error);
          // });

          // (window as any).resolveLocalFileSystemURL(path2, (entry) => {
          //   entry.file((file2) => {
          //     var nativePath = entry.toURL();
          //     console.log('Native URI: ' + nativePath, file2.localURL);
          //     (window as any).resolveLocalFileSystemURL(file2.localURL, (entry) => {
          //       var nativePath = entry.toURL();
          //       console.log('Native URI2: ' + nativePath, file2.localURL);
          //     })
          //     self.previewVideo.nativeElement.src = file2.localURL;// nativePath;
          //     // document.getElementById('video').src = nativePath;
          //   })

          // });

          // (window as any).resolveLocalFileSystemURL(path2, (fileEntry) => {

          //   console.log("fileEntry", fileEntry);
          //   fileEntry.file((file2) => {
          //     console.log("file", file2);

          //     // let path = this.file.dataDirectory + myFile.name;
          //     // let url = fileEntry.nativeURL.replace(/^file:\/\//, '');

          //     // self.previewVideo.nativeElement.src = "file:/"+fileEntry.fullPath;//file2.localURL;// "file:/"+fileEntry.fullPath;
          //     // console.log(
          //     //   normalizeURL(fileEntry.nativeURL) // file:///storage/emulated/0/DCIM/Camera/VID_20190301_135648.mp4
          //     //   ,normalizeURL(fileEntry.fullPath) // /DCIM/Camera/VID_20190301_135648.mp4
          //     //   ,normalizeURL(file2.localURL)     // cdvfile://localhost/sdcard/DCIM/Camera/VID_20190301_135648.mp4
          //     //   );

          //     // let extension = fileName.split('.').pop();
          //     // let fileObj = new File([file2], 'capture.' + extension, { type: file.type, lastModified: Date.now() });


          //     let blob = new Blob([file2], { type: file.type });
          //     console.log(blob);
          //     const reader2 = new FileReader();
          //     reader2.onload = (e) => {
          //       console.log(reader2.result);
          //     }
          //     reader2.readAsArrayBuffer(file2);

          //     // var url = URL.createObjectURL(blob);
          //     // self.previewVideo.nativeElement.src = url;

          //     const reader = new FileReader();
          //     reader.onloadend = (data) => {
          //       console.log("reader.onloadend");
          //       // reader.
          //       // console.log("success", JSON.stringify(this.result));
          //       // let blob = new Blob([new Uint8Array(result.)]);
          //       // let blob = new Blob([new Uint8Array((this.result as ArrayBuffer))]);
          //       // var blob = new Blob([new Uint8Array((this.result as ArrayBuffer))], { type: file.type });
          //       // var blob = new Blob([fileEntry.result], { type: file.type });
          //       var blob = new Blob([reader.result], { type: file.type });
          //       // blob.name
          //       let extension = fileName.split('.').pop();
          //       // let fileObj = new File([blob], 'capture.' + extension, { type: file.type, lastModified: Date.now() });
          //       // (fileObj as any).name = 'capture.' + extension;
          //       (blob as any).name = 'capture.' + extension;
          //       // console.log("fileObj", fileObj);
          //       // console.log('Loaded', JSON.stringify(fileObj), fileObj.name);
          //       self.file.emit(blob);

          //       var url = URL.createObjectURL(blob);
          //       console.log("Reader finished", url);
          //       console.log(url, extension, file.type);
          //       self.previewVideo.nativeElement.src = url;

          //     };
          //     reader.onabort = (ev) => {
          //       console.log("onabort");
          //     }
          //     reader.onloadstart = (ev) => {
          //       console.log("onloadstart");
          //     }
          //     reader.onload = (ev) => {
          //       console.log("onload");
          //     };
          //     // reader.onprogress = (ev) => {
          //     //   console.log("onprogress");
          //     //   console.log(ev.loaded);
          //     // }
          //     reader.onerror = (error) => {
          //       console.log("reader error");
          //       console.error("error reader", error);
          //     };
          //     // const koa = reader.readAsArrayBuffer(file2);
          //     // File.readAsArrayBuffer(path, fileName);
          //     // reader
          //     // console.log("reader init", koa);
          //   }, (error) => {
          //     console.error("Error read file", error);
          //   });
          // },
          // (error) => {
          //   console.log("resolveLocalFileSystemURL", error);
          // }
          // );

          // var xhr = new XMLHttpRequest;
          // xhr.open("get", file.fullPath);
          // xhr.responseType = "blob";
          // xhr.onload = function () {
          //   console.log("xhr.status",xhr.status, xhr.statusText);
          //   let file = new File([xhr.response], 'capture.mp4', { type: 'video/mp4', lastModified: Date.now() });
          //   self.file.emit(file);

          //   var url = URL.createObjectURL(xhr.response);
          //   self.previewVideo.nativeElement.src = url;
          //   self.previewVideo.nativeElement.play();
          //   // var yourNewBlob = xhr.response;
          //   // audioElement.src = URL.createObjectURL(yourNewBlob)
          // };
          // xhr.send();
          // https://github.com/apache/cordova-plugin-media-capture/issues/103
          // https://stackoverflow.com/questions/39272830/playback-a-mp3-blob-in-a-cordova-app
          // file.fullPath
          // https://stackoverflow.com/questions/22906026/reading-a-file-created-by-the-media-capture-plugin-in-cordova-creating-thumbna
          // console.log(data)
        },
        (err: CaptureError) => {
          this.analytics.debug('capture - error2', { error: JSON.stringify(err) });
          console.error(err)
        }
      );
    // .then(mediaFiles => {
    //   var i, path, len;
    //   for (i = 0, len = mediaFiles.length; i < len; i += 1) {
    //       path = mediaFiles[i].fullPath;
    //       // do something interesting with the file
    //   }
    //   // files.
    // })
  }

  public onShowAlternates() {
    this.showAlternates = true;
    this.analytics.onShowAlternateOptions();
  }

  public setActiveAlternate(alternate: string | boolean) {
    this.activeAlternate = alternate;
    if (alternate) {
      this.analytics.onClickAlternateOption(this.translate.instant('media-capture/video/alternate/' + alternate + '/label'), alternate as string);
    } else {
      this.onTextTyped.emit('');
      // this.showAlternates = false;
      this.analytics.onBackToVideo(this.translate.instant('media-capture/video/alternate/back-to-video/label'));
    }
  }

}
