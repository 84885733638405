import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FeedComponent } from './feed.component';
import { FeedResponseItemComponent } from './items/response-item/response-item.component';
import { Routes, RouterModule } from '@angular/router';
import { ModelsModule } from 'src/app/core/models/models.module';
import { FeedMessageItemComponent } from './items/message-item/message-item.component';
import { SendPostComponent } from './send-post/send-post.component';
import { FormsModule } from '@angular/forms';
import { FeedPostComponent } from './post/post.component';
import { MatIconModule } from '@angular/material/icon';
import { MomentModule, TimeAgoPipe } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { TitleSizeModule } from 'src/app/core/title-size/title-size.module';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { SafePipeModule } from 'safe-pipe';
import { TaskUpcomingComponent } from './post/task-upcoming/task-upcoming.component';
import { TaskCompletedComponent } from './post/task-completed/task-completed.component';
import { PostTaskQuoteComponent } from './post/quote/quote.component';
import { PostInviteComponent } from './post/invite/invite.component';
import { PostMessageComponent } from './post/message/message.component';
import { NgPipesModule } from 'ngx-pipes';
import { PostResponseMediaComponent } from './post/response-media/response-media.component';

const routes: Routes = [
  {
    path: '',
    component: FeedComponent
  }
];

@NgModule({
  declarations: [
    FeedComponent,
    FeedResponseItemComponent,
    FeedMessageItemComponent,
    SendPostComponent,
    FeedPostComponent,
    TaskUpcomingComponent,
    TaskCompletedComponent,
    PostTaskQuoteComponent,
    PostInviteComponent,
    PostMessageComponent,
    PostResponseMediaComponent
  ],
  exports: [
    FeedComponent,
    FeedResponseItemComponent,
    FeedMessageItemComponent,
    SendPostComponent,
    FeedPostComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ModelsModule,
    // PipesModule,
    NgPipesModule,
    // RouterModule.forChild(routes),
    FormsModule,
    MatIconModule,
    MomentModule,
    TitleSizeModule,
    NgxMaskModule.forChild(),
    TranslateModule.forChild(),
    SafePipeModule
  ],
  providers: [
    TimeAgoPipe,
    DatePipe,
    MaskPipe
  ]
})
export class FeedModule { }
