import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { User } from '../core/models/user.model';
import { first } from 'rxjs/operators';
import { UserProfileAnalyticsService } from './user-profile-analytics-service';
import { ModalController, Platform } from '@ionic/angular';
import { DeleteAccountModalComponent } from './delete-account-modal/delete-account-modal.component';
import { Observable } from 'rxjs';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.scss']
})
export class UserProfilePageComponent implements OnInit {

  @ViewChild('fileInput') fileInput;
  processing: string;

  user: User;
  displayName: string;
  displayNameIsEditing = false;
  profilePictureIconVisible: boolean;
  progress = 0;

  languages = [
    {
      code: 'en',
      language: 'english'
    },
    {
      code: 'es',
      language: 'spanish'
    },
    {
      code: 'fr',
      language: 'french'
    },
    {
      code: 'ar',
      language: 'arabic'
    }
  ]

  constructor(
    public authService: AuthService,
    public analytics: UserProfileAnalyticsService,
    private modalCtrl: ModalController,
    public platform: Platform,
  ) {
  }

  async ngOnInit() {
    this.user = await this.authService.user$.pipe(first()).toPromise();
  }

  async changeLanguage($event: any) {
    this.user.language = $event.target.value;
    await this.user.save();
    window.location.reload();
  }

  setDisplayName(): void {
    this.analytics.createFullNameSaveButtonClickedEvent();
    this.user.save();
    this.displayNameIsEditing = false;
  }

  setProfilePictureUploadIconVisibility(visible: boolean) {
    if (this.isUploading() && visible === true) {
      return;
    }
    this.profilePictureIconVisible = visible;
  }

  isUploading(): boolean {
    return this.progress > 0 && this.progress < 100;
  }

  setUploadProgress(event): void {
    this.progress = event;
    if (this.progress == 0) {
      this.analytics.createProfilePictureFileSelectedClickedEvent();
    }
    this.toggleIconVisibilityOnUpload();
  }

  private toggleIconVisibilityOnUpload() {
    if (this.isUploading() && this.profilePictureIconVisible) {
      this.setProfilePictureUploadIconVisibility(false);
    }
  }

  setProfilePicture(url: string): void {
    this.user.photoURL = url;
    this.user.save();
    this.analytics.createProfilePictureUploadedEvent();
  }

  async removeProfile() {
    const modal = await this.modalCtrl.create({
      component: DeleteAccountModalComponent,
      componentProps: {
        user: this.user
      },
      cssClass: 'auto-height delete-account'
    });

    await modal.present();
    // this.analytics.createProfilePictureRemoveClickedEvent();
  }

  // Profile image
  imageChangedEvent: any;
  cropperReady = false;
  imageToUpload: File;
  uploadPercent$: Observable<number>;

  public async openSelectDialog() {
    this.fileInput.nativeElement.click();
  }

  public async fileSelected($event) {
    this.processing = 'preview';
    this.imageChangedEvent = $event;
    this.processing = null;
  }

  imageCropped($event: ImageCroppedEvent) {
    const fileName = this.user.id + '-' + Date.now() + '.jpg';
    if (this.platform.is('cordova')) {
      this.imageToUpload = base64ToFile($event.base64) as File;
      (this.imageToUpload as any).lastModifiedDate = new Date();
      (this.imageToUpload as any).name = fileName;
    } else {
      this.imageToUpload = new File([base64ToFile($event.base64)], fileName);
    }
  }

  imageBase64: string;

  async saveImage() {
    this.processing = 'profile-image';
    if (this.imageToUpload) {
      const { ref, uploadTask } = this.user.uploadFile('avatar', this.imageToUpload, {
        contentType: 'image/jpeg',
      });
      this.uploadPercent$ = uploadTask.percentageChanges();
      const task = await uploadTask;
      this.user.photoURL = await task.ref.getDownloadURL();
      this.uploadPercent$ = null;
      this.imageToUpload = null;
      this.cropperReady = false;
    }

    await this.user.save();
    this.processing = null;
  }




}
