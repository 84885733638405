import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrganizationRoutingModule } from './organization-routing.module';

import { OrganizationPage } from './organization.page';
import { OrganizationMembersComponent } from './organization-members/organization-members.component';
import { OrganizationWorkspacesComponent } from './organization-workspaces/organization-workspaces.component';
import { OrganizationPackageComponent } from './organization-package/organization-package.component';
import { OrganizationProfileComponent } from './organization-profile/organization-profile.component';
import { AppComponentsModule } from '../core/app-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ModelsModule } from '../core/models/models.module';
import { OrganizationInviteModalComponent } from './organization-invite-modal/organization-invite-modal.component';
import { OrganizationCreateModalComponent } from './organization-create-modal/organization-create-modal.component';
import { CurrentPackageComponent } from './organization-package/current-package/current-package.component';
import { OrganizationPlansComponent } from './organization-plans/organization-plans.component';
import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import { NgxPopperModule } from 'ngx-popper';
import { NgxStripeModule } from 'ngx-stripe';
import { OrganizationBillingComponent } from './organization-billing/organization-billing.component';
import { OrganizationInvoiceListComponent } from './organization-invoice-list/organization-invoice-list.component';
import { OrganizationService } from './organization.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { UpgradeRequiredModalComponent } from './upgrade-required-modal/upgrade-required-modal.component';
import { LogoutRequiredModalComponent } from './logout-required-modal/logout-required-modal.component';
import { NgPipesModule } from 'ngx-pipes';
import { OrganizationEnterpriseComponent } from './organization-enterprise/organization-enterprise.component';
import { NgxMaskModule } from 'ngx-mask';
import { CancelSubscriptionConfirmModalComponent } from './organization-package/current-package/cancel-subscription-confirm-modal/cancel-subscription-confirm-modal.component';
import { OrganizationIntegrationsComponent } from './organization-integrations/organization-integrations.component';
import { OrganizationMemberDeleteModalComponent } from './organization-members/organization-member-delete-modal/organization-member-delete-modal.component';
import { OrganizationMemberReactivateModalComponent } from './organization-members/organization-member-reactivate-modal/organization-member-reactivate-modal.component';
import { OrganizationAnalyticsService } from './organization.analytics.service';
import { AnalyticsModule } from '../core/analytics/analytics.module';
import { SafePipeModule } from 'safe-pipe';
import { PipesModule } from '../core/pipes/pipes.module';
import { ClipboardModule } from 'ngx-clipboard';
import { OrganizationJoinComponent } from './organization-join/organization-join.component';
import { AcceptInvitationModalComponent } from './accept-invitation-modal/accept-invitation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    OrganizationRoutingModule,
    AppComponentsModule,
    TranslateModule.forChild(),
    MatIconModule,
    ModelsModule,
    NgxPopperModule,
    NgxStripeModule.forRoot(),
    NgxStripeModule.forChild(),
    NgPipesModule,
    NgxMaskModule.forChild(),
    AnalyticsModule.forChild(),
    SafePipeModule,
    PipesModule,
    ClipboardModule
  ],
  declarations: [
    OrganizationPage,
    OrganizationProfileComponent,
    OrganizationMembersComponent,
    OrganizationWorkspacesComponent,
    OrganizationPackageComponent,
    OrganizationInviteModalComponent,
    AcceptInvitationModalComponent,
    OrganizationCreateModalComponent,
    OrganizationPlansComponent,
    OrganizationCreateComponent,
    CurrentPackageComponent,
    OrganizationBillingComponent,
    OrganizationInvoiceListComponent,
    UpgradeRequiredModalComponent,
    LogoutRequiredModalComponent,
    OrganizationEnterpriseComponent,
    CancelSubscriptionConfirmModalComponent,
    OrganizationIntegrationsComponent,
    OrganizationMemberDeleteModalComponent,
    OrganizationMemberReactivateModalComponent,
    OrganizationJoinComponent,
  ],
  entryComponents: [
    OrganizationInviteModalComponent,
    AcceptInvitationModalComponent,
    OrganizationCreateModalComponent,
    OrganizationPlansComponent,
    OrganizationCreateComponent,
    UpgradeRequiredModalComponent,
    LogoutRequiredModalComponent,
    OrganizationEnterpriseComponent,
    CancelSubscriptionConfirmModalComponent,
    OrganizationMemberDeleteModalComponent,
    OrganizationMemberReactivateModalComponent,
    OrganizationJoinComponent,
  ],
  exports: [
    UpgradeRequiredModalComponent,
    LogoutRequiredModalComponent,
  ],
  providers: [
    OrganizationAnalyticsService
  ]
})
export class OrganizationModule {
  static forRoot(
    service?: OrganizationService
  ): ModuleWithProviders {
    return {
      ngModule: OrganizationModule,
      providers: [
        OrganizationService,
      ]
    };
  }
}
