import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { TeamSidebarComponent } from './team-sidebar.component';
import { WorkspaceModule } from '../workspace.module';
import { PrivilegeModule } from '../../privilege/privilege.module';
import { MatIconModule } from '@angular/material/icon';
import { AnalyticsProvider } from '../../analytics/analytics';
import { WorkspaceOrderPipe } from '../../pipes/workspace-order.pipe';
import { AnalyticsModule } from '../../analytics/analytics.module';
import { TeamSidebarAnalyticsService } from './team-sidebar.analytics.service';
import { SafePipeModule } from 'safe-pipe';

@NgModule({
            imports: [
              CommonModule,
              RouterModule,
              IonicModule,
              TranslateModule.forChild(),
              PipesModule,
              WorkspaceModule,//.forChild()
              PrivilegeModule,
              MatIconModule,
              AnalyticsModule,
              SafePipeModule
            ],
            exports: [
              TeamSidebarComponent
            ],
            declarations: [
              TeamSidebarComponent
            ],
            providers: [
              TeamSidebarAnalyticsService,
              WorkspaceOrderPipe,
            ]
          })
export class TeamSidebarModule {}
