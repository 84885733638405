import { DatePipe } from '@angular/common';
import { TimeAgoPipe } from 'ngx-moment';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteLayoutItem } from 'src/app/core/models/layout-item-quote';
import { Player } from 'src/app/core/models/player.model';
import { Response } from 'src/app/core/models/response';
import { Role } from 'src/app/core/models/role.model';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { Task } from 'src/app/core/models/task.model';
import { User } from 'src/app/core/models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'post-task-completed',
  templateUrl: './task-completed.component.html',
  styleUrls: ['./task-completed.component.scss'],
})
export class TaskCompletedComponent implements OnInit {

  @Input()
  public tasks: Task[];

  @Input()
  public players: Player[];

  @Input()
  public roles: Role[];

  @Input()
  public users: User[];

  @Input()
  public myPlayer: Player;

  @Input()
  public task: Task;

  @Input()
  public responses: Response[];

  public LayoutItem = LayoutItem;

  videoType: 'mp4' | 'youtube';
  videoURL: string;

  role: Role;
  user: User;
  response: Response;
  responseTask: Task;

  title: string;
  timeString: string;

  hasQuote: boolean;
  quoteTask: Task;
  quoteResponse: Response;
  quotePlayer: Player;
  quoteUser: User;
  quoteRole: Role;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private amAgoPipe: TimeAgoPipe,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.responseTask = this.task;
    if (this.myPlayer) {
      this.user = this.users.find(user => user.ref.path === this.myPlayer.userRef.path);
      this.role = this.roles.find(role => role.ref.path === this.myPlayer.roleRef.path);
      this.response = this.responses.find(response => response.taskID === this.task.id);
      this.timeString = this.getTimeString();
      this.title = this.response.mediaType 
        ? this.translate.instant('playing/feed/post/task-completed/has-input')
        : this.translate.instant('playing/feed/post/task-completed/no-input')
    }
    if (this.task.hasQuote) {
      const quoteLayoutItem = this.task.layout.getItemByType(LayoutItem.TYPE_QUOTE, false) as QuoteLayoutItem;
      this.quoteTask = this.tasks.find(task => task.ref.path === quoteLayoutItem.properties.taskRef.path);

      // console.log(this.quoteTask);
      // this.players.find(player => {
      //   player.
      // })
      this.quoteResponse = this.responses.find(
        response => response.taskID === this.quoteTask.id
      );
      this.quotePlayer = this.players.find(player => player.ref.path === this.quoteResponse.playerRef.path);
      this.quoteUser = this.users.find(user => user.ref.path === this.quotePlayer.userRef.path);
      this.quoteRole = this.roles.find(role => role.ref.path === this.quotePlayer.roleRef.path);

      // this.title = this.role.title + ' (' + this.user.displayName + ') has shared response with you';

      if(this.response.mediaType) {
        this.hasQuote = true;
      } else {
        // if(this.task.id === 'pnSbrhbXi3bMEQhv113g') {
        //   console.log('NO HAS MEDIA')
        // }
        this.response = this.quoteResponse;
        this.responseTask = this.quoteTask;
        this.user = this.quoteUser;
        this.role = this.quoteRole;
        this.title = this.translate.instant('playing/feed/post/task-completed/no-input/has-quote/other', {user: this.user.displayName, role: this.role.title});
      }

    }
  }

  private getTimeString() {
    const time = this.task.getResponseTime(this.myPlayer);
    const oneDayAgo = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
    // day hour  min  sec  msec
    if (oneDayAgo < time.getTime()) {
      return this.amAgoPipe.transform(time);
    } else {
      return this.datePipe.transform(time, 'short');
    }
  }

  @Input()
  clickable = true

  @HostListener('click', ['$event'])
  async action($event: any) {
    if(this.clickable) {
      return await this.router.navigate(['../../', 'task', 'view'], { queryParams: { id: this.task.id }, relativeTo: this.route });
    }
  }

}
