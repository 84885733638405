import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperadminRoutingModule } from './superadmin-routing.module';
import { LoginAsPageModule } from './login-as/login-as.module';
import { SetSuperadminPageModule } from './set-superadmin/set-superadmin.module';
import { DashboardPageModule } from './dashboard/dashboard.module';
import { EmailVerificationPageModule } from './email-verification/email-verification.module';
import { SuperadminPlanListComponent } from './plan/list/list.component';
import { SuperadminPlanModule } from './plan/plan.module';
import { SuperadminOrganizationModule } from './organization/organization.module';
import { GenerateUrlPageModule } from './generate-url/generate-url.module';
import { SuperadminAnalyticsSessionsPageModule } from './analytics/sessions/sessions.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LoginAsPageModule,
    SetSuperadminPageModule,
    SuperadminRoutingModule,
    DashboardPageModule,
    EmailVerificationPageModule,
    SuperadminPlanModule,
    SuperadminOrganizationModule,
    GenerateUrlPageModule,
    SuperadminAnalyticsSessionsPageModule
  ]
})
export class SuperadminModule { }
