<div class="modal-content ion-padding">
  <img *ngIf="organization?.imageURL" [src]="organization?.imageURL" />
  <h4 class="text-center">{{invitedBy}} invited you to join "{{organization?.title}}" as a {{level}}.</h4>
  <div class="mt-4 text-right">
    <ion-button
      (click)="onCancel()"
      class="mr-3"
      color="secondary"
      shape="round"
      >{{ "general/cancel" | translate }}
    </ion-button>
    <ion-button [disabled]="processing" (click)="onAccept()" shape="round" color="primary">
      <ion-spinner *ngIf="processing"></ion-spinner>
      Accept Invitation
    </ion-button>
  </div>
</div>