<div class="modal-content ion-padding">
  <h2 class="modal-title">Update available</h2>
  <p>
    The app is outdated.<br />
    Please, download the latest version.
  </p>
  <p>
    Current version:{{updateService.currentVersion}}<br />
    Required version:{{updateService.minimumVersion}}<br />
  </p>
  <div class="ion-text-right">
      <ion-button class="text-uppercase" (click)="goToUpdate()" fill="clear" color="primary">Ok</ion-button>
  </div>
</div>
