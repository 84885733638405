import { Component, OnInit, ViewChild } from '@angular/core';
import { Role } from 'src/app/core/models/role.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Script } from 'src/app/core/models/script.model';
import { Task } from 'src/app/core/models/task.model';
// import { demoAiJSON } from './demo2';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { TextLayoutItem } from 'src/app/core/models/layout-item-text';
import { InputChoiceLayoutItem } from 'src/app/core/models/layout-item-input-choice';
import { InputTextLayoutItem } from 'src/app/core/models/layout-item-input-text';
import { InputPhotoLayoutItem } from 'src/app/core/models/layout-item-input-photo';
import { InputVideoLayoutItem } from 'src/app/core/models/layout-item-input-video';
import { Rule } from 'src/app/core/models/rule';
import { first } from 'rxjs/operators';
import { demo } from './demo3';
import { demoAiJSON } from './demo5';
import { SimulatorComponent } from 'src/app/designer/simulator/simulator.component';

interface RuleItemType { role: Role; task: Task; value: string | boolean; }
// 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

@Component({
  selector: 'app-content-generator-demo',
  templateUrl: './content-generator-demo.component.html',
  styleUrls: ['./content-generator-demo.component.scss'],
})
export class ContentGeneratorDemoComponent implements OnInit {
  @ViewChild("simulator") simulator: SimulatorComponent;

  // demoAiJSON = demoAiJSON;
  topic: string = 'Fire safety';
  learningObjectives = '';
  learningStructure = '';
  methodology = '';
  gameInfo: string = '';
  roleInfo: string = 'Two roles game';

  jsonText: string;
  json: {
    game: {
      title: string,
      description: string,
    },
    roles: Array<{
      id: string;
      title: string;
    }>,
    tasks: Array<{
      id: string;
      roleID: string; // ID of the role
      title: string;
      text?: string;
      // image?: string;
      // imageDescription: string;
      // video?: string;
      // videoDescription: string;
      inputType: 'input-choice' | 'input-text' | 'input-photo' | 'input-video';
      inputInstruction: string;
      inputChoiceOptions: Array<string>;

      dependency: {
        condition: "AND" | "OR",
        rules: [
          {
            roleID: string,
            taskID: string,
            value: "completed" | number
          }
        ]
      };
      point: number; // point based on difficulty
      terminate: boolean; // Is finishing task
    }>
  }

  processing = false;

  version: ScriptVersion;
  script: Script;
  roleMap: Map<string, Role>;
  taskMap: Map<string, Task>;
  roles: Array<Role>;
  tasks: Array<Task>;

  constructor(
    private modelProvider: ModelProvider,
    private workspaceService: WorkspaceService
  ) { }

  async ngOnInit() {

  }

  async submit() {
    this.processing = true;
    try {

      this.gameInfo = '';
      this.gameInfo += this.topic.trim().length > 0 ? 'Topic:\n' + this.topic : '';
      this.gameInfo += this.learningObjectives.trim().length > 0 ? "\n\nLearning objectives:\n" + this.learningObjectives.trim() : '';
      this.gameInfo += this.learningStructure.trim().length > 0 ? "\n\nLearning structure:\n" + this.learningStructure.trim() : '';
      this.gameInfo += this.methodology.trim().length > 0 ? "\n\nMethodology:\n" + this.methodology.trim() : '';

      const response =  await this.modelProvider.functions.httpsCallable('aiGenerateScript', { timeout: 530 * 1000 })({
        gameInfo: this.gameInfo,
        roleInfo: this.roleInfo
        // title: title,
        // category: category,
        // size: size,
      }).pipe(first()).toPromise();
      console.log(response.content);
      this.json = JSON.parse(response.content.trim());
      // this.json = JSON.parse(this.jsonText);
      console.log(this.json)
      this.script = await this.getGame();
      console.log(this.script);
      this.version = await this.getVersion(this.script);
      console.log(this.version);
      this.roleMap = await this.getRoles(this.version);
      this.taskMap = await this.getTasks(this.version);
      this.roles = Array.from(this.roleMap, ([id, role]) => role);
      this.tasks = Array.from(this.taskMap, ([id, task]) => task);
      console.log(this.roles, this.tasks);
      await new Promise(resolve => setTimeout(resolve, 300));  
      this.simulator.start();
    } catch (error) {
      throw error;
    } finally {
      this.processing = false;
    }

  }

  async loadDemo() {
    this.topic = demo.topic;
    this.learningObjectives = demo.objective;
    this.learningStructure = demo.structure;
    this.methodology = demo.methodology;
    this.roleInfo = demo.role;

    await new Promise(resolve => setTimeout(resolve, 3000));
    this.json = demoAiJSON as any;
    console.log(this.json)
    this.script = await this.getGame();
    console.log(this.script);
    this.version = await this.getVersion(this.script);
    console.log(this.version);
    this.roleMap = await this.getRoles(this.version);
    this.taskMap = await this.getTasks(this.version);   
    this.roles = Array.from(this.roleMap, ([id, role]) => role);
    this.tasks = Array.from(this.taskMap, ([id, task]) => task);
    console.log(this.roles, this.tasks);
    await new Promise(resolve => setTimeout(resolve, 300));  
    this.simulator.start();
  }

  async getGame() {
    const script = this.modelProvider.script.create('scripts/aiDemo', {});
    const workspace = this.workspaceService.activeWorkspace;
    script.workspaceRef = workspace.ref;
    script.active = true;
    return script;
  }

  async getVersion(script: Script) {
    const version = this.modelProvider.scriptVersion.create(script.ref.path + '/versions/draft', {
      title: this.json.game.title,
      description: this.json.game.description,
    });

    version.active = true;
    version.gameMode = 'multiplayer';
    version.hasFacilitator = false;
    // version.languages = {
    //   'en-us': true
    // }

    return version;
  }

  async getRoles(version: ScriptVersion) {
    const roles = new Map<string, Role>();
    for (let roleIndex = 0; roleIndex < this.json.roles.length; roleIndex++) {
      const roleData = this.json.roles[roleIndex];

      const role = this.modelProvider.role.create(version.ref.path + '/roles/' + roleData.id, {
        title: roleData.title,
        type: 'player',
        order: roleIndex
      });

      roles.set(roleData.id, role);
    }

    return roles;
  }

  async getTasks(version: ScriptVersion) {
    const tasks = new Map<string, Task>();
    for (let taskIndex = 0; taskIndex < this.json.tasks.length; taskIndex++) {
      const taskData = this.json.tasks[taskIndex];

      const task = this.modelProvider.task.create(version.ref.path + '/tasks/' + taskData.id, {
        title: taskData.title
      });
      task.roleRef = this.roleMap.get(taskData.roleID).ref;
      // task.title = taskData.title;
      task.point = taskData.point;
      task.terminate = taskData.terminate;
      if (taskData.text) {
        const text: TextLayoutItem = task.layout.getItemByType(LayoutItem.TYPE_TEXT, true) as TextLayoutItem;
        text.text = taskData.text;
      }
      if (taskData.inputType === 'input-choice') {
        const choice: InputChoiceLayoutItem = task.layout.getItemByType(LayoutItem.TYPE_INPUT_CHOICE, true) as InputChoiceLayoutItem;
        if (taskData.inputInstruction) {
          choice.question = taskData.inputInstruction;
          // choice.setFields(choice.toData())

          // console.log(choice);
        }
        if(taskData.inputChoiceOptions?.length > 0) {
          for (const choiceText of taskData.inputChoiceOptions) {
            const _choice = choice.addChoice(choiceText);
            _choice.text = choiceText;
          }
        }
      }
      if (taskData.inputType === 'input-text') {
        const inputText: InputTextLayoutItem = task.layout.getItemByType(LayoutItem.TYPE_INPUT_TEXT, true) as InputTextLayoutItem;
        if (taskData.inputInstruction) { inputText.question = taskData.inputInstruction; }
      }

      if (taskData.inputType === 'input-photo') {
        const inputPhoto: InputPhotoLayoutItem = task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO, true) as InputPhotoLayoutItem;
        // inputPhoto.properties.
        // if(taskData.inputInstruction) { inputPhoto = taskData.inputInstruction; }
      }

      if (taskData.inputType === 'input-video') {
        const inputVideo: InputVideoLayoutItem = task.layout.getItemByType(LayoutItem.TYPE_INPUT_VIDEO, true) as InputVideoLayoutItem;
        // inputVideo.getInstructionText
        // if(taskData.inputInstruction) { inputPhoto = taskData.inputInstruction; }
      }

      if(taskData.dependency) {
        console.log(taskData);
        task.dependency.condition = taskData.dependency.condition;
        for (const dependencyData of taskData.dependency.rules) {
          if(dependencyData.taskID === taskData.id) { continue; }
          const ruleItem: RuleItemType = {
            role: this.roleMap.get(dependencyData.roleID),
            task: tasks.get(dependencyData.taskID),
            value: dependencyData.value === 'completed'
              ? dependencyData.value
              : this.getChoiceIdByIndex(dependencyData.value, dependencyData.taskID, tasks.get(dependencyData.taskID))
          }
          task.dependency.ruleSet.push(this.getRuleByRuleItem(ruleItem, task));
        }  
      }

      tasks.set(taskData.id, task);
    }

    return tasks;
  }

  getChoiceIdByIndex(index: string | number, taskDataID: string, task: Task) {
    const indexNumber = typeof index === 'string' ? parseInt(index): index;

    // const taskDataID = this.tasks

    console.log(taskDataID, this.json.tasks.find(task => task.id === taskDataID))

    const choiceText = this.json.tasks.find(task => task.id === taskDataID).inputChoiceOptions[indexNumber - 1];
    let foundChoiceID = null;
    for (const choiceID in task.layout.choice.choices) {
      if (Object.prototype.hasOwnProperty.call(task.layout.choice.choices, choiceID)) {
        const choice = task.layout.choice.choices[choiceID];
        if(choice.text === choiceText) {
          foundChoiceID = choiceID;
        }
      }
    }

    return foundChoiceID;
  }

  getRuleByRuleItem(ruleItem: RuleItemType, task: Task) {
    return new Rule({
      taskRef: ruleItem.task && ruleItem.task.ref,
      operator: (ruleItem.task && ruleItem.value !== 'completed' && ruleItem.task.hasMultiChoiceLayout()) ? 'chosen' : 'done',
      layoutID: (ruleItem.task && ruleItem.value !== 'completed' && ruleItem.task.hasMultiChoiceLayout()) ?
        ruleItem.task.layout.getLayoutIDByType(LayoutItem.TYPE_INPUT_CHOICE)
        : null,
      required: true,
      value:
        (ruleItem.task && ruleItem.value !== 'completed' && ruleItem.task.hasMultiChoiceLayout())
          ? ruleItem.value
          : 'completed'
    }, task, {}, this.modelProvider);
  }

  getColumnSizeByNumOfRoles(): string {
    switch (this.roles.length) {
      case 1:
        return "col-12";
      case 2:
        return "col-6";
      case 3:
        return "col-4";
      default:
        return "col-3";
    }
  }

}