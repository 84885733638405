import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { PrivilegeService } from './privilege.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { Subscription } from 'rxjs';
import { WorkspaceMember } from '../models/workspace-member';

@Pipe({
  name: 'privilege',
  // pure: false
})
export class PrivilegePipe implements OnDestroy, PipeTransform {
  public subscriptions: Subscription = new Subscription();
  
  private _return: boolean;
  private _role: string;

  constructor(
    private workspaceService: WorkspaceService,
    private privilegeService: PrivilegeService,
    private _ref: ChangeDetectorRef,
  ) {

    this.subscriptions.add(
      this.workspaceService.activatedWorkspace.subscribe( async workspace => {
        this._return = await this.transform(this._role);
        this.update();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async transform(role: string, member?: WorkspaceMember) {
    this._role = role;
    this._return = await this.privilegeService.hasAccess(role, member);
    return this._return;
  }

  private update() {
    this._ref.markForCheck();
  }

}
