import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ModelsModule } from 'src/app/core/models/models.module';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { SuperadminOrganizationListComponent } from './list/list.component';
import { SuperadminOrganizationEditComponent } from './edit/edit.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    SuperadminOrganizationListComponent,
    SuperadminOrganizationEditComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ModelsModule,
    AppComponentsModule,
    TranslateModule.forChild()
  ]
})
export class SuperadminOrganizationModule { }
