<app-header active="superadmin">
  <ion-title>Login as</ion-title>
</app-header>

<ion-content>
  <content-container class="my-5 pt-3 pb-3" pageTitle="Login as" back-button="/superadmin/dashboard">
    <ion-button *ngIf="environment.app === 'designer'" color="secondary" shape="round" class="alternate-app" target="_blank" href="https://play.gamoteca.com/superadmin/login-as">Login as Player</ion-button>
    <ion-button *ngIf="environment.app === 'player'" color="secondary" shape="round" class="alternate-app" target="_blank" href="https://app.gamoteca.com/superadmin/login-as">Login as Designer</ion-button>
    <div class="alert alert-warning mb-3 col-8" role="alert">
      <!-- <ion-icon name="information"></ion-icon> -->
      You can find user ID by email address <a class="alert-link" target="_blank" href="https://console.firebase.google.com/project/{{projectId}}/authentication/users">here</a>
    </div>
    <div class="row">
      <form #form="ngForm" (ngSubmit)="submit()" class="col-sm-4">
        <div class="form-group">
          <label for="userID" class="label-with-popper">
            <span>User ID</span>
            <!-- <ion-icon name="help-circle" class="ml-1" [popper]="userIDPopper" [popperTrigger]="'hover'"
              [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'"></ion-icon>
            <popper-content popperApplyClass="popover-color" #userIDPopper>
              You can find user ID by email address <a target="_blank" href="https://console.firebase.google.com/project/{{projectId}}/authentication/users">here</a>
            </popper-content> -->
          </label>
          <input #userId="ngModel"
                type="text"
                [(ngModel)]="userID"
                required
                name="userID"
                id="userID">
          <div *ngIf="userId.touched && userId.hasError('required')"
               class="alert invalid-field">
            {{'task-edit/form/global/required' | translate}}
          </div>
        </div>
        <div class="form-group">
          <ion-button [disabled]="processing" color="primary" shape="round" type="submit">
            <ion-spinner *ngIf="processing === userID" name="lines-small"></ion-spinner>
            Login as
          </ion-button>
        </div>
      </form>
    </div>
  </content-container>
  <content-container class="mt-3" pageTitle="Last {{usersLimit}} users signed in" [back-button]="false">
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="text-left border-top-0" scope="col">Name</th>
          <th class="text-left border-top-0" scope="col">Email</th>
          <th class="text-left border-top-0" scope="col">Last login at</th>
          <th class="text-left border-top-0" scope="col">App</th>
          <th scope="col" class="border-top-0"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users">
          <td class="align-middle">{{user.displayName}}</td>
          <td class="align-middle">{{user.email}}</td>
          <td class="align-middle">{{user.lastLoginAt.toDate() | date: 'short'}}</td>
          <td class="align-middle">
            <ng-container *ngFor="let appItem of (user.roles | keyvalue); let i = index">
              <ion-badge class="mx-1" color="warning text-capitalize">{{appItem.key}}</ion-badge>
            </ng-container>
          </td>
          <td class="text-right align-middle">
            <ion-button (click)="submit(user.id)" [disabled]="processing" color="primary" shape="round" type="button">
              <ion-spinner *ngIf="processing === user.id" name="lines-small"></ion-spinner>
              Login as
            </ion-button>
          </td>
        </tr>
      </tbody>
    </table>
  </content-container>
</ion-content>
