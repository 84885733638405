import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { MemberChipComponent } from './member-chip/member-chip.component';
import { PipesModule } from '../core/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
            imports: [
              CommonModule,
              RouterModule,
              IonicModule,
              AppComponentsModule,
              TranslateModule.forChild(),
              PipesModule,
              MatIconModule
            ],
            exports: [
              // MemberChipComponent
            ],
            declarations: [
              // MemberChipComponent
            ]
          })
export class PlayComponentsModule {}
