import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BrowserRecommendationService } from '../browser-recommendation.service';

@Component({
  selector: 'app-browser-recommendation-modal',
  templateUrl: './browser-recommendation-modal.component.html',
  styleUrls: ['./browser-recommendation-modal.component.scss'],
})
export class BrowserRecommendationModalComponent implements OnInit {

  constructor(
    public modalController: ModalController,
    public browserRecommendationService: BrowserRecommendationService,
  ) { }

  ngOnInit() { }

  async onCancel() {
    console.log('on cancel')
    await this.browserRecommendationService.modal.dismiss();
  }

}
