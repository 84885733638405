import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ContextMenuGroupComponent } from '../context-menu-group/context-menu-group.component';

@Component({
  selector: 'context-menu-icon',
  templateUrl: './context-menu-icon.component.html',
  styleUrls: ['./context-menu-icon.component.scss'],
})
export class ContextMenuIconComponent implements OnInit {
  @ViewChild('contextGroup', {static: true}) contextGroup: ContextMenuGroupComponent;
  @Input('icon-name') matIcon = 'more_vert';

  constructor() { }

  ngOnInit() {}

}
