import { Component, Input, OnInit } from '@angular/core';
import { Script } from 'src/app/core/models/script.model';
import { ModalController } from '@ionic/angular';
import { ScriptDeleteConfirmationDialogAnalyticsService } from './script-delete-confirmation-dialog.analytics.service';

@Component({
             selector: 'app-publish-confirmation',
             templateUrl: './script-delete-confirmation-dialog.component.html',
             styleUrls: ['./script-delete-confirmation-dialog.component.scss']
           })
export class ScriptDeleteConfirmationDialogComponent implements OnInit {

  @Input() script: Script;

  constructor(private modalCtrl: ModalController, public analytics: ScriptDeleteConfirmationDialogAnalyticsService) {
  }

  ngOnInit() {
    this.analytics.onOpened();
  }

  onCancel(closeType: string) {
    this.analytics.onCancelClicked(closeType);
    this.modalCtrl.dismiss({action: 'cancel'});
  }

  public async onDelete() {
    this.analytics.onDeleteButtonClicked();
    this.modalCtrl.dismiss({action: 'delete'});
  }
}
