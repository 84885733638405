import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Player } from 'src/app/core/models/player.model';
import { Play } from 'src/app/core/models/play.model';
import { PlayService } from '../play.service';
import { Role } from 'src/app/core/models/role.model';
import { Team } from 'src/app/core/models/team.model';
import { PlayDetailsAnalyticsService } from '../play-details/play-details-analytics';
import { first, map } from 'rxjs/operators';
import { AlertController, ActionSheetController, Platform, ToastController, PopoverController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { PlayStatusAnalyticsService } from './play-status-analytics.service';
import { RoleSelectComponent } from '../role-select/role-select.component';
import { Workspace } from 'src/app/core/models/workspace.model';
import { CloudMessagingService } from 'src/app/core/cloud-messaging/cloud-messaging.service';

@Component({
  selector: 'play-status',
  templateUrl: './play-status.component.html',
  styleUrls: ['./play-status.component.scss'],
  providers: [
    SocialSharing
  ]
})
export class PlayStatusComponent implements OnInit {

  processing: string;

  @Input()
  player: Player;

  @Input()
  roles: Role[];

  playerRoles: Role[];

  private _play: Play;
  public get play(): Play {
    return this._play;
  }
  @Input()
  public set play(value: Play) {
    this._play = value;

    if (this.play.type === 'organized-game') {
      this.type = 'organized-game';
    } else if (this.play.type === 'public-game') {

      if (this.play.accessLevel === 'private') {
        this.type = 'instant-game'
      } else {
        this.type = 'invite-game';
      }
    }
  }

  type: 'organized-game' | 'instant-game' | 'invite-game';

  constructor(
    public playService: PlayService,
    private analytics: PlayStatusAnalyticsService,
    public alertController: AlertController,
    public actionSheetController: ActionSheetController,
    public translate: TranslateService,
    private clipboard: ClipboardService,
    private socialSharing: SocialSharing,
    public platform: Platform,
    private toastController: ToastController,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modelProvider: ModelProvider,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private cloudMessaging: CloudMessagingService
  ) { }

  ngOnInit() {
    // this.playerRoles = this.playService.roles.filter((role) => role.type !== 'facilitator');
  }

  ngOnChanges() {
    this.playerRoles = this.playService.roles.filter((role) => role.type !== 'facilitator');
  }

  public async playClicked() {

    const actualTasks = await this.playService.myTasksActual$.pipe(first()).toPromise();
    if (actualTasks.length === 0) {
      this.router.navigate(['/playing/play', this.play.id, 'task', 'list'], { relativeTo: this.route });
    }

    if (actualTasks.length > 0) {
      // this.router.navigate(['/playing/play', this.play.id, 'task', 'list'], { relativeTo: this.route });
      await this.router.navigate(['/playing/play/', this.play.id, 'task', 'view'], { queryParams: { id: actualTasks[actualTasks.length - 1].id }, relativeTo: this.route });
      // this.router.navigate(['playing/play/', this.play.id, 'task', 'view', actualTasks[0].id], { skipLocationChange: true });
    }
  }

  public async joinClicked() {
    this.processing = 'join';
    console.error('joinPlay clicked - 1');
    this.analytics.onJoinGameButtonClicked();
    console.error('joinPlay clicked - 2');
    if (this.playService.myPlayer && this.playService.myPlayer.role && this.playService.myPlayer.team) {
      return;
    }


    try {
      let role: Role;
      // const freeRoles = await this.getFreeRoles();
      // if (this.playService.play.slots.available === 0) {
      //   return;
      // }

      // if (this.playService.play.slots.available === 1) {
      //   role = freeRoles[0];
      // }

      // if (this.playService.play.slots.available > 1) {
      //   role = await this.presentRoleSelect(freeRoles);
      // }

      // const workspace = await this.playService.workspace$.pipe(first()).toPromise();

      if (this.play.type === 'organized-game') {
        // if(this.playService.roles )
        const roles = this.playService.roles.filter((role) => role.type !== 'facilitator');

        // role = await this.presentRoleSelect(roles);
        // if (roles.length === 1 && (workspace.getMyMember() || workspace.isPersonal) ) {
        if (roles.length === 1) {
          role = roles[0];
        } else {
          // role = await this.presentRoleSelect(roles, workspace);
          role = await this.presentRoleSelectNew(roles);
        }

      } else {
        const freeRoles = this.getFreeRoles();
        if (freeRoles.length > 1) {
          // role = await this.presentRoleSelect(freeRoles, workspace);
          role = await this.presentRoleSelectNew(freeRoles);
        }
        if (freeRoles.length === 1) {
          role = freeRoles[0];
        }
        if (freeRoles.length === 0) {
          this.processing = undefined;
          return;
        }
      }


      // console.error('joinPlay called');
      await this.modelProvider.functions.httpsCallable('joinPlay')({
        'play-path': this.play.ref.path,
        'role-path': role.ref.path
      }).pipe(first()).toPromise();
    } catch (error) {
      console.error(JSON.stringify(error));
    } finally {
      this.processing = undefined
    }

    // const team = this.getFreeTeam(role);
    // this.joinEvent(role, team);
  }

  public get hasFreeSlot() {
    if (this.play && this.play.slots && this.play.slots.available) {
      return this.play.slots.available > 0;
    }

    return false;
  }

  private getFreeRoles() {
    // const roles = await this.playService.scriptVersion.roles$.pipe(first()).toPromise();
    return Object.entries(this.playService.play.slots.roles)
      .filter(([roleID, slots]) => {
        return slots.available > 0;
      }).map(([roleID, slots]) => {
        return this.roles.find((role) => {
          return role.id === roleID;
        });
      });
  }

  private getFreeTeam(role: Role): Team {
    const teams = this.playService.teams.filter(team => {
      const playerWithRole = this.playService.players.find(
        player => team.getReference().isEqual(player.teamRef) && role.getReference().isEqual(player.roleRef)
      );

      return (playerWithRole === undefined);
    });

    if (teams.length === 0) {
      const team = this.playService.modelProvider.team.create(this.play.ref.path + '/teams', {
        title: 'Team ' + (this.playService.teams.length + 1),
        order: (this.playService.teams.length + 1)
      });
      team.save();

      return team;
    } else {
      return teams[0];
    }
  }

  //   display: inline-block !important;
  //     /* margin-bottom: -5px; */
  //     margin-right: -5px;
  //     font-size: 22px;
  //     line-height: 22px;
  // }

  private async joinEvent(role: Role, team: Team) {
    let player: Player;
    if (this.playService.myPlayer) {
      player = this.playService.myPlayer;
    } else {
      player = this.playService.modelProvider.player.create(this.play.ref.path + '/players', {
        playRef: this.playService.play.ref,
        roleRef: role.ref,
        scriptRef: this.playService.play.scriptRef,
        scriptVersionRef: this.playService.play.scriptVersionRef,
        status: Player.STATUS_ACTIVE,
        type: 'player',
        teamRef: team.ref,
        userRef: this.playService.modelProvider.user.meReference
      });
    }

    player.status = Player.STATUS_ACTIVE;
    player.roleRef = role.ref;
    player.teamRef = team.ref;
    await player.save();
  }

  async presentRoleSelectNew(roles: Role[]): Promise<Role> {
    return new Promise<Role>(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: RoleSelectComponent,
        componentProps: {
          // play: Play
          roles: roles,
          // workspace: workspace
        },
        cssClass: 'auto-height',
        keyboardClose: false,
        backdropDismiss: false,
        showBackdrop: true
      });
      await modal.present();
      this.analytics.onSelectRoleOpened();
      const response = await modal.onWillDismiss();
      if (response.data && response.data.role) {
        this.analytics.onRoleSelected(response.data.role);
        resolve(response.data.role);
      } else {
        this.analytics.onRoleDismissed();
        reject('role-not-selected');
      }
    });
  }

  async presentRoleSelect(roles: Array<Role>): Promise<Role> {
    return new Promise<Role>(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.instant('playing/play-details/role-select/title'),
        cssClass: 'role-selection',
        inputs: roles.map(role => {
          return {
            name: 'role',
            type: 'radio',
            label: role.title,
            value: role
          };
        }),
        buttons: [
          {
            text: this.translate.instant('playing/play-details/role-select/cancel'),
            role: 'cancel',
            handler: data => {
              reject('Cancel clicked');
              this.analytics.onRoleDismissed();
              // console.log('Cancel clicked');
            }
          },
          {
            text: this.translate.instant('playing/play-details/role-select/ok'),
            cssClass: 'ok',
            handler: (_role: Role) => {
              if (_role) {
                resolve(_role);
                this.analytics.onRoleSelected(_role);
              } else {
                reject(_role);
                return false;
              }
            }
          }
        ]
      });
      await alert.present();
      this.analytics.onSelectRoleOpened();
    });
  }

  async invite(text: string, inputElement?: HTMLInputElement | HTMLTextAreaElement) {
    this.analytics.onInviteClicked();
    if (this.platform.is('cordova')) {
      return this.socialSharing.share(text);
    } else {
      if (inputElement) {
        this.clipboard.copyFromInputElement(inputElement);
        const toast = await this.toastController.create({
          message: this.translateService.instant('playing/play-details/status/invite/copy-message'),
          duration: 6500,
          position: "middle",
          cssClass: "copy text-center",
          color: 'dark'
        });
        await toast.present();
      } else {
        this.clipboard.copyFromContent(text)
      }
    }
  }

  async inviteCode(text: string, inputElement?: HTMLInputElement | HTMLTextAreaElement) {
    if (this.platform.is('cordova')) {
      return this.socialSharing.share(text);
    } else {
      if (inputElement) {
        this.clipboard.copyFromInputElement(inputElement);
        const toast = await this.toastController.create({
          message: this.translateService.instant('playing/play-details/status/invite-code/copy-message'),
          duration: 6500,
          position: "middle",
          cssClass: "copy text-center",
          color: 'dark'
        });
        await toast.present();
      } else {
        this.clipboard.copyFromContent(text)
      }
    }
  }

  isNotified$ = this.modelProvider.user.getMe$().pipe(map(user => {
    return typeof user?.device?.web.pushNotificationToken === 'string'
  }));

  async getNotified() {
    this.processing = 'notification';
    try {
      const token = await this.cloudMessaging.requestPermission();
      await this.cloudMessaging.storeToUser(token);
      await new Promise(resolve => setTimeout(resolve, 500));
    } catch (error) {
      throw error;
    } finally {
      this.processing = undefined;
    }
  }


}
