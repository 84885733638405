import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { access } from 'fs/promises';
import { first } from 'rxjs/operators';
import { Play } from 'src/app/core/models/play.model';
import { Role } from 'src/app/core/models/role.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Script } from 'src/app/core/models/script.model';

@Component({
  selector: 'app-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss'],
})
export class RoleSelectComponent implements OnInit {

  @Input()
  mode: 'invite' | 'random' | 'custom' = 'invite';

  @Input()
  public play: Play;

  @Input()
  public script: Script;

  // @Input()
  // public scriptVersion: ScriptVersion;

  @Input()
  public roles: Role[];

  // @Input()
  // public workspace: Workspace;

  selectedRole: Role;
  // addToWorkspaceApproved: boolean;

  // addToWorkspaceRequired: boolean;

  constructor(
    public modalController: ModalController,
    // public popoverController: PopoverController
  ) { }

  async ionViewWillEnter() {
    if (this.roles.length === 1) { this.selectedRole = this.roles[0]; }

    if(this.mode === 'random') {
      // const maxAvailableRole: Role = null;

      const maxAvailableRole: Role = this.roles.reduce((acc, i)=>(this.script.getRandomSessionAvailableSlot(i) > this.script.getRandomSessionAvailableSlot(acc) ? i : acc))
      // this.roles.forEach(role => {
      //   this.script.getRandomSessionAvailableSlot(role)
      // })
      if(maxAvailableRole) {
        this.selectedRole = maxAvailableRole;
      }
    }



    
    // this.roles = await this.scriptVersion.roles$.pipe(first()).toPromise();
    // this.

    // this.addToWorkspaceRequired = !!!(await this.workspace.getMyMember()) && !this.workspace.isPersonal;
  }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
    // this.popoverController.dismiss();
    console.log('dismiss');
  }

  submit() {
    this.modalController.dismiss({ role: this.selectedRole });
    // this.popoverController.dismiss({ role: this.selectedRole });
    // console.log('submit');
  }

}
