import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase/app"
import { Observable } from 'rxjs/Observable';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { ReplaySubject, Subscription } from 'rxjs';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { AnalyticsProvider } from '../core/analytics/analytics';
import { NavController, Platform } from '@ionic/angular';
import { User } from '../core/models/user.model';
import { ModelProvider } from '../core/models/general/model.provider';
import { environment } from 'src/environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';
import { concatMap, filter, first, map, shareReplay } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { SignInWithApple, AppleSignInResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CloudMessagingService } from '../core/cloud-messaging/cloud-messaging.service';
import { OrganizationService } from '../organization/organization.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: firebase.User;

  constructor(
    public toastCtrl: ToastController,
    public translate: TranslateService,
    public afAuth: AngularFireAuth,
    public appVersion: AppVersion,
    private device: Device,
    private platform: Platform,
    private fsDB: AngularFirestore,
    private facebook: Facebook,
    private googlePlus: GooglePlus,
    private firebaseMessaging: FirebaseMessaging,
    public analytics: AnalyticsProvider,
    private modelProvider: ModelProvider,
    private fsFunction: AngularFireFunctions,
    private storage: Storage,
    private router: Router,
    private signInWithAppleService: SignInWithApple,
    private iab: InAppBrowser,
    private navCtrl: NavController,
    private cloudMessaging: CloudMessagingService,
    private injector: Injector,
    private translateService: TranslateService,
  ) {
    afAuth.authState.subscribe(user => {
      this.user = user;
    });
  }

  private _user$: ReplaySubject<User>;
  public get user$(): ReplaySubject<User> {
    if (this._user$ !== undefined) { return this._user$; }
    this._user$ = new ReplaySubject<User>(1);

    let _userLoaded = undefined;

    this.afAuth.authState.subscribe(async fsUser => {
      if (_userLoaded !== undefined && (fsUser && fsUser.uid) === (_userLoaded && _userLoaded.uid)) { return; }

      if (fsUser) {
        try {
          await this.user.getIdTokenResult(true).then(token => console.log(token));
        } catch (error) {
          console.error(error);
        }

        const organizationService = this.injector.get(OrganizationService);
        const organization = await organizationService.getDefaultActiveOrganization();
        await organizationService.setOrganization(organization);

        this.modelProvider.user.getMe$().subscribe(user => {
          _userLoaded = user;
          this._user$.next(user);
          this.isSuperAdmin().then()
        });
      } else {
        _userLoaded = null;
        this._user$.next(null);
      }
    });
    return this._user$;
  }

  async signUp(credentials) {
    if (credentials.name.length < 1) {
      return Promise.reject({ code: 'auth/name-too-short' });
    }
    try {
      const credential = await this.createUserWithEmailAndPassword(credentials);
      const user = await this.authState().pipe(filter(user => !!user), first()).toPromise();
      await this.updateUserProfile(user, credentials);
      await this.saveUserData(credential.user, credentials);
      this.setAnalyticsUser(user, credentials);
      this.sendValidationEmail();
      return credential;
    } catch (error) {
      console.error(error);
      return this.handleExistingEmailError(error, credentials);
    }
  }
  async createUserWithEmailAndPassword(credentials) {
    return await this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password);
  }
  async updateUserProfile(user, credentials) {
    await user.updateProfile({ displayName: credentials.name });
    await user.reload();
  }
  async saveUserData(user, credentials) {
    const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';

    await this.fsDB.collection('users').doc(user.uid).set({
      email: credentials.email,
      displayName: credentials.name,
      company: credentials.company,
      newsletter: credentials.newsletter,
      emailNotifications: credentials.emailNotifications,
      language,
      roles: {
        [environment.app]: {
          active: true,
          lastLoginAt: new Date(),
        }
      }
    }, { merge: true });
  }
  setAnalyticsUser(user, credentials) {
    this.analytics.setUser(user);
    this.analytics.setUserProperties({
      newsletter: credentials.newsletter,
      company: credentials.company,
      'display-name': credentials.name,
    });
  }

  async handleExistingEmailError(error, credentials) {
    if (error.code === 'auth/email-already-in-use') {
      const toast = await this.toastCtrl.create({
        message: await this.translate.get(error.code || error).toPromise(),
        duration: 3000,
        position: 'top'
      });
      await toast.present();
      
    }

    return Promise.reject(error);

  }

  async signOut(): Promise<void> {
    if (this.platform.is('cordova')) {
      const user = await this.user$.pipe(first()).toPromise();
      delete user.device[this.device.uuid];
      await user.save();
      try {
        await this.googlePlus.logout();
      } catch (error) {
        console.error(error);
      }

      try {
        await this.facebook.logout();
      } catch (error) {
        console.error(error);
      }
    }

    return this.afAuth.signOut().then(async (data) => {
      await this.afAuth.authState.pipe(filter(user => !user), first()).toPromise();
      try {
        localStorage.clear();
      } catch (error) {
        console.error(error);
      }

      try {
        sessionStorage.clear();
      } catch (error) {
        console.error(error);
      }

      try {
        this.analytics.logout();
      } catch (error) {
        console.error(error);
      }

      const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';
      await this.translateService.use(language).pipe(first()).toPromise();
      this.translateService.setDefaultLang(language || 'en');

      try {
        await this.storage.clear();
        await this.storage.remove('redirectTo');
      } catch (error) {
        console.log(error);
      }
      return data;
    });
  }

  delete(): Promise<void> {
    return this.user.delete();
  }

  get authenticated(): boolean {
    return this.user !== null;
  }

  async getHomeURL() {
    const storedRedirectTo = await this.storage.get('redirectTo');

    if (storedRedirectTo) {
      return storedRedirectTo;
    }

    return environment.homeURL;
  }

  async removeHomeURL() {
    return await this.storage.remove('redirectTo');
  }

  async setHomeURL(url: string) {
    if (url !== environment.homeURL) {
      return this.storage.set('redirectTo', url);
    }
  }

  async navigateToHome(redirectUrl?: string) {
    this.analytics.event('navigateToHome - 0');
    this.analytics.event('navigateToHome - 1', null, { homeURL: await this.getHomeURL() });

    if(redirectUrl){
      await this.navCtrl.navigateRoot(redirectUrl);
    }else{
      await this.navCtrl.navigateRoot(await this.getHomeURL());
    }
    
    this.analytics.event('navigateToHome - 2');
    await this.storage.remove('redirectTo')
    this.analytics.event('navigateToHome - 3');
  }

  signInWithEmail(credentials) {
    return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  async changeOrganization(organizationRef: DocumentReference) {
    const callable = this.fsFunction.httpsCallable('authOrganizationChange');
    const data = await callable({
      organizationPath: organizationRef.path || null,
    }).pipe(first()).toPromise();

    if (data.success === true && data.token) {
      await this.signInWithCustomToken(data.token);
      const result = await this.user.getIdTokenResult(true);
      return true;
    } else {
      throw data.error;
    }
  }

  async signInWithEmailAndPassword(email: string, password: string, organizationPath?: string) {
    const callable = this.fsFunction.httpsCallable('authEmailAndPassword');

    const data = await callable({
      email: email,
      password: password,
      organizationPath: organizationPath || null,
      provider: 'password'
    }).pipe(first()).toPromise();

    if (data.success === true && data.token) {
      return await this.signInWithCustomToken(data.token);
    } else {
      console.error(data);
      throw data.error;
    }
  }

  async singInWithEmailLink(email, href) {
    return firebase.auth().signInWithEmailLink(
      email,
      href
    );
  }

  async sendValidationEmail() {
    const callable = this.fsFunction.httpsCallable('sendVerificationEmail');
    const result$ = await callable({
      environment: environment,
      redirectUrl: await this.getHomeURL()
    }).pipe(first()).toPromise();
  }

  async signInWithFacebook() {
    try {
      if (this.platform.is('cordova')) {
        const connectedResult = await this.facebook.getLoginStatus();
        let accessToken = null;
        if (connectedResult.status === 'connected') {
          accessToken = connectedResult.authResponse.accessToken;
        } else {
          const loginResult = await this.facebook.login(['email']);
          accessToken = loginResult &&
            loginResult.authResponse &&
            loginResult.authResponse.accessToken;
        }

        if (!accessToken) { throw new Error('Access rejected'); return; }

        // Check if user is already registered with other provider(s)
        // If registered sign in with custom token and link email provider to this
        const callable = this.fsFunction.httpsCallable('authFacebook');
        const data = await callable({
          accessToken: accessToken,
          environment: environment,
          provider: 'facebook'
        }).toPromise();

        if (data.success === true && data.token) {
          await this.signInWithCustomToken(data.token);
        }
        return await this.authState().pipe(filter(user => !!user), first()).toPromise();
      } else {
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('email');
        await this.afAuth.signInWithPopup(provider)
          .catch(async error => {
            if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
              const callable = this.fsFunction.httpsCallable('authFacebook');
              const data = await callable({
                accessToken: error.credential.accessToken,
                environment: environment,
                provider: 'facebook'
              }).toPromise();

              if (data.success === true && data.token) {
                await this.signInWithCustomToken(data.token);
                return await this.authState().pipe(filter(user => !!user), first()).toPromise();
              }

              Promise.reject(error);
            } else {
              Promise.reject(error);
            }
          });
        return await this.authState().pipe(filter(user => !!user), first()).toPromise();
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async signInWithGoogle() {
    try {
      if (this.platform.is('cordova')) {
        const connectedResult = await this.googlePlus.trySilentLogin({}).catch(error => {
          return error;
        });
        let accessToken = null;

        if (connectedResult.accessToken) {
          accessToken = connectedResult.accessToken;
        } else {
          const loginResult = await this.googlePlus.login({
            webClientId: environment.auth.google.webClientID
          }).catch(error => {
            console.error('google login error: ');
            console.error(error);
            throw error;
          });
          accessToken = loginResult && loginResult.accessToken;
        }

        if (!accessToken) { throw new Error('Access rejected'); return; }

        // Check if user is already registered with other provider(s)
        // If registered sign in with custom token and link email provider to this
        const callable = this.fsFunction.httpsCallable('authGoogle');
        const data = await callable({
          accessToken: accessToken,
          environment: environment,
          provider: 'google'
        }).toPromise();

        if (data.success === true && data.token) {
          await this.signInWithCustomToken(data.token);
        }

        const user = await this.authState().pipe(filter(user => !!user), first()).toPromise();

        return user;
      } else {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('email');
        await this.afAuth.signInWithPopup(provider)
          .then(userCredential => { console.log('userCredential', userCredential); return userCredential; })
          .catch(async error => {
            if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
              const callable = this.fsFunction.httpsCallable('authGoogle');
              const data = await callable({
                accessToken: error.credential.accessToken,
                environment: environment,
                provider: 'google'
              }).toPromise();

              if (data.success === true && data.token) {
                await this.signInWithCustomToken(data.token);
                return await this.authState().pipe(filter(user => !!user), first()).toPromise();
              }

              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
          });
        return await this.authState().pipe(first()).toPromise();
      }

    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async signInWithApple() {
    try {
      if (this.platform.is('cordova') && this.platform.is('ios')) {
        await this.signInWithAppleService.signin({
          requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
          ]
        }).then(async (res: AppleSignInResponse) => {
          if (res.identityToken) {
            const callable = this.fsFunction.httpsCallable('authApple');
            const data = await callable({
              res: res,
              accessToken: res.identityToken,
              environment: environment,
              provider: 'apple'
            }).toPromise();

            if (data.success === true && data.token) {
              await this.signInWithCustomToken(data.token);
            }

            const user = await this.authState().pipe(filter(user => !!user), first()).toPromise();
            return user;
          }
        })
      } else {
        const provider = new firebase.auth.OAuthProvider('apple.com')
        provider.addScope('email');
        await this.afAuth.signInWithPopup(provider)
          .catch(async error => {
            console.error('apple error: ', error, ['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code));
            if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
              const callable = this.fsFunction.httpsCallable('authApple');
              const data = await callable({
                accessToken: error.credential.accessToken,
                environment: environment,
                provider: 'apple'
              }).toPromise();

              if (data.success === true && data.token) {
                await this.signInWithCustomToken(data.token);
                return await this.authState().pipe(filter(user => !!user), first()).toPromise();
              }

              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
          });
        return await this.authState().pipe(first()).toPromise();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async signInWithMoodle(domain: string) {
    const self = this;

    return new Promise(async (resolve, reject) => {
      let browser;
      const subscriptions: Subscription[] = [];

      const url = domain + '/local/oauth/login.php?client_id=gamoteca&response_type=code';

      const receiveMessage = async (event) => {
        if (self.platform.is('cordova')) {
          if (event.url.indexOf('.cloudfunctions.net/authMoodleRedirect') !== -1) {
            try {
              (browser as any).executeScript({ code: 'error' }, async (values) => {
                browser.close();
                subscriptions.forEach(sub => sub.unsubscribe);
                reject('auth/custom/access-denied');
              });
            } catch (error) {
              console.error(JSON.stringify(error));
            } finally {
              subscriptions.forEach(sub => sub.unsubscribe);
            }

            try {
              (browser as any).executeScript({ code: 'token' }, async (values) => {
                if (values[0]) {
                  await self.signInWithCustomToken(values[0]);
                  resolve(true);
                }

                browser.close();
              });
            } catch (error) {
              console.error(error);
              reject(error);
            } finally {
              subscriptions.forEach(sub => sub.unsubscribe);
            }

          }
        } else {
          if (event.data.method === 'loginWithCustomToken') {
            if (event.data.error) {
              reject('Invalid auth');
            } else {
              if (event.data.token) {
                await self.signInWithCustomToken(event.data.token);
                resolve(true);
              }
            }
          }
        }
      };

      if (this.platform.is('cordova')) {
        browser = this.iab.create(url, '_blank', 'height=315,width=400');
        subscriptions.push(
          browser.on('loadstop').subscribe((event) => { receiveMessage(event) })
        );
        subscriptions.push(
          browser.on('message').subscribe((event) => { receiveMessage(event) })
        );
      } else {
        browser = window.open(url, '_blank', 'height=315,width=400');
        browser.removeEventListener('loadstop', receiveMessage);
        browser.addEventListener('loadstop', receiveMessage);
      }

      window.removeEventListener('message', receiveMessage);
      window.addEventListener('message', receiveMessage, false);
    });
  }

  public async signInWithKaya() {
    const self = this;

    return new Promise(async (resolve, reject) => {
      const receiveMessage = async (event) => {
        if (self.platform.is('cordova')) {
          if (event.url.indexOf('.cloudfunctions.net/redirect') !== -1) {
            try {
              (browser as any).executeScript({ code: 'error' }, async (values) => {
                browser.close();
                reject('auth/custom/access-denied');
              });
            } catch (error) {
              console.error(error);
            }

            try {
              (browser as any).executeScript({ code: 'token' }, async (values) => {
                if (values[0]) {
                  await self.signInWithCustomToken(values[0]);
                  resolve(true);
                }

                browser.close();
              });
            } catch (error) {
              console.error(error);
              reject(error);
            }

          }
        } else {
          if (event.data.method === 'loginWithCustomToken') {
            if (event.data.error) {
              reject('Invalid auth');
            } else {
              if (event.data.token) {
                await self.signInWithCustomToken(event.data.token);
                resolve(true);
              }
            }
          }
        }
      };

      const url = 'https://kayaconnect.org/local/oauth/login.php?client_id=gamoteca&response_type=code';
      const browser = window.open(url, '_blank', 'height=315,width=400');
      browser.removeEventListener('loadstop', receiveMessage);
      browser.addEventListener('loadstop', receiveMessage);
      window.removeEventListener('message', receiveMessage);
      window.addEventListener('message', receiveMessage, false);
    });
  }


  signInWithCustomToken(token) {
    return this.afAuth.signInWithCustomToken(token);
  }

  private authWithCredential(credential: firebase.auth.AuthCredential) {
    return firebase.auth().signInWithCredential(credential)
      .then((success) => {
        return success;
      }).catch((error) => {
        return error;
      });
  }

  private async oauthSignIn(provider: firebase.auth.AuthProvider) {
    const self = this;
    if (!this.platform.is('cordova')) {
      await this.afAuth.signInWithPopup(provider);
      return await this.authState().pipe(first()).toPromise();
    } else {
      if (provider instanceof firebase.auth.FacebookAuthProvider) {
        return self.facebook.getLoginStatus().then(async (response) => {
          if (response.status === 'connected') {
            await self.authWithCredential(firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken));
            return await this.authState().pipe(first()).toPromise();
          } else {
            return self.facebook.login(['email']).then(async (_response) => {
              await self.authWithCredential(firebase.auth.FacebookAuthProvider.credential(_response.authResponse.accessToken));
              return await this.authState().pipe(first()).toPromise();
            }).catch((_error) => {
              console.log('_error', _error);
            });
          }
        }).catch((error) => {
          console.log('error', error);
        });
      } else {
        return this.afAuth.signInWithRedirect(provider)
          .then(() => {
            return this.afAuth.getRedirectResult().then(async result => {
              const token = (result.credential as any).accessToken;
              const user = result.user;
              return await this.authState().pipe(first()).toPromise();
            }).catch(function (error) {
              alert(error.message);
            });
          });
      }
    }
  }

  public async isEmailVerified() {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    if (user.emailVerified) {
      return true;
    }

    return false;
  }

  private _authState: Observable<firebase.User>;
  public authState(): Observable<firebase.User> {
    if (this._authState) {
      return this._authState;
    }

    this._authState = this.afAuth.authState.pipe(
      concatMap(async user => {
        if (user && user.uid) {
          return await this.storeUserToDB(user).then(async ready => {
            await this.analytics.setUser(user);
            await this.analytics.setUserProperties({
              'display-name': user.displayName,
              email: user.email,
              ['is-' + environment.app]: true,
              'user_id': user.uid
            });
            return user;
          }).catch(error => {
            console.log('storeUserToDB error', JSON.stringify(error));
            return Promise.reject(error);
          });
        } else {
          return user;
        }
      }),
      shareReplay(1)
    )

    return this._authState;
  }

  private async storeUserToDB(authUser: firebase.User): Promise<any> {
    const fsDbUser = await this.fsDB.collection('users').doc(authUser.uid).get().toPromise();
    const fsDbUserData: any = fsDbUser.data() || {};

    const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';
 
    const params: any = {
      ...fsDbUserData,
      displayName: fsDbUserData.displayName || authUser.displayName,
      email: fsDbUserData.email || authUser.email,
      photoURL: fsDbUserData.photoURL || authUser.photoURL,
      phoneNumber: fsDbUserData.phoneNumber || authUser.phoneNumber,
      language: fsDbUserData?.language || language,
      lastLoginAt: firebase.firestore.Timestamp.now(),
      roles: {
        [environment.app]: {
          active: true,
          lastLoginAt: firebase.firestore.Timestamp.now(),
        }
      }
    };

    if (!fsDbUser.exists || !fsDbUserData.roles || !fsDbUserData.roles[environment.app] || !fsDbUserData.roles[environment.app].registeredAt) {
      params.roles[environment.app].registeredAt = firebase.firestore.Timestamp.now();
    }

    if (authUser.displayName && !fsDbUserData.displayName) {
      params.displayName = authUser.displayName;
    }

    if (this.platform.is('cordova')) {
      this.fsFunction.httpsCallable('authSetIP')({ deviceID: this.device.uuid }).pipe().toPromise().then();
      params.device = {
        [this.device.uuid]: {
          platform: this.device.platform,
          manufacturer: this.device.manufacturer,
          model: this.device.model,
          serial: this.device.serial,
          uuid: this.device.uuid,
          version: this.device.version,
          appVersion: await this.getAppVersion(), // TODO,
          lastLoginAt: firebase.firestore.Timestamp.now(),
          pushNotificationToken: await this.firebaseMessaging.getToken(),
          roles: {
            [environment.app]: {
              active: true,
              lastLoginAt: firebase.firestore.Timestamp.now(),
            }
          }
        }
      };
    } else {
      this.fsFunction.httpsCallable('authSetIP')({ deviceID: 'web' }).pipe().toPromise().then();
      params.device = {
        web: {
          platform: navigator.platform,
          language: navigator.language,
          userAgent: navigator.userAgent,
          appVersion: await this.getAppVersion(), // TODO,
          lastLoginAt: firebase.firestore.Timestamp.now(),
          roles: {
            [environment.app]: {
              active: true,
              lastLoginAt: firebase.firestore.Timestamp.now(),
            }
          }
        }
      };
    }

    const partnerProviderID = sessionStorage.getItem('partnerProviderID')
    const partnerEncryptedParams = sessionStorage.getItem('partnerEncryptedParams');
    if (partnerProviderID && partnerEncryptedParams) {
      this.fsFunction.httpsCallable('addMoodleProvider')({
        providerID: partnerProviderID,
        encryptedParams: partnerEncryptedParams,
      }).pipe(first()).toPromise().then(async result => {
        const tokenResult = await this.user.getIdTokenResult(true);
        sessionStorage.removeItem('partnerProviderID');
        sessionStorage.removeItem('partnerEncryptedParams');
      });
    }
    return this.fsDB.collection('users').doc(authUser.uid).set(params, { merge: true });
  }

  public getDeviceID() {
    if (this.platform.is('cordova')) {
      return this.device.uuid;
    } else {
      return 'web';
    }
  }

  public async getAppVersion(): Promise<string> {
    if (this.platform.is('cordova')) {
      await this.platform.ready();
      return await this.appVersion.getVersionNumber().catch(error => { console.log(error); throw error; });
    } else {
      return await this.readConfig().then(config => config.appVersion);
    }
  }

  private cache = null;
  private readConfig(): Promise<any> {
    const self = this;
    return new Promise<string>((resolve, reject) => {
      if (this.cache === null) {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('load', function () {
          try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(xhr.responseText, 'application/xml');
            const widget = doc.getElementsByTagName('widget').item(0);

            self.cache = {
              appVersion: widget.getAttribute('version'),
              appName: widget.getElementsByTagName('name').item(0).textContent,
              packageName: widget.getAttribute('id'),
              versionCode: widget.getAttribute('browser-versionCode')
            };
            resolve(self.cache);
          } catch (e) {
            reject(e);
          }
        });

        xhr.addEventListener('error', function (e) {
          reject(e);
        });
        xhr.open('get', '../assets/config.xml', true);
        xhr.send();
      } else {
        setTimeout(function () {
          resolve(self.cache);
        }, 0);
      }
    });
  }

  async isSuperAdmin() {
    const fbUser = await this.authState().pipe(first()).toPromise();
    if (!fbUser) { return false };

    const tokenResult = await this.user.getIdTokenResult();

    return tokenResult.claims.superadmin;
  }
}
