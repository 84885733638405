<div class="invoices">
  <h2>Invoices</h2>
  <div class="row head py-3">
    <div class="col-3">Date</div>
    <div class="col-3">Payment</div>
    <div class="col-2">Price</div>
    <div class="col-2">Status</div>
    <div class="col-2"></div>
  </div>
  <div *ngFor="let invoice of invoices"class="row align-items-center">
    <div class="col-3">{{invoice.created * 1000 | date: 'mediumDate'}}</div>
    <div class="col-3">
      <ng-container *ngIf="invoice.collection_method === 'charge_automatically'">Card</ng-container>
      <ng-container *ngIf="invoice.collection_method === 'send_invoice'">Invoice</ng-container>
    </div>
    <div class="col-2">{{invoice.total / 100 | currency: (invoice.currency | uppercase)}}</div>
    <div class="col-2">{{invoice.status | titlecase}}</div>
    <div class="col-2">
      <ion-button *ngIf="invoice.status !== 'open'" [href]="invoice.invoice_pdf" target="blank">View</ion-button>
      <ion-button *ngIf="invoice.status === 'open'" [href]="invoice.hosted_invoice_url" target="blank" color="warning">Pay</ion-button>
    </div>
  </div>


</div>
