import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { BrowsePage } from './browse/browse.page';
import { PlayPage } from './play/play.page';
import { WorkspaceResolver } from 'src/app/core/models/resolvers/workspace.resolver';
import { PlayResolver } from '../core/models/resolvers/play.resolver';
import { ScriptResolver } from '../script/script.resolver';
import { PlayLandingComponent } from './landing/play-landing.component';
import { PlayPageModule } from '../event/play.module';
import { BrowsePageModule } from './browse/browse.module';
import { FeedComponent } from './play/feed/feed.component';
import { OrganizationNotFoundGuard } from '../organization/organization-not-found/organization-not-found.guard';

const routes: Routes = [
  {
    path: 'play-landing',
    component: PlayLandingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'playing/game/:id',
    redirectTo: 'playing/browse/design-details/:id'
  },
  {
    path: 'playing/event/:playID',
    redirectTo: 'playing/play/:playID'
  },
  {
    path: 'playing/browse',
    redirectTo: 'playing/browse/play-list',
    pathMatch: 'full',
  },
  {
    path: 'playing/browse/play-list',
    loadChildren: './browse/play-list/play-list.module#PlayListPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'playing/browse/design-list-overview',
    loadChildren: './browse/script-list-overview/script-list-overview.module#ScriptListOverviewPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'playing/browse/design-list/:workspaceID',
    loadChildren: './browse/script-list/script-list.module#ScriptListPageModule',
    canActivate: [AuthGuard],
    resolve: { workspace: WorkspaceResolver }
  },
  // {
  //   path: 'playing/browse',
  //   component: BrowsePage,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: 'play-list',
  //       loadChildren: './browse/play-list/play-list.module#PlayListPageModule',
  //       canActivate: [AuthGuard],
  //     },
  //     {
  //       path: 'design-list/:workspaceID',
  //       loadChildren: './browse/script-list/script-list.module#ScriptListPageModule',
  //       canActivate: [AuthGuard],
  //       resolve: { workspace: WorkspaceResolver }
  //     },
  //     {
  //       path: 'design-list-overview',
  //       loadChildren: './browse/script-list-overview/script-list-overview.module#ScriptListOverviewPageModule',
  //       canActivate: [AuthGuard],
  //     },
  //     // {
  //     //   path: 'design-details/:scriptID',
  //     //   loadChildren: './browse/script-details/script-details.module#ScriptDetailsPageModule',
  //     //   resolve: { script: ScriptResolver }
  //     // },
  //     {
  //       path: '',
  //       redirectTo: 'play-list',
  //       pathMatch: 'full',
  //     }
  //   ]
  // },
  {
    path: 'playing/browse/design-details/:scriptID',
    component: PlayPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './browse/script-details/script-details.module#ScriptDetailsPageModule',
        resolve: { script: ScriptResolver },
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'playing/play/:playID',
    component: PlayPage,
    canActivate: [AuthGuard],
    resolve: { play: PlayResolver },
    children: [
      {
        path: 'details',
        loadChildren: './play/play-details/play-details.module#PlayDetailsPageModule',
      },
      {
        path: 'task',
        // loadChildren: './play/task-list/task-list.module#TaskListPageModule'
        children: [
          {
            path: 'list',
            component: FeedComponent
            // loadChildren: './play/task-list/task-list.module#TaskListPageModule',
            // loadChildren: './play/feed/feed.module#FeedModule'
          },
          {
            path: 'view',
            loadChildren: './play/task-view/task-view.page.module#TaskViewPageModule'
          },
          {
            path: 'view/:taskID',
            loadChildren: './play/task-view/task-view.page.module#TaskViewPageModule'
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          }
        ],
      },
      // {
      //   path: 'task-list',
      //   children: [{
      //     path: '',
      //     loadChildren: './play/play-details/play-details.module#PlayDetailsPageModule',
      //   }],
      // },
      // {
      //   path: 'task-view/:taskID',
      //   children: [{
      //     loadChildren: './play/task-view/task-view.page.module#TaskViewPageModule'
      //   }],
      // },
      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full'
      }
    ]

    // children: [
    //   {
    //     path: 'play-details',
    //     loadChildren: './play/play-details/play-details.module#PlayDetailsPageModule',
    //     // resolve: { workspace: PlayResolver },
    //   },
    //   {
    //     path: 'task-list',
    //     loadChildren: './play/task-list/task-list.module#TaskListPageModule'
    //   },
    //   {
    //     path: 'task-view/:taskID',
    //     loadChildren: './play/task-view/task-view.page.module#TaskViewPageModule'
    //   },
    //   {
    //     path: '',
    //     redirectTo: 'play-details',
    //     pathMatch: 'full'
    //   }
    // ]
  },
  {
    path: 'playing/profile',
    loadChildren: './profile/profile.module#ProfilePageModule'
  },
  {
    path: 'teaser-modal',
    loadChildren: './browse/script-details/teaser-modal#TeaserModalPageModule'
  },

];

@NgModule({
  imports: [
    BrowsePageModule,
    PlayPageModule,
    RouterModule.forChild(routes)
  ],
  providers: [

  ],
  entryComponents: [
    BrowsePage,
    PlayPage
  ]
})
export class PlayingRoutingModule { }
