import { Component, NgZone } from '@angular/core';

import { Platform, ModalController, Config, AnimationController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { AppUpdateService } from './core/app-update/app-update.service';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { environment } from 'src/environments/environment';
import { AnalyticsProvider } from './core/analytics/analytics';
import { debounceTime, filter, first } from 'rxjs/operators';
import { AppAnalyticsService } from './app.analytics';
import { Title, Meta } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { NavController } from '@ionic/angular';
import firebase from "firebase/app";

// import { Animation } from '@ionic/core'
import { BrowserRecommendationService } from './core/browser-recommendation/browser-recommendation.service';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { CloudMessagingService } from './core/cloud-messaging/cloud-messaging.service';
import { Storage } from '@ionic/storage-angular';
import { ColorService } from './core/color/color.service';
import { ModelProvider } from './core/models/general/model.provider';
import { BrandingService } from './core/branding/branding.service';
import { OrganizationService } from './organization/organization.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  // tslint:disable-next-line: use-host-property-decorator
  host: { 'class': 'app-' + environment.app },
  providers: [
    FirebaseDynamicLinks,
    // AuthService,
    AppAnalyticsService
  ]
})
export class AppComponent {

  direction = 'ltr';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private dynamicLinks: FirebaseDynamicLinks,
    private router: Router,
    private auth: AuthService,
    private ngZone: NgZone,
    private appUpdate: AppUpdateService,
    private browserRecommendation: BrowserRecommendationService,
    private firebaseMessaging: FirebaseMessaging,
    private analytics: AppAnalyticsService,
    private titleService: Title,
    private metaService: Meta,
    private config: Config,
    private navCtrl: NavController,
    private remoteConfig: AngularFireRemoteConfig,
    private cloudMessaging: CloudMessagingService,
    private storage: Storage,
    private brandService: BrandingService,
    private organizationService: OrganizationService
    // private colorService: ColorService,
    // private modelProvider: ModelProvider
  ) {
    // this.initSentry();
    // this.initializeApp();
    // this.initTranslate();
    // this.initializeDynamicLink().then();
    // this.initializeMessaging();
    // this.initBrowserRecommendation();
    // this.titleService.setTitle('Gamoteca ' + environment.title);
  }

  async ngOnInit() {

    await Promise.all([
      // this.initSentry(),
      this.initializeApp(),
      this.initTranslate(),
      this.initializeDynamicLink(),
      this.initializeMessaging(),
      this.initBrowserRecommendation(),
      this.initBrand(),
      this.titleService.setTitle('Gamoteca ' + environment.title)
    ]);


    // Quill.register('modules/smart-breaker', (window as any).SmartBreaker);

    // Change to test github
    if (this.platform.is("desktop")) {
      // disable the nav animations:

      // Animation
      // this.config.set('navAnimation', (AnimationC: Animation): Promise<Animation> => { return Promise.resolve(new AnimationC()); });
      // this.config.set('navAnimation', (nav: Animation): Promise<Animation> => { return Promise.resolve(new Animation()); });

      // https://stackoverflow.com/questions/55583239/how-to-disable-custom-angular-ionic-page-transitions-when-not-using-a-mobile-dev
      this.config.set('navAnimation', _ => new AnimationController().create());

      // this.config.set('navAnimation', null);

      // this.config.set('navAnimation', null);
      // or disable all animations
      // this.config.set('animated', false);
    }

    if (environment.app === 'player') {
      this.metaService.addTags([
        { name: 'apple-itunes-app', content: 'app-id=' + environment.iOS.playAppId + ', app-argument=' + location.href }
      ])
    }
  }

  private async initializeMessaging() {
    await this.platform.ready();
    if (this.platform.is('cordova')) {
      this.firebaseMessaging.requestPermission({ forceShow: true }).then(token => {
        // console.log("Got device token 1: ", JSON.stringify(token));
        this.firebaseMessaging.getToken().then(token => {
          console.log('Got device token: ', token);
        });

        this.firebaseMessaging.onBackgroundMessage().subscribe(data => {
          console.log('MESSAGE BACKGROUND: ');
          console.log(JSON.stringify(data));

          if (data.redirectURL) {
            this.router.navigateByUrl(data.redirectURL);
          }
        }, error => console.log(error));

        // this.firebaseMessaging.onMessage().subscribe(data => {
        //   // if (data.redirectURL) {
        //   //   this.router.navigateByUrl(data.redirectURL);
        //   // }
        //   console.log('MESSAGE FOREGROUND: ');
        //   console.log(JSON.stringify(data));
        // }, error => console.error(error));
      });


      // this.firebaseMessaging.onBackgroundMessage().subscribe(data => {
      //   console.log('MESSAGE BACKGROUND: ');
      //   console.log(JSON.stringify(data));
      // }, error => console.log(error));

      // this.firebaseMessaging.onMessage().subscribe(data => {
      //   console.log('MESSAGE FOREGROUND: ');
      //   console.log(JSON.stringify(data));
      // }, error => console.log(error));
    } else {
      // this.auth.authState()
      await this.auth.user$.pipe(filter(user => !!user), first()).subscribe(async user => {
        try {
          const hasToken = await this.cloudMessaging.hasToken();
          if (!hasToken) {
            const token = await this.cloudMessaging.requestPermission();
            await this.cloudMessaging.storeToUser(token);
          }
        } catch (error) {

        }

      })
    }
  }

  private async initializeApp() {
    await this.storage.create();
    firebase.firestore().settings({ experimentalForceLongPolling: true });

    await this.platform.ready();

    if (this.platform.is('cordova')) {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      window.open = (window as any).cordova.InAppBrowser.open;
    }
  }

  async initializeDynamicLink() {
    await this.platform.ready();

    const handleMoodlePartner = (url: string) => {  
      const parsedURL = this.parseURL(url);
      if (parsedURL.searchObject) {
        const params = parsedURL.searchObject;
        const providerID = params.partner;
        const encryptedParams = params.partnervars;
        if(providerID && encryptedParams) {
          console.log('handleMoodlePartner', url);
          sessionStorage.setItem('partnerProviderID', providerID);
          sessionStorage.setItem('partnerEncryptedParams', encryptedParams);
        }
      }
    }

    const handleAnalytics = (url: string) => {
      console.log('handleAnalytics', url);
      const parsedURL = this.parseURL(url);
      if (parsedURL.searchObject) {
        const params = parsedURL.searchObject;
        const campaignName = params.utm_campaign || params.cn;
        if (campaignName) {
          (window as any).ga('set', 'campaignName', campaignName);
          (window as any).mixpanel.people.set_once({ utm_campaign: campaignName });
          (window as any).mixpanel.people.set({ "utm_campaign [last touch]": campaignName });
          (window as any).mixpanel.register({ "utm_campaign [last touch]": campaignName });

        }

        const campaignSource = params.utm_source || params.cs;
        if (campaignSource) {
          (window as any).ga('set', 'campaignSource', campaignSource);
          (window as any).mixpanel.people.set_once({ utm_source: campaignSource });
          (window as any).mixpanel.people.set({ "utm_source [last touch]": campaignSource });
          (window as any).mixpanel.register({ "utm_source [last touch]": campaignSource });
        }

        const campaignMedium = params.utm_medium || params.cm;
        if (campaignMedium) {
          (window as any).ga('set', 'campaignMedium', campaignMedium);
          (window as any).mixpanel.people.set_once({ utm_medium: campaignMedium });
          (window as any).mixpanel.people.set({ "utm_medium [last touch]": campaignMedium });
          (window as any).mixpanel.register({ "utm_medium [last touch]": campaignMedium });
        }

        const campaignContent = params.utm_content || params.cc;
        if (campaignContent) {
          (window as any).ga('set', 'campaignContent', campaignContent);
          (window as any).mixpanel.people.set_once({ utm_content: campaignContent });
          (window as any).mixpanel.people.set({ "utm_content [last touch]": campaignContent });
          (window as any).mixpanel.register({ "utm_content [last touch]": campaignContent });
        }

        const campaignID = params.ci;
        if (campaignID) {
          (window as any).ga('set', 'campaignId', campaignID);
        }

        const docReferrer = params.dr;
        if (docReferrer) { (window as any).ga('set', 'referrer', docReferrer); }
      }

    }

    const handleLink = async link => {
      // await this.debugDynamicLink(link);
      console.log(link);
      // console.log(link.deepLink);
      const parsedURL = this.parseURL(link.deepLink);
      if (parsedURL.pathname.startsWith('/__/auth/action')) {
        const parsedContinueURL = this.parseURL(decodeURIComponent(parsedURL.searchObject.continueUrl));
        if (parsedURL.searchObject.mode === 'signIn' && parsedContinueURL.searchObject.verificationCode) {
          let emailVerified = false;
          await this.auth.afAuth.applyActionCode(parsedContinueURL.searchObject.verificationCode).then(() => {
            emailVerified = true;
          });
          await this.auth.singInWithEmailLink(decodeURIComponent(parsedContinueURL.searchObject.email), link.deepLink).then((user) => {
            if (emailVerified) {
              this.auth.analytics.setUserProperties({ 'auth-email-verified': true });
              this.auth.analytics.event(['Email verified'], null, {}, 'Authentication');
            }
            console.log('logged in via email link', user);
          }).catch((error) => {
            console.log('ERROR logged in via email link', error);
          });
        }
        this.ngZone.run(() => {
          const urlWithoutDomain = parsedURL.searchObject.continueUrl.replace(/^.*\/\/[^\/]+/, '');
          handleAnalytics(parsedURL.searchObject.continueUrl);
          handleMoodlePartner(parsedURL.searchObject.continueUrl);

          this.navCtrl.navigateRoot(urlWithoutDomain);
          // this.router.navigateByUrl(urlWithoutDomain);
        });
        return;
      }

      const pathName = link.deepLink.replace(/^.*\/\/[^\/]+/, '');
      console.log('onDynamicLink', link.deepLink, pathName);
      handleAnalytics(link.deepLink);
      handleMoodlePartner(link.deepLink);
      this.navCtrl.navigateRoot(pathName);
      // this.router.navigateByUrl(pathName);
    }

    if (this.platform.is('cordova')) {
      const link = await this.dynamicLinks.getDynamicLink();
      // console.log('link');
      // console.log(link);
      if (link) { await handleLink(link) }
      this.dynamicLinks.onDynamicLink().subscribe(link => handleLink(link));
    } else {
      handleAnalytics(window.location.href)
      handleMoodlePartner(window.location.href);
      return true;
    }

  }

  private async debugDynamicLink(link: string) {
    try {
      if (this.platform.is('cordova') && link) {
        const address = (await (await fetch('https://myexternalip.com/raw')).text());
        if (address) {
          await this.remoteConfig.fetchAndActivate();
          const dynamicLinkConfig = JSON.parse((await this.remoteConfig.getValue('dynamic_link')).asString());
          if (dynamicLinkConfig && (dynamicLinkConfig.debugIPs as Array<string>).includes(address)) {
            alert(JSON.stringify(link))
          }
        }
      }
    } catch (error) {

    }

  }

  private parseURL(url) {
    let parser: any = document.createElement('a'),
      searchObject: any = {},
      queries, split, i;
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for (i = 0; i < queries.length; i++) {
      split = queries[i].split('=');
      searchObject[split[0]] = split[1];
    }
    return {
      protocol: parser.protocol,
      host: parser.host,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      searchObject: searchObject,
      hash: parser.hash
    };
  }

  async initTranslate() {
    // Set the default language for translation strings, and the current language.
    const user = await this.auth.user$.pipe(first()).toPromise();
    this.translate.setDefaultLang(user?.language || 'en');
    // this.translate.setDefaultLang('en');
    this.translate.addLangs(['en', 'es', 'fr', 'ar']);
    const browserLang = this.translate.getBrowserLang();

    if (browserLang) {
      if (browserLang === 'zh') {
        const browserCultureLang = this.translate.getBrowserCultureLang();

        if (browserCultureLang.match(/-CN|CHS|Hans/i)) {
          this.translate.use('zh-cmn-Hans');
        } else if (browserCultureLang.match(/-TW|CHT|Hant/i)) {
          this.translate.use('zh-cmn-Hant');
        }
      } else {
        this.translate.use(user?.language || this.translate.getBrowserLang());
      }
    } else {
      this.translate.use(user?.language || 'en'); // Set your language here
    }

    this.direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    console.log('this.direction', this.direction, this.translate.currentLang)

    const backButtonText = await this.translate.get('general/back').pipe(first()).toPromise();
    this.config.set('backButtonText', backButtonText);

    this.auth.user$.pipe(filter(user => !!user),first()).subscribe(async user => {
      if(user.language) {
        this.translate.use(user.language);
        this.direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
        console.log('this.direction', this.direction, this.translate.currentLang)
    
        const backButtonText = await this.translate.get('general/back').pipe(first()).toPromise();
        this.config.set('backButtonText', backButtonText);
      }  
    })
  }

  async initBrowserRecommendation() {
    if (
      environment.app === 'player' &&
      !this.browserRecommendation.platform.is('cordova') &&
      this.browserRecommendation.isSafari()
      && parseFloat(this.browserRecommendation.browser().version) < 14.1
    ) {
      await this.browserRecommendation.showModal();
    }
  }

  async initSentry() {
    if (!environment.production) { return false }
    Sentry.init({
      dsn: environment.sentry.dsn,
      release: environment.sentry.project + "@" + await this.auth.getAppVersion(),
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
          tracingOrigins: environment.sentry.tracingOrigins,
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
    this.auth.user$.subscribe(user => {
      if (user) {
        Sentry.setUser({ id: user.id, username: user.displayName, email: user.email, ip_address: '{{auto}}' });
      } else {
        Sentry.configureScope(scope => scope.setUser(null));
      }
    })

  }

  async initBrand() {
    await this.brandService.init();
    // await this.brandService.setBrand('gamoteca')
    await this.brandService.setBrand('FpBM0aALYD9Tn7fu6ohC');
    combineLatest([this.auth.user$, this.organizationService.activeOrganization$])
    // .pipe(debounceTime(200))
    .subscribe(async ([user, organization]) => {
      if(user && organization) {
        await this.brandService.setBrand(organization.id);
      } else {
        await this.brandService.setBrand('FpBM0aALYD9Tn7fu6ohC');
      }
    })
  }
}
