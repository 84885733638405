import { DocumentReference} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import firebase from "firebase/app"

@Injectable(
    { providedIn: 'root' }
)
export class FirebaseCache {
    public models: Map<DocumentReference, Observable<any>> = new Map();
    public queries: Map<firebase.firestore.Query, Observable<Array<any>>> = new Map();


    // public modelsCache$: {
    //     [path: string]: any
    // } = {};

    public getModel(docRef: DocumentReference) {
        // return this.modelsCache$[docRef.path];

        let found = null;
        this.models.forEach((model, ref) => {
            if (ref.isEqual(docRef)) {
                found = model;
            }
        });

        return found;
    }

    public setModel(docRef: DocumentReference, model: Observable<any>) {
        // return this.modelsCache$[docRef.path] = model;

        this.models.set(docRef, model);
    }

    public getQuery(query: firebase.firestore.Query) {
        let found = null;
        this.queries.forEach((models, _query) => {
            if (query.isEqual(_query)) {
                found = models;
            }
        });

        return found;
    }
    
    public setQuery(query: firebase.firestore.Query, models: Observable<Array<any>>) {
        this.queries.set(query, models);
    }
}
