import { FirestoreField } from '../../firebase/firestore-field';
import { FirestoreModel } from '../../firebase/firestore-model';
import { ModelProvider } from './model.provider';
import { Model } from './model';

export class ImageWithSizes extends FirestoreField {

  public sizes;

  public static instantiate(data: any,
    model: FirestoreModel<Model>,
    options: any,
    modelProvider: ModelProvider): ImageWithSizes {
    let imageWithSizes = new ImageWithSizes(data, model, options, modelProvider);
    if (!imageWithSizes.sizes) {
      imageWithSizes.sizes = { original: { url: '' } };
    }
    return imageWithSizes;
  }

  public getUrl() {
    const sizes = this.modelProvider.platform.is('desktop') ? ['800x', '576x', 'original'] : ['576x', '800x', 'original'];
    for (const size of sizes) {
      if (this.sizes && this.sizes[size] && this.sizes[size].url) {
        return this.sizes[size].url;
      }
    }

    return undefined;
  }

  public getSizedUrl(preferedSizeMd: string, preferedSizeSm?: string) {
    let sizes = [preferedSizeMd, '800x', '576x', 'original'];
    if (preferedSizeSm && !this.modelProvider.platform.is('desktop')) {
      sizes = [preferedSizeMd, '576x', '800x', 'original']
    }
    for (const size of sizes) {
      if (this.sizes && this.sizes[size] && this.sizes[size].url) {
        return this.sizes[size].url;
      }
    }

    return undefined;
  }

  public setSizedUrl(url: string, size = 'original') {
    this.sizes = {
      [size]: {
        url: url
      }
    }
  }

  public getOriginalUrl() {
    return this.sizes.original.url;
  }

  public setOriginalUrl(url: string) {
    this.sizes.original.url = url;
  }

  protected rules() {
    return {
      sizes: {}
    };
  }
}
