import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputTextLayoutItem } from 'src/app/core/models/layout-item-input-text';

@Component({
  selector: 'task-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {
  @Input()
  public layoutItem: InputTextLayoutItem;
  
  @Output('onTextTyped') _onTextTyped: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {}

  onTextTyped(event: CustomEvent) {
    this._onTextTyped.emit(event.detail.value);
  }

}
