<div *ngIf="user && task" class="container-fluid">
  <!-- <div class="col-12"> -->
  <div class="row top pb-0 align-items-center">
    <div class="col-auto pl-0 avatar-container">
      <img class="profile" [src]="user?.photoURL" [alt]="user.displayName" />
    </div>
    <div class="col pl-2 pr-0">
      <div>
        <p class="font-bold" class="title my-0">{{title}}</p>
        <p class="text-muted time my-0">
          <small>
            {{timeString}}
            <!-- {{post.createdAt.toDate() | date: 'short'}} -->
            <!-- {{post.createdAt.toDate() | amTimeAgo:true}} -->
          </small>
        </p>
      </div>
    </div>
    <div class="col-auto pr-0 call-to-action text-right">
      <ion-button (click)="action()" class="call-to-action" size="small" shape="round" color="warning">{{'playing/feed/post/task-upcoming/call-to-action' | translate}}</ion-button>
    </div>
  </div>
  <div class="row task mt-5 mb-3" [class.mb-5]="quoteResponse?.mediaType">
    <div class="col col-auto px-0 icon">
      <span>
        <mat-icon>{{task.inputTypeIcon}}</mat-icon>
      </span>
    </div>
    <div class="col pl-0 mt-1">
      <p class="my-0">{{task.title}}</p>
    </div>
  </div>
  <post-response-media *ngIf="quoteResponse?.mediaType" [task]="quoteTask" [response]="quoteResponse" class="color-warning"></post-response-media>
  <!-- </div> -->
</div>