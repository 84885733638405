<app-header></app-header>

<app-team-sidebar></app-team-sidebar>

<ion-content>
  <ng-template #personal>
    <app-team-personal></app-team-personal>
  </ng-template>
  <ng-container *ngIf="!workspaceService.isPersonalWorkspace(workspace); else personal">
    <h1 class="page-title-with-submenu">
      {{ 'manage-teams/title' | translate }}
    </h1>

    <div class="submenu">
      <div class="submenu-item">{{ 'manage-teams/settings-title' | translate }}</div>

      <div class="submenu-item" [routerLink]="'../members'">{{ 'manage-teams/members-title' | translate }}</div>

      <div [routerLink]="'../analytics'" class="submenu-item active-submenu">{{ 'manage-teams/analytics-title' | translate }}</div>
    </div>

    <content-container class="py-5" [back-button]="false">
      <div class="container py-3">
        <h2>Plays</h2>
        <play-stat [plays]="plays"></play-stat>
      </div>
    </content-container>
  </ng-container>
</ion-content>
