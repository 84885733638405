import { Injectable } from '@angular/core';
import { AnalyticsProvider } from '../../core/analytics/analytics';
import { AnalyticsService } from '../../core/analytics/analytics.service';
import { WorkspaceLevel } from '../../core/models/workspace.model';

@Injectable()
export class ManageTeamAnalyticsService implements AnalyticsService {
  constructor(private analyticsService: AnalyticsProvider) {
    this.analyticsService.setCurrentScreen('Manage teams');
  }

  createTitleEditClickedEvent(): void {
    this.analyticsService.event([
                                  'Team name',
                                  'edit clicked'
                                ], 'Team name');
  }

  createTitleFieldClickedEvent(): void {
    this.analyticsService.event([
                                  'Team name',
                                  'field clicked'
                                ], 'Team name');
  }

  createTitleSaveButtonClickedEvent(): void {
    this.analyticsService.event([
                                  'Team name',
                                  'save clicked'
                                ], 'Team name', {type: 'button'});
  }

  createLogoUploadClickedEvent(): void {
    this.analyticsService.event([
                                  'Team logo',
                                  'upload clicked'
                                ]);
  }

  createLogoFileSelectedClickedEvent(): void {
    this.analyticsService.event([
                                  'Team logo',
                                  'file selected'
                                ]);
  }

  createLogoUploadedEvent(): void {
    this.analyticsService.event([
                                  'Team logo',
                                  'uploaded'
                                ]);
  }

  createLogoRemoveClickedEvent(): void {
    this.analyticsService.event([
                                  'Team logo',
                                  'remove clicked'
                                ]);
  }

  createChangeRoleLevelEvent(level: WorkspaceLevel): void {
    this.analyticsService.event([
                                  'Team member\'s role level',
                                  'changed to ' + level
                                ]);
  }

  createInviteMemberDialogOpenEvent(): void {
    this.analyticsService.event([
                                  'Invite member dialog',
                                  'opened'
                                ]);
  }

  createInviteMemberDialogOnCancelClickedEvent(closeType: string): void {
    this.analyticsService.event([
                                  'Invite member dialog',
                                  `cancel ${closeType} clicked`
                                ]);
  }

  createInviteMemberDialogOnSubmitClickedEvent(): void {
    this.analyticsService.event([
                                  'Invite member dialog',
                                  'form filled correctly and submitted'
                                ]);
  }

  createRemoveMemberFromTeamDialogOpenEvent(): void {
    this.analyticsService.event([
                                  'Team member remove confirmation',
                                  'opened'
                                ]);
  }

  createRemoveMemberFromTeamDialogOnCancelClickedEvent(closeType: string): void {
    this.analyticsService.event([
                                  'Team member remove confirmation',
                                  `cancel ${closeType} clicked`
                                ]);
  }

  createRemoveMemberFromTeamDialogOnRemoveClickedEvent(): void {
    this.analyticsService.event([
                                  'Team member remove confirmation',
                                  'removed'
                                ]);
  }

  createAddUserToTeamDialogOpenEvent(): void {
    this.analyticsService.event([
                                  'Add user to the team again confirmation',
                                  'opened'
                                ]);
  }

  createAddUserToTeamDialogOnCancelClickedEvent(closeType: string): void {
    this.analyticsService.event([
                                  'Add user to the team again confirmation',
                                  `cancel ${closeType} clicked`
                                ]);
  }

  createAddUserToTeamDialogOnOkClickedEvent(): void {
    this.analyticsService.event([
                                  'Add user to the team again confirmation',
                                  'ok'
                                ]);
  }

}
