import { PipeTransform, Pipe } from '@angular/core';
import { Platform } from '@ionic/angular';

@Pipe({
    name: 'size'
})
export class SizePipe implements PipeTransform {
    public constructor(
        public platform: Platform
        // private datePipe: DatePipe
    ) {

    }

    transform(value: any, sizeMD: string = 'md', sizeSM: string = 'sm', fallbackURL?: string,...args: any[]) {
        let sizes = this.platform.is('desktop') ? [sizeMD, 'md', '800x', '576x', 'original'] : [sizeSM, 'sm', '576x', '800x', 'original'];

        for (const size of sizes) {
            if (value && value.sizes && value.sizes[size] && value.sizes[size].url) {
                return (value.sizes[size].url as string).replace("(", "%28").replace(")", "%29").replace("'", "%27");
            }
        }

        // if (value && value.sizes && value.sizes.original && value.sizes.original.url) {
        //     return value.sizes.original.url;
        // }

        

        return fallbackURL || '/assets/images/img-placeholder.png';
    }
}
