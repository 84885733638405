import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WorkspaceMember } from 'src/app/core/models/workspace-member';
import { ManageTeamAnalyticsService } from '../manage-teams/manage-team-analytics-service';

@Component({
             selector: 'app-team-member-reactivate-confirmation',
             templateUrl: './team-member-reactivate-confirmation-dialog.component.html'
           })
export class TeamMemberReactivateConfirmationDialogComponent implements OnInit {

  public processing = false;

  @Input()
  public member: WorkspaceMember;

  constructor(private modalCtrl: ModalController, public analytics: ManageTeamAnalyticsService) {
  }

  ngOnInit() {
    this.analytics.createAddUserToTeamDialogOpenEvent();
  }

  onCancel(closeType: string) {
    this.analytics.createAddUserToTeamDialogOnCancelClickedEvent(closeType);
    this.modalCtrl.dismiss({action: 'cancel'});
  }

  public async onReactivate() {
    this.processing = true;
    try {
      this.analytics.createAddUserToTeamDialogOnOkClickedEvent();
      await this.member.reactivateUserAsMember();
      await this.modalCtrl.dismiss({action: 'reactivate'});
    } catch (error) {
      console.error(error);
    } finally {
      this.processing = false
    }


  }
}
