import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-generate-url',
  templateUrl: './generate-url.page.html',
  styleUrls: ['./generate-url.page.scss'],
})
export class SuperadminGenerateUrlPage implements OnInit {
  processing = null;
  rawURL: string = '';
  utmCampaign: string = '';
  utmMedium: string = '';
  utmSource: string = '';
  resultLink: string;

  constructor(
    private clipboard: ClipboardService,
    private toastController: ToastController,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  async submit() {

    let deepLink = this.rawURL;
    if (deepLink.includes('page.link')) {
      const url = new URL(deepLink);
      deepLink = decodeURIComponent(url.searchParams.get('link'));
    }

    const utmParams = (this.utmCampaign ? ('utm_campaign=' + this.utmCampaign) : '') +
      (this.utmMedium ? ('&utm_medium=' + this.utmMedium) : '') +
      (this.utmSource ? ('&utm_source=' + this.utmSource) : '');

    if (utmParams.length > 0) {
      deepLink += (deepLink.includes('?') ? '&' : '?') + utmParams
    }

    const url = 'https://' + environment.dynamicLinkDomainPlayer + '/?link=' +
      encodeURIComponent(deepLink) +
      '&apn=' + environment.android.playPackageName + '&isi=' + environment.iOS.playAppId + '&ibi=' + environment.iOS.playBundleId
      + ((utmParams.length > 0) ? '&' + utmParams : '');

    this.resultLink = url;

    return false;
  }

  async copyURL() {
    this.clipboard.copyFromContent(this.resultLink);

    const toast = await this.toastController.create({
      message: this.translateService.instant('play-modal/invite-event/call-to-action/success'),
      duration: 2000
    });
    toast.present();
  }
}
