import { Component, EventEmitter, ViewChild, Output, OnDestroy, Input, Optional } from '@angular/core';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { Platform } from '@ionic/angular';
import { TaskViewComponentAnalyticsService } from '../task-view.analytics';
import { VideoRecordDesktopAnalyticsService } from './video-record-desktop.analytics';
import { Response } from 'src/app/core/models/response';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { InputVideoLayoutItem } from 'src/app/core/models/layout-item-input-video';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the VideoDesktopComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'video-record-desktop',
  templateUrl: 'video-record-desktop.html',
  styleUrls: ['./video-record-desktop.scss'],
  providers: [VideoRecordDesktopAnalyticsService]
})
export class VideoRecordDesktopComponent implements OnDestroy {
  @ViewChild('captureVideo') captureVideo;
  @ViewChild('previewVideo') previewVideo;

  // tslint:disable-next-line: no-output-rename
  @Output('file') file: EventEmitter<File | Blob> = new EventEmitter<File | Blob>();
  @Output('onTextTyped') onTextTyped: EventEmitter<string> = new EventEmitter<string>();

  public status: string = 'inactive';

  @Input()
  public layoutItem: InputVideoLayoutItem;
  @Input()
  public language = 'en';

  public hasInfo = false;
  public alternateOptions: Array<string> = [];
  public showAlternates = true;
  public activeAlternate: string | boolean = false

  private chunks = [];
  private mediaRecorder: any;
  private stream: MediaStream;


  public timer: Observable<string>;// = new ReplaySubject<string>(1);

  constructor(
    private platform: Platform,
    public analytics: VideoRecordDesktopAnalyticsService,
    public storage: Storage,
    public translate: TranslateService
  ) {
    // console.log('Hello VideoDesktopComponent Component');
  }

  async ngOnInit() {
    this.hasInfo = this.layoutItem.getInstructionText()?.length > 0;
    this.alternateOptions = this.layoutItem.properties && this.layoutItem.properties.alternate &&
      Object.keys(this.layoutItem.properties.alternate).filter(key => this.layoutItem.properties.alternate[key].active);
  }

  ngOnDestroy(): void {
    this.stopCamera();
  }

  public stopCamera() {
    if (this.stream) {
      var videoTracks = this.stream.getVideoTracks();
      videoTracks.forEach(function (track) {
        track.stop();
      })

      var audioTracks = this.stream.getAudioTracks();
      audioTracks.forEach(function (track) {
        track.stop();
      })
    }
  }

  private getBrowserVersion(): string {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join('-');
  };

  public getStream() {
    let self = this;
    if (navigator.mediaDevices) {
      var constraints = {
        video: true,
        //  audio: true
        audio: true
      };
      var chunks = [];

      navigator.mediaDevices.getUserMedia(constraints)
        .then(function (stream) {
          self.mediaRecorder = new MediaRecorder(stream);
          console.log(self.mediaRecorder.state);

          self.stream = stream;

          self.status = 'active';

          self.mediaRecorder.ondataavailable = function (e) {
            self.chunks.push(e.data);
          }

          self.visualize(stream);

          self.mediaRecorder.onstop = function (e) {
            console.log("recorder onstop");
            var blob = new Blob(self.chunks, { 'type': 'video/mp4' });
            self.chunks = [];

            var url = URL.createObjectURL(blob);
            self.previewVideo.nativeElement.src = url;
            self.previewVideo.nativeElement.play();

            timer(100).subscribe((value) => {
              self.status = 'success';
              let fileName = "capture-" + new Date().getTime() + "-" + self.platform.platforms().join("-") + "-" + self.getBrowserVersion().toLowerCase() + ".mp4";
              let file = new File([blob], fileName, { type: self.mediaRecorder.mimeType, lastModified: Date.now() });
              // console.log("file", file);
              self.file.emit(file);
            })
          }

        })
    }
  }

  private visualize(stream) {
    let self = this;
    self.captureVideo.nativeElement.srcObject = stream;
    self.captureVideo.nativeElement.volume = 0;
    self.captureVideo.nativeElement.play();
  }

  public record() {
    this.analytics.onRecordClick();
    this.mediaRecorder.start();
    this.status = 'recording';
    const startTime = moment(new Date());
    this.timer = timer(0, 1000).pipe(map(time => {
      const now = moment(new Date());
      const difference = moment.duration(now.diff(startTime));

      return difference.minutes().toString().padStart(2, '0') + ':' + difference.seconds().toString().padStart(2, '0');
    }));
    console.log(this.mediaRecorder.state);
    console.log("recorder started");
  }

  public stop() {
    this.analytics.onStopClick();
    let self = this;
    this.status = 'success';
    this.timer = null;
    timer(100).subscribe((value) => {
      this.mediaRecorder.stop();
    });
    console.log("recorder stopped");
  }

  public backToStart() {
    if (this.status === 'recording') {
      this.mediaRecorder.stop();
      this.timer = null;
    }
    this.stopCamera();
    document.querySelectorAll('video').forEach(video => video.pause());
    this.status = 'inactive';
    this.file.emit(null);
  }

  public cancel() {
    this.analytics.onRetakeClick();
    if (!this.mediaRecorder) {
      this.getStream();
    } else {
      this.status = 'active';
    }
    this.file.emit(null);
  }

  public onShowAlternates() {
    this.showAlternates = true;
    this.analytics.onShowAlternateOptions();
  }

  public setActiveAlternate(alternate: string | boolean) {
    this.activeAlternate = alternate;
    if (alternate) {
      this.analytics.onClickAlternateOption(this.translate.instant('media-capture/video/alternate/' + alternate + '/label'), alternate as string);
    } else {
      this.onTextTyped.emit('');
      // this.showAlternates = false;
      this.analytics.onBackToVideo(this.translate.instant('media-capture/video/alternate/back-to-video/label'));
    }
  }

}

declare interface MediaRecorderErrorEvent extends Event {
  name: string;
}

declare interface MediaRecorderDataAvailableEvent extends Event {
  data: any;
}

interface MediaRecorderEventMap {
  'dataavailable': MediaRecorderDataAvailableEvent;
  'error': MediaRecorderErrorEvent;
  'pause': Event;
  'resume': Event;
  'start': Event;
  'stop': Event;
  'warning': MediaRecorderErrorEvent;
}


declare class MediaRecorder extends EventTarget {

  readonly mimeType: string;
  readonly state: 'inactive' | 'recording' | 'paused';
  readonly stream: MediaStream;
  ignoreMutedMedia: boolean;
  videoBitsPerSecond: number;
  audioBitsPerSecond: number;

  ondataavailable: (event: MediaRecorderDataAvailableEvent) => void;
  onerror: (event: MediaRecorderErrorEvent) => void;
  onpause: () => void;
  onresume: () => void;
  onstart: () => void;
  onstop: () => void;

  constructor(stream: MediaStream);

  start();

  stop();

  resume();

  pause();

  isTypeSupported(type: string): boolean;

  requestData();


  addEventListener<K extends keyof MediaRecorderEventMap>(type: K, listener: (this: MediaStream, ev: MediaRecorderEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;

  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;

  removeEventListener<K extends keyof MediaRecorderEventMap>(type: K, listener: (this: MediaStream, ev: MediaRecorderEventMap[K]) => any, options?: boolean | EventListenerOptions): void;

  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;

}
