import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponentsModule } from '../core/app-components.module';
import { Routes, RouterModule } from '@angular/router';
import { ManageTeamsPageComponent } from './manage-teams/manage-teams-page.component';
import { AuthGuard } from '../auth/auth.guard';
import { PrivilegeGuard } from '../core/privilege/privilege.guard';
import { PRIVILEGES } from '../core/privilege/privilege-roles';
import { TeamMembersPageComponent } from './team-members/team-members-page.component';
import { FormsModule } from '@angular/forms';
import { AnalyticsModule } from '../core/analytics/analytics.module';
import { TeamMemberDeleteConfirmationDialogComponent } from './member-delete-confirmation-dialog/team-member-delete-confirmation-dialog.component';
import { TeamMemberReactivateConfirmationDialogComponent } from './member-reactivate-confirmation-dialog/team-member-reactivate-confirmation-dialog.component';
import { InviteNewMemberDialogComponent } from './invite-new-member-dialog/invite-new-member-dialog.component';
import { ManageTeamAnalyticsService } from './manage-teams/manage-team-analytics-service';
import { PersonalComponent } from './personal/personal.component';
import { PrivilegeModule } from '../core/privilege/privilege.module';
import { MatIconModule } from '@angular/material/icon';
import { WorkspaceAnalyticsComponent } from './workspace-analytics/workspace-analytics.component';
import { DashboardPageModule } from '../superadmin/dashboard/dashboard.module';
import { NgxPopperModule } from 'ngx-popper';
import { SafePipeModule } from 'safe-pipe';
import { PipesModule } from '../core/pipes/pipes.module';

const routes: Routes = [
  {
    path: 'teams/settings',
    component: ManageTeamsPageComponent,
    canActivate: [AuthGuard, PrivilegeGuard],
    data: { privilege: PRIVILEGES.WORKSPACE_VIEW, redirectTo: 'play-landing' }
  },
  {
    path: 'teams/members',
    component: TeamMembersPageComponent,
    canActivate: [AuthGuard, PrivilegeGuard],
    data: { privilege: PRIVILEGES.WORKSPACE_VIEW, redirectTo: 'play-landing', levels: ['admin', 'editor'], page: 'members' }
  },
  {
    path: 'teams/players',
    component: TeamMembersPageComponent,
    canActivate: [AuthGuard, PrivilegeGuard],
    data: { privilege: PRIVILEGES.WORKSPACE_VIEW, redirectTo: 'play-landing', levels: ['player'], page: 'players' }
  },
  {
    path: 'teams/analytics',
    component: WorkspaceAnalyticsComponent,
    canActivate: [AuthGuard, PrivilegeGuard],
    data: { privilege: PRIVILEGES.WORKSPACE_VIEW, redirectTo: 'play-landing' }
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    AnalyticsModule.forRoot(),
    AppComponentsModule,
    PrivilegeModule,
    MatIconModule,
    DashboardPageModule,
    NgxPopperModule,
    SafePipeModule,
    PipesModule
  ],
  declarations: [
    ManageTeamsPageComponent,
    TeamMembersPageComponent,
    TeamMemberDeleteConfirmationDialogComponent,
    TeamMemberReactivateConfirmationDialogComponent,
    InviteNewMemberDialogComponent,
    PersonalComponent,
    WorkspaceAnalyticsComponent
  ],
  entryComponents: [
    TeamMemberDeleteConfirmationDialogComponent,
    TeamMemberReactivateConfirmationDialogComponent,
    InviteNewMemberDialogComponent
  ],
  providers: [
    ManageTeamAnalyticsService,
    
  ]
})
export class TeamsModule { }
