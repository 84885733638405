import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DashboardPageRoutingModule } from './dashboard-routing.module';

import { DashboardPage } from './dashboard.page';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { ModelsModule } from 'src/app/core/models/models.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { UserStatComponent } from './user-stat/user-stat.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GameStatComponent } from './game-stat/game-stat.component';
import { PlayStatComponent } from './play-stat/play-stat.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DashboardPageRoutingModule,
    AppComponentsModule,
    ModelsModule,
    FilterPipeModule,
    NgxChartsModule,
    MatIconModule
  ],
  declarations: [
    DashboardPage,
    UserStatComponent,
    GameStatComponent,
    PlayStatComponent
  ],
  exports: [
    UserStatComponent,
    GameStatComponent,
    PlayStatComponent
  ]
})
export class DashboardPageModule {}
