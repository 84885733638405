import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { Workspace } from 'src/app/core/models/workspace.model';

@Injectable({
  providedIn: 'root'
})
export class UseTemplateAnalyticsService {

  private previousScreen: string;
  private previousScreenProperties: {};

  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService,
    // private modalCtrl: ModalController,
  ) { }

  setScreen(scriptID: string, scriptTitle: string) {
    this.previousScreen = this.analyticsService.currentScreen;
    this.previousScreenProperties = this.analyticsService.screenProperties;
    this.analyticsService.setCurrentScreen('Use template popup', { 'game-id': scriptID, 'game-title': scriptTitle });
  }

  async onSubmit(buttonTitle: string, workspace: Workspace) {
    await this.analyticsService.event('Submit button clicked', buttonTitle, { 'workspace-id': workspace.id });
  }

  async onWorkspaceChange(workspace: Workspace) {
    await this.analyticsService.event('Workspace changed', workspace.title, { 'workspace-id': workspace.id });
  }

  // async copyInstantSessionURL() {
  //   await this.analyticsService.event('Instant session / copy url button clicked');
  // }

  // async toggleRandomPlayerMode(isTurnedOn: boolean) {
  //   await this.analyticsService.event('Random player mode / toggle', this.translateService.instant('play-modal/invite-event/auto-join-enabled/label'), { status: isTurnedOn });
  // }

  async onClose() {
    await this.analyticsService.event('close');
    this.analyticsService.currentScreen = this.previousScreen;
    this.analyticsService.screenProperties = this.previousScreenProperties;
  }

}
