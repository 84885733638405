import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Team } from 'src/app/core/models/team.model';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss'],
})
export class DeleteConfirmationDialogComponent implements OnInit {

  @Input() team: Team;

  constructor(
    private modalCtrl: ModalController,
    ) {
  }

  ngOnInit() {
    // this.analytics.onOpened();
  }

  onCancel(closeType: string) {
    // this.analytics.onCancelClicked(closeType);
    this.modalCtrl.dismiss({action: 'cancel'});
  }

  public async onDelete() {
    // this.analytics.onDeleteButtonClicked();
    this.team.remove();
    this.modalCtrl.dismiss({action: 'delete'});
  }

}
