import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizationService } from './organization.service';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { WorkspaceService } from '../core/workspace/workspace.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationRedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private authService: AuthService,
    private workspaceService: WorkspaceService
  ) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> { //Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve, reject) => {
      const user = await this.authService.authState().pipe(first()).toPromise();
      const organizationID = next.paramMap.get('organizationID');

      const organizations = await this.organizationService.organizations$.pipe(first()).toPromise();

      const organization = organizations.find(organization => organization.id === organizationID);
      if (organization) {
        // await this.organizationService.setOrganization(organization);



        const workspaces = this.workspaceService.workspaces;

        await this.workspaceService.modelProvider.asyncFilter(workspaces, async (workspace) =>
          (await workspace.getMyMember()).level !== 'player'
        );

        const filteredWorkspaces = workspaces.filter(workspace => {
          if(workspace.isPersonal) {
            return true;
          }
    
          if (workspace.ownerOrganizationRef) {
            return workspace.ownerOrganizationRef.isEqual(organization.ref);
          } else {
            return organization.isOrphan();
          }
        })
        .filter(workspace => workspace.myMember.level !== 'player')
        .sort((a, b) => {
          if (a.isPersonal && !b.isPersonal) {
            return -1;
          } else if (!a.isPersonal && b.isPersonal) {
            return 1;
          }
          //  else {
          //   return 0;
          // }
    
          if (a.myMember.joinedAt && !b.myMember.joinedAt) {
            return -1;
          } else if (!a.myMember.joinedAt && b.myMember.joinedAt) {
            return 1;
          } else if (a.myMember.joinedAt && b.myMember.joinedAt) {
            return a.myMember.joinedAt.toMillis() > b.myMember.joinedAt.toMillis() ? 1 : -1;
          }
    
          return 0;
        });
    
        this.workspaceService.setActiveWorkspace(filteredWorkspaces[0]);
        await this.organizationService.setOrganization(organization);


      }

      this.router.navigate([environment.homeURL]);

      resolve(true);
      return true;
    })
  }

}
