<div class="row">
  <div class="col-sm-8">
    <form *ngIf="plan" #form="ngForm">
      <div class="form-group">
        <label for="organizationName">{{'organization/plan/current-plan' | translate}}</label>
        <p>{{plan.title}}</p>
      </div>

      <div class="form-group">
        <label for="organizationName">{{'organization/plan/plan-status' | translate}}</label>
        <p>
          <ng-container>
            {{'organization/plan/status/' + organization.plan.status | translate}}<ng-container
              *ngIf="organization.plan?.status !== 'canceled' && organization.plan?.cancelAt?.toDate();">,
            </ng-container>
          </ng-container>
          <ng-container *ngIf="organization.plan?.status !=='canceled' && organization.plan?.cancelAt?.toDate(); let
              cancelAtDate">
            subscription ends at {{cancelAtDate | date: 'd MMM, y h:mm a'}}
            <br />
            <ion-button [disabled]="processing === 'reactivate-subscription'" (click)="reactivate()" color="warning" shape="round" class="mt-3">
              <ion-spinner *ngIf="processing === 'reactivate-subscription'" slot="start" name="lines-small" class="ml-2">
              </ion-spinner>
              {{'organization/plan-details/reactivate-subscription/button/label' | translate}}
            </ion-button>
          </ng-container>
          <ng-container *ngIf="organization?.stripe?.customerID && organization.plan?.status =='past_due'">
            <br />
              <ion-button (click)="goToInvoice.emit()" color="warning" shape="round" class="mt-3">Pay invoice</ion-button>
          </ng-container>
        </p>
      </div>

      <div class="form-group">
        <label for="organizationName2">{{'organization/plan/workspaces' | translate}}</label>
        <ng-container *ngIf="organization.workspaces$ | async; let workspaces">
          <p *ngIf="plan.features.workspaceLimit; let workspaceLimit"
            [class.invalid]="workspaces.length >= workspaceLimit && workspaceLimit >= 0">
            {{workspaces.length}} /
            <ng-container *ngIf="workspaceLimit === -1">∞</ng-container>
            <ng-container *ngIf="workspaceLimit >= 0">{{workspaceLimit}}</ng-container>
          </p>
        </ng-container>
        <organization-workspaces [plan]="plan" [organization]="organization"></organization-workspaces>
      </div>
      <!-- <div *ngIf="false" class="form-group">
        <label for="organizationName2">Total Game</label>
        <p *ngIf="plan.features.scriptPerWorkspaceLimit; let scriptLimit">
          4 /
          <ng-container *ngIf="scriptLimit === -1">∞</ng-container>
          <ng-container *ngIf="scriptLimit >= 0">{{scriptLimit}}</ng-container>
        </p>
      </div> -->
    </form>
    <ng-container *ngIf="(organization.myMember$ | async); let organizationMember">
      <ion-button
        *ngIf="organization.stripe.customerID && organization.plan.status !== 'canceled' && !organization.plan.cancelAt && ['owner', 'admin'].includes(organizationMember?.level)"
        (click)="cancelSubscription()" class="mt-5 px-0" size="small" fill="clear" color="warning"
        [disabled]="processing === 'cancel-subscription'">
        <ion-spinner *ngIf="processing === 'cancel-subscription'" name="lines-small" class="ml-2" color="warning">
        </ion-spinner>
        {{'organization/plan-details/cancel-subscription/button/label' | translate}}
      </ion-button>
    </ng-container>
  </div>
  <div *ngIf="plan?.id === 'pro'" class="col-sm-4 px-0">
    <div class="upgrade-box">
      <h2>Do more with Gamoteca</h2>
      <ul>
        <li>
          <mat-icon>trending_up</mat-icon>
          <h3>Scale Gamoteca</h3>
          <ul class="p-0 mt-1 mb-3">
            <li>Unlimited Game Creators</li>
            <li>Unlimited Team Workspaces</li>
            <li>Unlimited Games</li>
            <li>Unlimited Learners</li>
          </ul>
        </li>
        <li>
          <mat-icon>build</mat-icon>
          <h3>More Functionality</h3>
          <ul class="p-0 mt-1 mb-3">
            <li>Advanced game analytics</li>
            <li>LMS integration</li>
          </ul>
        </li>
      </ul>
      <div class="text-center mt-5">
        <ion-button (click)="upgradeToEnterprise()" shape="round" expand="block">Upgrade</ion-button>
      </div>
    </div>
  </div>
</div>