<!-- <content-container *ngIf="data" [back-button]="false" class="py-4">
  <h2>Plays</h2> -->
  <!-- <ngx-charts-number-card
  class="total"
  [scheme]="colorScheme"
  [cardColor]="cardColor"
  [view]="[920,200]"
  [results]="data">
  </ngx-charts-number-card> -->
  <ngx-charts-advanced-pie-chart
  [view]="[920,250]"
  [scheme]="colorScheme"
  [results]="data"
  [gradient]="true"
  ></ngx-charts-advanced-pie-chart>
  <div class="clearfix"></div>
<!-- </content-container> -->
