<app-header>
  <!-- <ion-title>{{'organization/title' | translate}}</ion-title> -->
</app-header>
<app-team-sidebar></app-team-sidebar>
<ion-content [scrollEvents]="true" (ionScroll)="onWindowScroll($event)">
  <content-container *ngIf="language == 'en'">
    <div *ngIf="free && pro" class="px-5 py-4">
      <div class="row" [class.sticky]="sticky">
        <div class="col-sm-4 plan">
          <h2>Free</h2>
          <p class="price">{{0 | currency : (currency | uppercase):'symbol':'1.0-0'}}</p>
          <p class="price-note"></p>
          <ion-button class="current-plan" shape="round" expand="block" fill="clear" color="secondary">
            <span>
              <ion-icon slot="start" name="checkmark"></ion-icon>
              Current plan
            </span>
          </ion-button>
          <ul class="d-none features mt-5">
            <li>
              Number of game creators: 1
            </li>
            <li>
              Personal Workspace
            </li>
            <li>
              Number of Games: 5
            </li>
            <li>
              Access to game templates
            </li>
            <li>
              Create single or multiplayer games designs
            </li>
          </ul>
        </div>
        <div class="col-sm-4 plan">
          <h2>Pro</h2>
          <p class="price">{{proPrice / 12 | currency : (currency | uppercase):'symbol':'1.0-0'}}</p>
          <p class="price-note">Per month billed annually (or £100 per month billed monthly) + VAT (where applicable)</p>
          <!-- <ion-button shape="round" expand="block" (click)="selectContact(pro.id)">Contact</ion-button> -->
          <ion-button shape="round" expand="block" (click)="selectPlan(pro)">Upgrade</ion-button>
          <ul class="d-none features mt-5">
            <li>
              Number of game creators: 10
            </li>
            <li>
              Collaborative creation & publishing through 3 Team Workspaces
            </li>
            <li>
              Number of Games per workspace: 20 x 3 Workspaces = 60
            </li>
            <li>
              Access to game templates
            </li>
            <li>
              Create single or multiplayer games designs
            </li>
          </ul>
        </div>
        <div class="col-sm-4 plan">
          <h2>Enterprise</h2>
          <p class="price">Custom</p>
          <p class="price-note"></p>
          <ion-button shape="round" expand="block" (click)="selectContact(enterprise.id)">Contact</ion-button>
        </div>
      </div>
      <div class="row features">
        <h3 class="col-sm-12"></h3>

        <div class="col-sm-4"></div>
        <div class="col-sm-4 text-uppercase2"><b>All Free features +</b></div>
        <div class="col-sm-4 text-uppercase2"><b>All Pro features +</b></div>

        <div class="col-sm-4">Number of game creators: {{free.features.designerLimitPerWorkspace}}</div>
        <div class="col-sm-4">Number of game creators: {{pro.features.designerLimitPerWorkspace}}</div>
        <div class="col-sm-4">Number of game creators: Unlimited</div>

        <div class="col-sm-4">
          Personal Workspace
          <mat-icon [popper]="info1_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_1 popperApplyClass="popover-color">
            Only you have access to your game templates and published events
          </popper-content>
        </div>
        <div class="col-sm-4">
          {{pro.features.workspaceLimit}} Team Workspaces
          <mat-icon [popper]="info1_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_2 popperApplyClass="popover-color">
            Allows multiple teams members to collaborate on game creation and publishing events
          </popper-content>
        </div>
        <div class="col-sm-4">
          Unlimited Team Workspaces
          <mat-icon [popper]="info1_3" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_3 popperApplyClass="popover-color">
            Allows multiple teams members to collaborate on game creation and publishing events
          </popper-content>
        </div>

        <div class="col-sm-4">Create up to {{free.features.scriptPerWorkspaceLimit}} games</div>
        <div class="col-sm-4">
          Create up to {{pro.features.workspaceLimit * pro.features.scriptPerWorkspaceLimit}} games
          <mat-icon [popper]="info2_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info2_2 popperApplyClass="popover-color">
            {{pro.features.scriptPerWorkspaceLimit}} games per Workspace x {{pro.features.workspaceLimit}} Workspaces =
            {{pro.features.workspaceLimit * pro.features.scriptPerWorkspaceLimit}} games
          </popper-content>
        </div>
        <div class="col-sm-4">Unlimited games</div>

        <div class="col-sm-4">Invite up to {{free.features.playerLimit}} learners</div>
        <div class="col-sm-4">Invite up to {{pro.features.playerLimit}} learners per Workspace</div>
        <div class="col-sm-4">Unlimited learners</div>

        <div class="col-sm-4">Basic game analytics</div>
        <div class="col-sm-4">
          Advanced game analytics
          <mat-icon [popper]="info3_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info3_2 popperApplyClass="popover-color">
            Insights on learner engagement to iteratively improve game design
          </popper-content>
        </div>
        <div class="col-sm-4">
          Advanced game analytics
          <mat-icon [popper]="info3_3" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info3_3 popperApplyClass="popover-color">
            Insights on learner engagement to iteratively improve game design
          </popper-content>
        </div>

        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4">
          Learning design consultancy
          <mat-icon [popper]="info4_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info4_1 popperApplyClass="popover-color">
            For Instant games, Single Sign-on (SSO) and Learning Management System (LMS) integration to track
            progress/completions
          </popper-content>
        </div>

        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4">LMS integration</div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Create</h3>

        <div class="col-sm-4">Access to game templates</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">Create single or multiplayer games designs</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">Preview game flow</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Support
          <mat-icon [popper]="info5_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info5_1 popperApplyClass="popover-color">
            Access to how-to articles, videos, chat
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

      </div>

      <div class="row features">
        <h3 class="col-sm-12">Publish</h3>

        <div class="col-sm-4">
          Instant games
          <mat-icon [popper]="info6_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info6_1 popperApplyClass="popover-color">
            Publish games at scale instantly via a link (URL)
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Custom sessions
          <mat-icon [popper]="info7_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info7_1 popperApplyClass="popover-color">
            Trainers organise game sessions by inviting players with a game code.
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Play</h3>

        <div class="col-sm-4">
          Multi platform mobile access
          <mat-icon [popper]="info8_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info8_1 popperApplyClass="popover-color">
            Players can join on mobile app (iOS, Android) or browser
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Real time in-app notifications
          <mat-icon [popper]="info9_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info9_1 popperApplyClass="popover-color">
            With feedback from team and trainers
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Analytics</h3>

        <div class="col-sm-4">
          Live Feed
          <mat-icon [popper]="info10_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info10_1 popperApplyClass="popover-color">
            Trainers view real time progress and player interactions
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Trainer Feedback
          <mat-icon [popper]="info11_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info11_1 popperApplyClass="popover-color">
            Trainers provide individual or team feedback
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <ng-container *ngIf="false">
        <div class="row features">
          <h3 class="col-sm-12">Create</h3>
          <div class="col-sm-4">Number of game creators: 1</div>
          <div class="col-sm-4">Number of game creators: 10</div>
          <div class="col-sm-4">Number of game creators: Unlimited</div>

          <div class="col-sm-4">Personal Workspace</div>
          <div class="col-sm-4">Collaborative creation & publishing through 3 Team Workspaces</div>
          <div class="col-sm-4">Collaborative creation & publishing through Unlimited Team Workspaces</div>

          <div class="col-sm-4">Number of Games: 5</div>
          <div class="col-sm-4">Number of Games per workspace:<br />20 x 3 Workspaces = 60</div>
          <div class="col-sm-4">Number of Games: Unlimited</div>

          <div class="col-sm-4">Access to game templates</div>
          <div class="col-sm-4">Access to game templates</div>
          <div class="col-sm-4">Access to game templates</div>

          <div class="col-sm-4">Create single or multiplayer games designs</div>
          <div class="col-sm-4">Create single or multiplayer games designs</div>
          <div class="col-sm-4">Create single or multiplayer games designs</div>

          <div class="col-sm-4">Preview game flow</div>
          <div class="col-sm-4">Preview game flow</div>
          <div class="col-sm-4">Preview game flow</div>

          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>
          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>
          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>

          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4">Access to learning design consultancy</div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Publish</h3>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>

          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4">Instant games: Single Sign-on (SSO) and Learning Management System (LMS) integration to
            track progress/completions.</div>

          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Play</h3>
          <div class="col-sm-4">Maximum number of learners: 50</div>
          <div class="col-sm-4">Maximum number of learners per Workspace: 500</div>
          <div class="col-sm-4">Unlimited</div>

          <div class="col-sm-4">Players can join on mobile app or browser</div>
          <div class="col-sm-4">Players can join on mobile app or browser</div>
          <div class="col-sm-4">Players can join on mobile app or browser</div>

          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Analytics</h3>
          <div class="col-sm-4">Basic game analytics.</div>
          <div class="col-sm-4">Advanced analytics to iteratively improve learning design.</div>
          <div class="col-sm-4">Advanced analytics to iteratively improve learning design.</div>

          <div class="col-sm-4">Trainers view real time progress and interactions.</div>
          <div class="col-sm-4">Trainers view real time progress and interactions.</div>
          <div class="col-sm-4">Trainers view real time progress and interactions.</div>

          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
        </div>
      </ng-container>
      <!-- <organization-create *ngIf="plan === 'pro'" [planID]="plan"></organization-create> -->
      <!-- <organization-enterprise *ngIf="plan === 'enterprise'"></organization-enterprise> -->
    </div>
  </content-container>
  <content-container *ngIf="language == 'es'">
    <div *ngIf="free && pro" class="px-5 py-4">
      <div class="row" [class.sticky]="sticky">
        <div class="col-sm-4 plan">
          <h2>Free</h2>
          <p class="price">{{0 | currency : (currency | uppercase):'symbol':'1.0-0'}}</p>
          <p class="price-note"></p>
          <ion-button class="current-plan" shape="round" expand="block" fill="clear" color="secondary">
            <span>
              <ion-icon slot="start" name="checkmark"></ion-icon>
              Plan actual
            </span>
          </ion-button>
          <ul class="d-none features mt-5">
            <li>
              Number of game creators: 1
            </li>
            <li>
              Personal Workspace
            </li>
            <li>
              Number of Games: 5
            </li>
            <li>
              Access to game templates
            </li>
            <li>
              Create single or multiplayer games designs
            </li>
          </ul>
        </div>
        <div class="col-sm-4 plan">
          <h2>Pro</h2>
          <p class="price">{{proPrice / 12 | currency : (currency | uppercase):'symbol':'1.0-0'}}</p>
          <p class="price-note">Por mes, se paga anualmente (o 100 por mes pagado mensualmente) + el Impuesto de Valor Agregado (si aplica)</p>
          <!-- <ion-button shape="round" expand="block" (click)="selectContact(pro.id)">Contact</ion-button> -->
          <ion-button shape="round" expand="block" (click)="selectPlan(pro)">Ascender de categoria</ion-button>
          <ul class="d-none features mt-5">
            <li>
              Number of game creators: 10
            </li>
            <li>
              Collaborative creation & publishing through 3 Team Workspaces
            </li>
            <li>
              Number of Games per workspace: 20 x 3 Workspaces = 60
            </li>
            <li>
              Access to game templates
            </li>
            <li>
              Create single or multiplayer games designs
            </li>
          </ul>
        </div>
        <div class="col-sm-4 plan">
          <h2>Enterprise</h2>
          <p class="price">Custom</p>
          <p class="price-note"></p>
          <ion-button shape="round" expand="block" (click)="selectContact(enterprise.id)">Contact</ion-button>
        </div>
      </div>
      <div class="row features">
        <h3 class="col-sm-12"></h3>

        <div class="col-sm-4"></div>
        <div class="col-sm-4 text-uppercase2"><b>Todas las categorías gratuitas +</b></div>
        <div class="col-sm-4 text-uppercase2"><b>Todas las categorías Pro +</b></div>

        <div class="col-sm-4">Número de creadores de juegos: {{free.features.designerLimitPerWorkspace}}</div>
        <div class="col-sm-4">Número de creadores de juegos: {{pro.features.designerLimitPerWorkspace}}</div>
        <div class="col-sm-4">Número de creadores de juegos: Sin limite</div>

        <div class="col-sm-4">
          Espacio de trabajo personal
          <mat-icon [popper]="info1_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_1 popperApplyClass="popover-color">
            Only you have access to your game templates and published events
          </popper-content>
        </div>
        <div class="col-sm-4">
          {{pro.features.workspaceLimit}} Espacios de trabajo
          <mat-icon [popper]="info1_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_2 popperApplyClass="popover-color">
            Allows multiple teams members to collaborate on game creation and publishing events
          </popper-content>
        </div>
        <div class="col-sm-4">
          Espacios de trabajo ilimitados
          <mat-icon [popper]="info1_3" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_3 popperApplyClass="popover-color">
            Allows multiple teams members to collaborate on game creation and publishing events
          </popper-content>
        </div>

        <div class="col-sm-4">Crear hasta {{free.features.scriptPerWorkspaceLimit}} juegos</div>
        <div class="col-sm-4">
          Crear hasta {{pro.features.workspaceLimit * pro.features.scriptPerWorkspaceLimit}} juegos
          <mat-icon [popper]="info2_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info2_2 popperApplyClass="popover-color">
            {{pro.features.scriptPerWorkspaceLimit}} games per Workspace x {{pro.features.workspaceLimit}} Workspaces =
            {{pro.features.workspaceLimit * pro.features.scriptPerWorkspaceLimit}} games
          </popper-content>
        </div>
        <div class="col-sm-4">Crear juegos ilimitados</div>

        <div class="col-sm-4">Invitar hasta {{free.features.playerLimit}} jugadores</div>
        <div class="col-sm-4">Invitar hasta {{pro.features.playerLimit}} jugadores por Espacio de Trabajo</div>
        <div class="col-sm-4">Jugadores ilimitados</div>

        <div class="col-sm-4">Análisis de juego básico</div>
        <div class="col-sm-4">
          Análisis de juego avanzado
          <mat-icon [popper]="info3_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info3_2 popperApplyClass="popover-color">
            Insights on learner engagement to iteratively improve game design
          </popper-content>
        </div>
        <div class="col-sm-4">
          Análisis de juego avanzado
          <mat-icon [popper]="info3_3" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info3_3 popperApplyClass="popover-color">
            Insights on learner engagement to iteratively improve game design
          </popper-content>
        </div>

        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4">
          Learning design consultancy
          <mat-icon [popper]="info4_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info4_1 popperApplyClass="popover-color">
            For Instant games, Single Sign-on (SSO) and Learning Management System (LMS) integration to track
            progress/completions
          </popper-content>
        </div>

        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4">Integración con LMS</div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Create</h3>

        <div class="col-sm-4">Access to game templates</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">Create single or multiplayer games designs</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">Preview game flow</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Support
          <mat-icon [popper]="info5_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info5_1 popperApplyClass="popover-color">
            Access to how-to articles, videos, chat
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

      </div>

      <div class="row features">
        <h3 class="col-sm-12">Publish</h3>

        <div class="col-sm-4">
          Instant games
          <mat-icon [popper]="info6_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info6_1 popperApplyClass="popover-color">
            Publish games at scale instantly via a link (URL)
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Custom sessions
          <mat-icon [popper]="info7_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info7_1 popperApplyClass="popover-color">
            Trainers organise game sessions by inviting players with a game code.
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Play</h3>

        <div class="col-sm-4">
          Multi platform mobile access
          <mat-icon [popper]="info8_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info8_1 popperApplyClass="popover-color">
            Players can join on mobile app (iOS, Android) or browser
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Real time in-app notifications
          <mat-icon [popper]="info9_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info9_1 popperApplyClass="popover-color">
            With feedback from team and trainers
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Analytics</h3>

        <div class="col-sm-4">
          Live Feed
          <mat-icon [popper]="info10_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info10_1 popperApplyClass="popover-color">
            Trainers view real time progress and player interactions
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Trainer Feedback
          <mat-icon [popper]="info11_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info11_1 popperApplyClass="popover-color">
            Trainers provide individual or team feedback
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <ng-container *ngIf="false">
        <div class="row features">
          <h3 class="col-sm-12">Create</h3>
          <div class="col-sm-4">Number of game creators: 1</div>
          <div class="col-sm-4">Number of game creators: 10</div>
          <div class="col-sm-4">Number of game creators: Unlimited</div>

          <div class="col-sm-4">Personal Workspace</div>
          <div class="col-sm-4">Collaborative creation & publishing through 3 Team Workspaces</div>
          <div class="col-sm-4">Collaborative creation & publishing through Unlimited Team Workspaces</div>

          <div class="col-sm-4">Number of Games: 5</div>
          <div class="col-sm-4">Number of Games per workspace:<br />20 x 3 Workspaces = 60</div>
          <div class="col-sm-4">Number of Games: Unlimited</div>

          <div class="col-sm-4">Access to game templates</div>
          <div class="col-sm-4">Access to game templates</div>
          <div class="col-sm-4">Access to game templates</div>

          <div class="col-sm-4">Create single or multiplayer games designs</div>
          <div class="col-sm-4">Create single or multiplayer games designs</div>
          <div class="col-sm-4">Create single or multiplayer games designs</div>

          <div class="col-sm-4">Preview game flow</div>
          <div class="col-sm-4">Preview game flow</div>
          <div class="col-sm-4">Preview game flow</div>

          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>
          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>
          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>

          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4">Access to learning design consultancy</div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Publish</h3>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>

          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4">Instant games: Single Sign-on (SSO) and Learning Management System (LMS) integration to
            track progress/completions.</div>

          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Play</h3>
          <div class="col-sm-4">Maximum number of learners: 50</div>
          <div class="col-sm-4">Maximum number of learners per Workspace: 500</div>
          <div class="col-sm-4">Unlimited</div>

          <div class="col-sm-4">Players can join on mobile app or browser</div>
          <div class="col-sm-4">Players can join on mobile app or browser</div>
          <div class="col-sm-4">Players can join on mobile app or browser</div>

          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Analytics</h3>
          <div class="col-sm-4">Basic game analytics.</div>
          <div class="col-sm-4">Advanced analytics to iteratively improve learning design.</div>
          <div class="col-sm-4">Advanced analytics to iteratively improve learning design.</div>

          <div class="col-sm-4">Trainers view real time progress and interactions.</div>
          <div class="col-sm-4">Trainers view real time progress and interactions.</div>
          <div class="col-sm-4">Trainers view real time progress and interactions.</div>

          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
        </div>
      </ng-container>
      <!-- <organization-create *ngIf="plan === 'pro'" [planID]="plan"></organization-create> -->
      <!-- <organization-enterprise *ngIf="plan === 'enterprise'"></organization-enterprise> -->
    </div>
  </content-container>
  <content-container *ngIf="language === 'fr'">
    <div *ngIf="free && pro" class="px-5 py-4">
      <div class="row" [class.sticky]="sticky">
        <div class="col-sm-4 plan">
          <h2>Free</h2>
          <p class="price">{{0 | currency : (currency | uppercase):'symbol':'1.0-0'}}</p>
          <p class="price-note"></p>
          <ion-button class="current-plan" shape="round" expand="block" fill="clear" color="secondary">
            <span>
              <ion-icon slot="start" name="checkmark"></ion-icon>
              Plan actuel
            </span>
          </ion-button>
          <ul class="d-none features mt-5">
            <li>
              Nombre de créateurs: 1
            </li>
            <li>
              Espace de travail personnel
            </li>
            <li>
              Number of Games: 5
            </li>
            <li>
              Access to game templates
            </li>
            <li>
              Create single or multiplayer games designs
            </li>
          </ul>
        </div>
        <div class="col-sm-4 plan">
          <h2>Pro</h2>
          <p class="price">{{proPrice / 12 | currency : (currency | uppercase):'symbol':'1.0-0'}}</p>
          <p class="price-note">Par mois, payé annuellement (ou 100 par mois payé mensuellement) + TVA (si applicable)</p>
          <!-- <ion-button shape="round" expand="block" (click)="selectContact(pro.id)">Contact</ion-button> -->
          <ion-button shape="round" expand="block" (click)="selectPlan(pro)">Passer à la catégorie supérieure</ion-button>
          <ul class="d-none features mt-5">
            <li>
              Number of game creators: 10
            </li>
            <li>
              Collaborative creation & publishing through 3 Team Workspaces
            </li>
            <li>
              Number of Games per workspace: 20 x 3 Workspaces = 60
            </li>
            <li>
              Access to game templates
            </li>
            <li>
              Create single or multiplayer games designs
            </li>
          </ul>
        </div>
        <div class="col-sm-4 plan">
          <h2>Enterprise</h2>
          <p class="price">Custom</p>
          <p class="price-note"></p>
          <ion-button shape="round" expand="block" (click)="selectContact(enterprise.id)">Contactez-nous</ion-button>
        </div>
      </div>
      <div class="row features">
        <h3 class="col-sm-12"></h3>

        <div class="col-sm-4"></div>
        <div class="col-sm-4 text-uppercase2"><b>Toutes les catégories gratuites +</b></div>
        <div class="col-sm-4 text-uppercase2"><b>Toutes les catégories Pro +</b></div>

        <div class="col-sm-4">Nombre de créateurs: {{free.features.designerLimitPerWorkspace}}</div>
        <div class="col-sm-4">Nombre de créateurs: {{pro.features.designerLimitPerWorkspace}}</div>
        <div class="col-sm-4">Nombre de créateurs de jeux: Illimité</div>

        <div class="col-sm-4">
          Espaces de travail illimités
          <mat-icon [popper]="info1_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_1 popperApplyClass="popover-color">
            Only you have access to your game templates and published events
          </popper-content>
        </div>
        <div class="col-sm-4">
          {{pro.features.workspaceLimit}} espaces de travail
          <mat-icon [popper]="info1_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_2 popperApplyClass="popover-color">
            Allows multiple teams members to collaborate on game creation and publishing events
          </popper-content>
        </div>
        <div class="col-sm-4">
          Espaces de travail illimités
          <mat-icon [popper]="info1_3" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info1_3 popperApplyClass="popover-color">
            Allows multiple teams members to collaborate on game creation and publishing events
          </popper-content>
        </div>

        <div class="col-sm-4">Créer jusqu'à {{free.features.scriptPerWorkspaceLimit}} jeux</div>
        <div class="col-sm-4">
          Créer jusqu'à {{pro.features.workspaceLimit * pro.features.scriptPerWorkspaceLimit}} jeux
          <mat-icon [popper]="info2_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info2_2 popperApplyClass="popover-color">
            {{pro.features.scriptPerWorkspaceLimit}} games per Workspace x {{pro.features.workspaceLimit}} Workspaces =
            {{pro.features.workspaceLimit * pro.features.scriptPerWorkspaceLimit}} games
          </popper-content>
        </div>
        <div class="col-sm-4">Créer des jeux illimités</div>

        <div class="col-sm-4">Inviter jusqu'à {{free.features.playerLimit}} joueurs</div>
        <div class="col-sm-4">Inviter jusqu'à {{pro.features.playerLimit}} joueurs par espace de travail</div>
        <div class="col-sm-4">Joueurs illimités</div>

        <div class="col-sm-4">Analyse de jeu de base</div>
        <div class="col-sm-4">
          Analyse de jeu avancée
          <mat-icon [popper]="info3_2" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info3_2 popperApplyClass="popover-color">
            Insights on learner engagement to iteratively improve game design
          </popper-content>
        </div>
        <div class="col-sm-4">
          Analyse de jeu avancée
          <mat-icon [popper]="info3_3" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info3_3 popperApplyClass="popover-color">
            Insights on learner engagement to iteratively improve game design
          </popper-content>
        </div>

        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4">
          Learning design consultancy
          <mat-icon [popper]="info4_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info4_1 popperApplyClass="popover-color">
            For Instant games, Single Sign-on (SSO) and Learning Management System (LMS) integration to track
            progress/completions
          </popper-content>
        </div>

        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4 text-center">-</div>
        <div class="col-sm-4">LMS integration</div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Create</h3>

        <div class="col-sm-4">Access to game templates</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">Create single or multiplayer games designs</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">Preview game flow</div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Support
          <mat-icon [popper]="info5_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info5_1 popperApplyClass="popover-color">
            Access to how-to articles, videos, chat
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

      </div>

      <div class="row features">
        <h3 class="col-sm-12">Publish</h3>

        <div class="col-sm-4">
          Instant games
          <mat-icon [popper]="info6_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info6_1 popperApplyClass="popover-color">
            Publish games at scale instantly via a link (URL)
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Custom sessions
          <mat-icon [popper]="info7_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info7_1 popperApplyClass="popover-color">
            Trainers organise game sessions by inviting players with a game code.
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Play</h3>

        <div class="col-sm-4">
          Multi platform mobile access
          <mat-icon [popper]="info8_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info8_1 popperApplyClass="popover-color">
            Players can join on mobile app (iOS, Android) or browser
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Real time in-app notifications
          <mat-icon [popper]="info9_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info9_1 popperApplyClass="popover-color">
            With feedback from team and trainers
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <div class="row features">
        <h3 class="col-sm-12">Analytics</h3>

        <div class="col-sm-4">
          Live Feed
          <mat-icon [popper]="info10_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info10_1 popperApplyClass="popover-color">
            Trainers view real time progress and player interactions
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          Trainer Feedback
          <mat-icon [popper]="info11_1" [popperTrigger]="'hover'" [popperPlacement]="'bottom'"
            [popperApplyClass]="'info-text-popper'">info</mat-icon>
          <popper-content #info11_1 popperApplyClass="popover-color">
            Trainers provide individual or team feedback
          </popper-content>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>

      <ng-container *ngIf="false">
        <div class="row features">
          <h3 class="col-sm-12">Create</h3>
          <div class="col-sm-4">Number of game creators: 1</div>
          <div class="col-sm-4">Number of game creators: 10</div>
          <div class="col-sm-4">Number of game creators: Unlimited</div>

          <div class="col-sm-4">Personal Workspace</div>
          <div class="col-sm-4">Collaborative creation & publishing through 3 Team Workspaces</div>
          <div class="col-sm-4">Collaborative creation & publishing through Unlimited Team Workspaces</div>

          <div class="col-sm-4">Number of Games: 5</div>
          <div class="col-sm-4">Number of Games per workspace:<br />20 x 3 Workspaces = 60</div>
          <div class="col-sm-4">Number of Games: Unlimited</div>

          <div class="col-sm-4">Access to game templates</div>
          <div class="col-sm-4">Access to game templates</div>
          <div class="col-sm-4">Access to game templates</div>

          <div class="col-sm-4">Create single or multiplayer games designs</div>
          <div class="col-sm-4">Create single or multiplayer games designs</div>
          <div class="col-sm-4">Create single or multiplayer games designs</div>

          <div class="col-sm-4">Preview game flow</div>
          <div class="col-sm-4">Preview game flow</div>
          <div class="col-sm-4">Preview game flow</div>

          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>
          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>
          <div class="col-sm-4">Access to support - how-to articles & videos, chat</div>

          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4">Access to learning design consultancy</div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Publish</h3>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>
          <div class="col-sm-4">Instant games: Publish games at scale instantly via a link (URL)</div>

          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4 text-center">-</div>
          <div class="col-sm-4">Instant games: Single Sign-on (SSO) and Learning Management System (LMS) integration to
            track progress/completions.</div>

          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
          <div class="col-sm-4">Custom sessions: Trainers organise game sessions by inviting players with a game code.
          </div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Play</h3>
          <div class="col-sm-4">Maximum number of learners: 50</div>
          <div class="col-sm-4">Maximum number of learners per Workspace: 500</div>
          <div class="col-sm-4">Unlimited</div>

          <div class="col-sm-4">Players can join on mobile app or browser</div>
          <div class="col-sm-4">Players can join on mobile app or browser</div>
          <div class="col-sm-4">Players can join on mobile app or browser</div>

          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
          <div class="col-sm-4">Receive real time app notifications with feedback from team and trainers.</div>
        </div>
        <div class="row features">
          <h3 class="col-sm-12">Analytics</h3>
          <div class="col-sm-4">Basic game analytics.</div>
          <div class="col-sm-4">Advanced analytics to iteratively improve learning design.</div>
          <div class="col-sm-4">Advanced analytics to iteratively improve learning design.</div>

          <div class="col-sm-4">Trainers view real time progress and interactions.</div>
          <div class="col-sm-4">Trainers view real time progress and interactions.</div>
          <div class="col-sm-4">Trainers view real time progress and interactions.</div>

          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
          <div class="col-sm-4">Facilitators provide individual or team feedback.</div>
        </div>
      </ng-container>
      <!-- <organization-create *ngIf="plan === 'pro'" [planID]="plan"></organization-create> -->
      <!-- <organization-enterprise *ngIf="plan === 'enterprise'"></organization-enterprise> -->
    </div>
  </content-container>
</ion-content>