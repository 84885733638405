import { Pipe, PipeTransform } from '@angular/core';
import { Player } from '../models/player.model';
import { DocumentReference } from '@angular/fire/firestore';
import { Role } from '../models/role.model';

@Pipe({
  name: 'skipFacilitator',
})
export class SkipFacilitatorPipe implements PipeTransform {

  transform(roles: Array<Role> | Array<Player>, ...args: any[]): Array<Role> | Array<Player> {
    if(roles[0] instanceof Role) {
      return (roles as Array<Role>).filter(role => role.type !== 'facilitator');
    }

    if(roles[0] instanceof Player) {
      return (roles as Array<Role>).filter(role => role.type !== 'facilitator');
    }

    return (roles as Array<Role>).filter(role => role.type !== 'facilitator');
        
  }
}