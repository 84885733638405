import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PlayPage } from './play.page';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { PlayStatusModule } from './play-status/play-status.module';
import { TitleSizeModule } from 'src/app/core/title-size/title-size.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { FeedModule } from './feed/feed.module';
import { PlayerProfileModule } from './player-profile/player-profile.module';

const routes: Routes = [
  {
    path: '',
    component: PlayPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    MatIconModule,
    PlayStatusModule,
    TitleSizeModule,
    PipesModule,
    FeedModule,
    PlayerProfileModule
  ],
  declarations: [PlayPage]
})
export class PlayPageModule {}
