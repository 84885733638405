import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { ModelProvider } from '../general/model.provider';
import { Workspace } from '../workspace.model';


@Injectable({
    providedIn: 'root',
})
export class WorkspaceResolver implements Resolve<Workspace> {
    constructor(
        private modelProvider: ModelProvider,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Workspace> | Observable<never> {
        const workspaceID = route.paramMap.get('workspaceID');

        return Observable.create((observer) => {
            this.modelProvider.workspace.findByID(workspaceID).subscribe(workspace => {
                observer.next(workspace);
                observer.complete();
            }, error => {
                console.error('Workspace Resolver error', error);
                this.router.navigate(['design']);
            });
        });
    }
}
