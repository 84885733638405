import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ScriptListPageComponent } from './script-list-page.component';
import { ModelsModule } from 'src/app/core/models/models.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { SimulatorModule } from '../../designer/simulator/simulator.module';
import { ScriptCardComponent } from './script-card/script-card.component';
import { LocalizeModule } from 'src/app/core/localize/localize.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { ScripListAnalyticsService } from './script-list-analytics-service';
import { ScriptCardAnalyticsService } from './script-card/script-card-analytics-service';
import { PublishModalModule } from '../publish-modal/publish-modal.module';
import { ScriptDeleteConfirmationDialogModule } from './script-card/script-delete-confirmation-dialog/script-delete-confirmation-dialog.module';
import { ClipboardModule } from 'ngx-clipboard';
import { ContextMenuModule } from 'src/app/core/context-menu/context-menu.module';
import { ScriptContextMenuModule } from '../script-context-menu/script-context-menu.module';
import { WorkspaceModule } from 'src/app/core/workspace/workspace.module';
import { MatIconModule } from '@angular/material/icon';
import { PrivilegeModule } from 'src/app/core/privilege/privilege.module';
import { ScriptEmptyListComponent } from './empty-list/empty-list.component';

const routes: Routes = [
  {
    path: '',
    component: ScriptListPageComponent
  }
];

@NgModule({
            imports: [
              CommonModule,
              FormsModule,
              IonicModule,
              ModelsModule,
              SimulatorModule,
              ContextMenuModule,
              RouterModule.forChild(routes),
              TranslateModule.forChild(),
              AppComponentsModule,
              LocalizeModule,
              PipesModule,
              NgPipesModule,
              // AnalyticsModule,
              PublishModalModule,
              ScriptDeleteConfirmationDialogModule,
              ClipboardModule,
              ScriptContextMenuModule,
              WorkspaceModule,
              MatIconModule,
              PrivilegeModule,
            ],
            declarations: [
              ScriptListPageComponent,
              ScriptCardComponent,
              ScriptEmptyListComponent
            ],
            providers: [
              ScripListAnalyticsService,
              {
                provide: ScriptCardAnalyticsService,
                useExisting: ScripListAnalyticsService
              }
            ]
          })
export class ScriptListPageModule {}
