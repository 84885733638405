<!-- Generated template for the CameraMobileComponent component -->
<div class="cameraContainer {{status}}" >
    <!-- <mat-icon class="startCamera visible-inactive" (click)="capture()" center>camera_alt</mat-icon> -->
    <div class="start-container visible-inactive text-center">
      <ion-button class="startCamera" color="secondary" size="small" (click)="capture()" shape="round" center>
        <mat-icon slot="start">camera_alt</mat-icon>
        Use Camera
      </ion-button>
      <p>{{'general/or' | translate}}</p>
      <ion-button color="secondary" size="small" (click)="capture(true)" shape="round" center>
        <ion-icon name="images" slot="start"></ion-icon>
        Upload Photo
      </ion-button>
    </div>
    <!-- <button ion-button class="visible-inactive" (click)="capture()">Take photo</button> -->
    <!-- <button ion-button class="visible-success" (click)="cancel()"><mat-icon>undo</mat-icon></button> -->
    <ion-button color="secondary" size="small" shape="round" class="visible-success bottom" (click)="retake()">
      <mat-icon slot="start">camera_alt</mat-icon> Retake photo
    </ion-button>
    <img *ngIf="previewURL" class="visible-success" [attr.src]="previewURL" style="width:100%" />
  </div>
