import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PlayViewPageComponent } from './play-view-page.component';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { AuthGuard } from '../../auth/auth.guard';
import { PrivilegeGuard } from '../../core/privilege/privilege.guard';
import { PRIVILEGES } from '../../core/privilege/privilege-roles';

const routes: Routes = [
  {
    path: '',
    component: PlayViewPageComponent,
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.PLAY_VIEW}
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    AppComponentsModule,
  ],
  declarations: [PlayViewPageComponent]
})
export class PlayViewPageModule {}
