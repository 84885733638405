import { Component, OnInit, ContentChildren, QueryList, Inject, HostBinding, ContentChild, ViewChild, ViewContainerRef, ElementRef, Input, Optional } from '@angular/core';
import { ContextMenuItemInterface } from './content-menu-item.interface';
import { ContextMenuGroupComponent } from '../context-menu-group/context-menu-group.component';
import { ContextMenuIconComponent } from '../context-menu-icon/context-menu-icon.component';


@Component({
  selector: 'context-item , [context-item]',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss'],
  host: {
    "(click)": "onClick($event)"
  }
})
export class ContextMenuItemComponent implements OnInit, ContextMenuItemInterface {
  @Input()
  label: string;

  @HostBinding('attr.dir')
  @Input()
  dir: string;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  private _processing = false;
  public get processing() {
    return this._processing;
  }
  @Input()
  public set processing(value) {
    this.disabled = value;
    this._processing = value;
  }

  @Input()
  hideOnClick = true;

  private _contextMenuGroup!: ContextMenuGroupComponent;
  public get contextMenuGroup(): ContextMenuGroupComponent {
    return this._contextMenuGroup;
  }
  @ContentChild(ContextMenuGroupComponent)
  public set contextMenuGroup(value: ContextMenuGroupComponent) {
    if(value) {
      value.parent = this;
    }

    this._contextMenuGroup = value;
  }

  flipmodifier = {
    flip: {
      behavior: ['right', 'left', 'bottom']
    }
  };
  
  constructor(
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    @Optional() @Inject(ContextMenuGroupComponent) public parent: ContextMenuGroupComponent,
    @Optional() @Inject(ContextMenuIconComponent) public iconComponent: ContextMenuIconComponent,
  ) {

  }

  onClick($event: MouseEvent | any) {
    // $event.stopPropagation();
    // $event.preventDefault();

    $event.item = this;

    if(this.processing) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    if(this.hideOnClick && !this.hasNestedGroup) {
      this.hideMenu();
    }

    return $event;
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if(!this.parent && this.iconComponent) {
      this.parent = this.iconComponent.contextGroup;
    }

    this.parent.items.push(this);
  }

  public get root() {
    let parent = this.parent;
    while(parent.parent) {
      parent = parent.parent as any;
    }
    return parent as ContextMenuGroupComponent;
  }

  hideMenu() {
    this.root.popper.hide();
  }

  get hasNestedGroup() {
    return !!this.contextMenuGroup;
  }

}
