import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { ModelProvider } from '../core/models/general/model.provider';


@Injectable({
    providedIn: 'root',
})
export class ScriptVersionResolver implements Resolve<ScriptVersion> {
    constructor(
        private modelProvider: ModelProvider,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ScriptVersion> | Observable<never> {
        const scriptId = route.paramMap.get('scriptID');
        const versionId = route.paramMap.get('versionID');

        return Observable.create((observer) => {
            this.modelProvider.scriptVersion.findByID(versionId, scriptId).subscribe(version => {
                observer.next(version);
                observer.complete();
            }, error => {
                console.error(error);
                this.router.navigate(['design']);
            });
        });
    }
}
