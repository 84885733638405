import { Directive, Input, ElementRef, HostBinding, ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver, Renderer2 } from '@angular/core';
import { PopperContent, PopperController, Placement, Trigger, PopperContentOptions } from 'ngx-popper';
import { ContextMenuGroupComponent } from '../context-menu-group/context-menu-group.component';

@Directive({
  selector: '[context-menu]',
  host: {
    "(click)": "onClick($event)"
  }
})
export class ContextMenuTriggerDirective extends PopperController {
  private _contextMenu: ContextMenuGroupComponent;
  public get contextMenu(): ContextMenuGroupComponent {
    return this._contextMenu;
  }
  @Input('context-menu')
  public set contextMenu(value: ContextMenuGroupComponent) {
    this.content = value.popper;
    this._contextMenu = value;
  }

  placement: Placement = 'bottom';
  appendTo = 'ion-app';
  showTrigger: Trigger | undefined = 'click';
  popperModifiers = { offset: { offset: '0,5px' } };
  boundariesElement = 'body'

  async onClick($event: MouseEvent) {
    if (this.showTrigger === 'click') {
      $event.stopPropagation();
      // (this.content as PopperContent).update();
      (this.content as PopperContent).show();
      await new Promise(resolve => setTimeout(resolve, 50));
      (this.content as PopperContent).show();
    }
  }
}
