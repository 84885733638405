import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { PRIVILEGES } from 'src/app/core/privilege/privilege-roles';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { TourFactoryService } from 'src/app/designer/services/tour-factory.service';
import { ScriptListPageComponent } from '../script-list-page.component';

@Component({
  selector: 'script-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class ScriptEmptyListComponent implements OnInit {
  
  @Input()
  public listPage: ScriptListPageComponent;

  public PRIVILEGES = PRIVILEGES;
  public processing = null;

  constructor(
    private workspaceService: WorkspaceService,
    private modelProvider: ModelProvider,
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  async create() {
    this.processing = 'create';
    this.modalController.dismiss();
    await this.listPage.addNewScript(false);
    this.processing = null;
    // this.onCreate.emit(true);
  }

  async goToTemplate() {
    this.listPage.analytics.templateButtonClicked(this.workspaceService.activeWorkspace).then().catch();
    await this.workspaceService.setActiveWorkspaceByRef(this.modelProvider.fsDB.collection('workspaces').doc('demo-games').ref);
    await this.modalController.dismiss();
  }

}
