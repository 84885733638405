import { Injectable } from '@angular/core';
import { Color, RGB } from './color';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() {

  }

  setColor(variable: string, color: string) {
    document.documentElement.style.setProperty(variable, color);
  }

  setIonicColor(name: string, hexColor: string) {
    const color = new Color(hexColor);

    this.setColor('--ion-color-' + name, color.hex);
    this.setColor('--ion-color-' + name + '-rgb', this.rgbToString(color.rgb));
    this.setColor('--ion-color-' + name + '-contrast', color.contrast(154).hex);
    this.setColor('--ion-color-' + name + '-contrast-rgb', this.rgbToString(color.contrast().rgb));
    this.setColor('--ion-color-' + name + '-shade', color.shade().hex);
    this.setColor('--ion-color-' + name + '-tint', color.tint().hex);
  }

  private rgbToString = (c: RGB): string => {
    return `${c.r},${c.g},${c.b}`;
  };
}
