import { Injectable, SkipSelf } from '@angular/core';
import { AnalyticsProvider } from '../../analytics/analytics';

@Injectable({
  providedIn: 'root'
})
export class TeamSidebarAnalyticsService {

  constructor(
    public analyticsService: AnalyticsProvider,
  ) { }

  async createWorkspace(params) {
    this.analyticsService.event('Create Workspace button clicked', 'icon', params, 'Team')
  }
}
