import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

@Injectable()
export class ScriptDeleteConfirmationDialogAnalyticsService {
  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
  }

  onOpened(): void {
    this.analyticsService.event([
                                  'Script Delete confirmation',
                                  'popup opened'
                                ]);
  }

  onCancelClicked(closeType: string): void {
    this.analyticsService.event([
                                  'Script Delete confirmation',
                                  `cancel ${closeType} clicked`
                                ], this.translateService.instant('script-card/confirmation-dialog/cancel'));
  }

  onDeleteButtonClicked(): void {
    this.analyticsService.event([
                                  'Script Delete confirmation',
                                  'delete button clicked'
                                ], this.translateService.instant('script-card/confirmation-dialog/delete'));
  }

}
