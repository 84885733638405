<form #form="ngForm" (ngSubmit)="submit()">
  <div class="row align-items-center px-4">
    <div class="col px-2">
      <ion-textarea [(ngModel)]="text" name="text"
      [autoGrow]="true" [rows]="1" [disabled]="processing" class="mt-0"
      [placeholder]="'playing/feed/send-post/placeholder' | translate"
      ></ion-textarea>
    </div>
    <div class="col-auto px-0 text-center">
      <ion-button [disabled]="processing" type="submit" fill="clear" class="rtl-flip">
        <ion-icon name="send" slot="icon-only"></ion-icon>
      </ion-button>
    </div>
  </div>
</form>
