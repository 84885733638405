import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ImageLayoutItem } from 'src/app/core/models/layout-item-image';
import { Response } from 'src/app/core/models/response';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { SizePipe } from 'src/app/core/pipes/size.pipe';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';


/**
 * Generated class for the LayoutImageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'task-image',
  templateUrl: 'layout-image.html',
  styleUrls: ['./layout-image.scss'],
  providers: [
    SizePipe
  ]
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutImageComponent {

  @HostBinding('class.full-screen')
  fullscreen = false;

  public sanitizedURL: SafeUrl;
  private _url: string;
  public get url(): string {
    return this._url;
  }
  @Input()
  public set url(value: string) {
    this._url = value;
    this.sanitizedURL = (typeof value === 'string') ? this.sanitize(this._url) : this._url;
  }

  @Input()
  set response(response: Response) {
    if(this.platform.is('desktop') && response.photo.md && response.photo.md.url) {
      this.url = response.photo.md.url;
      return;
    }

    if(!this.platform.is('desktop') && response.photo.sm && response.photo.sm.url) {
      this.url = response.photo.sm.url;
      return;
    }

    // Legacy
    if (response && response.photoURL) {
      this.url = response.photoURL;
    }

    if (response && response.photo && response.photo.original && response.photo.original.url) {
      this.url = response.photo.original.url;
    }
  }
  get response(): Response {
    return this._response;
  }
  private _response: Response;

  @Input()
  set taskItem(taskItem: ImageLayoutItem) {
    this._taskItem = taskItem;
    this.url = this.sizePipe.transform(taskItem.properties, 'md', 'sm')
  }
  get taskItem(): ImageLayoutItem {
    return this._taskItem;
  }
  private _taskItem: ImageLayoutItem;

  loaded = false;

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setLoaded() {
    this.loaded = true;
    return;
  }

  toggleFullScreen() {
    if(this.platform.is('cordova')) {
      this.photoViewer.show(this.url);
    } else {
      this.fullscreen = !this.fullscreen; 
    }
  }

  constructor(
    private sanitizer: DomSanitizer,
    private platform: Platform,
    private sizePipe: SizePipe,
    private photoViewer: PhotoViewer
  ) {

  }

}
