import { Component, OnInit, ContentChildren, QueryList, HostBinding, ContentChild, ViewChild, ChangeDetectionStrategy, ElementRef, Input } from '@angular/core';
import { ContextMenuItemComponent } from '../context-menu-item/context-menu-item.component';
import { PopperContent, PopperContentOptions } from 'ngx-popper';
// import { ContextMenuGroupInterface } from './context-menu-group.interface';
import { ContextMenuItemInterface } from '../context-menu-item/content-menu-item.interface';
import { ContextMenuGroupInterface } from './context-menu-group.interface';
// import { ContextMenuGroupInterface } from './context-menu-group.interface';

@Component({
  selector: 'context-group',
  templateUrl: './context-menu-group.component.html',
  styleUrls: ['./context-menu-group.component.scss'],
})
export class ContextMenuGroupComponent implements OnInit, ContextMenuGroupInterface {
  @ViewChild('popperContent', { static: true }) popper: PopperContent = null;
  public parent: ContextMenuItemInterface;
  public items: Array<ContextMenuItemInterface> = [];

  constructor() { }

  ngOnInit() { 

  }
}

