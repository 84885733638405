<form #form="ngForm">

  <div class="form-group col-sm-4 px-0">
    <label for="organizationName">{{'organization-profile/title/label' | translate}}</label>
    <inline-editor (ok)="save('organizationName', organizationName)">
      <input id="organizationName" name="organizationName" type="text" [(ngModel)]="organizationName"
        #organizationNameModel="ngModel" />
    </inline-editor>
  </div>

  <div class="form-group">
    <label>{{ 'organization-profile/logo/label' | translate }}</label>

    <div class="logo-wrapper" (mouseover)="setLogoUploadIconVisibility(true)"
      (mouseleave)="setLogoUploadIconVisibility(false)" [ngClass]="{'logo-image-faded': this.isUploading()}">
      <div class="logo-upload-button-wrapper" [hidden]="!logoIconVisible">
        <mat-icon slot="start" (click)="logoUpload.click();" class="logo-upload-button">camera_alt</mat-icon>

        <input type="file" [fileInput]="organization" [fileExtras]="{'fileName':'organization'}" [category]="'image'"
          (progress)="setUploadProgress($event)" (uploaded)="setLogo($event.url)" class="logo-upload-input"
          (change)="setUploadProgress(0); initImageUpload($event);"
          id="logoUpload" #logoUpload
          accept="image/jpeg;image/png"
          [fileMetadata]="{
            fsDocPath: organization.ref.path,
            fsDocField: 'image.sizes',
            isResize: 'true'
          }"
          >
      </div>
      <div class="progress logo-upload-progress-wrapper" [hidden]="!isUploading()">
        <ion-spinner name="lines"></ion-spinner>
      </div>
      <img *ngIf="organization" [src]="(organization?._image | size : 'xs' : 'xs' : organization?.photoURL) | safe : 'url'" alt="Team logo" [hidden]="isUploading()" class="img-fluid">
    </div>
  </div>
  <div class="form-group">
    <label for="exampleColorInput" class="form-label">{{ 'organization-profile/color/label' | translate }}</label>
    <div class="row m-0 mb-2">
      <input [(ngModel)]="color.primary" name="primary" type="color" (change)="onColorChange($event)" class="form-control form-control-color float-left align-middle" [value]="color.primary" title="Choose your color"><span class="align-middle">{{ 'organization-profile/color/option/primary' | translate }}</span>
    </div>
    <div class="m-0">
      <input [(ngModel)]="color.warning" name="warning" type="color" (change)="onColorChange($event)" class="form-control form-control-color float-left align-middle" [value]="color.warning" title="Choose your color"><span class="align-middle">{{ 'organization-profile/color/option/highlight' | translate }}</span>
    </div>
  </div>
</form>