import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Platform } from '@ionic/angular';
import { first, mergeMapTo } from 'rxjs/operators';
import { ModelProvider } from '../models/general/model.provider';
import { Device } from '@ionic-native/device/ngx';
import firebase from "firebase/app"

@Injectable({
  providedIn: 'root'
})
export class CloudMessagingService {

  constructor(
    private afMessaging: AngularFireMessaging,
    private modelProvider: ModelProvider,
    private firebaseMessaging: FirebaseMessaging,
    private platform: Platform,
    private device: Device,
  ) { }

  async requestPermission() {
    if (this.platform.is('cordova')) {
      return this.firebaseMessaging.requestPermission({ forceShow: true }).then(token => {
        return this.firebaseMessaging.getToken();
      })
    } else {
      return await this.afMessaging.requestPermission
        .pipe(mergeMapTo(this.afMessaging.tokenChanges))
        .pipe(first())
        .toPromise();
    }
  }

  async storeToUser(token: string, _deviceID?: string) {
    const deviceID = _deviceID || this.getDeviceID();
    const me = await this.modelProvider.user.getMe();
    me.device[deviceID]['pushNotificationToken'] = token;
    await me.save();
  }

  async hasToken(_deviceID?: string) {
    const me = await this.modelProvider.user.getMe();
    const deviceID = _deviceID || this.getDeviceID();
    return !!(me?.device[deviceID]?.pushNotificationToken);
  }

  async deleteToken(token: string) {
    return await this.afMessaging.deleteToken(token).pipe(first()).toPromise();
  }

  public getDeviceID() {
    if (this.platform.is('cordova')) {
      return this.device.uuid;
    } else {
      return 'web';
    }
  }

  public isSupported() {
    if(this.platform.is('cordova')) {
      return true;
    } else {
      return firebase.messaging.isSupported();
    }
  }
}
