<mat-icon (click)="onCancel('icon')" class="modal-close-icon" slot="start">close</mat-icon>

<div class="modal-content ion-padding">
  <h2 class="modal-title">{{'onboarding/team-name/title' | translate}}</h2>

  <form #createOrgForm="ngForm" (ngSubmit)="onSubmit(createOrgForm)">

    <div class="form-group">
      <div class="input-text-wrapper">
        <input type="text" name="team" #team="ngModel" [(ngModel)]="teamName"
          [placeholder]="'onboarding/team-name/placeholder' | translate" teamName required />
        <div *ngIf="team.touched" class="alert invalid-field pl-0">
          <span *ngIf="team.errors?.required">
            {{'task-edit/form/global/required' | translate}}
          </span>
        </div>
      </div>
    </div>

    <ion-footer mode="ios">
      <div class="text-right">
        <ion-button (click)="onCancel('button')" class="mr-2" color="secondary" shape="round">
          {{'general/cancel' | translate}}
        </ion-button>

        <ion-button type="submit" color="primary" [disabled]="processing || !createOrgForm.valid" shape="round">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          {{'onboarding/finish-button' | translate}}
        </ion-button>
      </div>
    </ion-footer>
  </form>

</div>