import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationNotFoundComponent } from './organization-not-found.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

const routes: Routes = [
  {
    path: '',
    component: OrganizationNotFoundComponent
  }
];

@NgModule({
  declarations: [
    OrganizationNotFoundComponent,
  ],
  exports: [
    OrganizationNotFoundComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    IonicModule,
    FormsModule,
    MatIconModule,
    TranslateModule.forChild(),
    NgxMaskModule
  ]
})
export class OrganizationNotFoundModule { }
