<div class="line">
  <!-- <mat-icon>expand_less</mat-icon> -->
  <div class="arrow-up"></div>
  <!-- <mat-icon>arrow_upward</mat-icon> -->
</div>
<div *ngIf="user && response" class="container-fluid">
  <div class="row top pb-3 align-items-center">
    <div class="col-auto pl-0 avatar-container">
      <img class="profile" [src]="user?.photoURL" [alt]="user.displayName" />
    </div>
    <div class="col pl-2">
      <div>
        <p class="font-bold" class="title my-0">{{title}}</p>
        <p class="text-muted time my-0">
          <small>{{timeString}}</small>
        </p>
      </div>
    </div>
    <!-- <div class="col-auto pr-0 call-to-action text-right">
      <ion-button class="call-to-action" size="small" shape="round" color="primary">Show</ion-button>
    </div> -->
  </div>
  <post-response-media *ngIf="response?.mediaType" [response]="response" [task]="task"></post-response-media>
</div>