import { Component, OnInit, Input } from '@angular/core';
import { Image360LayoutItem } from 'src/app/core/models/layout-item-image360';

@Component({
  selector: 'image-vr',
  templateUrl: './image-vr.component.html',
  styleUrls: ['./image-vr.component.scss'],
})
export class ImageVrComponent implements OnInit {
  @Input() url: string;

  @Input()
  set taskItem(taskItem: Image360LayoutItem) {
    this._taskItem = taskItem;
    this.url = taskItem.url;
  }
  get taskItem(): Image360LayoutItem {
    return this._taskItem;
  }
  private _taskItem:  Image360LayoutItem;

  constructor() { }

  ngOnInit() {}

}
