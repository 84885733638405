import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MaskService } from 'ngx-mask';
import { Play } from 'src/app/core/models/play.model';
import { User } from 'src/app/core/models/user.model';
import { PlayPage } from '../../../play.page';
import { PlayService } from '../../../play.service';

@Component({
  selector: 'post-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  providers: [
    MaskService
  ]
})
export class PostInviteComponent implements OnInit {

  @ViewChild('codeInputTarget', { read: ElementRef }) codeInputTarget: ElementRef<HTMLTextAreaElement>;

  @Input()
  user: User;

  title: string;
  taskLabel: string;

  playService: PlayService;
  play: Play;

  constructor(
    @Inject(PlayPage) public playPage: PlayPage,
    private translate: TranslateService,
    private maskService: MaskService,
  ) { }

  ngOnInit() {
    this.playService = this.playPage.playService;
    this.play = this.playService.play;
    this.user = this.playService?.meUser;
    this.title = this.translate.instant('playing/feed/post/invite/title');
    this.taskLabel = this.translate.instant('playing/feed/post/invite/subtitle/prefix') + ' ' +
      this.maskService.applyMask(this.play.share.code, 'AAA-AAA');
    // 'Share your game code with your friend';
  }

  action() {
    this.playPage.playStatus.invite(this.codeInputTarget.nativeElement.value, this.codeInputTarget.nativeElement)
  }

}
