import { FirestoreModel } from '../firebase/firestore-model';
import { map, first } from 'rxjs/operators';
import { Play } from './play.model';
import { Player } from './player.model';
import { Observable } from 'rxjs';

export class Team extends FirestoreModel<Team> {
    public COLLECTION_NAME = 'teams';

    public title: string;
    public order: number;

    protected rules() {
        return {
            title: {},
            order: {}
        };
    }

    /**
     * Members
     */
    // tslint:disable-next-line: member-ordering
    private _members$: Observable<Player[]>;
    public get members$(): Observable<Player[]> {
        if (this._members$ !== undefined) { return this._members$; }

        this._members$ = this.modelProvider.player.findAllByTeam(this);
        return this._members$;
    }
    // tslint:disable-next-line: member-ordering
    private _members: Array<Player>;
    public get members(): Array<Player> {
        if (this._members !== undefined) { return this._members; }
        this._members = [];

        this.members$.pipe(first()).subscribe(members => {
            this._members = members;
        });

        return this._members;
    }

    public findAllByPlay(play: Play) {
        return this.findAllBy(null, play.getDocument().collection<Team>('teams').ref);
        // return play.getDocument().collection<Team>('teams').snapshotChanges().pipe(
        //     map(actions => actions.map(a => {
        //         return this.instantiate(a.payload.doc.ref.path, a.payload.doc.data());
        //     }))
        // );
    }

    public getScore(players: Player[]) {
        let score = 0;
        players.filter(player => player.teamRef.isEqual(this.ref) ).forEach(player => {
            score += player.score;
        });

        return score;
    }


    protected instantiate(path, data, options?: any) {
        return new Team(path, data, options, this.modelProvider);
    }
}
