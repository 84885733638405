import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

@Injectable()
export class SessionArchiveConfirmationDialogAnalyticsService {
  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
  }

  onOpened(): void {
    this.analyticsService.event([
      'Session Archive confirmation',
      'popup opened'
    ]);
  }

  onCancelClicked(closeType: string): void {
    this.analyticsService.event([
      'Session Archive confirmation',
      `cancel ${closeType} clicked`
    ], this.translateService.instant('play-list/archive-session/confirmation-dialog/cancel'));
  }

  onArchiveButtonClicked(): void {
    this.analyticsService.event([
      'Session Archive confirmation',
      'archive button clicked'
    ], this.translateService.instant('play-list/archive-session/confirmation-dialog/archive'));
  }

}
