import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationMember, OrganizationMemberLevel } from 'src/app/core/models/organization-members.model';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { OrganizationInviteModalComponent } from '../organization-invite-modal/organization-invite-modal.component';
import { OrganizationMemberDeleteModalComponent } from './organization-member-delete-modal/organization-member-delete-modal.component';
import { OrganizationMemberReactivateModalComponent } from './organization-member-reactivate-modal/organization-member-reactivate-modal.component';
import { OrganizationAnalyticsService } from '../organization.analytics.service';
import { map, first, switchMap, catchError } from 'rxjs/operators';
import { OrganizationService } from '../organization.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularFirestore, QueryDocumentSnapshot, DocumentChangeAction } from '@angular/fire/firestore';
import { User } from 'src/app/core/models/user.model';
import { ToastController } from '@ionic/angular';
import { ModelProvider } from 'src/app/core/models/general/model.provider';

@Component({
  selector: 'organization-members',
  templateUrl: './organization-members.component.html',
  styleUrls: ['./organization-members.component.scss'],
})
export class OrganizationMembersComponent implements OnInit {
  @Input()
  activeTab: 'members' | 'players';

  @Input()
  organization: Organization;

  members: Array<{ member: OrganizationMember; user: User }> = [];
  totalRecords: number = 0;
  pageSizeOptions = [10, 25, 50, 100, 250];
  pageSize = this.pageSizeOptions[0];
  currentPage: number = 1;
  lastVisible: QueryDocumentSnapshot<OrganizationMember> | null = null;
  firstVisible: QueryDocumentSnapshot<OrganizationMember> | null = null;
  paginationForm: FormGroup;
  searchString: string;
  isDataFetching: boolean = true;

  processingMembers: OrganizationMember[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    public toastCtrl: ToastController,
    private modalController: ModalController,
    private organizationAnalytics: OrganizationAnalyticsService,
    private organizationService: OrganizationService,
    private firestore: AngularFirestore,
    private fb: FormBuilder,
    private modelProvider: ModelProvider
  ) {
    this.paginationForm = this.fb.group({
      rowsPerPage: [this.pageSize]
    });
    this.searchString = "";
  }

  ngOnInit() {
    this.fetchTotalRecords();
    this.fetchMembers();
    this.handleRowsPerPageChange();
  }

  fetchTotalRecords() {
    const totalSub = this.firestore.collection(`organizations/${this.organization.id}/members`, (ref) => {
      if (this.activeTab === 'players') {
        return ref.where('level', '==', 'player');
      } else {
        return ref.where('level', '!=', 'player').where('isInvitationAccepted', '==', true);
      }
    }).snapshotChanges().subscribe(snapshot => {
      this.totalRecords = snapshot.length;
    }, error => {
      console.error('Error fetching total records:', error);
    });

    this.subscriptions.push(totalSub);
  }

  get startRecord(): number {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get endRecord(): number {
    return Math.min(this.currentPage * this.pageSize, this.totalRecords);
  }

  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.pageSize);
  }

  fetchTotalSearchedRecords() {
    const totalSub = this.firestore.collection(`organizations/${this.organization.id}/members`, (ref) => {
      let query;
      if (this.activeTab === 'players') {
        query = ref.orderBy("email").startAt(this.searchString).endAt(this.searchString + "\uf8ff")
          .where('level', '==', 'player');
      } else {
        const levelsNotPlayer = ['owner', 'admin', 'billing'];
        query = ref.orderBy("email")
          .startAt(this.searchString)
          .endAt(this.searchString + "\uf8ff")
          .where('level', 'in', levelsNotPlayer)
          .where('isInvitationAccepted', '==', true);
      }
      return query;
    }).snapshotChanges().subscribe(snapshot => {
      this.totalRecords = snapshot.length;
    }, error => {
      console.error('Error fetching total records:', error);
    });

    this.subscriptions.push(totalSub);
  }

  fetchSearchedMembers(isNextPage: boolean = true) {
    this.isDataFetching = true;

    const membersSub = this.firestore.collection(`organizations/${this.organization.id}/members`, ref => {
      let query;
      if (this.activeTab === 'players') {
        query = ref.orderBy("email").startAt(this.searchString).endAt(this.searchString + "\uf8ff")
          .where('level', '==', 'player');
      } else {
        const levelsNotPlayer = ['owner', 'admin', 'billing'];
        query = ref.orderBy("email")
          .startAt(this.searchString)
          .endAt(this.searchString + "\uf8ff")
          .where('level', 'in', levelsNotPlayer)
          .where('isInvitationAccepted', '==', true);
      }

      if (this.currentPage > 1) {
        if (isNextPage) {
          query = query.startAfter(this.lastVisible).limit(this.pageSize);
        } else {
          query = query.endBefore(this.firstVisible).limitToLast(this.pageSize);
        }
      } else {
        query = query.limit(this.pageSize);
      }
      return query;
    }).snapshotChanges()

      .pipe(
        switchMap(snapshot => {
          if (snapshot.length > 0) {
            this.firstVisible = snapshot[0].payload.doc as QueryDocumentSnapshot<OrganizationMember>;
            this.lastVisible = snapshot[snapshot.length - 1].payload.doc as QueryDocumentSnapshot<OrganizationMember>;
            return this.processMembers(snapshot as DocumentChangeAction<OrganizationMember>[]);
          }
          return of([]);
        }),
        catchError(error => {
          console.error("Error fetching members: ", error);
          return of([]);
        }),
      )
      .subscribe(membersData => {
        this.members = membersData;
        this.isDataFetching = false;
      });
    this.subscriptions.push(membersSub);
  }

  handleSearch(event) {
    this.searchString = event.target.value.trim().toLowerCase();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.currentPage = 1;
    if (this.searchString.length > 0) {
      this.fetchTotalSearchedRecords();
      this.fetchSearchedMembers();
    } else {
      this.fetchTotalRecords();
      this.fetchMembers();
    }
  }

  fetchMembers(isNextPage: boolean = true) {
    this.isDataFetching = true;

    const membersSub = this.firestore.collection(`organizations/${this.organization.id}/members`, ref => {
      let query;
      if (this.activeTab === 'players') {
        query = ref.where('level', '==', 'player').orderBy("level").orderBy("createdAt", "desc");
      } else {
        query = ref.where('level', '!=', 'player').where('isInvitationAccepted', '==', true).orderBy("level").orderBy("createdAt", "desc");
      }


      if (this.currentPage > 1) {
        if (isNextPage) {
          query = query.startAfter(this.lastVisible).limit(this.pageSize);
        } else {
          query = query.endBefore(this.firstVisible).limitToLast(this.pageSize);
        }
      } else {
        query = query.limit(this.pageSize);
      }
      return query;
    }).snapshotChanges()

      .pipe(
        switchMap(snapshot => {
          if (snapshot.length > 0) {
            this.firstVisible = snapshot[0].payload.doc as QueryDocumentSnapshot<OrganizationMember>;
            this.lastVisible = snapshot[snapshot.length - 1].payload.doc as QueryDocumentSnapshot<OrganizationMember>;
            return this.processMembers(snapshot as DocumentChangeAction<OrganizationMember>[]);
          }
          return of([]);
        }),
      )
      .subscribe(membersData => {
        this.members = membersData;
        this.isDataFetching = false;
      });
    this.subscriptions.push(membersSub);
  }

  private processMembers(snapshot: DocumentChangeAction<OrganizationMember>[]): Observable<{ member: OrganizationMember, user: User | null }[]> {
    const memberObservables = snapshot.map(doc => {
      const member = doc.payload.doc.data() as OrganizationMember;
      return this.firestore.collection('users').doc(member.userRef.id).valueChanges().pipe(
        map(user => ({ member, user: user as User })),
        catchError(error => {
          return of({ member, user: null as User });
        })
      );
    });
    return combineLatest(memberObservables);
  }

  handleRowsPerPageChange() {
    this.paginationForm.get('rowsPerPage').valueChanges.subscribe(value => {
      this.pageSize = value;
      this.currentPage = 1;
      if (this.searchString.length > 0) {
        this.fetchSearchedMembers();
      } else {
        this.fetchMembers();
      }
    });
  }

  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      if (this.searchString.length > 0) {
        this.fetchSearchedMembers(false);
      } else {
        this.fetchMembers(false);
      }
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      if (this.searchString.length > 0) {
        this.fetchSearchedMembers(true);
      } else {
        this.fetchMembers(true);
      }
    }
  }

  ngAfterViewInit(): void {
    this.organizationAnalytics.openPage('Manage / ' + this.activeTab.charAt(0).toUpperCase() + this.activeTab.slice(1));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async openInviteMember() {
    const plan = await this.organization.plan$.pipe(first()).toPromise();

    if (plan.features.designerLimitPerWorkspace != -1 && (this.members.length + 1) > plan.features.designerLimitPerWorkspace) {
      await this.organizationService.showDesignerLimitWarning();
    } else {
      const modal = await this.modalController.create({
        component: OrganizationInviteModalComponent,
        componentProps: {
          organization: this.organization
        },
        cssClass: [
          'auto-height',
          'sm-modal'
        ]
      });
      await modal.present();

      const { data } = await modal.onDidDismiss();
      if (data.action !== 'cancel') {
        if (data.error && data.error.code === 'already-exists') {
          const toast = await this.toastCtrl.create({
            message: `Error: ${data.mail} is already part of your team.`,
            duration: 5000,
            position: 'bottom',
            cssClass: 'member-invitation-toast error',
            buttons: [
              {
                side: 'end',
                role: 'cancel',
                icon: 'close-outline'
              }
            ]
          });
          await toast.present();
        } else if (data.error && data.error.code === 'internal') {
          const toast = await this.toastCtrl.create({
            message: `An unknown error occurred.`,
            duration: 5000,
            cssClass: 'member-invitation-toast error',
            buttons: [
              {
                side: 'end',
                role: 'cancel',
                icon: 'close-outline'
              }
            ]
          });
          await toast.present();
        } else {
          const toast = await this.toastCtrl.create({
            message: `Success: Invitation sent to ${data.mail}.`,
            duration: 5000,
            cssClass: 'member-invitation-toast success',
            buttons: [
              {
                side: 'end',
                role: 'cancel',
                icon: 'close-outline'
              }
            ]
          });
          await toast.present();
        }
      }
    }
  }

  async changeLevel(member: OrganizationMember, level: OrganizationMemberLevel) {
    this.processingMembers.push(member);
    const memberPath = '/organizations/' + this.organization.id + '/members/' + member.userRef.id;
    try {
      const data = await this.modelProvider.functions.httpsCallable('organizationChangeLevel')({
        memberPath,
        level: level,
      }).toPromise();
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      this.processingMembers = this.processingMembers.filter(el => el !== member);
    }
  }

  async showRemoveFromTeamConfirmationDialog(member: OrganizationMember) {
    const modal = await this.modalController.create({
      component: OrganizationMemberDeleteModalComponent,
      componentProps: {
        member: member,
      },
      cssClass: [
        'auto-height',
        'sm-modal'
      ]
    });
    await modal.present();
  }

  async showAddUserToTeamConfirmationDialog(member: OrganizationMember) {
    const modal = await this.modalController.create({
      component: OrganizationMemberReactivateModalComponent,
      componentProps: {
        member: member,
      },
      cssClass: [
        'auto-height',
        'sm-modal'
      ]
    });
    await modal.present();
  }

}