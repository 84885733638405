import { Injectable, SkipSelf } from '@angular/core';
import { AnalyticsProvider } from '../../core/analytics/analytics';
import { ScriptCardAnalyticsService } from '../list/script-card/script-card-analytics-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ScripViewAnalyticsService extends ScriptCardAnalyticsService {
  constructor(
    @SkipSelf() analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
    super(analyticsService);

  }

  setScreen(scriptID: string, scriptTitle: string) {
    this.analyticsService.setCurrentScreen('Game edit', {'game-id': scriptID, 'game-title': scriptTitle});
  }

  createScriptImageUploadClickedEvent(): void {
    this.analyticsService.event(['Game image', 'upload clicked']);
  }

  createScriptImageFileSelectedClickedEvent(): void {
    this.analyticsService.event(['Game image', 'file selected']);
  }

  createScriptImageUploadedEvent(): void {
    this.analyticsService.event(['Game image', 'uploaded']);
  }

  createTitleEditedEvent(): void {
    this.analyticsService.event(['Title', 'edited'], 'Title');
  }

  createTitleFieldClickedEvent(): void {
    this.analyticsService.event(['Title', 'field clicked'], 'Title');
  }

  createTitleSaveButtonClickedEvent(value: string): void {
    this.analyticsService.event(['Title', 'save clicked'], 'Title', {type: 'button', value: value});
  }

  createEditDesignButtonEvent(): void {
    this.analyticsService.event('Edit design button clicked');
  }

  createDescriptionEditClickedEvent(): void {
    this.analyticsService.event(['Description', 'edit clicked'], 'Description');
  }

  createDescriptionFieldClickedEvent(): void {
    this.analyticsService.event(['Description', 'field clicked'], 'Description');
  }

  createDescriptionSaveButtonClickedEvent(value: string): void {
    this.analyticsService.event(['Description', 'save clicked'], 'Description', {type: 'button', value: value});
  }

  createVideoTeaserButtonEvent(): void {
    this.analyticsService.event(['Video teaser', 'edit button clicked'], this.translateService.instant('script-details/video/edit-button'));
  }


}
