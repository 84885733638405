import { NgModule } from '@angular/core';
import { TaskPreviewComponent } from './task-preview.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgPipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeModule } from 'src/app/core/localize/localize.module';
import { TaskViewModule } from 'src/app/playing/play/task-view/task-view/task-view.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    NgPipesModule,
    TranslateModule.forChild(),
    LocalizeModule,
    TaskViewModule,
    MatIconModule
  ],
  declarations: [
    TaskPreviewComponent,
  ],
  exports: [
    TaskPreviewComponent
  ]
})
export class TaskPreviewModule { }
