import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScriptVersion } from "src/app/core/models/script-version.model";
import { ScriptForm } from "src/app/script/script.form";
import { FormControl } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { TeaserUrlEditorComponent } from "./script-designer-url-editor/teaser-url-editor.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Script } from "src/app/core/models/script.model";
import { take, first, switchMap } from "rxjs/operators";
import { ScripViewAnalyticsService } from "./script-view-analytics-service";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { ModelProvider } from "src/app/core/models/general/model.provider";
import { OrganizationService } from "src/app/organization/organization.service";
import { PlayModalComponent } from "../play-modal/play-modal.component";
import { WorkspaceService } from "src/app/core/workspace/workspace.service";
import { PrivilegeService } from "src/app/core/privilege/privilege.service";
import { PRIVILEGES } from "src/app/core/privilege/privilege-roles";
import { UseTemplateModalComponent } from "../use-template-modal/use-template-modal.component";

@Component({
  selector: "script-view",
  templateUrl: "./script-view-page.component.html",
  styleUrls: ["./script-view-page.component.scss"],
})
export class ScriptViewPageComponent {
  private subscriptions: Subscription[] = [];

  public script: Script;
  public version: ScriptVersion;
  public scriptForm: ScriptForm;

  public moodleConfig = environment.moodle;

  titleIsEditing = false;
  descriptionIsEditing = false;
  thumbnailIconVisible: boolean;
  progress = 0;
  teaserURL: SafeResourceUrl;
  readonly: boolean;
  contextMenuItems;

  constructor(
    private route: ActivatedRoute,
    public analytics: ScripViewAnalyticsService,
    private modalController: ModalController,
    private domSanitizer: DomSanitizer,
    private modelProvider: ModelProvider,
    public organizationService: OrganizationService,
    // public workspaceService: WorkspaceService,
    public priviledgeService: PrivilegeService
  ) {
    // ???
  }

  async ionViewWillEnter() {
    const data = await this.route.data.pipe(first()).toPromise();
    this.readonly = !(await this.priviledgeService.hasAccess(
      PRIVILEGES.DESIGN_EDIT
    ));

    this.script = data.script;
    // this.version = await this.script.draft$.pipe(take(1)).toPromise();
    this.version = await this.script.draft$.pipe(first()).toPromise();

    this.analytics.setScreen(this.script.id, this.version.title);

    // const workspaceMember = await this.workspaceService.activeWorkspace.getMyMember();
    const script$ = this.modelProvider.script.findByRef(this.script.ref);
    const version$ = script$.pipe(switchMap((script) => script.draft$));

    const scriptSubscription = script$.subscribe(async (script) => {
      this.script = script;
      if (this.script.moodle) {
        this.moodleURI = this.script.moodle.uri;
        this.moodleFunction = this.script.moodle.function;
        this.moodleToken = this.script.moodle.token;
        this.moodleCourseID = this.script.moodle.courseID;
        this.moodleCourseModuleID = this.script.moodle.moduleID;
      }
    });
    this.subscriptions.push(scriptSubscription);

    const version$$ = version$.subscribe((version) => {
      this.version = version;
      // this.version = await this.modelProvider.scriptVersion.findByRef(this.script.ref.collection('versions').doc('draft')).pipe(first()).toPromise();

      // this.initContextMenuItems();
      if (this.version.hasTeaser()) {
        this.teaserURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.version.teaser.url
        );
      }
      this.scriptForm = new ScriptForm(this.version);

      // const lanugagePipe = new LanguagePipe(this.language);
      this.scriptForm.get("title").setValue(this.version.title);
      this.scriptForm.get("description").setValue(this.version.description);
    });
    this.subscriptions.push(version$$);
  }

  ionViewDidLeave(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async setThumbnail(event: any) {
    this.version.imageUrl = event.url;
    await this.version.save();
    this.analytics.createScriptImageUploadedEvent();
  }

  setThumbnailUploadIconVisibility(visible: boolean) {
    if (this.isUploading() && visible === true) {
      return;
    }
    this.thumbnailIconVisible = visible;
  }

  saveDescription(): void {
    const formControlValue = this.getDescriptionFormControl().value;
    this.analytics.createDescriptionSaveButtonClickedEvent(formControlValue);

    if (!this.scriptForm.valid) {
      return;
    }

    if (formControlValue !== this.version.title) {
      this.version.description = formControlValue;
      this.version.save();
      this.descriptionIsEditing = false;
    } else {
      this.descriptionIsEditing = false;
    }
  }

  getDescriptionFormControl(): FormControl {
    return this.scriptForm.getControl("description");
  }

  onTitleEdit(): void {
    this.titleIsEditing = true;
    this.analytics.createTitleEditedEvent();
  }

  saveTitle(): void {
    const formControlValue = this.getTitleFormControl().value;
    this.analytics.createTitleSaveButtonClickedEvent(formControlValue);

    if (!this.scriptForm.valid) {
      return;
    }

    if (formControlValue !== this.version.title) {
      this.version.title = formControlValue;
      this.version.save();
      this.titleIsEditing = false;
    } else {
      this.titleIsEditing = false;
    }
  }

  getTitleFormControl(): FormControl {
    return this.scriptForm.getControl("title");
  }

  thumbnailClasses() {
    const classes = [];
    if (this.isUploading()) {
      classes.push("thumbnail-image-faded");
    }

    if (!this.version || !this.version.imageUrl) {
      classes.push("blank-image");
    }

    return classes;
  }

  isUploading(): boolean {
    return this.progress > 0 && this.progress < 100;
  }

  initImageUpload($event: any) {
    this.version._image.setSizedUrl(
      URL.createObjectURL($event.target.files[0])
    );
  }

  setProgress(event): void {
    this.progress = event;
    if (this.progress === 0) {
      this.analytics.createScriptImageFileSelectedClickedEvent();
    }
    this.toggleIconVisibilityOnUpload();
  }

  private toggleIconVisibilityOnUpload() {
    if (this.isUploading() && this.thumbnailIconVisible) {
      this.setThumbnailUploadIconVisibility(false);
    }
  }

  async openPlayModal() {
    const modal = await this.modalController.create({
      component: PlayModalComponent,
      componentProps: {
        script: this.script,
        // version: this.version
      },
      cssClass: "play-modal auto-height",
    });
    return await modal.present();
  }

  async openUrlEditorModal(): Promise<void> {
    this.analytics.createVideoTeaserButtonEvent();
    const modal = await this.modalController.create({
      component: TeaserUrlEditorComponent,
      componentProps: {
        version: this.version,
      },
      cssClass: "script-url-editor-modal",
    });
    modal.onDidDismiss().then(() => {
      this.teaserURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.version.teaser.url
      );
      this.version.save();
    });
    return await modal.present();
  }

  public advancedVisible = false;
  showAdvanced() {
    this.advancedVisible = true;
  }

  public moodleURI = "";
  public moodleFunction = "";
  public moodleToken = "";
  public moodleCourseID;
  public moodleCourseModuleID;
  async saveMoodle(field: string, value: string) {
    // if( ['moodleToken', 'moodleCourseID', 'moodleCourseModuleID'].indexOf(field) === -1) {
    //   return;
    // }

    this.script.moodle = this.script.moodle || {};

    switch (field) {
      case "moodleURI":
        this.script.moodle.uri = value;
        break;
      case "moodleFunction":
        this.script.moodle.function = value;
        break;
      case "moodleToken":
        this.script.moodle.token = value;
        break;
      case "moodleCourseID":
        this.script.moodle.courseID = parseInt(value);
        break;
      case "moodleCourseModuleID":
        this.script.moodle.moduleID = parseInt(value);
        break;
      default:
        break;
    }

    await this.script.save();
  }

  saveDirection($event: Event) {
    this.version.direction = ($event.target as any).value;
    this.version.save();
  }

  async presentUseTemplate() {
    const modal = await this.modalController.create({
      component: UseTemplateModalComponent,
      componentProps: {
        script: this.script,
        // version: this.version
      },
      cssClass: "use-template-modal auto-height",
    });
    return await modal.present();
  }
}
