import { LayoutItem } from './task-layout-item';

export class InputVideoLayoutItem extends LayoutItem {
    public type: string = LayoutItem.TYPE_INPUT_VIDEO;
    public order = 90;

    // public instructions: {
    //     languages: {
    //         [language: string]: string;
    //     }
    //     format: 'plain' | 'html';
    // }

    // public alternate: {
    //     text?: {
    //         active: boolean;
    //     }
    // }

    public properties: {
        instructions?: {
            languages: {
                [language: string]: string;
            }
            format: 'plain' | 'html';
        },
        alternate?: {
            text?: {
                active: boolean;
            }
        },
        format?: 'plain' | 'html';
    };

    setAlternate(type: 'text', value: boolean) {
        if (!this.properties.alternate) {
            this.properties.alternate = {
            }
        }

        if (!this.properties.alternate[type]) {
            this.properties.alternate[type] = {
                active: value
            }
        }

        this.properties.alternate[type].active = value;
    }

    getAlternate(type: string = 'text') {
        return this.properties.alternate && this.properties.alternate && this.properties.alternate[type] && this.properties.alternate[type].active;
    }

    setInstructionText(value: string, language = 'en', format: 'plain' | 'html' = 'plain') {
        if (!this.properties.instructions) {
            this.properties.instructions = {
                languages: {
                    [language]: value
                },
                format: format
            }
        }

        this.properties.instructions.languages[language] = value;
        this.properties.instructions.format = format;
    }

    getInstructionText(language: string = 'en') {
        return this.properties.instructions && this.properties.instructions.languages && this.properties.instructions.languages[language];
    }


}

