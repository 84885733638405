<app-header></app-header>

<app-team-sidebar></app-team-sidebar>

<ion-content>
  <!-- <ng-template #personal>
    <app-team-personal></app-team-personal>
  </ng-template> -->
  <ng-container *ngIf="true || !workspaceService.isPersonalWorkspace(workspace)">
    <h1 class="page-title-with-submenu">
      {{ 'manage-teams/title' | translate }}
    </h1>

    <div class="submenu">
      <div class="submenu-item active-submenu">{{ 'manage-teams/settings-title' | translate }}</div>

      <div *ngIf="(workspaceEditPrivilege | privilege : myMember) | async"
          [routerLink]="'../members'"
          class="submenu-item">
        {{ 'manage-teams/members-title' | translate }}
      </div>

      <div *ngIf="!((workspaceEditPrivilege | privilege : myMember) | async)"
          class="submenu-item disabled">
        {{ 'manage-teams/members-title' | translate }}
      </div>

      <div *ngIf="(workspaceEditPrivilege | privilege : myMember) | async"
          [routerLink]="'../players'"
          class="submenu-item">
        {{ 'manage-teams/players-title' | translate }}
      </div>

      <div *ngIf="!((workspaceEditPrivilege | privilege : myMember) | async)"
          class="submenu-item disabled">
        {{ 'manage-teams/players-title' | translate }}
      </div>

    </div>

    <content-container class="py-5"
                      [back-button]="false">

      <div class="container py-3">

        <div class="row"
            *ngIf="workspaceService.hasOnlyPersonalWorkspace()">
          <div class="col-12">
            <p>{{ 'manage-teams/no-team-available' | translate }}</p>
            <ion-button (click)="workspaceService.addNew()"
                        *ngIf="(workspaceEditPrivilege | privilege) | async"
                        shape="round"
                        type="submit">
              {{'manage-teams/new-team' | translate}}
            </ion-button>
          </div>
        </div>

        <div class="row"
            *ngIf="workspace && !workspaceService.hasOnlyPersonalWorkspace()">

          <form #workspaceForm="ngForm">

            <div class="detail-wrapper">
              <div class="detail-label">{{ 'manage-teams/team-name' | translate }}</div>
              <div *ngIf="!titleIsEditing"
                  class="title-container">
                <div>{{ workspace?.title }}</div>
                <mat-icon (click)="titleIsEditing = true; analytics.createTitleEditClickedEvent()"
                          *ngIf="(workspaceEditPrivilege | privilege) | async"
                          class="edit-icon"
                          slot="start">create</mat-icon>
              </div>

              <div *ngIf="titleIsEditing && (workspaceEditPrivilege | privilege) | async"
                  class="title-container">
                <div class="title-editor">
                  <div class="input-container">
                    <input type="text"
                          #titleField="ngModel"
                          name="titleField"
                          (focusin)="analytics.createTitleFieldClickedEvent()"
                          [(ngModel)]="workspace.title"
                          required>
                    <div *ngIf="titleField.touched && titleField.invalid"
                        class="alert invalid-field">
                      {{'manage-teams/team-name-required' | translate}}
                    </div>
                  </div>

                  <div class="title-action">
                    <ion-button shape="round"
                                type="submit"
                                color="secondary"
                                (click)="setTitle()"
                                [disabled]="titleField.invalid">
                      {{'general/ok' | translate}}
                    </ion-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="detail-wrapper">
              <div class="detail-label">{{ 'manage-teams/team-logo' | translate }}</div>

              <div class="logo-wrapper"
                  (mouseover)="setLogoUploadIconVisibility(true)"
                  (mouseleave)="setLogoUploadIconVisibility(false)"
                  [ngClass]="{'logo-image-faded': this.isUploading()}">
                <div class="logo-upload-button-wrapper"
                    [hidden]="!logoIconVisible || !((workspaceEditPrivilege | privilege) | async)">
                  <mat-icon slot="start"
                            (click)="logoUpload.click(); analytics.createLogoUploadClickedEvent()"
                            class="logo-upload-button">camera_alt</mat-icon>

                  <input type="file"
                        [fileInput]="workspace"
                        [fileExtras]="{'fileName':'workspace'}"
                        [category]="'image'"
                        (progress)="setUploadProgress($event)"
                        (uploaded)="setLogo($event.url)"
                        (change)="setUploadProgress(0); initImageUpload($event);"
                        accept="image/jpeg;image/png"
                        class="logo-upload-input"
                        id="logoUpload"
                        #logoUpload
                        [fileMetadata]="{
                          fsDocPath: workspace.ref.path,
                          fsDocField: 'image.sizes',
                          isResize: 'true'
                        }">
                </div>
                <div class="progress logo-upload-progress-wrapper"
                    [hidden]="!isUploading()">
                  <ion-spinner name="lines"></ion-spinner>
                </div>
                <img *ngIf="workspace"
                    [src]="workspace._image | size : 'xs' : 'xs' | safe : 'url'"
                    alt="Team logo"
                    [hidden]="isUploading()"
                    class="img-fluid logo-image2">
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>

            <!-- TODO: remove ngIf if the expected functionality is final -->
            <div class="detail-wrapper"
                *ngIf="false">
              <div class="detail-label">{{ 'manage-teams/delete-team' | translate }}</div>
              <p>{{ 'manage-teams/delete-team-description' | translate }}</p>
              <a href="javascript:void(0)"
                class="remove-btn"
                (click)="removeWorkspace()">
                {{ 'manage-teams/delete-team' | translate }}
              </a>
            </div>

            <div *ngIf="false && organization && (organization.isOrphan() || ['owner', 'admin'].indexOf((organization.myMember$ | async)?.level) >= 0 )" class="form-group mt-3">
              <label for="organizationSelect">Team</label>
              <!-- (ngModelChange)="onOrganizationChange($event, organization)" -->

              <select [(ngModel)]="organization" [compareWith]="compareModel"  id="organizationSelect" class="form-control float-left w-auto" name="organization">
                <ng-container *ngFor="let _organization of (organizationService.organizations$ | async)">
                  <ng-container *ngIf="_organization.isOrphan() || (_organization?.myMember$ | async); let organizationMember">
                    <ng-container *ngIf="_organization.isOrphan() || organizationMember.level == 'owner' || organizationMember.level == 'admin'">
                      <option [ngValue]="_organization">{{_organization.title}}</option>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </select>
              <ion-button *ngIf="organization?.ref?.path !== workspace?.ownerOrganizationRef?.path" (click)="saveOrganization()" shape="round" class="float-left" class="float-left">Move</ion-button>
              <!-- <ion-icon *ngIf="organization?.ref?.path !== workspace?.ownerOrganizationRef?.path" (click)="saveOrganization()" name="checkmark" mode="md" class="float-left"></ion-icon> -->
              <div class="clearfix"></div>
            </div>
            <div *ngIf="organization" class="form-group mt-3">
              <label for="organizationSelect">{{'manage-teams/team-id' | translate}}</label>
              <p class="mt-0">{{workspace?.id}}</p>
            </div>
          </form>

        </div>
      </div>
    </content-container>
  </ng-container>
</ion-content>
