import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WorkspaceMember } from 'src/app/core/models/workspace-member';
import { ManageTeamAnalyticsService } from '../manage-teams/manage-team-analytics-service';

@Component({
             selector: 'app-team-member-delete-confirmation',
             templateUrl: './team-member-delete-confirmation-dialog.component.html'
           })
export class TeamMemberDeleteConfirmationDialogComponent implements OnInit {

  public processing = false;

  @Input()
  public member: WorkspaceMember;

  constructor(private modalCtrl: ModalController, public analytics: ManageTeamAnalyticsService) {
  }

  ngOnInit() {
    this.analytics.createRemoveMemberFromTeamDialogOpenEvent();
  }

  onCancel(closeType: string) {
    this.analytics.createRemoveMemberFromTeamDialogOnCancelClickedEvent(closeType);
    this.modalCtrl.dismiss({action: 'cancel'});
  }

  public async onDelete() {
    this.processing = true;
    try {
      this.analytics.createRemoveMemberFromTeamDialogOnRemoveClickedEvent();
      await this.member.deactivateUserAsMember();
      await this.modalCtrl.dismiss({action: 'delete'});      
    } catch (error) {
      console.error(error);
    } finally {
      this.processing = false
    }

  }
}
