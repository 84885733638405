import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[ionIconTitle]'
})
export class IonIconTitleDirective {

  @Input()
  ionIconTitle: string;

  constructor(private el: ElementRef) {
    console.log(this.el.nativeElement);
   }
  ngOnInit() {
    const changeTitle = () => {
      if (this.el.nativeElement && this.el.nativeElement.shadowRoot && this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title')) {
        this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title').innerHTML =  this.el.nativeElement.getAttribute('title');;
      } else {
        setTimeout(() => {
          changeTitle();
        }, 500);
      }
    }
    changeTitle()
  }

}
