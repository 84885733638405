import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Play } from 'src/app/core/models/play.model';
import { Player } from 'src/app/core/models/player.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Script } from 'src/app/core/models/script.model';
import { Task } from 'src/app/core/models/task.model';
import { User } from 'src/app/core/models/user.model';
import { environment } from 'src/environments/environment';
import { UAParser } from 'ua-parser-js';


@Component({
  selector: 'superadmin-analytics-session-item',
  templateUrl: './session-item.component.html',
  styleUrls: ['./session-item.component.scss'],
  providers: [DatePipe],
})
export class SuperadminAnalyticsSessionItemComponent implements OnInit {

  @Input()
  play: Play;

  @Input()
  script: Script;

  version: ScriptVersion;
  tasks: Task[];

  players: Player[];

  users: User[];

  // unresolvedTasks: Task[];

  // @Input()
  // scriptVersion: ScriptVersion;

  constructor(
    private datePipe: DatePipe
  ) { }

  async ngOnInit() {
    this.players = (await this.play.players$.pipe(first()).toPromise()).filter(player => !player.id.includes('facilitator')).sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis());
    this.users = await Promise.all(this.players.map(player => player.user$.pipe(first()).toPromise()));
    this.version = await this.play.scriptVersion$.pipe(first()).toPromise();
    this.tasks = await this.version.tasks$.pipe(first()).toPromise();
  }

  getUnresolvedTasks(player: Player) {
    return this.tasks
      .filter(
        task => (task.isResolved(this.players) && !task.isDone(this.players) && task.roleRef.isEqual(player.roleRef))
      )
      .sort((a: Task, b: Task) => (a.order > b.order) ? 1 : -1);

    // unresolvedTasks.length >= 1 ? unresolvedTasks[0].
  }

  private getTaskType(task: Task) {
    const types = ['text'
      , 'image'
      , 'video'
      , 'image-360'
      , 'image-360'
      , 'video-360'
      , 'input-choice'
      , 'input-text'
      , 'input-photo'
      , 'input-video'].reverse();

    for (const type of types) {
      if (task.layout && task.layout.getItemByType(type, false)) {
        return type.split('-').reverse().map(_type => _type.charAt(0).toUpperCase() + _type.slice(1)).join(' ');
        // return type;
      }
    }

  }

  getProgress(player: Player) {
    if (player.responses.isEmpty) { return 'Not started' }

    const responses = player.responses.values;

    let unresolvedTaskText: string = '';
    const unresolvedTasks = this.getUnresolvedTasks(player);

    if (unresolvedTasks.length > 0) {
      const unresolvedTasksType = this.getTaskType(unresolvedTasks[0]);
      // if (player.id === '9Nn9rJoaueY04mIPirY3') {
      //   console.log('unresolvedTasks', unresolvedTasksType, unresolvedTasks);
      // }
      unresolvedTaskText = 'Stopped at ' + unresolvedTasksType
       + (player.progress ? (' (' + Math.round(player.progress * 100) + '%)') : '');
    } else {
      unresolvedTaskText = 'Waiting for another player'
       + (player.progress ? (' (' + Math.round(player.progress * 100) + '%)') : '');

    }
    if (player.isFinished(this.tasks)) {
      unresolvedTaskText = 'Finished';
    }

    const completedText = responses.length + ' Task' + (responses.length > 1 ? 's' : '') + ' completed'

    return unresolvedTaskText;

  }

  getUser(player: Player) {
    return this.users && this.users.find(user => user.ref.isEqual(player.userRef));
  }

  getDevice(user: User) {
    let lastDevice = null;
    let lastDeviceKey = null;
    // let lastDec
    for (const [key, device] of Object.entries(user.device)) {
      // console.log(`${key}: ${device}`, (device as any).lastLoginAt);
      if (!lastDevice) { lastDevice = device; lastDeviceKey = key; }

      // console.log('device',(device as any).lastLoginAt.toDate().getTime());
      // console.log('lastDevice', lastDevice.lastLoginAt.toDate().getTime());

      if ((device as any).lastLoginAt.toDate().getTime() > lastDevice.lastLoginAt.toDate().getTime()) {
        lastDevice = device;
        lastDeviceKey = key;
      }
    }

    // console.log(lastDevice, user);

    if (!lastDevice) { return '' }

    if (lastDeviceKey === 'web') {
      const uaPArser = new UAParser(lastDevice.userAgent);
      return ['Web', lastDevice.platform, uaPArser.getBrowser().name].join(' ');
    }

    return [lastDevice.platform, lastDevice.version].join(' ');
  }

  getLastLoginTime(player: Player) {
    const joinDate = player.createdAt.toDate();
    const lastLoginTime = this.getUser(player).lastLoginAt.toDate();
    if (lastLoginTime.getTime() < joinDate.getTime()) {
      return 'Never returned';
    }

    // Refreshed within 15 minutes
    if (lastLoginTime.getTime() < joinDate.getTime() + (15 * 60 * 1000)) {
      return 'Refreshed at ' + this.datePipe.transform(lastLoginTime, 'shortTime');
    }

    return this.datePipe.transform(lastLoginTime, 'short');
  }

  openMixpanelPlayer(player: Player) {
    window.open('https://eu.mixpanel.com/project/2517500/view/3058275/app/profile#distinct_id=' + player.userRef.id)
  }

  openMixpanelSession() {
    window.open('https://eu.mixpanel.com/project/2517500/view/3058275/app/profile#distinct_id=' + this.play.id + '&data_group_id=4470020081891586662&')
  }

  openMonitorSession() {
    window.open('https://app.gamoteca.com/organize/' + this.play.id + '/live')
  }

  openFirebasePlayer(player: Player) {
    window.open('https://console.firebase.google.com/u/0/project/' + environment.firebaseConfig.projectId + '/firestore/data/~2Fusers~2F' + player.userRef.id)
  }

  openFirebaseSession() {
    window.open('https://console.firebase.google.com/u/0/project/' + environment.firebaseConfig.projectId + '/firestore/data/~2Fplays~2F' + this.play.id)
  }

}
