import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Plan } from 'src/app/core/models/plan';
import { Organization } from 'src/app/core/models/organization.model';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CancelSubscriptionConfirmModalComponent } from './cancel-subscription-confirm-modal/cancel-subscription-confirm-modal.component';
import { first } from 'rxjs/operators';
import { OrganizationAnalyticsService } from '../../organization.analytics.service';

@Component({
  selector: 'organization-current-package',
  templateUrl: './current-package.component.html',
  styleUrls: ['./current-package.component.scss'],
})
export class CurrentPackageComponent implements OnInit {

  @Input()
  organization: Organization;

  @Input()
  plan: Plan;

  @Output() goToInvoice = new EventEmitter<string>();

  processing: string;

  constructor(
    private router: Router,
    private alertController: AlertController,
    private modalController: ModalController,
    private toastController: ToastController,
    private translate: TranslateService,
    private organizationAnalytics: OrganizationAnalyticsService
  ) { }

  async ngOnInit() {
    this.plan = await this.organization.plan$.pipe(first()).toPromise();
  }

  ngAfterViewInit(): void {
    this.organizationAnalytics.openPage('Manage / Plan details');
  }

  async cancelSubscription() {
    const modal = await this.modalController.create({
      component: CancelSubscriptionConfirmModalComponent,
      componentProps: {
        organization: this.organization,
        plan: this.plan
      },
      cssClass: 'auto-height cancel-subscription-confirm'
    });
    modal.present();
    const data = await modal.onDidDismiss();
    return data;

    // const alert = await this.alertController.create({
    //   cssClass: 'cancel-subscription',
    //   keyboardClose: false,
    //   backdropDismiss: false,
    //   header: this.translate.instant('organization/plan-details/cancel-subscription/confirm/title'),
    //   message: this.translate.instant('organization/plan-details/cancel-subscription/confirm/message'),
    //   buttons: [
    //     {
    //       text: this.translate.instant('organization/plan-details/cancel-subscription/confirm/cancel'),
    //       role: 'cancel',
    //       cssClass: 'secondary',
    //       handler: (blah) => {
    //         // console.log('Confirm Cancel: blah');
    //       }
    //     }, {
    //       text: this.translate.instant('organization/plan-details/cancel-subscription/confirm/ok'),
    //       cssClass: 'warning',
    //       handler: async () => {
    //         await this.doCancel();

    //         // return false;
    //       }
    //     }
    //   ]
    // });

    // await alert.present();
    // await alert.onDidDismiss();

  }

  // private async doCancel() {
  //   try {
  //     if (this.processing === 'cancel-subscription') { return; }
      
  //     this.processing = 'cancel-subscription';
  //     await this.organization.cancelSubscription();
  //     const toast = await this.toastController.create({
  //       message: this.translate.instant('organization/plan-details/cancel-subscription/confirm/success'),
  //       duration: 3500,
  //       color: 'primary',
  //       cssClass: 'text-center mt-5',
  //       position: 'top'
  //     });
  //     await toast.present();
  //   } catch (error) {

  //   } finally {
  //     this.processing = null;
  //   }
  // }

  public async reactivate() {
    try {
      if (this.processing === 'reactivate-subscription') { return; }
      
      this.processing = 'reactivate-subscription';
      await this.organization.reactivateSubscription();
      const toast = await this.toastController.create({
        message: this.translate.instant('organization/plan-details/reactivate-subscription/success'),
        duration: 3500,
        color: 'primary',
        cssClass: 'text-center mt-5',
        position: 'top'
      });
      await toast.present();
    } catch (error) {

    } finally {
      this.processing = null;
    }
  }

  async upgradeToEnterprise() {
    await this.router.navigateByUrl('organization/create/enterprise');
  }

  // async goToInvoice() {

  // }

}
