import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IonVirtualScroll } from '@ionic/angular';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { first, map } from 'rxjs/operators';
import { Plan, Price } from 'src/app/core/models/plan';
import { Router } from '@angular/router';
import { OrganizationAnalyticsService } from '../organization.analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organization-plans',
  templateUrl: './organization-plans.component.html',
  styleUrls: ['./organization-plans.component.scss'],
})
export class OrganizationPlansComponent implements OnInit {

  // plan: string;

  public free: Plan;
  public pro: Plan;
  public enterprise: Plan;
  public proPrice: number;
  public currency = 'gbp';

  public language = 'en';

  public sticky = false;
  onWindowScroll(e: CustomEvent) {
    this.sticky = (e.detail.scrollTop > 75);
  }

  constructor(
    private modelProvider: ModelProvider,
    private router: Router,
    private organizationAnalytics: OrganizationAnalyticsService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    // const plans = await this.modelProvider.plan.findAllBy().pipe(first()).toPromise();
    this.free = await this.modelProvider.plan.findByID('free').pipe(first()).toPromise();
    this.pro = await this.modelProvider.plan.findByID('pro').pipe(first()).toPromise();
    this.proPrice = this.pro.pricing['yearly'].currencies[this.currency].amount;

    this.language = this.translate.currentLang;

    this.enterprise = await this.modelProvider.plan
    .findAllBy(ref => ref.where('role', '==', 'enterprise'))
    .pipe(map(plans => plans[0]), first()).toPromise();
    // this.proPrice = plans.team.pricing.find(price => price.currency === this.currency && price.recurring.interval === 'year');
    // this.plan = plans[this.planName];

    // this.yearly = plans.team.pricing.find(price => price.currency === this.currency && price.recurring.interval === 'year');
    // this.monthly = plans.team.pricing.find(price => price.currency === this.currency && price.recurring.interval === 'month');

  }

  ngAfterViewInit(): void {
    this.organizationAnalytics.openPage('Pricing');
  }

  async selectPlan(plan: Plan) {
    await this.router.navigate(['organization/create', plan.id]);
  }

  async selectContact(planID: string) {
    await this.router.navigate(['organization/contact', planID]);
  }

}
