import { PipeTransform, Pipe, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
    name: 'dateShort',
})
export class DateShortPipe implements PipeTransform {

    public constructor(
        @Inject(LOCALE_ID) private locale: string,
        // private datePipe: DatePipe
    ) {

    }

    isCurrentYear(date: Date): boolean {
        return new Date().getFullYear() === date.getFullYear();
    }

    transform(date: Date, size?: string, ...args: any[]) {
        if (date instanceof Date) {
            if (this.isCurrentYear(date)) {
                return new DatePipe(this.locale).transform(date, 'dd/MM');
            } else {
                return new DatePipe(this.locale).transform(date, 'shortDate');
            }
        }
    }
}
