<app-header [active]="'play'"></app-header>

<ion-content>
  <content-container class="py-5"
                     [back-button]="false">
    <div class="row">
      <div class="col-md-4 col-xs-12 horizontal-centered-cel-content">
        <img src="../../../assets/images/gamoteca-mobile-app.png"
             alt="Gamoteca Mobile App"
             class="gamoteca-app-img">
      </div>
      <div class="col-md-8 col-xs-12">
        <h1>{{'playing/landing/header/title' | translate}}</h1>
        <p class="description">{{'playing/landing/header/description' | translate}}</p>

        <form>
          <div class="form-check">
            <input type="checkbox"
                   class="form-check-input"
                   id="task-terminate"
                   name="showAgain"
                   (change)="analytics.onShowAgainCheckboxClicked(dontShowAgain)"
                   [(ngModel)]="dontShowAgain">
            <label class="note form-check-label"
                   for="task-terminate">{{'playing/landing/header/dont-show-label' | translate}}</label>
          </div>
        </form>

        <div class="row">
          <div class="col-4 vertical-centered-cel-content">
            <a [href]="getPlayStoreUrl()"
               (click)="analytics.onAndroidClicked()"
               target="_blank"
               class="img-button-link">
              <img src="../../../assets/images/playstore-img-btn.png"
                   alt="Google Play Store Button"
                   class="app-store-img-button">
            </a>

            <a [href]="getAppStoreUrl()"
               (click)="analytics.onIosClicked()"
               target="_blank"
               class="img-button-link">
              <img src="../../../assets/images/appstore-img-btn.png"
                   alt="Apple App Store Button"
                   class="app-store-img-button">
            </a>

          </div>
          <div class="col-8 vertical-centered-cel-content">
            <ion-button shape="round"
                        type="submit"
                        [href]="getPlayInBrowserUrl()"
                        target="_blank"
                        (click)="addDontShowAgainFlagToLocalStorage(); analytics.onOpenInBrowserClicked()">
              <mat-icon class="cta-btn-icon">open_in_new</mat-icon>
              {{'playing/landing/header/play-in-browser' | translate}}
            </ion-button>
          </div>
        </div>

      </div>
    </div>
  </content-container>
</ion-content>
