<div class="modal-content ion-padding">
  <h2 class="modal-title">
    {{ "logout-required/title" | translate }}
  </h2>
  <p>
    {{ "logout-required/paragraph" | translate }}
  </p>
  <div class="mt-4 text-right">
    <ion-button (click)="cancel()" class="mr-3" color="secondary" shape="round"
      >{{ "general/cancel" | translate }}
    </ion-button>
    <ion-button (click)="logout()" shape="round" color="primary">
      {{ "logout-required/logout-button/label" | translate }}
    </ion-button>
  </div>
</div>
