<app-header active="superadmin">
  <ion-title>Set as superadmin</ion-title>
</app-header>


<ion-content>
  <content-container back-button="/superadmin/dashboard">
    <form #form="ngForm" (ngSubmit)="submit()" class="col-sm-4">
      <div class="form-group">
        <label for="userID">
          User ID
        </label>
        <input type="text" [(ngModel)]="userID" required name="userID" id="userID">
      </div>
      <div class="form-group">
        <ion-button color="primary" type="submit">Set as superadmin</ion-button>
      </div>
    </form>
  </content-container>
</ion-content>
