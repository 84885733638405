import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { first } from 'rxjs/operators';

@Injectable()
export class PlayLandingAnalyticsService {
  constructor(
    private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
  }

  setScreen() {
    this.analyticsService.setCurrentScreen('Play landing');
  }

  onIosClicked(): void {
    this.analyticsService.event(['IOS logo clicked']);
  }

  onAndroidClicked(): void {
    this.analyticsService.event(['Android logo clicked']);
  }

  onOpenInBrowserClicked(): void {
    this.analyticsService.event(
      ['Open in browser'],
      this.translateService.instant('playing/landing/header/play-in-browser')
    );
  }

  onShowAgainCheckboxClicked(value: boolean): void {
    this.analyticsService.event(
      ['Show again', 'checkbox clicked'],
      value ? 'checked' : 'unchecked'
    );
  }

}
