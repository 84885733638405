import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { TeaserUrlEditorComponent } from './teaser-url-editor.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeModule } from 'src/app/core/localize/localize.module';
import { ScripViewAnalyticsService } from '../script-view-analytics-service';
import { ScriptCardAnalyticsService } from '../../list/script-card/script-card-analytics-service';
import { ModelsModule } from 'src/app/core/models/models.module';
import { TeaserUrlEditorAnalyticsService } from './teaser-url-editor-analytics.service';
import { MatIconModule } from '@angular/material/icon';


// const routes: Routes = [
//   {
//     path: '',
//     component: TeaserUrlEditorComponent
//   }
// ];

@NgModule({
            imports: [
              CommonModule,
              FormsModule,
              IonicModule,
              // RouterModule.forChild(routes),
              TranslateModule.forChild(),
              ReactiveFormsModule,
              LocalizeModule,
              ModelsModule,
              MatIconModule
            ],
            declarations: [
              TeaserUrlEditorComponent
            ],
            entryComponents: [TeaserUrlEditorComponent],
            providers: [
              TeaserUrlEditorAnalyticsService
            ]
          })
export class TeaserUrlEditorModule {}
