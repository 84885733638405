import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PublishModalComponent } from './publish-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsModule } from 'src/app/core/analytics/analytics.module';
import { PublishModalAnalyticsService } from './publish-modal-analytics.service';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxPopperModule } from 'ngx-popper';



@NgModule({
  declarations: [
    PublishModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    // AnalyticsModule,
    PipesModule,
    MatIconModule,
    NgxPopperModule
  ],
  exports: [
    PublishModalComponent
  ],
  providers: [
    PublishModalAnalyticsService
  ],
  entryComponents: [
    PublishModalComponent
  ],
})
export class PublishModalModule { }
