import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { UserProfilePageComponent } from './user-profile-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponentsModule } from '../core/app-components.module';
import { UserProfileAnalyticsService } from './user-profile-analytics-service';
import { MatIconModule } from '@angular/material/icon';
import { DeleteAccountModalComponent } from './delete-account-modal/delete-account-modal.component';
import { AuthModule } from '../auth/auth.module';
import { ImageCropperModule } from 'ngx-image-cropper';

const routes: Routes = [
  {
    path: 'user-profile',
    component: UserProfilePageComponent
  }
];

@NgModule({
            imports: [
              CommonModule,
              FormsModule,
              IonicModule,
              RouterModule.forChild(routes),
              TranslateModule.forChild(),
              AppComponentsModule,
              MatIconModule,
              AuthModule,
              ImageCropperModule
            ],
            declarations: [
              UserProfilePageComponent,
              DeleteAccountModalComponent
            ],
            entryComponents: [
              DeleteAccountModalComponent
            ],
            providers: [UserProfileAnalyticsService]
          })
export class UserProfileModule {}
