<mat-icon (click)="cancel('icon')" class="modal-close-icon" slot="start">close</mat-icon>

<div class="modal-content ion-padding">
  <form>
    <h2 class="modal-title">{{'organization/members/remove/confirmation-title' | translate}}</h2>
    <p class="confirmation-description mb-0">
      {{'organization/members/remove/confirmation-question' | translate}}
    </p>
    <div class="form-check ml-0 mt-3 mb-5">
      <input [(ngModel)]="isDeleteFromWorkspaces" name="isDeleteFromWorkspaces" type="checkbox" class="form-check-input"
        id="isDeleteFromWorkspaces">
      <label class="note form-check-label"
        for="isDeleteFromWorkspaces">{{'organization/members/remove/isDeleteFromWorkspaces/label' | translate}}</label>
    </div>
    <ion-footer mode="ios">
      <div class="text-right">
        <ion-button (click)="cancel('button')" [disabled]="processing === 'delete'" class="mr-2" color="secondary"
          shape="round">

          {{'general/cancel' | translate}}
        </ion-button>

        <ion-button (click)="delete()" [disabled]="processing === 'delete'" type="submit" color="primary" shape="round">
          <ion-spinner *ngIf="processing === 'delete'" name="lines-small"></ion-spinner>
          {{'organization/members/remove/confirm-button/label' | translate}}
        </ion-button>
      </div>
    </ion-footer>
  </form>
</div>