import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization-member-reactivate-modal',
  templateUrl: './organization-member-reactivate-modal.component.html',
  styleUrls: ['./organization-member-reactivate-modal.component.scss'],
})
export class OrganizationMemberReactivateModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
