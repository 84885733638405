import { Injectable, SkipSelf, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

@Injectable()
export class TeaserUrlEditorAnalyticsService {
  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,

    private translateService: TranslateService
  ) {
    // this.analyticsService.setCurrentScreen('Game edit / Teaser Video');
  }

  createURLFieldClickedEvent(): void {
    this.analyticsService.event(
      ['Video teaser', 'URL', 'field clicked'],
      this.translateService.instant('script-designer/url-editor/edit-url-placeholder')
    );
  }

  createSaveButtonClickedEvent(url): void {
    this.analyticsService.event(
      ['Video teaser', 'save button clicked'],
      this.translateService.instant('script-details/video/save-button'),
      { value: url }
    );
  }

  createCancelButtonClickedEvent(): void {
    this.analyticsService.event(
      ['Video teaser', 'cancel button clicked'],
      this.translateService.instant('script-details/video/cancel-button')
    );
  }

  createCloseIconClickedEvent(): void {
    this.analyticsService.event(
      ['Video teaser', 'cancel icon clicked'],
      this.translateService.instant('script-details/video/cancel-button')
    );
  }

  createBackdropClickedEvent(): void {
    this.analyticsService.event(
      ['Video teaser', 'backdrop clicked']
    );
  }

}
