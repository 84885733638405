import { NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Platform, IonicModule } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { AnalyticsProvider } from '../core/analytics/analytics';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { AnalyticsModule } from '../core/analytics/analytics.module';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { AuthService } from './auth.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@NgModule({
    declarations: [],
    imports: [
        AnalyticsModule.forRoot(),
        IonicStorageModule,
    ],
    exports: [],
    providers: [
        FirebaseDynamicLinks,
        AngularFireAuth,
        AppVersion,
        Device,
        Platform,
        AngularFirestore,
        AngularFireFunctionsModule,
        Facebook,
        FirebaseMessaging,
        AnalyticsProvider,
        SignInWithApple,
        GooglePlus,
        InAppBrowser
        // AuthService
        //   FirebaseAnalytics,
        //   Angulartics2GoogleAnalytics,
        //   Angulartics2GoogleTagManager,
        //   Angulartics2Mixpanel,
        //   AnalyticsProvider
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders {
      return {
        ngModule: AuthModule,
        providers: [
          AuthService
          // PrivilegeService,
          // { provide: WorkspaceService, useClass: WorkspaceService }
        ]
      };
    }
}
