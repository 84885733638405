import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { EventManager } from '../event-manager/event-manager.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line: no-input-rename
  @Input('back-button') backButton: string | Array<string> | boolean = true;
  @Input() pageTitle = '';

  @Output('back-button-action') backButtonAction: EventEmitter<any> = new EventEmitter();

  private navigationIsDisabled: boolean;
  private inProgressEventSubscriber: Subscription;
  private finishedEventSubscriber: Subscription;

  constructor(
    private _location: Location,
    private eventManager: EventManager,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  backClicked() {
    if(this.backButtonAction.observers.length > 0) {
      return this.backButtonAction.next();
    }

    if (Array.isArray(this.backButton)) {
      this.router.navigate(this.backButton, {relativeTo: this.route});
      return;
    }

    if(typeof this.backButton === 'string') {
      this.router.navigateByUrl(this.backButton);
      return;
    }

    this._location.back();
  }

  ngOnInit() {
    this.inProgressEventSubscriber = this.eventManager.subscribe('UploadInProgressEvent',
      response => this.navigationIsDisabled = true);
    this.finishedEventSubscriber = this.eventManager.subscribe('UploadFinishedEvent',
      response => this.navigationIsDisabled = false);
  }

  ngOnDestroy(): void {
    this.eventManager.destroy(this.inProgressEventSubscriber);
    this.eventManager.destroy(this.finishedEventSubscriber);
  }
}
