import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleSelectComponent } from './role-select.component';
import { RoleSelectAnalyticsService } from './role-select-analytics.service';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';



@NgModule({
  declarations: [
    RoleSelectComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    // ReactiveFormsModule,
    PipesModule,
    FormsModule,
  ],
  providers: [
    RoleSelectAnalyticsService
  ],
  entryComponents: [
    RoleSelectComponent
  ]
})
export class RoleSelectModule { }
