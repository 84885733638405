import { Pipe, PipeTransform } from '@angular/core';
import { LayoutItem } from 'src/app/core/models/task-layout-item';

/**
 * Generated class for the LayoutOrderPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'layoutOrderPipe',
})
export class LayoutOrderPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(items: LayoutItem[], ...args) {


    let newItems = [];
    for (const taskID in items) {
      if (items.hasOwnProperty(taskID)) {
        const item = items[taskID];

        // item.taskID = taskID;
        newItems.push(item);
      }
    }

    newItems.sort((a, b) => {
      return a.order - b.order;
    });

    return newItems;





    // return value.toLowerCase();
  }
}
