import { Component, OnInit } from '@angular/core';
import { ScriptVersion } from '../../../core/models/script-version.model';
import { ModalController } from '@ionic/angular';
import { TeaserUrlEditorAnalyticsService } from './teaser-url-editor-analytics.service';

@Component({
  selector: 'app-teaser-url-editor',
  templateUrl: './teaser-url-editor.component.html',
  styleUrls: ['./teaser-url-editor.component.scss'],
  // providers: [
  //   TeaserUrlEditorAnalyticsService
  // ]
})
export class TeaserUrlEditorComponent implements OnInit {

  version: ScriptVersion;
  url: string;

  constructor(
    private modalController: ModalController,
    public analytics: TeaserUrlEditorAnalyticsService
  ) {
  }

  ngOnInit() {
    this.url = this.version.teaser && this.version.teaser.url ? this.version.teaser.url : "";
  }

  dismissModal(context?: string): void {
    // Analytics
    if (context === 'icon') { this.analytics.createCloseIconClickedEvent(); }
    if (context === 'close button') { this.analytics.createCancelButtonClickedEvent(); }

    this.modalController.dismiss();
  }

  saveUrl(): void {
    this.analytics.createSaveButtonClickedEvent(this.url);

    if (!this.version.teaser) {
      this.version.teaser = {};
    }
    // Fix for: https://gamoteca-team.monday.com/boards/339963270/pulses/340784554
    // Youtube's same origin policy does not allow embedding shorthanded urls
    const isYoutubeUrl = this.url.indexOf('youtu') > -1; // must accept youtube.com and youtu.be too
    if (isYoutubeUrl) {
      const id = this.getYoutubeIdFromUrl(this.url);
      this.url = 'https://www.youtube.com/embed/' + id;
    }
    this.version.teaser.url = this.url;
    this.dismissModal();
  }

  private getYoutubeIdFromUrl(url: string) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  }

}
