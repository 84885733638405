<form #form="ngForm" (ngSubmit)="save()">


  <ng-container *ngIf="formTemplate === 'simple'">
    <div class="row">
      <div *ngIf="lti" class="col-sm-8">
        <h2>
          {{'organization/integrations/lti/title' | translate}}
          <ion-toggle class="float-right pt-2 pr-0" [(ngModel)]="integrations.lti" name="ltiEnabled"></ion-toggle>
        </h2>
        <h4><span class="order">1</span>{{'organization/integrations/lti/moodle/install/title' | translate}}</h4>
        <div class="form-group">
          <p [innerHTML]="'organization/integrations/lti/moodle/install/lead' | translate">Install the Moodle plugin using <strong><a href="https://moodle.org/plugins/mod_gamoteca" target="_blank">https://moodle.org/plugins/mod_gamoteca</a></strong>.</p>
        </div>
        <div class="form-group">
          <p class="mb-0">{{'organization/integrations/lti/moodle/encryption' | translate}}</p>
          <div class="card border rounded bg-light text-dark p-3 py-4">
            <ion-spinner *ngIf="!lti?.encryptionKey"></ion-spinner>
            <strong>{{lti?.encryptionKey}}</strong>
            <ion-icon (click)="copyEncryptionKey()" name="copy-outline"></ion-icon></div>
        </div>
        <h4><span class="order">2</span>{{'organization/integrations/lti/site/title' | translate}}</h4>
        <div class="form-group">
          <div class="form-group">
            <label for="domain">{{'organization/integrations/lti/site/label' | translate}}</label>
            <input #domainControl [(ngModel)]="domain" (ngModelChange)="onDomainUpdate($event)" name="domain" required id="domain" type="text" placeholder="subdomain.domain.com"/>
          </div>
        </div>
        <h4><span class="order">3</span>Create a Webservice</h4>
        <div class="form-group">
          <p>
            {{'organization/integrations/lti/web-service/lead/prefix' | translate}}
            <a *ngIf="form.controls.domain?.valid" [href]="'https://' + domain + '/admin/webservice/tokens.php'" target="_blank">"{{'organization/integrations/lti/web-service/lead/inner' | translate}}"</a>
            <ng-container *ngIf="!form.controls.domain?.valid">"{{'organization/integrations/lti/web-service/lead/inner' | translate}}"</ng-container>
            {{'organization/integrations/lti/web-service/lead/postfix' | translate}}
          </p>
        </div>
        <div class="form-group">
          <label for="token">{{'organization/integrations/lti/web-service/label' | translate}}</label>
          <input [(ngModel)]="lti.token" name="token" required id="token" type="text" placeholder="" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="formTemplate === 'advanced'">
    <div class="row">
      <div *ngIf="sso" class="col-sm-6">
        <h2>Single Sign On
          <ion-toggle class="float-right pt-2 pr-0" [(ngModel)]="integrations.sso" name="ssoEnabled"></ion-toggle>
        </h2>
        <div class="form-group">
          <label for="providerID">Unique Provider ID</label>
          <input [(ngModel)]="integrations.providerID" name="providerID" required id="providerID" type="text" />
        </div>
        <div class="form-group">
          <label for="clientID">Client ID</label>
          <input [(ngModel)]="sso.clientID" name="clientID" required id="clientId" type="text" placeholder="gamoteca" />
        </div>
        <div class="form-group">
          <label for="clientSecret">Client Secret</label>
          <input [(ngModel)]="sso.clientSecret" name="clientSecret" required id="clientSecret" type="text"
            placeholder="c0102f8c762ce3433a24cb6c3d34" />
        </div>
        <div class="form-group">
          <label for="host">Token Host</label>
          <input [(ngModel)]="sso.host" name="host" required id="host" type="text" placeholder="https://domain.com" />
        </div>
        <div class="form-group">
          <label for="tokenPath">Token Path</label>
          <input [(ngModel)]="sso.tokenPath" name="tokenPath" required id="tokenPath" type="text"
            placeholder="/local/oauth/token.php" />
        </div>
        <div class="form-group">
          <label for="billingName">Scope</label>
          <input [(ngModel)]="sso.scope" name="scope" required id="scope" type="text" placeholder="user_info" />
        </div>
        <div class="form-group">
          <label for="userInfoPath">User Info Path</label>
          <input [(ngModel)]="sso.userInfoPath" name="userInfoPath" required id="userInfoPath" type="text"
            placeholder="/local/oauth/user_info.php" />
        </div>
      </div>
      <div *ngIf="lti" class="col-sm-6">
        <h2>
          Learning Tools Interoperability
          <ion-toggle class="float-right pt-2 pr-0" [(ngModel)]="integrations.lti" name="ltiEnabled"></ion-toggle>
        </h2>
        <div class="form-group">
          <label for="providerID">Unique Provider ID</label>
          <input [(ngModel)]="integrations.providerID" name="providerID" required id="providerID" type="text" />
        </div>
        <div class="form-group">
          <label for="url">Service URL</label>
          <input [(ngModel)]="lti.url" name="url" required id="url" type="text"
            placeholder="https://domain.com/webservice/rest/server.php" />
        </div>
        <div class="form-group">
          <label for="token">Token</label>
          <input [(ngModel)]="lti.token" name="token" required id="token" type="text"
            placeholder="sIdyGlv2aaaaaa5fQPgY4aoR9" />
        </div>
        <div class="form-group">
          <label for="wsFunction">Service function</label>
          <input [(ngModel)]="lti.wsFunction" name="wsFunction" required id="wsFunction" type="text"
            placeholder="gamoteca" />
        </div>
        <!-- <div class="card border rounded bg-light text-dark p-3 mt-5">
          <h3 class="mt-0">Add to config.auth</h3>
          <pre><code>id: {{integrations?.providerID}},
  title: [button title],
  logoURL: [logo's URL],
  type: moodle,
  url: {{sso.host}}</code></pre>
        </div> -->
      </div>
    </div>
  </ng-container>
  <div class="form-group mt-4 text-right2">
    <ion-button type="submit" color="primary" shape="round" [disabled]="form.invalid || processing || !lti?.encryptionKey">
      <ion-spinner *ngIf="processing === 'save'" name="lines-small" slot="start"></ion-spinner>
      {{'general/save' | translate}}
    </ion-button>
  </div>
</form>