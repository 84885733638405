import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

@Injectable()
export class SimulatorAnalyticsService {
  constructor(
    // @SkipSelf() private analyticsService: AnalyticsProvider,
    private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
    // this.analyticsService.setCurrentScreen('App update popup');
  }

  onStart() {
    this.analyticsService.event(
      ['Simulator', 'start button clicked'],
      this.translateService.instant('script-designer/actions/simulate/start/title')
    );
  }

  onStop() {
    this.analyticsService.event(
      ['Simulator', 'stop button clicked'],
      this.translateService.instant('script-designer/actions/simulate/stop/title')
    );
  }

}
