import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ScriptDeleteConfirmationDialogComponent } from './script-delete-confirmation-dialog.component';
import { ScriptDeleteConfirmationDialogAnalyticsService } from './script-delete-confirmation-dialog.analytics.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
            declarations: [
              ScriptDeleteConfirmationDialogComponent
            ],
            imports: [
              CommonModule,
              IonicModule,
              TranslateModule.forChild(),
              MatIconModule
            ],
            exports: [
              ScriptDeleteConfirmationDialogComponent
            ],
            providers: [
              ScriptDeleteConfirmationDialogAnalyticsService
            ],
            entryComponents: [
              ScriptDeleteConfirmationDialogComponent
            ]
          })
export class ScriptDeleteConfirmationDialogModule {}
