import { Model } from './general/model';
import { ModelProvider } from './general/model.provider';
import { ImageLayoutItem } from './layout-item-image';
import { QuoteLayoutItem } from './layout-item-quote';
import { VideoLayoutItem } from './layout-item-video';
import { Image360LayoutItem } from './layout-item-image360';
import { Video360LayoutItem } from './layout-item-video360';
import { TextLayoutItem } from './layout-item-text';
import { InputChoiceLayoutItem } from './layout-item-input-choice';
import { InputTextLayoutItem } from './layout-item-input-text';
import { InputPhotoLayoutItem } from './layout-item-input-photo';
import { InputVideoLayoutItem } from './layout-item-input-video';
import { LayoutItem } from './task-layout-item';

export class TaskLayoutItemFactory {

  public static createItemByType(data, documentInstance: Model, modelProvider: ModelProvider): LayoutItem {
    let item: LayoutItem = null;
    switch (data.type) {
      case LayoutItem.TYPE_IMAGE:
        item = new ImageLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_QUOTE:
        item = new QuoteLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_VIDEO:
        item = new VideoLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_IMAGE_360:
        item = new Image360LayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_VIDEO_360:
        item = new Video360LayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_TEXT:
        item = new TextLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_INPUT_CHOICE:
        item = new InputChoiceLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_INPUT_TEXT:
        item = new InputTextLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_INPUT_PHOTO:
        item = new InputPhotoLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      case LayoutItem.TYPE_INPUT_VIDEO:
        item = new InputVideoLayoutItem(data, documentInstance, {}, modelProvider);
        break;
      default:
        console.log(documentInstance);
        throw new Error('Could not find task layout for type: ' + data.type);
    }
    return item;
  }

}