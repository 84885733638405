import { Component, OnInit, Input } from '@angular/core';
import { Script } from 'src/app/core/models/script.model';
import { ScriptCardAnalyticsService } from '../list/script-card/script-card-analytics-service';
import { Router } from '@angular/router';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { take, first } from 'rxjs/operators';
import { Workspace } from 'src/app/core/models/workspace.model';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { Subscription } from 'rxjs';
import { ContextMenuClickEvent } from 'src/app/core/context-menu/context-menu-events';
import { ClipboardService } from 'ngx-clipboard';
import { ToastController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PublishModalComponent } from '../publish-modal/publish-modal.component';
import { ScriptDeleteConfirmationDialogComponent } from '../list/script-card/script-delete-confirmation-dialog/script-delete-confirmation-dialog.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { PlayModalComponent } from '../play-modal/play-modal.component';

@Component({
  selector: 'script-context-menu',
  templateUrl: './script-context-menu.component.html',
  styleUrls: ['./script-context-menu.component.scss'],
})
export class ScriptContextMenuComponent implements OnInit {

  @Input()
  script: Script;

  draftVersion: ScriptVersion;
  workspaces: Workspace[];

  private subscriptions: Subscription[] = [];

  constructor(
    private analytics: ScriptCardAnalyticsService,
    private router: Router,
    public workspaceService: WorkspaceService,
    private clipboard: ClipboardService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private modalController: ModalController,
    public  organizationService: OrganizationService,
    private alertController: AlertController,
    private loadingController: LoadingController
  ) { }

  async ngOnInit() {
    this.draftVersion = await this.script.draft$.pipe(take(1)).toPromise();

    this.subscriptions.push(
      this.workspaceService.workspaces$.subscribe(workspaces => this.workspaces = workspaces.sort((a, b) => {
        if (!a.ownerOrganizationRef) {
          return -1;
        }

        if (!b.ownerOrganizationRef) {
          return 1;
        }

        return (a.ownerOrganizationRef.path < b.ownerOrganizationRef.path) ? -1 : (a.ownerOrganizationRef.path > b.ownerOrganizationRef.path) ? 1 : 0;
      }))
    );
  }

  async ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openDetails(event: ContextMenuClickEvent) {
    this.analytics.createEditDetailsButtonClickedEvent(this.script, this.draftVersion);
    this.router.navigateByUrl('/design/' + this.script.id);
  }

  editDesign(event: ContextMenuClickEvent) {
    this.analytics.createEditDesignButtonClickedEvent(this.script, this.draftVersion);
    this.router.navigateByUrl('/design/' + this.script.id + '/task');
  }

  async openPlayModal() {
    const modal = await this.modalController.create({
      component: PlayModalComponent,
      componentProps: {
        script: this.script,
      },
      cssClass: 'play-modal auto-height'
    });
    return await modal.present();
  }

  async openOrganize(event: ContextMenuClickEvent) {
    try {
      this.analytics.createOrganizeButtonClickedEvent(this.script, this.draftVersion);

      const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
      if (!organization.isActive) {
        throw new Error('plan-status-invalid');
      }

      this.router.navigateByUrl('/organize/create/' + this.script.id);
    } catch (error) {
      if (error.message === 'plan-status-invalid') {
        event.item.hideMenu();
        await this.organizationService.showWarning(this.translateService.instant('script-details/action/edit/move'));
      }
    }
  }

  async copyTo(workspace: Workspace, event: ContextMenuClickEvent) {
    const loading = await this.presentProcessing(this.translateService.instant('process/copy'), false);
    try {
      const isScriptLimitValid = await workspace.isValidScriptLimit();
      if (!isScriptLimitValid) {
        throw new Error('script-limit-invalid');
      }
      const item = event.item;
      item.processing = true;

      const organization = await workspace.ownerOrganization$.pipe(first()).toPromise();

      await this.presentAlertConfirm(
        this.translateService.instant('script-details/action/edit/make-a-copy-confirm/title'),
        this.translateService.instant('script-details/action/edit/make-a-copy-confirm/message', {
          scriptTitle: this.draftVersion.title,
          workspaceTitle: workspace.title,
          organizationTitle: organization.isOrphan() ? '' : ' (' + organization.title +') '
        }),
        this.translateService.instant('script-details/action/edit/make-a-copy-confirm/ok-button/label')
      );
      
      await loading.present();
      await this.script.copyTo(workspace.ref);
      this.analytics.createDuplicateScriptButtonClickedEvent(this.script, this.draftVersion, workspace);
      item.processing = false;
      item.hideMenu();
      await this.workspaceService.setActiveWorkspace(workspace);
      await this.organizationService.setByWorkspace(workspace);
      this.router.navigateByUrl('/design');
    } catch (error) {
      if (error.message === 'script-limit-invalid') {
        event.item.hideMenu();
        const organization = await workspace.ownerOrganization$.pipe(first()).toPromise();
        await this.organizationService.showScriptLimitWarning(organization, this.translateService.instant('script-details/action/make-a-copy/title'));
      }
    } finally {
      loading.dismiss();
    }
  }

  async moveTo(workspace: Workspace, event: ContextMenuClickEvent) {
    const loading = await this.presentProcessing(this.translateService.instant('process/move'), false);
    try {
      const isScriptLimitValid = await workspace.isValidScriptLimit();
      if (!isScriptLimitValid) {
        throw new Error('script-limit-invalid');
      }

      const item = event.item;
      item.processing = true;

      const organization = await workspace.ownerOrganization$.pipe(first()).toPromise();

      await this.presentAlertConfirm(
        this.translateService.instant('script-details/action/edit/move-confirm/title'),
        this.translateService.instant('script-details/action/edit/move-confirm/message', {
          scriptTitle: this.draftVersion.title,
          workspaceTitle: workspace.title,
          organizationTitle: organization.isOrphan() ? '' : ' (' + organization.title +') '
        }),
        this.translateService.instant('script-details/action/edit/move-confirm/ok-button/label')
      );

      this.analytics.createMoveToButtonClickedEvent(this.script, this.draftVersion, workspace);
      if (!this.script.workspaceRef.isEqual(workspace.ref)) {
        loading.present();
        await this.script.moveTo(workspace.ref);
      }
      await this.workspaceService.setActiveWorkspace(workspace);
      await this.organizationService.setByWorkspace(workspace);
      item.processing = false;
      item.hideMenu();
    } catch (error) {
      if (error.message === 'script-limit-invalid') {
        event.item.hideMenu();
        const organization = await workspace.ownerOrganization$.pipe(first()).toPromise();
        await this.organizationService.showScriptLimitWarning(organization, this.translateService.instant('script-details/action/edit/move'));
      }
    } finally {
      event.item.processing = false;
      event.item.hideMenu();
      await loading.dismiss();
    }
  }

  async openPublish(event: ContextMenuClickEvent) {
    try {
      this.analytics.createPublishButtonClickedEvent(this.script, this.draftVersion);
      const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
      if (!organization.isActive) {
        throw new Error('plan-status-invalid');
      }

      const modal = await this.modalController.create({
        component: PublishModalComponent,
        componentProps: {
          script: this.script
        },
        cssClass: 'auto-height'
      });
      return await modal.present();
    } catch (error) {
      if (error.message === 'plan-status-invalid') {
        event.item.hideMenu();
        await this.organizationService.showWarning(this.translateService.instant('publish-settings/page-title'));
      }
    }
  }

  async copyInviteURL(event: ContextMenuClickEvent) {
    if (this.script.isPublished) {
      const url = 'https://' + environment.dynamicLinkDomainPlayer + '/?link=' +
        encodeURIComponent(environment.web.playUrl + '/playing/game/' + this.script.id) +
        '&apn=' + environment.android.playPackageName + '&isi=' + environment.iOS.playAppId + '&ibi=' + environment.iOS.playBundleId;

      this.clipboard.copyFromContent(url);

      const toast = await this.toastController.create({
        message: this.translateService.instant('script-list/menu/invite/url-copied'),
        duration: 2000
      });
      toast.present();

      return false;
    }
  }

  async delete(event: ContextMenuClickEvent) {
    this.analytics.createDeleteButtonClickedEvent(this.script, this.draftVersion);
    const modal = await this.modalController.create({
      component: ScriptDeleteConfirmationDialogComponent,
      componentProps: {},
      cssClass: [
        'auto-height',
        'sm-modal'
      ]
    });
    modal.onDidDismiss().then(async (dismissed) => {
      if (dismissed.data && dismissed.data.action) {
        if (dismissed.role === 'backdrop') { }
        switch (dismissed.data.action) {
          case 'cancel':
            break;

          case 'delete':
            await this.script.delete();
            this.router.navigateByUrl('/design');
            break;

          default:
            break;
        }
      }
    });
    await modal.present();
  }

  async presentAlertConfirm(title: string, message: string, cta: string) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'context-menu-confirm',
        backdropDismiss: false,
        header: title,
        message: message,
        buttons: [
          {
            text: this.translateService.instant('general/cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              reject('cancel');
            }
          }, {
            text: cta ? cta : this.translateService.instant('general/ok'),
            handler: () => {
              resolve('ok');
            }
          }
        ]
      });

      await alert.present();
    })

  }

  async presentProcessing(message: string, isPresent = true) {
    const loading = await this.loadingController.create({
      message: message,
    });

    if (isPresent) {
      await loading.present();
    }

    return loading;
  }

}
