import { Component, OnInit, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationAnalyticsService } from '../organization.analytics.service';
import { OrganizationService } from '../organization.service';

@Component({
  selector: 'organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.scss'],
})
export class OrganizationProfileComponent implements OnInit {

  @Input()
  organization: Organization;

  public organizationName = '';

  public color = {
    primary: '#20cfc6',
    warning: '#dd753b',
  }

  public logoIconVisible: boolean;
  private progress = 0;

  constructor(
    private organizationService: OrganizationService,
    private organizationAnalytics: OrganizationAnalyticsService
  ) { }

  ngOnInit() {
    console.log(this.organization);
    this.organizationName = this.organization.title;
    this.color = this.organization.color ? this.organization.color : this.color;
  }

  ngAfterViewInit(): void {
    this.organizationAnalytics.openPage('Manage / Profile');
  }

  async save(field: string, value: string) {
    switch (field) {
      case 'organizationName':
        this.organization.title = this.organizationName;
        await this.organization.save();
        const activeOrganization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
        activeOrganization.title = this.organization.title;
        break;
    
      default:
        break;
    }
  }

  setUploadProgress(event): void {
    this.progress = event;
    if (this.progress === 0) {
      // this.analytics.createLogoFileSelectedClickedEvent();
    }
    this.toggleIconVisibilityOnUpload();
  }

  private toggleIconVisibilityOnUpload() {
    if (this.isUploading() && this.logoIconVisible) {
      this.setLogoUploadIconVisibility(false);
    }
  }

  initImageUpload($event: any) {
    this.organization._image.setSizedUrl(URL.createObjectURL($event.target.files[0]));
  }

  setLogoUploadIconVisibility(visible: boolean) {
    if (this.isUploading() && visible === true) {
      return;
    }
    this.logoIconVisible = visible;
  }

  async setLogo(url: string) {
    // this.organization.photoURL = url;
    this.organization.imageUrl = url;
    await this.organization.save();
    const activeOrganization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
    activeOrganization.photoURL = this.organization.photoURL;
    // this.analytics.createLogoUploadedEvent();
  }

  isUploading(): boolean {
    return this.progress > 0 && this.progress < 100;
  }
  
  getLogo(): string {
    return this.organization && this.organization.imageUrl ? this.organization.imageUrl : '/assets/images/img-placeholder.png';
  }

  async onColorChange($event) {
    // this.color;/
    this.organization.color = this.color;
    await this.organization.save();
    // console.log($event, this.color, name);
  }

}
