import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PlayEditPageComponent } from './play-edit-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { PlayComponentsModule } from '../play-components.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ModelsModule } from 'src/app/core/models/models.module';
import { AuthGuard } from '../../auth/auth.guard';
import { PrivilegeGuard } from '../../core/privilege/privilege.guard';
import { PRIVILEGES } from '../../core/privilege/privilege-roles';
import { NgxPopperModule } from 'ngx-popper';
import { TeamBoxModule } from './team-box/team-box.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule, MaskPipe, MaskApplierService } from 'ngx-mask'
import { PlayEditAnalyticsService } from './play-edit-analytics.service';

const routes: Routes = [
  {
    path: '',
    component: PlayEditPageComponent,
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.PLAY_EDIT},
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    ModelsModule,
    AppComponentsModule,
    PlayComponentsModule,
    PipesModule,
    FilterPipeModule,
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPopperModule,
    TeamBoxModule,
    MatIconModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [PlayEditPageComponent],
  providers: [
    PlayEditAnalyticsService
    // { provide: MaskPipe, useClass: MaskPipe }
  ]
})
export class PlayEditPageModule {}
