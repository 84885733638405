import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { ClipboardService } from 'ngx-clipboard';
import { ToastController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { Organization } from 'src/app/core/models/organization.model';
import { Plan } from 'src/app/core/models/plan';
import { DocumentReference } from '@angular/fire/firestore';

@Component({
  selector: 'app-superadmin-organization-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class SuperadminOrganizationEditComponent implements OnInit {

  public processing = false;

  public organization: Organization;

  public plans: Plan[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelProvider: ModelProvider,
    // private clipboard: ClipboardService,
    // private toastController: ToastController,
  ) { }

  async ngOnInit() {
    const organizationID = this.route.snapshot.paramMap.get('id');

    if (organizationID) {
      this.organization = await this.modelProvider.organization.findByID(organizationID).pipe(first()).toPromise();
    } else {
      this.organization = this.modelProvider.organization.create('organizations', {});
    }

    this.plans = await this.modelProvider.plan.findAllBy(ref => ref).pipe(first()).toPromise();
  }

  async save() {
    this.processing = true;
    console.log(this.organization);
    await this.organization.save();
    this.processing = false;
    await this.router.navigate(['superadmin', 'organization'])
  }

  byReference(item1: DocumentReference, item2: DocumentReference) {
    if (!item1 || !item2) {
      return false;
    }

    return item1.isEqual(item2);
  }


}
