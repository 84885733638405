import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OrganizationService } from 'src/app/organization/organization.service';

@Component({
  selector: 'app-team-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
})
export class PersonalComponent implements OnInit {

  public processing = false;

  constructor(
    private workspaceService: WorkspaceService,
    private organizationService: OrganizationService,
    private router: Router,
  ) { }

  ngOnInit() { }

  async createNew() {
    // this.processing = true;
    // const workspace = await this.workspaceService.addNew();
    // this.workspaceService.setActiveWorkspace(workspace);
    // this.processing = false;

    const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
    const isValidWorkspaceLimit = await organization.isValidWorkspaceLimit();
    if (!isValidWorkspaceLimit) {
      await this.organizationService.showWorkspaceLimitWarning();
      return;
    }

    if (this.processing) { return; }
    this.processing = true;
    const workspace = await this.workspaceService.addNew(organization);
    if (workspace) {
      const workspaces = await this.workspaceService.workspaces$.pipe(first()).toPromise();
      const activeWorkspace = workspaces.find((_workspace => _workspace.ref.isEqual(workspace.ref)));

      this.workspaceService.setActiveWorkspace(activeWorkspace);
      this.router.navigate(['/teams/settings']);
    }

    this.processing = false;

  }

}
