import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkspaceService } from '../../core/workspace/workspace.service';
import { Subscription } from 'rxjs';
import { Workspace, WorkspaceLevel } from '../../core/models/workspace.model';
import { ManageTeamAnalyticsService } from '../manage-teams/manage-team-analytics-service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModelProvider } from '../../core/models/general/model.provider';
import { WorkspaceMember } from '../../core/models/workspace-member';
import { TeamMemberReactivateConfirmationDialogComponent } from '../member-reactivate-confirmation-dialog/team-member-reactivate-confirmation-dialog.component';
import { InviteNewMemberDialogComponent } from '../invite-new-member-dialog/invite-new-member-dialog.component';
import { PRIVILEGES } from '../../core/privilege/privilege-roles';
import { PrivilegeService } from '../../core/privilege/privilege.service';
import { TeamMemberDeleteConfirmationDialogComponent } from '../member-delete-confirmation-dialog/team-member-delete-confirmation-dialog.component';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import { OrganizationService } from 'src/app/organization/organization.service';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members-page.component.html',
  styleUrls: ['./team-members-page.component.scss']
})
export class TeamMembersPageComponent implements OnInit, OnDestroy {

  levels: Array<WorkspaceLevel>;
  page: 'members' | 'players';

  workspace: Workspace;
  members: WorkspaceMember[] = [];
  processingMembers: WorkspaceMember[] = [];
  workspaceEditPrivilege = PRIVILEGES.WORKSPACE_EDIT;

  private workspaceActivatedSubscription: Subscription;
  private workspaceSubscription: Subscription;
  private workspaceMembersSubscription: Subscription;

  constructor(
    public workspaceService: WorkspaceService,
    public organizationService: OrganizationService,
    public analytics: ManageTeamAnalyticsService,
    private modelProvider: ModelProvider,
    private translateService: TranslateService,
    private modalController: ModalController,
    public privilegeService: PrivilegeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

  }

  async changeLevel(member: WorkspaceMember, level: WorkspaceLevel) {
    this.processingMembers.push(member);
    try {
      await member.changeLevel(level);
    } catch (error) {
      console.error(error);
    } finally {
      this.processingMembers = this.processingMembers.filter(el => el !== member);
      this.analytics.createChangeRoleLevelEvent(level);
    }
  }

  async ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    this.levels = snapshot.data.levels;
    this.page = snapshot.data.page;

    this.workspaceActivatedSubscription = this.workspaceService.activatedWorkspace.subscribe((workspace) => {
      if (this.workspaceSubscription) {
        this.workspaceSubscription.unsubscribe();
      }

      this.workspaceSubscription = this.modelProvider.workspace.findByRef(workspace.ref)
        .subscribe(async workspace => {
          this.workspace = workspace;

          await this.checkPrivilege(workspace);

          if (this.workspaceMembersSubscription) {
            this.workspaceMembersSubscription.unsubscribe();
          }

          this.workspaceMembersSubscription = this.workspace.members$.subscribe(members => {
            this.members = members.filter(
              member => this.levels.includes(member.level)
            ).sort((a, b) => {
              if (a.createdAt && !b.createdAt) {
                return 1;
              } else if (!a.createdAt && b.createdAt) {
                return -1;
              } else if (a.createdAt && b.createdAt) {
                return a.createdAt.toMillis() > b.createdAt.toMillis() ? -1 : 1;
              }
      
              return 0;
            })
          });

          // this.members = Object.values(workspace.members.items).filter(
          //   member => this.levels.includes(member.level)
          // )
          // .sort((a, b) => {
          //   if (!a.joinedAt) {
          //     return -1;
          //   }

          //   if (!b.joinedAt) {
          //     return 1;
          //   }

          //   return (a.joinedAt < b.joinedAt) ? -1 : 1;
          // });
        });
    });
  }

  async checkPrivilege(workspace: Workspace) {
    const myMember = await this.workspace.getMyMember();
    switch (myMember.level) {
      case 'owner':
        break;
      case 'admin':
        break;
      case 'editor':
        await this.router.navigateByUrl('/teams/settings');
        break;
      case 'player':
        await this.router.navigateByUrl('/play-landing');
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.workspaceActivatedSubscription.unsubscribe();
    if (this.workspaceSubscription) {
      this.workspaceSubscription.unsubscribe();
    }
    if(this.workspaceMembersSubscription) {
      this.workspaceMembersSubscription.unsubscribe();
    }
  }

  async openInviteNewMemberDialog() {

    // const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
    if (this.page === 'members') {
      await this.workspaceService.refresh();
      const workspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
      // console.log(workspace);
      const isValidDesignerLimit = await workspace.isValidDesignerLimit();
      if (!isValidDesignerLimit) {
        await this.organizationService.showDesignerLimitWarning();
        return;
      }
    }

    return new Promise<boolean>(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: InviteNewMemberDialogComponent,
        componentProps: {
          workspace: this.workspace,
          levels: this.levels
        },
        cssClass: [
          'auto-height',
          'sm-modal'
        ]
      });
      modal.onDidDismiss().then((dismissed) => {
        if (dismissed.data && dismissed.data.action) {
          if (dismissed.role === 'backdrop') { }
          switch (dismissed.data.action) {
            case 'cancel':
              break;

            case 'submit':
              // TODO: add email sender functionality
              console.log(`Invite mail sent to '${dismissed.data.mail}' as a '${dismissed.data.level}.'`);
              resolve(true);
              break;

            default:
              break;
          }
        }
      });
      await modal.present();
    });
  }

  showRemoveFromTeamConfirmationDialog(member: WorkspaceMember) {
    return new Promise<boolean>(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: TeamMemberDeleteConfirmationDialogComponent,
        componentProps: {
          member: member,
        },
        cssClass: [
          'auto-height',
          'sm-modal'
        ]
      });
      modal.onDidDismiss().then(async (dismissed) => {
        if (dismissed.data && dismissed.data.action) {
          if (dismissed.role === 'backdrop') { }
          switch (dismissed.data.action) {
            case 'cancel':
              break;

            case 'delete':
              // this.workspace.getMemberByUser()
              // await member.deactivateUserAsMember();
              // for (const memberId in this.workspace.members.items) {
              //   if (memberId === userId) {
              //     const currentMember = this.workspace.members.items[memberId];
              //     currentMember.deactivateUserAsMember();
              //     this.workspace.save();
              //   }
              // }
              resolve(true);
              break;

            default:
              break;
          }
        }
      });
      await modal.present();
    });
  }

  async showAddUserToTeamConfirmationDialog(member: WorkspaceMember) {

    if (this.page === 'members') {
      await this.workspaceService.refresh();
      const workspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
      const isValidDesignerLimit = await workspace.isValidDesignerLimit();
      if (!isValidDesignerLimit) {
        await this.organizationService.showDesignerLimitWarning();
        return;
      }
    }

    return new Promise<boolean>(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: TeamMemberReactivateConfirmationDialogComponent,
        componentProps: {
          member: member,
        },
        cssClass: [
          'auto-height',
          'sm-modal'
        ]
      });
      modal.onDidDismiss().then(async (dismissed) => {
        if (dismissed.data && dismissed.data.action) {
          if (dismissed.role === 'backdrop') { }
          switch (dismissed.data.action) {
            case 'cancel':
              break;

            case 'reactivate':
              // await member.reactivateUserAsMember();
              // for (const memberId in this.workspace.members.items) {
              //   if (memberId === userId) {
              //     const currentMember = this.workspace.members.items[memberId];
              //     currentMember.reactivateUserAsMember();
              //     this.workspace.save();
              //   }
              // }
              resolve(true);
              break;

            default:
              break;
          }
        }
      });
      await modal.present();
    });
  }
}
