import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from '../core/models/organization.model';
import { ModelProvider } from '../core/models/general/model.provider';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { first } from 'rxjs/operators';
import { OrganizationMember } from '../core/models/organization-members.model';

type organizationTabNames = 'profile' | 'package' | 'members' | 'players' | 'billing' | 'integrations';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.page.html',
  styleUrls: ['./organization.page.scss'],
})
export class OrganizationPage implements OnInit {

  activeTab: organizationTabNames;

  organization: Organization;
  organization$: Observable<Organization>;

  member: OrganizationMember;

  private subscriptions: Subscription[] = [];

  public isSuperadmin = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelProvider: ModelProvider,
    private authService: AuthService
  ) { }

  async ngOnInit() {

    if (this.route.snapshot.paramMap.has('organizationID')) {
      const organizationID = this.route.snapshot.paramMap.get('organizationID');
      this.organization$ = this.modelProvider.organization.findByRef('organizations/' + organizationID);
      const orgSub = this.organization$.subscribe(organization => this.organization = organization);
      console.log(organizationID);
      this.subscriptions.push(orgSub);

      this.isSuperadmin = await this.authService.isSuperAdmin()

      await this.organization$.pipe(first()).toPromise();
      this.member = await this.organization.myMember$.pipe(first()).toPromise();

      if (!this.member && !this.isSuperadmin) {
        await this.router.navigateByUrl('/design');
        return;
      }

      if (this.member && this.member.level === 'billing') {
        this.activeTab = 'billing';
      } else {
        this.activeTab = 'profile'
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setActiveTab(tabName: organizationTabNames) {
    this.activeTab = tabName;
  }

}
