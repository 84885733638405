<ul *ngIf="workspaces" class="p-0">
  <li *ngFor="let workspace of workspaces">
    <div [style.background-image]="getWorkspaceLogo(workspace)" class="workspace-logo mr-3 float-left"></div>
    <div class="float-left">
      <p class="my-0 title">{{workspace.title}}</p>
      <ng-container *ngIf="(workspace.scripts$ | async); let scripts">
        <ng-container *ngIf="plan?.features?.scriptPerWorkspaceLimit; let scriptLimit">
          <p class="my-0 scriptLimit" [class.invalid]="scripts.length >= scriptLimit && scriptLimit >= 0">

            <!-- {{(workspace.scripts$ | async).length}} /
            <ng-container *ngIf="scriptLimit === -1">∞</ng-container>
            <ng-container *ngIf="scriptLimit >= 0">{{scriptLimit}}</ng-container> -->

            {{'organization/plan/scriptLimit' | translate: {scriptNumber: scripts.length, scriptLimit: (scriptLimit <= -1)?'∞':scriptLimit } }}
          </p>
        </ng-container>
      </ng-container>
    </div>
    <div class="clearfix"></div>
  </li>
</ul>