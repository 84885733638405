import { Component, ViewChild, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CameraDesktopAnalyticsService } from './camera-desktop.analytics';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Generated class for the CameraDesktopComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'camera-desktop',
  templateUrl: 'camera-desktop.html',
  styleUrls: ['./camera-desktop.scss'],
  providers: [
    CameraDesktopAnalyticsService,
    Ng2ImgMaxService
  ]
})
export class CameraDesktopComponent implements OnDestroy {
  @ViewChild('captureVideo') captureVideo;
  @ViewChild('captureCanvas') captureCanvas;

  // tslint:disable-next-line: no-output-rename
  @Output('file') file: EventEmitter<File | Blob> = new EventEmitter<File | Blob>();

  imageToPreview: SafeUrl;

  public status: string = 'inactive';
  private stream: MediaStream;

  constructor(
    private cdr: ChangeDetectorRef,
    public analytics: CameraDesktopAnalyticsService,
    private ng2ImgMax: Ng2ImgMaxService,
    public sanitizer: DomSanitizer
  ) {
    console.log('Hello CameraDesktopComponent Component');
    // this.text = 'Hello World';
  }

  ngOnDestroy(): void {
    this.stopCamera();
  }

  public captureDesktopPhoto() {
    this.analytics.onCaptureClick()
    let self = this;

    // https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Taking_still_photos
    let n = <any>navigator;

    n.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(function (stream) {
        self.captureVideo.nativeElement.srcObject = stream;
        self.captureVideo.nativeElement.play();

        self.stream = stream;

        let video = self.captureVideo.nativeElement;

        self.status = 'active';
      })
      .catch(function (err) {
        console.log("An error occurred! " + err);
      });
  }

  public cancel() {
    this.analytics.onRetakeClick();
    this.status = 'active';
    this.file.emit(null);
  }

  public backToStart() {
    this.stopCamera();
    this.status = 'inactive';
    this.file.emit(null);
    this.imageToPreview = null;
  }

  public async fileSelected($event) {
    const file: File = $event.target.files[0];
    const resizedFile = await this.resizeFile(file);
    this.imageToPreview = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(resizedFile));
    this.file.emit(resizedFile);
    // console.log($event);
  }

  private async resizeFile(file: File) {
    return await this.ng2ImgMax.resizeImage(file, 1600, 1600).pipe(first()).toPromise() as Blob;
  }

  private b64toBlob(b64, onsuccess, onerror) {
    var img = new Image();

    img.onerror = onerror;

    img.onload = function onload() {
      var canvas: any = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(onsuccess);
    };

    img.src = b64;
  }

  public stopCamera() {
    if (this.stream) {
      if (this.captureVideo && this.captureVideo.nativeElement) {
        this.captureVideo.nativeElement.pause();
        this.captureVideo.nativeElement.currentTime = 0;
      }
      var videoTracks = this.stream.getVideoTracks();
      videoTracks.forEach(function (track) {
        track.stop();
      })

      var audioTracks = this.stream.getAudioTracks();
      audioTracks.forEach(function (track) {
        track.stop();
      })
    }
  }



  async desktopTakePicture() {
    let self = this;
    var context = this.captureCanvas.nativeElement.getContext('2d');
    // console.log(this.captureCanvas, this.captureVideo.nativeElement.clientWidth, this.captureVideo.nativeElement.clientHeight);
    this.captureCanvas.nativeElement.width = this.captureVideo.nativeElement.videoWidth;
    this.captureCanvas.nativeElement.height = this.captureVideo.nativeElement.videoHeight;
    // this.captureCanvas.nativeElement.style.height = this.captureVideo.nativeElement.clientHeight + 'px';
    // context.drawImage(this.captureVideo.nativeElement, 0, 0, this.captureVideo.nativeElement.clientWidth, this.captureVideo.nativeElement.clientHeight);
    context.drawImage(this.captureVideo.nativeElement, 0, 0, this.captureCanvas.nativeElement.width, this.captureCanvas.nativeElement.height);
    // var data = this.captureCanvas.nativeElement.toDataURL('image/png');

    const blob = await this.canvasToBlob();
    const file = new File([blob], 'capture.jpg', { type: 'image/jpeg', lastModified: Date.now() });
    self.file.emit(file);
    self.status = 'success';

    // this.captureCanvas.nativeElement.toBlob( (blob) => {
    //   let file = new File([blob], 'capture.jpg', { type: 'image/jpeg', lastModified: Date.now() });
    //   self.file.emit(file);
    //   self.status = 'success';

    //   // this.cdr.detach();
    //   // this.cdr.detectChanges();
    //   // this.cdr.markForCheck();
    //   // this.cdr.reattach();

    // }, 'image/jpeg', 0.95);
  }

  async canvasToBlob() {
    return new Promise<Blob>((resolve, reject) => {
      this.captureCanvas.nativeElement.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.95);
    });
  }

}
