import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Workspace } from 'src/app/core/models/workspace.model';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { Script } from 'src/app/core/models/script.model';
import { ScriptContextMenuComponent } from 'src/app/script/script-context-menu/script-context-menu.component'
import { first, map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OrganizationService } from 'src/app/organization/organization.service';
import { UseTemplateAnalyticsService } from './use-template.analytics.service';


@Component({
  selector: 'app-use-template-modal',
  templateUrl: './use-template-modal.component.html',
  styleUrls: ['./use-template-modal.component.scss'],
})
export class UseTemplateModalComponent implements OnInit {

  @Input()
  script: Script;

  // @Input()
  @ViewChild('scriptContextMenu') contextMenu: ScriptContextMenuComponent;

  selectedWorkspace: Workspace;
  workspaces: Workspace[];

  processing = null;

  constructor(
    private modalCtrl: ModalController,
    private translateService: TranslateService,
    private workspaceService: WorkspaceService,
    private router: Router,
    public organizationService: OrganizationService,
    public analytics: UseTemplateAnalyticsService
  ) {}

  async ngOnInit() {
    // this.workspaces = this.workspaceService.workspaces;
    const activeOrganization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
    // console.log(organization);
    // this.workspaces =  await organization.workspaces$.pipe(first()).toPromise();

    this.workspaces = await this.workspaceService.workspaces$
    .pipe(map(workspaces => workspaces.map(workspace => workspace))) // Clone array
    .pipe(mergeMap(async workspaces => {
      return await this.workspaceService.modelProvider.asyncFilter(workspaces, async workspace => 
        (await workspace.getMyMember()).level !== 'player'
      );
    }))
    .pipe(
      map(workspaces => workspaces.filter(workspace => {
        // if(workspace.isPersonal) {
        //   return true;
        // }

        if (workspace.ownerOrganizationRef) {
          return workspace.ownerOrganizationRef.isEqual(activeOrganization.ref);
        } else {
          return activeOrganization.isOrphan();
        }
      })),
      map(workspaces => workspaces.sort((a, b) => {
        if (a.isPersonal && !b.isPersonal) {
          return -1;
        } else if (!a.isPersonal && b.isPersonal) {
          return 1;
        }
        //  else {
        //   return 0;
        // }

        if (a.myMember.joinedAt && !b.myMember.joinedAt) {
          return -1;
        } else if (!a.myMember.joinedAt && b.myMember.joinedAt) {
          return 1;
        } else if (a.myMember.joinedAt && b.myMember.joinedAt) {
          return a.myMember.joinedAt.toMillis() > b.myMember.joinedAt.toMillis() ? 1 : -1;
        }

        return 0;
      }))
    ).pipe(first()).toPromise();

    // this.workspaces = organization.workspaces;

    this.selectedWorkspace = this.workspaces[0];

    // this.organizationService.activeOrganization$.subscribe(org => org.workspaces$)

    // await this.modelProvider.script.findByRef(this.script.ref).pipe(first()).toPromise();
    const draft = await this.script.draft$.pipe(first()).toPromise();
    this.analytics.setScreen(this.script.id, draft.title);
  }


  // public async onSave() {
  //   this.processing = true;

  //   await this.contextMenu.copyTo(this.selectedWorkspace);
  //   this.processing = false;
  // }
  public async onWorkspaceChange(workspace: Workspace) {
    this.analytics.onWorkspaceChange(workspace).then().catch();
  }

  public async onSave() {
    this.processing = true;
    const workspace = this.selectedWorkspace;

    this.analytics.onSubmit(
      this.translateService.instant('use-template/submit-button/label'),
      workspace
    ).then().catch();
    // const loading = await this.presentProcessing(this.translateService.instant('process/copy'), false);
    try {
      const isScriptLimitValid = await workspace.isValidScriptLimit();
      if (!isScriptLimitValid) {
        throw new Error('script-limit-invalid');
      }

      // const organization = await workspace.ownerOrganization$.pipe(first()).toPromise();
      
      await this.script.copyTo(workspace.ref);
      // this.analytics.createDuplicateScriptButtonClickedEvent(this.script, this.draftVersion, workspace);

      await this.workspaceService.setActiveWorkspace(workspace);
      // await this.organizationService.setByWorkspace(workspace);
      this.router.navigateByUrl('/design');
      await this.modalCtrl.dismiss();
    } catch (error) {
      if (error.message === 'script-limit-invalid') {
        const organization = await workspace.ownerOrganization$.pipe(first()).toPromise();
        await this.organizationService.showScriptLimitWarning(organization, this.translateService.instant('script-details/action/make-a-copy/title'));
      }
    } finally {
      this.processing = false;
    }
  }

  public onCancel() {
    // this.analytics.createCancelButtonClickedEvent();
    // this.script.resetToStored();
    this.modalCtrl.dismiss({ action: 'canceled' });
  }

  public ngOnDestroy() {
    this.analytics.onClose().then().catch();
  }

}
