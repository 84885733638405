import { Injectable } from '@angular/core';
import { TaskViewComponentAnalyticsService } from '../task-view.analytics';

@Injectable()
export class VideoRecordDesktopAnalyticsService extends TaskViewComponentAnalyticsService {
  private name = 'Video record';

  onRecordClick() {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'record clicked']);
  }

  onStopClick() {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'stop clicked']);
  }

  onRetakeClick() {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'retake clicked']);
  }

  onShowAlternateOptions() {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'alternate', 'show options click']);
  }

  onClickAlternateOption(label: string, option: string) {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'alternate', 'option clicked'], label, { option: option });
  }

  onBackToVideo(label: string) {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'alternate', 'back to video clicked'], label);
  }

}
