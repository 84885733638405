<div class="personal">
  <h1 class="page-title-with-submenu">
    {{ 'manage-teams/personal/title' | translate }}
  </h1>
  <div class="container pt-0 pb-3">
    <p class="pm-5" [innerHTML]="'manage-teams/personal/info' | translate"></p>
    <ion-button (click)="createNew()" class="mt-5" [disabled]="processing" color="primary" shape="round">
      <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
      {{'manage-teams/personal/button/label' | translate}}
    </ion-button>
  </div>
</div>