<ng-container *ngIf="taskItem?.mediaType === 'youtube'">
  <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" [src]="sanitizeResourceUrl('https://www.youtube.com/embed/' + taskItem.getYoutubeID() + '?autoplay=1&rel=0')" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-container>
<ng-container *ngIf="taskItem?.mediaType === 'mp4' || response">
  <ion-skeleton-text *ngIf="!loaded"></ion-skeleton-text>
  <video #video controls playsinline preload="metadata" (loadstart)="onFirstFrameLoaded($event)" [class.loaded]="loaded" disablePictureInPicture controlsList="nodownload noremoteplayback">
    <source *ngIf="sanitizedURL" [src]="sanitizedURL" type="video/mp4">
  </video>
  <p *ngIf="isTranscoding" class="processing text-center">Please wait.<br />Video is being processed...</p>
</ng-container>