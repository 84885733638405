import { Component, OnInit } from '@angular/core';
import { Play } from 'src/app/core/models/play.model';
import { User } from 'src/app/core/models/user.model';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Script } from 'src/app/core/models/script.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {

  plays: Play[];
  users: User[];
  scripts: Script[];

  private subscriptions: Subscription[] = [];

  constructor(
    // private modelProvider: ModelProvider
  ) { }

  ngOnInit() {
    // const queryFn = ref => ref.limit(10)

    // const playSub = this.modelProvider.play.findAllBy(queryFn).subscribe(plays => {
    //   this.plays = plays;
    // });
    // const userSub = this.modelProvider.user.findAllBy(queryFn).subscribe(users => {
    //   this.users = users;
    // });
    // const scriptSub = this.modelProvider.script.findAllBy(queryFn).subscribe(scripts => {
    //   this.scripts = scripts;
    // });

    // this.subscriptions.push(playSub);
    // this.subscriptions.push(userSub);
    // this.subscriptions.push(scriptSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
