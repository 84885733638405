import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FirebaseFileUploaderEntity } from '../firebase/firebase-file-uploader-entity';
import { finalize, first, tap } from 'rxjs/operators';
import { EventManager } from '../event-manager/event-manager.service';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[fileInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FireStoreUploadDirective,
      multi: true
    }
  ]
})
export class FireStoreUploadDirective implements ControlValueAccessor {
  @Input() fileInput: FirebaseFileUploaderEntity;
  @Input() fileMetadata: any;
  @Input() uploadOn: string = 'change';
  @Input() fileExtras: any;
  @Input() category;
  @Output() progress: EventEmitter<number> = new EventEmitter();
  @Output() uploaded: EventEmitter<any> = new EventEmitter();

  file: File;

  onChange = _ => {
  };
  onTouched = () => {
  };

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private eventManager: EventManager,
    private sanitizer: DomSanitizer
  ) {
    // this.uploadOn = 'change';
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    this.file = event && event.item(0);
    if (this.uploadOn === 'change') {
      this.upload();
    } else {
      this.uploaded.emit({
        url: URL.createObjectURL(this.file),//this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file)),
        file: this.file,
        ref: null
      });
    }
  }

  async upload() {
    const upload = this.fileInput.uploadFile(this.category, this.file, this.fileMetadata, this.fileExtras);
    // const that = this;
    this.eventManager.broadcast({ name: 'UploadInProgressEvent' });
    upload.uploadTask.percentageChanges().subscribe(percent => { /*console.log('percent: ',percent);*/ this.progress.next(percent)} );
    //actually upload starts 
    // this triggers backend function
    const finished = await upload.uploadTask;

    const url = await finished.ref.getDownloadURL();

    // url and finished.ref.fullPath is not seeting up in firebase document
    return finished;
    // this.uploaded.emit({
    //   url: url,
    //   file: this.file,
    //   ref: finished.ref.fullPath//upload.uploadTask.task.snapshot.ref.fullPath
    // });
    // this.eventManager.broadcast({ name: 'UploadFinishedEvent' });
    // if (this.onChange) {
    //   this.onChange(this.file);
    // }

    // upload.uploadTask.snapshotChanges().pipe(finalize(() => {
    //   const downloadUrl = upload.ref.getDownloadURL();
    //   downloadUrl.pipe(first()).subscribe(url => {
    //     that.uploaded.emit({
    //       url: url,
    //       file: this.file,
    //       ref: upload.uploadTask.task.snapshot.ref.fullPath
    //     });
    //     this.eventManager.broadcast({ name: 'UploadFinishedEvent' });
    //     if (this.onChange) {
    //       this.onChange(this.file);
    //     }
    //   }, (error) => {
    //     console.error(error);
    //   });
    // }), tap((snapshot: any) => {
    //   that.progress.emit((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    // })).subscribe();
  }


  // upload() {
  //   const upload = this.fileInput.uploadFile(this.category, this.file, this.fileMetadata, this.fileExtras);
  //   const that = this;
  //   this.eventManager.broadcast({ name: 'UploadInProgressEvent' });

  //   upload.uploadTask.snapshotChanges().pipe(finalize(() => {
  //     const downloadUrl = upload.ref.getDownloadURL();
  //     downloadUrl.pipe(first()).subscribe(url => {
  //       that.uploaded.emit({
  //         url: url,
  //         file: this.file,
  //         ref: upload.uploadTask.task.snapshot.ref.fullPath
  //       });
  //       this.eventManager.broadcast({ name: 'UploadFinishedEvent' });
  //       if (this.onChange) {
  //         this.onChange(this.file);
  //       }
  //     }, (error) => {
  //       console.error(error);
  //     });
  //   }), tap((snapshot: any) => {
  //     that.progress.emit((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  //   })).subscribe();
  // }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  isSuccess(snapshot) {
    return snapshot.bytesTransferred === snapshot.totalBytes;
  }

  writeValue(value: null) {
    this.host.nativeElement.value = '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
