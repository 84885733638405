<!-- Generated template for the CameraDesktopComponent component -->
<!-- <div> -->
  <div class="cameraContainer {{status}}" >
    <div *ngIf="!imageToPreview" class="start-container visible-inactive text-center">
      <ion-button class="startCamera" color="secondary" size="small" (click)="captureDesktopPhoto()" shape="round" center>
        <mat-icon slot="start">camera</mat-icon>
        {{'media-capture/photo/capture-button/label' | translate}}
      </ion-button>
      <p>{{'general/or' | translate}}</p>
      <ion-button color="secondary" size="small" shape="round" class="start-camera" (click)="fileInput.click()">
        <input #fileInput type="file" accept="image/jpeg,image/x-png" (change)="fileSelected($event)"/>
        <ion-icon name="images" slot="start"></ion-icon>
        {{'media-capture/photo/upload-button/label' | translate}}
      </ion-button>
    </div>

    <img *ngIf="imageToPreview" [src]="imageToPreview" class="image-preview">
    <ion-button *ngIf="imageToPreview" class="d-block top-right" fill="clear" (click)="backToStart()">
      <ion-icon name="close" slot="icon-only"></ion-icon>
    </ion-button>

    <button class="shoot visible-active bottom" (click)="desktopTakePicture()"></button>
    <ion-button class="visible-success bottom" color="secondary" size="small" shape="round" (click)="cancel()">
      <mat-icon slot="start">camera</mat-icon> Retake photo
    </ion-button>
    <ion-button class="visible-active top-right" fill="clear" (click)="backToStart()">
      <ion-icon name="close" slot="icon-only"></ion-icon>
    </ion-button>
    <video class="visible-inactive visible-active" #captureVideo></video>
    <canvas class="visible-success" #captureCanvas>

    </canvas>
  </div>
  <!-- {{text}} -->
<!-- </div> -->
