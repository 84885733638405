import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { TaskViewComponentAnalyticsService } from '../task-view.analytics';

@Injectable()
export class CameraDesktopAnalyticsService extends TaskViewComponentAnalyticsService{
  private name = 'Capture image';

  onCaptureClick() {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'capture clicked']);
  }

  onRetakeClick() {
    if (this.context !== 'task-view') { return; }
    this.analyticsService.event([this.name, 'retake clicked']);
  }

}
