import { LayoutItem } from './task-layout-item';
import { DocumentReference } from '@angular/fire/firestore';
import { ReplaySubject } from 'rxjs';
import { Task } from './task.model'

export class QuoteLayoutItem extends LayoutItem {
    public type: string = LayoutItem.TYPE_QUOTE;
    public order = 0;

    public properties: {
        taskRef: DocumentReference
    };

    private _taskRef$: ReplaySubject<DocumentReference>;
    public setFields(data: any) {
        super.setFields(data);
        this._taskRef$ = new ReplaySubject(1);
        if (this.properties && this.properties.taskRef) {
            this._taskRef$.next(this.properties.taskRef);
        }
    }

    // tslint:disable-next-line: member-ordering
    private _task$: ReplaySubject<Task>;
    public get task$(): ReplaySubject<Task> {
        if (this._task$ !== undefined) { return this._task$; }

        this._task$ = new ReplaySubject<Task>(1);
        this._taskRef$.subscribe(taskRef => {
            if (taskRef) {
                this.modelProvider.task.findByRef(taskRef).subscribe(this._task$);
            } else {
                this._task$.next(null);
            }
        });
        return this.task$;
    }

    // tslint:disable-next-line: member-ordering
    private _task: Task;
    public get task(): Task {
        if (this._task !== undefined) { return this._task; }
        this._task = null;

        this.task$.subscribe(task => {
            this._task = task;
        });
        return this._task;
    }

    public set task(task: Task) {
        this.properties.taskRef = (task instanceof Task) ? task.getDocument().ref : null;
        this._taskRef$.next(this.properties.taskRef);
    }

    // // tslint:disable-next-line: member-ordering
    // private _defaultResponse$: ReplaySubject<Response>;
    // public get defaultResponse$(): ReplaySubject<Response> {
    //     if (this._defaultResponse$ !== undefined) { return this._defaultResponse$; }

    //     this._defaultResponse$ = new ReplaySubject<Response>(1);
    //     this.task$.pipe(map((task) => {
    //         const response = new Response({});
    //         if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_TEXT)) {
    //             response.text = this.modelProvider.translate.instant('script-details/quote/default/text');
    //         }
    //         if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO) || task.layout.getItemByType(LayoutItem.TYPE_INPUT_VIDEO)) {
    //             response.photo = {
    //                 original: {
    //                     ref: '',
    //                     // tslint:disable-next-line: max-line-length
    //                     url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGO6BwAA5ADhV3yGqAAAAABJRU5ErkJggg==',
    //                     mimeType: 'image/png'
    //                 }
    //             };
    //         }
    //         if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_CHOICE)) {
    //             const item = (task.layout.choice);
    //             response.choice = Object.values(item.choices)[0].id;
    //         }
    //         return response;
    //     })).subscribe(this._defaultResponse$);

    //     return this._defaultResponse$;
    // }
}
