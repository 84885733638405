import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { environment } from 'src/environments/environment';
import { AppUpdateAnalyticsService } from './app-update.analytics';
import { AppUpdateService } from './app-update.service';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  styleUrls: ['./app-update.component.scss'],
})
export class AppUpdateComponent implements OnInit {

  constructor(
    private platform: Platform,
    private market: Market,
    public analytics: AppUpdateAnalyticsService,
    public updateService: AppUpdateService,
  ) { }

  public goToUpdate() {
    this.analytics.onUpdateButtonClicked();

    if (this.platform.is('ios')) {
      this.market.open(environment.iOS.bundleId);
    }

    if (this.platform.is('android')) {
      this.market.open(environment.android.packageName);
    }

    if (!this.platform.is('cordova')) {
      // tslint:disable-next-line: deprecation
      location.reload(true);
    }
  }

  ngOnInit() {}

}
