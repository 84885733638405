import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelsModule } from 'src/app/core/models/models.module';
import { IonicModule } from '@ionic/angular';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { SuperadminPlanListComponent } from './list/list.component';
import { SuperadminPlanEditComponent } from './edit/edit.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    SuperadminPlanListComponent,
    SuperadminPlanEditComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ModelsModule,
    AppComponentsModule
  ],
  exports: [
    SuperadminPlanListComponent
  ]
})
export class SuperadminPlanModule { }
