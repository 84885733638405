<div class="ion-padding">
  <form #form="ngForm" (ngSubmit)="submit()">
    <img *ngIf="organization?.imageURL" [src]="organization?.imageURL" />
    <h4 class="text-center">{{organization?.title}}</h4>
    <p class="text-center"><strong>{{'organization/join/lead' | translate: {organization: organization?.title} }}</strong></p>
    <div class="form-check ml-0 mt-3 mb-2">
      <input
        name="terms"
        type="checkbox"
        class="form-check-input mx-2 mr-3"
        id="terms"
        required
        [(ngModel)]="terms"
        >
        <label class="note form-check-label"
        for="terms" #label [innerHTML]="'organization/join/privacy' | translate: {organization: organization?.title}">
      </label>
    </div>
    <div class="w-100 w-sm-50 mx-auto">
      <ion-button [disabled]="form.invalid || processing" type="submit" expand="block" color="warning" shape="round">
        <ion-spinner *ngIf="processing"></ion-spinner>
        {{'organization/join/button' | translate}}
      </ion-button>
    </div>
  </form>
</div>