import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../role.model';
import { ModelProvider } from '../general/model.provider';


@Injectable({
    providedIn: 'root',
})
export class RoleResolver implements Resolve<Role> {
    constructor(
        private modelProvider: ModelProvider,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Role> | Observable<never> {
        const id = route.paramMap.get('roleID');
        const scriptID = route.paramMap.get('scriptID');
        const versionID = route.paramMap.get('versionID');

        return Observable.create((observer) => {
            this.modelProvider.role.findByID(id, scriptID, versionID).subscribe(role => {
                observer.next(role);
                observer.complete();
            }, error => {
                console.error('Role Reslvover error', error);
                this.router.navigate(['design']);
            });
        });
    }
}
