<!-- <ion-header>
  <ion-toolbar>
    <ion-title>dashboard</ion-title>
  </ion-toolbar>
</ion-header> -->
<app-header active="superadmin"></app-header>

<ion-content>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/superadmin','email-verification']">
          <div class="content">
            <ion-icon name="mail"></ion-icon>
            <h3>Email Verification</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/superadmin', 'login-as']">
          <div class="content">
            <ion-icon name="log-in"></ion-icon>
            <h3>Login as</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/superadmin', 'set-superadmin']">
          <div class="content">
            <ion-icon name="person-add"></ion-icon>
            <h3>Add Superadmin</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-12"></div>
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/superadmin','plan']">
          <div class="content">
            <mat-icon>shopping_basket</mat-icon>
            <h3>Plans</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/superadmin','organization']">
          <div class="content">
            <mat-icon>folder_shared</mat-icon>
            <h3>Teams</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/ai','create-game']">
          <div class="content">
            <mat-icon>psychology</mat-icon>
            <h3>Ai</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-12"></div>
      <div class="col-sm-3">
        <div class="box">
          <div class="content">
            <ion-icon name="people"></ion-icon>
            <h3>User Analytics</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="box">
          <div class="content">
            <ion-icon name="albums"></ion-icon>
            <h3>Game Analytics</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/superadmin','analytics','sessions']">
          <div class="content">
            <ion-icon name="play-circle"></ion-icon>
            <h3>Session Analytics</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="box" [routerLink]="['/superadmin','generate-url']">
          <div class="content">
            <ion-icon name="link"></ion-icon>
            <h3>Generate URL</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <content-container [back-button]="false">
  
  </content-container> -->
  <!-- <user-stat></user-stat>
  <game-stat></game-stat>
  <play-stat></play-stat> -->
</ion-content>