<ng-container *ngIf="!isGamePage">
  <ion-header>
    <ion-toolbar>
      <a class="logo"
         slot="start"
         (click)="analytics.onLogoClicked()"
         [routerLink]="['']">
        <img src="../../../assets/layout/header/logo_horizontal_black.png">
      </a>
      <div class="title">
        <ng-content *ngIf="false"
                    class="title"
                    select="ion-title"></ng-content>
      </div>
      <ng-content></ng-content>
      <div *ngIf="workspaceService.hasDesignerWorkspace$() | async" class="top-menu">
        <ul>
          <li [class.active]="active == 'design'">
            <a *ngIf="(designPageViewPrivilege | privilege) | async"
               [routerLink]="['/design']"
               (click)="analytics.onScriptsButtonClicked()">
              {{'top-menu/design/title' | translate}}
            </a>
            <div *ngIf="!((designPageViewPrivilege | privilege) | async)"
                 class="disabled">
              {{'top-menu/design/title' | translate}}
            </div>
          </li>

          <li [class.active]="active == 'organize'">
            <a *ngIf="(organizePageViewPrivilege | privilege) | async"
               [routerLink]="['/organize']"
               (click)="analytics.onPlaysButtonClicled()">
              {{'top-menu/organize/title' | translate}}
            </a>
          </li>

          <li [class.active]="active == 'analytics'">
            <a 
               [routerLink]="['/analytics']"
               (click)="analytics.onPlaysButtonClicled()">
              {{'top-menu/analytics/title' | translate}}
            </a>
          </li>

          <li [class.active]="active == 'play'">
            <a *ngIf="!isPlayLandingPageVisible()"
               [routerLink]="['/play-landing']"
               (click)="analytics.onPlayButtonClicked(true)">
              {{'top-menu/play/title' | translate}}
            </a>
            <a href="{{environment?.web?.playUrl}}"
               target="_blank"
               *ngIf="isPlayLandingPageVisible()"
               (click)="analytics.onPlayButtonClicked(false)"
               >
              {{'top-menu/play/title' | translate}}
            </a>
          </li>

          <li *ngIf="isSuperadmin" class="superadmin" [class.active]="active == 'superadmin'">
            <a *ngIf="(organizePageViewPrivilege | privilege) | async"
               [routerLink]="['/superadmin/dashboard']">
              {{'top-menu/supearadmin/title' | translate}}
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="workspaceService.hasDesignerWorkspace$() | async" class="organization-select mr-3" slot="end">
        <!-- <ion-button *ngIf="!organizations || organizations?.length <= 1" shape="round" (click)="openPlansDialog()">Upgrade</ion-button> -->
        <ng-container *ngIf="organizations && organizations?.length > 0">
          <div *ngIf="organizationService.activeOrganization$ | async; let activeOrganization" class="desktop-trigger">
            <div class="organization-container"
              [context-menu]="organizationContextMenu"
              [popperPositionFixed]="true"
              [popperModifiers]="{offset: {offset: '-5px,5px'}, flip: {enabled: false}}"
              [popperApplyClass]="'menu-popper'"
              [popperBoundaries]="'body'"
              [popperAppendTo]="'body'"
            >
              <div class="img" [ngStyle]="{backgroundImage: 'url('+ (activeOrganization?._image | size : 'xs' : 'xs' : activeOrganization?.photoURL) +')'}">
                <!-- <span *ngIf="activeOrganization?.plan?.ref.path === 'plans/pro'">Pro</span> -->
              </div>
              {{activeOrganization?.title}}
            </div>
            <!-- <img [context-menu]="organizationContextMenu"
                [src]="activeOrganization?.photoURL"
                (click)="analytics.onOrganizationIconClicked()"
                [popperPositionFixed]="true"
                [popperModifiers]="{offset: {offset: '-5px,5px'}, flip: {enabled: false}}"
                [popperApplyClass]="'menu-popper'"> -->
            <context-group #organizationContextMenu>
              <context-item *ngFor="let organization of organizations; let organizationIndex = index"
              [label]="organization.title"
              (click)="setOrganization(organization)"
              [class.highlighted]="activeOrganization.ref.path === organization.ref.path"
              [class.divider]="organizations.length > 2 && organizationIndex === 1"
              ></context-item>
              <context-item
              label="{{'user-profile/context-menu/manage-organizations' | translate}}"
              (click)="openManageOrganizations(activeOrganization)"
              [disabled]="activeOrganization.ref.path === 'organizations/orphan' || !(activeOrganization.myMember$ | async)"
              class="divider"
              >
              </context-item>
              <context-item
              [label]="'user-profile/context-menu/create-organizations' | translate"
              (click)="openPlansDialog()"
              ></context-item>
            </context-group>
          </div>
        </ng-container>
      </div>
      <div class="user"
           slot="end">
          <!-- <div class="mobile-trigger">
            <img *ngIf="user"
                 [src]="user?.photoURL"
                 name="more"
                 mode="md"
                 (click)="analytics.onProfileIconClicked()">
          </div> -->

          <div class="desktop-trigger">
            <img *ngIf="user"
                 [context-menu]="profileContextMenu2"
                 [src]="user?.photoURL"
                 (click)="analytics.onProfileIconClicked()"

                >
            <context-group #profileContextMenu2>
              <context-item label="{{'user-profile/context-menu/profile' | translate}}"
                            (click)="openProfile($event)">
              </context-item>
              <!-- <context-item *ngIf="(workspaceEditPrivilege | privilege) | async"
                            label="{{'user-profile/context-menu/manage-teams' | translate}}"
                            (click)="openManageTeams($event)">
              </context-item> -->
              <context-item *ngIf="!((workspaceEditPrivilege | privilege) | async)"
                            label="{{'user-profile/context-menu/manage-teams' | translate}}"
                            class="disabled-menu-item">
              </context-item>
              <!-- <ng-container *ngIf="organizations">
                <ng-container>
                  <context-item *ngIf="(workspaceEditPrivilege | privilege) | async"
                  label="{{'user-profile/context-menu/manage-organizations' | translate}}"
                  (click)="(organizations.length === 1) && openManageOrganizations(organizations[0])">
                    <context-group *ngIf="organizations.length > 1">
                      <context-item *ngFor="let organization of organizations"
                      [label]="organization.title"
                      (click)="openManageOrganizations(organization)"></context-item>
                    </context-group>
                  </context-item>
                </ng-container>
              </ng-container> -->
              <context-item
                label="{{'user-profile/context-menu/help' | translate}}"
                (click)="openHelpCenter($event)">
              </context-item>
              <context-item label="{{'user-profile/context-menu/logout' | translate}}"
                            (click)="logout($event)">
              </context-item>
            </context-group>
          </div>
      </div>

    </ion-toolbar>
  </ion-header>
</ng-container>

<ng-container *ngIf="isGamePage">
  <ion-header>
    <ion-toolbar color="dark">
      <a class="logo"
         slot="start"
         (click)="analytics.onLogoClicked()"
         [routerLink]="['']">
        <img src="../../../assets/layout/header/logo_horizontal_white.png">
      </a>
      <ion-title class="centered-navbar-title"
                 slot="start">
        {{'script-designer/page-title' | translate}}
      </ion-title>

      <div class="organization-select mr-3" slot="end">
        <ion-button *ngIf="!organizations || organizations?.length === 0" shape="round" (click)="openPlansDialog()">Upgrade</ion-button>
        <ng-container *ngIf="organizations && organizations?.length > 0">
          <div *ngIf="organizationService.activeOrganization$ | async; let activeOrganization" class="desktop-trigger">
            <div class="organization-container"
              [context-menu]="organizationContextMenu"
              [popperPositionFixed]="true"
              [popperModifiers]="{offset: {offset: '-5px,5px'}, flip: {enabled: false}}"
              [popperApplyClass]="'menu-popper'"
            >
              <div class="img" [ngStyle]="{backgroundImage: 'url('+ activeOrganization?.photoURL +')'}">
                <!-- <span *ngIf="activeOrganization?.plan?.ref.path === 'plans/pro'">Pro</span> -->
              </div>
              {{activeOrganization?.title}}
            </div>
            <!-- <img [context-menu]="organizationContextMenu"
                [src]="activeOrganization?.photoURL"
                (click)="analytics.onOrganizationIconClicked()"
                [popperPositionFixed]="true"
                [popperModifiers]="{offset: {offset: '-5px,5px'}, flip: {enabled: false}}"
                [popperApplyClass]="'menu-popper'"> -->
            <context-group #organizationContextMenu>
              <context-item *ngFor="let organization of organizations; let organizationIndex = index"
              [label]="organization.title"
              (click)="setOrganization(organization)"
              [class.highlighted]="activeOrganization.ref.path === organization.ref.path"
              [class.divider]="organizations.length > 2 && organizationIndex === 1"
              ></context-item>
              <context-item
              label="{{'user-profile/context-menu/manage-organizations' | translate}}"
              (click)="openManageOrganizations(activeOrganization)"
              [disabled]="activeOrganization.ref.path === 'organizations/orphan'"
              class="divider"
              >
              </context-item>
              <context-item
              label="Create New Team"
              (click)="openPlansDialog()"
              ></context-item>
            </context-group>
          </div>
        </ng-container>
      </div>
      <div class="user"
           slot="end">
          <!-- <div class="mobile-trigger">
            <img *ngIf="user"
                 [src]="user?.photoURL"
                 name="more"
                 mode="md"
                 (click)="presentMainActionSheet(); analytics.onProfileIconClicked()">
          </div> -->

          <div class="desktop-trigger">
            <img *ngIf="user"
                 [context-menu]="profileContextMenu"
                 [src]="user?.photoURL"
                 (click)="analytics.onProfileIconClicked()"
                 [popperPositionFixed]="true"
                 [popperModifiers]="{offset: {offset: '-5px,5px'}, flip: {enabled: false}}"
                 [popperApplyClass]="'menu-popper'">
            <context-group #profileContextMenu>
              <context-item label="{{'user-profile/context-menu/profile' | translate}}"
                            (click)="openProfile($event)">
              </context-item>
              <context-item *ngIf="!((workspaceEditPrivilege | privilege) | async)"
                            label="{{'user-profile/context-menu/manage-teams' | translate}}"
                            class="disabled-menu-item">
              </context-item>
              <context-item
                label="{{'user-profile/context-menu/help' | translate}}"
                (click)="openHelpCenter($event)">
              </context-item>
              <context-item label="{{'user-profile/context-menu/logout' | translate}}"
                            (click)="logout($event)">
              </context-item>
            </context-group>
          </div>
      </div>
    </ion-toolbar>
  </ion-header>
</ng-container>
