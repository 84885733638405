<div class="embed-responsive embed-responsive-16by9">
  <iframe
    class="embed-responsive-item"
    [src]="videoUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</div>
