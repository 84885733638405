<mat-icon (click)="onCancel('icon')"
          class="modal-close-icon"
          slot="start">close</mat-icon>

<div class="modal-content ion-padding">
  <h2 class="modal-title">{{'manage-teams/invite/title' | translate}}</h2>

  <form #inviteForm="ngForm"
        (ngSubmit)="onSubmit(inviteForm)">

    <div class="form-group">
      <div class="input-text-wrapper">
        <input type="email"
               name="mail"
               #mail="ngModel"
               [(ngModel)]="email"
               [placeholder]="'manage-teams/invite/email' | translate"
               email
               required/>
        <div *ngIf="mail.touched"
             class="alert invalid-field">
                <span *ngIf="mail.errors?.required">
                  {{'task-edit/form/global/required' | translate}}
                </span>
                <span *ngIf="mail.errors?.email">
                  {{'task-edit/form/global/invalid-email' | translate}}
                </span>
        </div>
      </div>
    </div>



    <label for="level"  class="label-with-popper">
      <span>{{'manage-teams/invite/invite-as' | translate}}</span>
      
      <ion-icon name="help-circle" class="ml-2" [popper]="organizerNameInfoTextPopper" [popperTrigger]="'hover'"
      [popperPlacement]="'right'" [popperApplyClass]="'info-text-popper'" popperAppendTo="body"></ion-icon>
      <popper-content popperApplyClass="popover-color" #organizerNameInfoTextPopper>
          <p *ngFor="let level of levels" class="my-0">
            <b>{{'manage-teams/role/' + level | translate }}</b><br />
            <span [innerHTML]="'manage-teams/role/' + level + '/info' | translate"></span>
          </p>
      </popper-content>
    </label>

    <select class="form-control"
            name="level"
            [(ngModel)]="memberRoleLevel">
      <option *ngFor="let level of levels" [value]="level">{{'manage-teams/role/' + level | translate }}</option>    
      <!-- <option [value]="'admin'">{{'manage-teams/role/admin' | translate }}</option>
      <option [value]="'editor'">{{'manage-teams/role/editor' | translate }}</option>
      <option [value]="'player'">{{'manage-teams/role/player' | translate }}</option> -->
    </select>

    <ion-footer mode="ios">
      <div class="text-right">
        <ion-button (click)="onCancel('button')"
                    class="mr-2"
                    color="secondary"
                    shape="round">
          {{'general/cancel' | translate}}
        </ion-button>

        <ion-button type="submit"
                    color="primary"
                    [disabled]="processing || inviteForm.invalid"
                    shape="round">
          <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
          {{'manage-teams/invite/send-btn' | translate}}</ion-button>
      </div>
    </ion-footer>
  </form>

</div>
