import { Injectable } from '@angular/core';
import { AnalyticsProvider } from '../core/analytics/analytics';
import { AnalyticsService } from '../core/analytics/analytics.service';

@Injectable()
export class UserProfileAnalyticsService implements AnalyticsService {
  constructor(private analyticsService: AnalyticsProvider) {
    this.analyticsService.setCurrentScreen('Profile Settings');
  }

  createFullNameEditClickedEvent(): void {
    this.analyticsService.event(['Full name', 'edit clicked'], 'Full name');
  }

  createFullNameFieldClickedEvent(): void {
    this.analyticsService.event(['Full name', 'field clicked'], 'Full name');
  }

  createFullNameSaveButtonClickedEvent(): void {
    this.analyticsService.event(['Full name', 'save clicked'], 'Full name', {type: 'button'});
  }

  createProfilePictureUploadClickedEvent(): void {
    this.analyticsService.event(['Profile picture', 'upload clicked']);
  }

  createProfilePictureFileSelectedClickedEvent(): void {
    this.analyticsService.event(['Profile picture', 'file selected']);
  }

  createProfilePictureUploadedEvent(): void {
    this.analyticsService.event(['Profile picture', 'uploaded']);
  }

  createProfilePictureRemoveClickedEvent(): void {
    this.analyticsService.event(['Profile picture', 'remove clicked']);
  }

}
