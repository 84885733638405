import { AngularFirestoreDocument, DocumentReference, QueryFn, CollectionReference, AngularFirestoreCollection, QueryDocumentSnapshot, DocumentSnapshot } from '@angular/fire/firestore';
import { cloneDeep, isEqualWith, IsEqualCustomizer, PropertyName, cloneDeepWith, clone } from 'lodash';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { ModelProvider } from '../models/general/model.provider';
import { Model } from '../models/general/model';
import firebase from "firebase/app"
import { Observable, Subject, ReplaySubject } from 'rxjs';

export class FirestoreModel<T> extends Model {

  public id: string;
  private _storedFields: any = []; // Purpose: store only changed values
  protected _document: AngularFirestoreDocument;
  public isNew: Boolean;
  public createdAt: firebase.firestore.Timestamp;
  public createdBy: DocumentReference;
  public updatedAt: firebase.firestore.Timestamp;
  public updatedBy: DocumentReference;
  // filled in findAllBy only. should be added globally if other queries requires it
  public snapshot: firebase.firestore.DocumentSnapshot;

  public queries: {
    [id: string]: Observable<FirestoreModel<Model>> | Observable<FirestoreModel<Model>[]>;
  } = {};

  constructor(private _path: string, data: any, public options: any, protected modelProvider: ModelProvider) {
    super(data, { skipSetFields: true });

    // Trim "/" characters form the beginging and end
    this._path = this._path.trim().replace(/^\/+|\/+$/g, '');

    // Set whether the instance is new or not based on the path (odd number of segment)
    const isCreateID = !!(this._path.split('/').length % 2);

    // Add ID if new document instantiated
    if (isCreateID && this._path.length > 0) {
      this.id = this.modelProvider.fsDB.createId();
      this._path = this._path + '/' + this.id;
    } else {
      this.id = this._path.split('/').splice(-1).pop();
    }

    this.isNew = (isCreateID || (options && options.isNew)) ? options.isNew : false;

    this.setFields(data);

    if (!this.isNew) {
      if (options?.snapshot) {
        this.snapshot = options.snapshot;
        // console.log("USE SNAPSHOT", this.constructor.name, options, data)
        this._storedFields = (options.snapshot as QueryDocumentSnapshot<T>).data();
      } else {
        // console.log("USE CLONE DEEP", this.constructor.name, options, data)
        this._storedFields = cloneDeep(data);
      }
      // this._storedFields = cloneDeep(data);
    }
  }

  public getPath() {
    if (!this._path || this._path === '') {
      return this['COLLECTION_NAME'];
    }
    return this._path;
  }

  // public load() {
  //     return this.valueChanges().pipe(first()).subscribe(data => {
  //         this.setFields(data);
  //         this.isNew = false;
  //         this._storedFields = data;
  //     });
  // }

  public getDocument(): AngularFirestoreDocument {
    return this.modelProvider.fsDB.doc(this._path);
  }

  public get ref(): DocumentReference {
    return this.getReference();
  }

  public getReference(): DocumentReference {
    return this.getDocument().ref;
  }

  public async save() {
    const self = this;

    if (this.isNew) {
      this.createdAt = firebase.firestore.Timestamp.now();
      this.createdBy = this.modelProvider.fsDB.collection('users').doc(firebase.auth().currentUser.uid).ref;
      this.updatedAt = this.createdAt;
      this.updatedBy = this.createdBy;
      const createFields = this._getChangedFields();
      return await this.getDocument().set(createFields, { merge: true })
        .then(data => {
          self.isNew = false;
          this.storedFields = { ...this.storedFields, ...cloneDeep(createFields) };
          return data;
        });
    } else {
      this.updatedAt = firebase.firestore.Timestamp.now();
      this.updatedBy = this.modelProvider.fsDB.collection('users').doc(firebase.auth().currentUser.uid).ref;
      const updateFields = this._getChangedFields();
      return await this.getDocument().update(updateFields).then(stored => {
        this.storedFields = { ...this.storedFields, ...cloneDeep(updateFields) };
        // console.log(this.storedFields);
        // for (const f in this._storedFields) {
        //   if (this._storedFields.hasOwnProperty(f)) {
        //     const value = this[this.getPropertyNameByFieldName(f)];
        //     if (value !== undefined) {
        //       this._storedFields[f] = value && value.toData ? value.toData() : value;
        //     }
        //   }
        // }
        return stored;
      });
    }
  }

  public create(path: string, data: any, options: { withModelData: boolean } = { withModelData: true }) {
    const instance = this.instantiate(path, data, { skipSetFields: true, isNew: true });

    if (options && options.withModelData) {
      instance.setModelFields(data);
    } else {
      instance.setFields(data);
    }

    instance.setDefaultValues();

    return instance;
  }

  public remove(): Promise<void> {
    return this.getDocument().delete();
  }

  // public getValues() {
  //     const values = {};
  //     const fields = this.getFields();
  //     for (const property in fields) {
  //         if (fields.hasOwnProperty(property)) {
  //             const modelField = fields[property];

  //             values[modelField] = this[modelField];
  //         }
  //     }

  //     return cloneDeep(values);
  // }

  // public getFields() {
  //     const values = {};
  //     const rules = this.getRules();

  //     for (const propertyName in rules) {
  //         if (rules.hasOwnProperty(propertyName)) {
  //             values[this.getFieldNameByPropertyName(propertyName)] = this[propertyName];
  //         }
  //     }

  //     return cloneDeep(values);
  // }
  public getChangedFields() {
    return this._getChangedFields();
  }

  // tslint:disable-next-line: max-line-length
  private changeFieldComparator: IsEqualCustomizer = (value: any, other: any, indexOrKey: PropertyName | undefined, parent: any, otherParent: any, stack: any): boolean | undefined => {
    if (value && other && typeof value.isEqual === 'function' && typeof other.isEqual === 'function') {
      return value.isEqual(other);
    }
    if (value && other && value.constructor && value.constructor.name === 'DocumentReference' && other.constructor && other.constructor.name === 'DocumentReference') {
      return (value as DocumentReference).path === (other as DocumentReference).path;
    }
    return;
  }

  public get storedFields() {
    return this._storedFields;
  }

  public set storedFields(values) {
    this._storedFields = values;
  }

  private _getChangedFields(): object | null {
    const changed = {};

    const rules = this.getRules();
    for (const propertyName in rules) {
      if (rules.hasOwnProperty(propertyName)) {
        // const rule = rules[propertyName];

        if (this[propertyName] && this[propertyName].toData) {
          // tslint:disable-next-line: max-line-length
          if (!isEqualWith(this[propertyName].toData(), this._storedFields[this.getFieldNameByPropertyName(propertyName)], this.changeFieldComparator)) {
            changed[this.getFieldNameByPropertyName(propertyName)] = this[propertyName].toData();
            // console.log(this[propertyName].toData(), this._storedFields[this.getFieldNameByPropertyName(propertyName)]);
          }
        } else {
          if (!isEqualWith(this[propertyName], this._storedFields[propertyName], this.changeFieldComparator)) {
            changed[this.getFieldNameByPropertyName(propertyName)] = this[propertyName];
          }
        }
      }
    }

    return (Object.keys(changed).length > 0) ? changed : null;
  }

  protected getRules() {
    return {
      createdAt: {},
      createdBy: {},
      updatedAt: {},
      updatedBy: {}, ...super.getRules()
    };
  }

  public resetToStored() {
    if (this.snapshot) {
      this.setFields(this.snapshot.data());
      return;
    }

    this.setFields(this._storedFields);
  }

  public findByRef(ref: DocumentReference | string, options: { updateSelfFields: boolean } = { updateSelfFields: true }): Observable<T> {
    let path = '';
    if (typeof ref === 'string') {
      path = ref;
    } else {
      path = ref.path;
    }

    if (this.modelProvider.cache.getModel(this.modelProvider.fsDB.doc(path).ref)) {
      return this.modelProvider.cache.getModel(this.modelProvider.fsDB.doc(path).ref);
    }


    let instance: FirestoreModel<T> & T;

    const query = this.modelProvider.fsDB.doc(path).snapshotChanges().pipe(map(snapshot => {
      if (snapshot.payload.exists) {
        if (options.updateSelfFields) {
          if (instance) {
            instance.setFields(snapshot.payload.data())
            instance.snapshot = snapshot.payload;
          } else {
            instance = this.instantiate(snapshot.payload.ref.path, snapshot.payload.data(), { snapshot: snapshot.payload });
          }
          return instance;
        }
        return this.instantiate(snapshot.payload.ref.path, snapshot.payload.data(), { snapshot: snapshot.payload });
      } else {
        return null;
        console.error(snapshot);
        throw new Error(this.constructor.name + ' not found by ref: ' + path);
      }
    }))
      .pipe(shareReplay(1))
      ;

    this.modelProvider.cache.models.set(this.modelProvider.fsDB.doc(path).ref, query);

    return query;
  }

  public findByID(_id: string) {
    return this.findByRef(this.modelProvider.fsDB.collection(this.getPath()).doc(_id).ref);
  }

  public findAllBy(queryFn?: QueryFn, _collectionRef?: CollectionReference): Observable<Array<T>> {
    if (!queryFn) {
      queryFn = ref => ref;
    }

    const collection: AngularFirestoreCollection = _collectionRef ?
      this.modelProvider.fsDB.collection(_collectionRef, queryFn) :
      this.modelProvider.fsDB.collection(this.getPath(), queryFn);

    if (this.modelProvider.cache.getQuery(queryFn(collection.ref))) {
      return this.modelProvider.cache.getQuery(queryFn(collection.ref));
    }

    const query = collection
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          // console.log('map', a);

          // const cachedModel = this.modelProvider.cache.getModel(a.payload.doc.ref);
          // cachedModel

          const model = this.instantiate(a.payload.doc.ref.path, a.payload.doc.data(), { snapshot: a.payload.doc });
          model.snapshot = a.payload.doc;

          // console.log('!!!query snapshots ' + this.constructor.name, model)

          // const cachedModel = this.modelProvider.cache.getModel(model.getReference());
          // if (!cachedModel) {
          //   const observable = new ReplaySubject<T>(1);
          //   observable.next(model);
          //   this.modelProvider.cache.setModel(model.getReference(), observable);
          // }

          // if (cachedModel && cachedModel instanceof ReplaySubject) {
          //   if(this.constructor.name === 'Play') {
          //     console.log(this.constructor.name, a.payload.doc.ref.path, 'load cache');//, snapshot.payload.data());
          //   }
          //   // console.log('!!!cached model ' + this.constructor.name, cachedModel)
          //   cachedModel.pipe(first()).subscribe(_cachedModel => {
          //     _cachedModel.setFields(a.payload.doc.data());
          //     cachedModel.next(_cachedModel)
          //   })

          //   // return 
          //   // cachedModel.next(model);
          // }


          return model;
        }))
      )
      .pipe(shareReplay(1));
    this.modelProvider.cache.setQuery(queryFn(collection.ref), query);

    return query;
  }

  protected instantiateClass(modelClass: Model, data: any, options: any = {}) {
    return (modelClass as any).instantiate(data, this, options, this.modelProvider);
  }

  protected instantiate(path, data: any, options?: any): FirestoreModel<T> & T {
    throw new Error(this.constructor.name + ' Instantiate implementation required!');
  }
}
