import { DatePipe } from '@angular/common';
import { TimeAgoPipe } from 'ngx-moment';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FeedPost } from 'src/app/core/models/feed-post';
import { QuoteLayoutItem } from 'src/app/core/models/layout-item-quote';
import { Play } from 'src/app/core/models/play.model';
import { Player } from 'src/app/core/models/player.model';
import { Response } from 'src/app/core/models/response';
import { Role } from 'src/app/core/models/role.model';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { Task } from 'src/app/core/models/task.model';
import { User } from 'src/app/core/models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'post-task-upcoming',
  templateUrl: './task-upcoming.component.html',
  styleUrls: ['./task-upcoming.component.scss'],
})
export class TaskUpcomingComponent implements OnInit {

  // @Input()
  // public post: FeedPost;

  @Input()
  public tasks: Task[];

  @Input()
  public players: Player[];

  @Input()
  public roles: Role[];

  @Input()
  public users: User[];

  @Input()
  public play: Play;

  @Input()
  public myPlayer: Player;

  @Input()
  public task: Task;

  // @Input()
  // public responses: Response[];

  public LayoutItem = LayoutItem;

  videoType: 'mp4' | 'youtube';
  videoURL: string;

  role: Role;
  user: User;

  title: string;
  timeString: string;
  hasQuote: boolean;

  quoteResponse: Response;
  quoteLayoutItem: LayoutItem;
  quoteTask: Task;
  quotePlayer: Player;
  quoteUser: User;
  quoteRole: Role;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private amAgoPipe: TimeAgoPipe,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.myPlayer) {
      this.user = this.users.find(user => user.ref.path === this.myPlayer.userRef.path);
      this.role = this.roles.find(role => role.ref.path === this.myPlayer.roleRef.path);
      this.timeString = this.getTimeString();
      this.title = this.translate.instant('playing/feed/post/task-upcoming/no-quote');
    }
    if (this.task.hasQuote) {
      this.quoteLayoutItem = this.task.layout.getItemByType(LayoutItem.TYPE_QUOTE, false) as QuoteLayoutItem;
      this.quoteTask = this.tasks.find(task => task.ref.path === this.quoteLayoutItem.properties.taskRef.path);

      this.quotePlayer = this.players.find(player => player.responses.getByID(this.quoteTask.id));
      this.quoteResponse = this.quotePlayer.responses.getByID(this.quoteTask.id);
      // this.quoteResponse = this.responses.find(
      //   response => response.taskID === (this.task.layout.getItemByType(LayoutItem.TYPE_QUOTE, false) as QuoteLayoutItem).properties.taskRef.id
      // );
      // this.quotePlayer = this.players.find(player => player.ref.path === this.quoteResponse.playerRef.path);
      this.quoteUser = this.users.find(user => user.ref.path === this.quotePlayer.userRef.path);
      this.quoteRole = this.roles.find(role => role.ref.path === this.quotePlayer.roleRef.path);
      this.user = this.quoteUser;
      this.role = this.quoteRole;
      // this.quoteResponse.

      this.quoteResponse.mediaType

      this.title = this.user.isMe
      ? this.translate.instant('playing/feed/post/task-upcoming/has-quote/mine')
      : this.translate.instant('playing/feed/post/task-upcoming/has-quote/other', {user: this.user.displayName, role: this.role.title});

      this.hasQuote = true;
    }
  }

  private getTimeString() {
    const time = this.task.getArrivalTime(this.players, this.play);
    const oneDayAgo = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
    // day hour  min  sec  msec
    if (oneDayAgo < time.getTime()) {
      return this.amAgoPipe.transform(time);
    } else {
      return this.datePipe.transform(time, 'short');
    }
  }

  @Input()
  clickable = true

  @HostListener('click', ['$event'])
  async action($event: any) {
    if(this.clickable) {
      return await this.router.navigate(['../../', 'task', 'view'], { queryParams: { id: this.task.id }, relativeTo: this.route });
    }
  }

}
