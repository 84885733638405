import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Organization } from 'src/app/core/models/organization.model';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { first } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Plan, Price } from 'src/app/core/models/plan';
import { Stripe } from 'stripe';
import { OrganizationAnalyticsService } from '../organization.analytics.service';

type paymentMethod = 'card' | 'existing-card' | 'invoice';
type billingType = 'monthly' | 'yearly';

@Component({
  selector: 'organization-billing',
  templateUrl: './organization-billing.component.html',
  styleUrls: ['./organization-billing.component.scss'],
})
export class OrganizationBillingComponent implements OnInit {

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  @Input()
  organization: Organization;

  invoices: Stripe.Invoice[];

  customer: Stripe.Customer;

  taxID: string;

  subscription: Stripe.Subscription;

  paymentMethodID: string | Stripe.PaymentMethod;

  stripePaymentMethods: Array<Stripe.PaymentMethod> = [];

  processing = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    },
    hidePostalCode: true
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  cardValid = true;

  countries = {
    AE: {
      label: 'United Arab Emirates',
      vatLabel: 'United Arab Emirates TRN',
      vatPercent: 0,
      vatMask: '000000000000000',
      vatPrefix: '',
      vatType: 'ae_trn',
      isEU: false
    },
    AU: {
      label: 'Australia',
      vatLabel: 'Australian Business Number (AU ABN)',
      vatPercent: 0,
      vatMask: '00000000000',
      vatPrefix: '',
      vatType: 'au_abn',
      isEU: false
    },
    BR: {
      label: 'Brazil',
      vatLabel: 'Brazilian CNPJ number',
      vatPercent: 0,
      vatMask: '00.000.000/0000-00',
      vatPrefix: '',
      vatType: 'br_cnpj',
      isEU: false
    },
    CA: {
      label: 'Canada',
      vatLabel: 'Canadian BN',
      vatPercent: 0,
      vatMask: '000000000',
      vatPrefix: '',
      vatType: 'ca_bn',
      isEU: false
    },
    CH: {
      label: 'Switzerland',
      vatLabel: 'Switzerland VAT number',
      vatPercent: 0,
      vatMask: '000.000.000',
      vatPrefix: 'CHE-',
      vatSuffix: ' MWST',
      vatType: 'ch_vat',
      isEU: false
    },
    CL: {
      label: 'Chile',
      vatLabel: 'Chilean TIN',
      vatPercent: 0,
      vatMask: "00.000.000",
      vatPrefix: '',
      vatSuffix: '-K',
      vatType: 'cl_tin',
      isEU: false
    },
    HK: {
      label: 'Hong Kong',
      vatLabel: 'Hong Kong BR number',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'hk_br',
      isEU: false
    },
    ID: {
      label: 'Indonesia',
      vatLabel: 'Indonesian NPWP number',
      vatPercent: 0,
      vatMask: "00.000.000.0-000.000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'id_npwp',
      isEU: false
    },
    IN: {
      label: 'India',
      vatLabel: 'Indian GST number',
      vatPercent: 0,
      vatMask: "00SSSSS0000S0S0",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'in_gst',
      isEU: false
    },
    JP: {
      label: 'Japan',
      vatLabel: 'Japanese Corporate Number (Hōjin Bangō)',
      vatPercent: 0,
      vatMask: "0000000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'jp_cn',
      isEU: false
    },
    KR: {
      label: 'Republic of	Korea',
      vatLabel: 'Korean BRN',
      vatPercent: 0,
      vatMask: "000-00-00000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'kr_brn',
      isEU: false
    },
    LI: {
      label: 'Liechtenstein',
      vatLabel: 'Liechtensteinian UID number',
      vatPercent: 0,
      vatMask: "000000000",
      vatPrefix: 'CHE',
      vatSuffix: '',
      vatType: 'li_uid',
      isEU: false
    },
    MX: {
      label: 'Mexico',
      vatLabel: 'Mexican RFC number',
      vatPercent: 0,
      vatMask: "SSS000000SS0",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'mx_rfc',
      isEU: false
    },
    MY: {
      label: 'Malaysia',
      vatLabel: 'Malaysian FRP number',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'my_frp',
      isEU: false
    },
    NO: {
      label: 'Norway',
      vatLabel: 'Norwegian VAT number',
      vatPercent: 0,
      vatMask: "000000000",
      vatPrefix: '',
      vatSuffix: 'MVA',
      vatType: 'no_vat',
      isEU: false
    },
    NZ: {
      label: 'New Zealand',
      vatLabel: 'New Zealand GST number',
      vatPercent: 0,
      vatMask: "000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'nz_gst',
      isEU: false
    },
    RU: {
      label: 'Russian Federation',
      vatLabel: 'Russian INN',
      vatPercent: 0,
      vatMask: "0000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'ru_inn',
      isEU: false
    },
    SA: {
      label: 'Saudi Arabia',
      vatLabel: 'Saudi Arabia VAT',
      vatPercent: 0,
      vatMask: "000000000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'sa_vat',
      isEU: false
    },
    SG: {
      label: 'Saudi Arabia',
      vatLabel: 'Singaporean GST',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: 'M',
      vatSuffix: 'X',
      vatType: 'sg_gst',
      isEU: false
    },
    TH: {
      label: 'Thailand',
      vatLabel: 'Thai VAT',
      vatPercent: 0,
      vatMask: "0000000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'th_vat',
      isEU: false
    },
    TW: {
      label: 'Taiwan',
      vatLabel: 'Taiwanese VAT',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'tw_vat',
      isEU: false
    },
    US: {
      label: 'United States',
      vatLabel: 'United States EIN',
      vatPercent: 0,
      vatMask: '00-00000000',
      vatPrefix: '',
      vatType: 'us_ein',
      isEU: false,
    },
    ZA: {
      label: 'South Africa',
      vatLabel: 'South African VAT number',
      vatPercent: 0,
      vatMask: '000000000',
      vatPrefix: '4',
      vatType: 'za_vat',
      isEU: false,
    },


    AT: {
      label: 'Austria',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '00000000',
      vatPrefix: 'ATU',
      vatType: 'eu_vat',
      isEU: true
    },
    BE: {
      label: 'Belgium',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '000000000',
      vatPrefix: 'BE',
      vatType: 'eu_vat',
      isEU: true
    },
    BG: {
      label: 'Bulgaria',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '000000000',
      vatPrefix: 'BG',
      vatType: 'eu_vat',
      isEU: true
    },
    HR: {
      label: 'Croatia',
      vatLabel: 'European VAT number',
      vatPercent: 25,
      vatMask: '00000000000',
      vatPrefix: 'HR',
      vatType: 'eu_vat',
      isEU: true
    },
    CY: {
      label: 'Cyprus',
      vatLabel: 'European VAT number',
      vatPercent: 19,
      vatMask: '00000000A',
      vatPrefix: 'CY',
      vatType: 'eu_vat',
      isEU: true
    },
    CZ: {
      label: 'Czech Republic',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '0000000000',
      vatPrefix: 'CZ',
      vatType: 'eu_vat',
      isEU: true
    },
    DK: {
      label: 'Denmark',
      vatLabel: 'European VAT number',
      vatPercent: 25,
      vatMask: '00000000',
      vatPrefix: 'DK',
      vatType: 'eu_vat',
      isEU: true
    },
    EE: {
      label: 'Estonia',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '000000000',
      vatPrefix: 'EE',
      vatType: 'eu_vat',
      isEU: true
    },
    FI: {
      label: 'Finland',
      vatLabel: 'European VAT number',
      vatPercent: 24,
      vatMask: '00000000',
      vatPrefix: 'FI',
      vatType: 'eu_vat',
      isEU: true
    },
    FR: {
      label: 'France',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: 'AA000000000',
      vatPrefix: 'FR',
      vatType: 'eu_vat',
      isEU: true
    },
    DE: {
      label: 'Germany',
      vatLabel: 'European VAT number',
      vatPercent: 16,
      vatMask: '000000000',
      vatPrefix: 'DE',
      vatType: 'eu_vat',
      isEU: true
    },
    GR: {
      label: 'Greece',
      vatLabel: 'European VAT number',
      vatPercent: 24,
      vatMask: '000000000',
      vatPrefix: 'EL',
      vatType: 'eu_vat',
      isEU: true
    },
    HU: {
      label: 'Hungary',
      vatLabel: 'European VAT number',
      vatPercent: 27,
      vatMask: '00000000',
      vatPrefix: 'HU',
      vatType: 'eu_vat',
      isEU: true
    },
    IT: {
      label: 'Italy',
      vatLabel: 'European VAT number',
      vatPercent: 22,
      vatMask: '00000000000',
      vatPrefix: 'IT',
      vatType: 'eu_vat',
      isEU: true
    },
    JO: {
      label: 'Jordan',
      vatLabel: 'Tax Identification Number (TIN)',
      vatPercent: 0,
      vatMask: '0000000',
      vatPrefix: '',
      vatType: false,
      isEU: false
    },
    LV: {
      label: 'Latvia',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '00000000000',
      vatPrefix: 'LV',
      vatType: 'eu_vat',
      isEU: true
    },
    LT: {
      label: 'Lithuania',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '000000000000',
      vatPrefix: 'LT',
      vatType: 'eu_vat',
      isEU: true
    },
    LU: {
      label: 'Luxembourg',
      vatLabel: 'European VAT number',
      vatPercent: 17,
      vatMask: '00000000',
      vatPrefix: 'LU',
      vatType: 'eu_vat',
      isEU: true
    },
    MT: {
      label: 'Malta',
      vatLabel: 'European VAT number',
      vatPercent: 18,
      vatMask: '00000000',
      vatPrefix: 'MT',
      vatType: 'eu_vat',
      isEU: true
    },
    NL: {
      label: 'Netherlands',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '000000000A00',
      vatPrefix: 'NL',
      vatType: 'eu_vat',
      isEU: true
    },
    PL: {
      label: 'Poland',
      vatLabel: 'European VAT number',
      vatPercent: 23,
      vatMask: '0000000000',
      vatPrefix: 'PL',
      vatType: 'eu_vat',
      isEU: true
    },
    PT: {
      label: 'Portugal',
      vatLabel: 'European VAT number',
      vatPercent: 23,
      vatMask: '000000000',
      vatPrefix: 'PT',
      vatType: 'eu_vat',
      isEU: true
    },
    RO: {
      label: 'Romania',
      vatLabel: 'European VAT number',
      vatPercent: 19,
      vatMask: '0000000000',
      vatPrefix: 'RO',
      vatType: 'eu_vat',
      isEU: true
    },
    SK: {
      label: 'Slovakia',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '0000000000',
      vatPrefix: 'SK',
      vatType: 'eu_vat',
      isEU: true
    },
    SI: {
      label: 'Slovenia',
      vatLabel: 'European VAT number',
      vatPercent: 22,
      vatMask: '00000000',
      vatPrefix: 'SI',
      vatType: 'eu_vat',
      isEU: true
    },
    ES: {
      label: 'Spain',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: 'A00000000A',
      vatPrefix: 'ES',
      vatType: 'eu_vat',
      isEU: true
    },
    SE: {
      label: 'Sweden',
      vatLabel: 'European VAT number',
      vatPercent: 25,
      vatMask: '000000000000',
      vatPrefix: 'SE',
      vatType: 'eu_vat',
      isEU: true
    },
    GB: {
      label: 'United Kingdom',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '000000000',
      vatPrefix: 'GB',
      vatType: 'eu_vat',
      isEU: true
    },
  }

  price: Price;
  plan: Plan;
  currency = 'gbp';


  total = 0;
  vat = 0;
  vatPercent = 0;
  sourceCountry = 'GB';

  constructor(
    private modelProvider: ModelProvider,
    private toastController: ToastController,
    private stripeService: StripeService,
    private organizationAnalytics: OrganizationAnalyticsService
  ) {

  }

  async ngOnInit() {
    this.plan = await this.modelProvider.plan.findByRef(this.organization.plan.ref).pipe(first()).toPromise();

    const response = await this.modelProvider.functions.httpsCallable('organizationGetBilling')({
      organizationPath: this.organization.ref.path
    }).pipe(first()).toPromise();

    console.log(response);

    if (response.result === 'ok') {
      this.customer = response.customer;
      this.subscription = response.subscriptions[0];

      // const interval = this.subscription.plan.interval;
      if (this.subscription) {
        let interval: string = this.subscription.items.data[0].plan.interval;
        if (interval === 'year') { interval = 'yearly' }
        if (interval === 'month') { interval = 'monthly' }
        this.price = this.plan.pricing[interval];

        this.paymentMethodID = (this.subscription.collection_method === 'send_invoice') ? 'invoice' : this.customer.invoice_settings.default_payment_method;
      }
      this.stripePaymentMethods = response.paymentMethods;
      this.invoices = response.invoices;

      this.taxID = this.customer.tax_ids.data[0] ? this.customer.tax_ids.data[0].value : null;
    }
  }

  ngAfterViewInit(): void {
    this.organizationAnalytics.openPage('Manage / Billing');
  }

  calculateTotal() {
    const netPrice = this.price.currencies[this.customer.currency].amount;
    const countryItem = this.countries[this.customer.address.country];

    this.vatPercent = countryItem.vatPercent;
    this.vat = netPrice * (1 + this.vatPercent / 100) - netPrice;

    if(countryItem.vatType === false) {
      this.taxID = '';
    }

    if (this.taxID && this.taxID.length > 0 && countryItem.isEU && this.sourceCountry !== this.customer.address.country) {
      this.vatPercent = 0;
      this.vat = 0;
    }

    this.total = netPrice + this.vat;
  }


  cardUpdated(result) {
    this.cardValid = result.complete;
  }

  async save() {
    try {
      this.processing = true;

      let stripePaymentMethodID: string;
      if (this.paymentMethodID === 'card') {
        stripePaymentMethodID = await this.createStripePayment();
      }
      const response = await this.modelProvider.functions.httpsCallable('organizationSetBilling')({
        organizationPath: this.organization.ref.path,
        billingDetails: {
          address: this.customer.address,
          name: this.customer.name
        },
        taxID: (this.taxID && this.taxID.length > 0) ? this.countries[this.customer.address.country].vatPrefix + this.taxID : null,
        paymentMethod: this.paymentMethodID,
        stripePaymentMethodID: stripePaymentMethodID,
      }).pipe(first()).toPromise();
      // console.log(response);

      if (response.result === true) {
        await this.ngOnInit();
        const toast = await this.toastController.create({
          header: "Successfully saved",
          duration: 3000,
        });
        await toast.present();
      }

    } catch (error) {
      console.error(error);
    } finally {
      this.processing = false;
    }

  }

  async createStripePayment() {
    const createPaymentMethodResult = await this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: {
        address: this.customer.address,
        name: this.customer.name
      }
    }).pipe(first()).toPromise();

    if (createPaymentMethodResult.error) {
      throw new Error(createPaymentMethodResult.error.message);
    }

    return createPaymentMethodResult.paymentMethod.id;
  }

  onSelectPaymentMethod(event: CustomEvent<{ value: paymentMethod }>) {
    if (event.detail.value) {
      // this.paymentMethodID = event.detail.value;
    }

    this.cardValid = this.paymentMethodID !== 'card';
  }

}
