import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DesignerRoutingModule } from './designer-routing.module';

@NgModule({
  imports: [
    IonicModule,
    DesignerRoutingModule
  ],
  exports: [
    DesignerRoutingModule,
  ]
})
export class DesignerModule { }
