import { Component, OnInit } from '@angular/core';
import { Organization } from 'src/app/core/models/organization.model';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-superadmin-organization-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class SuperadminOrganizationListComponent implements OnInit {

  organizations: Organization[];

  subscriptions: Subscription[] = [];


  constructor(
    private modelProvider: ModelProvider,
    private router: Router
  ) { }

  ngOnInit() {
    const modelSub = this.modelProvider.organization.findAllBy(
      ref => ref.orderBy('createdAt', 'desc')
    ).subscribe(organizations => {
      this.organizations = organizations;
    });
    this.subscriptions.push(modelSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async editOrganization(organization: Organization) {
    await this.router.navigate(['superadmin', 'organization', 'edit', organization.id])
  }

  async settingsOrganization(organization: Organization) {
    await this.router.navigate(['organization', organization.id])
  }

  async createTeam() {
    await this.router.navigate(['superadmin', 'organization', 'create'])
  }

}
