<p class="my-0" *ngIf="!editing" #text>
  {{input?.value}}
  <ion-icon name="pencil" mode="md" [title]="iconTitle" ionIconTitle (click)="onEditButtonClick()"></ion-icon>
</p>
<div class="input-container has-button" [class.d-none]="!editing">
  <ng-content select='input'></ng-content>
  <ng-content select='textarea'></ng-content>
  <ng-content select='ion-button'></ng-content>
  <ion-button [disabled]="disabled" shape="round" color="secondary" (click)="onButtonClick()">
    {{'general/ok' | translate}}
  </ion-button>
</div>

<!-- <ng-template #edit>
  <div class="editable" [class.d-none]="!editing">
    <ng-content select="input"></ng-content>
  </div>
</ng-template> -->
<!-- <input *ngIf="editing" class="w-100" [name]="name" [(ngModel)]="value" #ctrl="ngModel" /> -->

<!-- <p #roleTitle class="editable">i
  {{role.title}}
  <ion-icon name="create" mode="md" (click)="toggleDisable(roleTitle); analytics.onTitleEditClickedEvent(role)">
  </ion-icon>
</p>
<input [name]="'roleTitle-'+role.id" [(ngModel)]="role.title" (focusin)="analytics.onTitleFieldClickedEvent(role)"
  (keydown.enter)="onSubmit(roleForm, false)" required /> -->

<!-- <div class="title-container" *ngIf="!">
    {{version?.title}}
    <ion-icon (click)="onTitleEdit()" *ngIf="!readonly" class="edit-icon" name="create" mode="md" slot="start">
    </ion-icon>
</div>

<div class="title-container" *ngIf="titleIsEditing && scriptForm">
  <div class="title-editor">
    <div class="input-container">
      <input type="text" id="scriptTitleName" name="scriptTitleName"
        (focusin)="analytics.createTitleFieldClickedEvent()" [formControl]="getTitleFormControl()"
        (keydown.enter)="saveTitle()" required>
      <div *ngIf="getTitleFormControl().touched && getTitleFormControl().invalid" class="alert invalid-field">
        {{'task-edit/form/global/required' | translate}}
      </div>
    </div>

    <div class="title-action">
      <ion-button shape="round" type="submit" color="secondary" (click)="saveTitle()"
        [disabled]="getTitleFormControl().invalid">
        {{'general/ok' | translate}}
      </ion-button>
    </div>
  </div>
</div> -->