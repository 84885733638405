import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SessionArchiveConfirmationDialogAnalyticsService } from './session-archive-confirmation-dialog.analytics.service';

@Component({
  selector: 'session-archive-confirmation',
  templateUrl: './session-archive-confirmation-dialog.component.html',
  styleUrls: ['./session-archive-confirmation-dialog.component.scss'],
})
export class SessionArchiveConfirmationDialogComponent implements OnInit {
  @Input() title: string;
  @Input() confirmQuestion: string;  
  @Input() confirmDescription: string;
  @Input() cancelBtnText: string;
  @Input() confirmBtnText: string;
  @Input() cancelAction: string;
  @Input() successAction: string;

  constructor(private modalCtrl: ModalController, public analytics: SessionArchiveConfirmationDialogAnalyticsService) {
  }

  ngOnInit() {
    this.analytics.onOpened();
  }

  onCancel(closeType: string) {
    this.analytics.onCancelClicked(closeType);
    this.modalCtrl.dismiss({ action: this.cancelAction });
  }

  public async onArchive() {
    this.analytics.onArchiveButtonClicked();
    this.modalCtrl.dismiss({ action: this.successAction });
  }
}
