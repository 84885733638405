import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { Role } from 'src/app/core/models/role.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Play } from 'src/app/core/models/play.model';
import { Player } from 'src/app/core/models/player.model';
import { DocumentReference } from '@angular/fire/firestore';

@Injectable()
export class PlayDetailsAnalyticsService {
  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
  }

  async setScreen(scriptRef: DocumentReference, version: ScriptVersion, play: Play, player: Player) {
    await this.analyticsService.setCurrentScreen('Play details', {
      'game-id': scriptRef.id,
      'game-title': version.title,
      'game-version-id': version.id,
      'game-facilitator': await version._hasFacilitator(),
      'game-mode': await version._gameMode(),
      'play-organize-type': play.organizeTypeForAnalytics,
      'play-id': play.id,
      'play-title': play.title,
      'play-status': play.status,
      'play-type': play.type,
      'player-id': player ? player.id : 'none',
      'player-status':  player ? player.status : 'none',
    });
  }

  onContextMenuClicked(): void {
    this.analyticsService.event(
      ['context menu clicked']
    );
  }

  onContextMenuCancelClicked(): void {
    this.analyticsService.event(
      ['Context menu', 'cancel clicked']
    );
  }

  onLeaveEventClicked(): void {
    this.analyticsService.event(
      ['Context menu', 'leave event button clicked']
    );
  }

}
