import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../models/role.model';

@Pipe({
  name: 'facilitatorOrder'
})
export class FacilitatorOrderPipe implements PipeTransform {

  transform(roles: Array<Role>, last = true, args?: any): Role[] {
    if (!Array.isArray(roles)) {
      return;
    }
    roles.sort((a: Role, b: Role) => {
      if (a.type === 'facilitator') {
        return (last) ? 1 : -1;
      }
      if (b.type === 'facilitator') {
        return (last) ? -1 : 11;
        // return -1;
      }

      if (a.order && b.order && a.order !== b.order) {
        return (a.order > b.order) ? -1 : 1;
      } else {
        return ('' + a.title).localeCompare(b.title);
      }
    });
    return roles;
  }

}
