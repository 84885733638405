import { QueryFn } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { FirestoreModel } from "../firebase/firestore-model";
import { FeedPost } from "./feed-post";
import { Model } from "./general/model";
import { Team } from "./team.model";

export class Feed extends FirestoreModel<Feed>{
    public type: 'team';

    protected rules() {
        return {
            type: {
                [Model.RULE_DEFAULT]: 'team'
            },

        }
    }
    protected instantiate(path, data, options?: any) {
        return new Feed(path, data, options, this.modelProvider);
    }

    public findByTeam(team: Team, queryFn?: QueryFn): Observable<Feed> {
        return this.findByRef(
            team.ref.parent.parent.path + '/feeds/team' + team.id
        ).pipe(shareReplay(1))
    }

    private _posts$: Observable<FeedPost[]>;
    get posts$() {
        if (this._posts$) { return this._posts$ };

        this._posts$ = this.modelProvider.feedItem.findAllByFeed(this).pipe(shareReplay(1));

        return this._posts$;
    }
}
