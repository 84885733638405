import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Plan } from 'src/app/core/models/plan';
import { first } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-superadmin-plan-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class SuperadminPlanEditComponent implements OnInit {

  public processing = false;

  // title: string;
  // invoiceDaysUntilDue: number;
  // trialPeriod: number;
  // stripeProductID: string;

  // features = {
  //   designerLimitPerWorkspace: 0,
  //   playerLimit: 0,
  //   scriptPerWorkspaceLimit: 0,
  //   workspaceLimit: 0
  // }

  public plan: Plan;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelProvider: ModelProvider,
    private clipboard: ClipboardService,
    private toastController: ToastController,
  ) { }

  async ngOnInit() {
    const planID = this.route.snapshot.paramMap.get('id');

    if (planID) {
      this.plan = await this.modelProvider.plan.findByID(planID).pipe(first()).toPromise();
    } else {
      this.plan = this.modelProvider.plan.create('plans', {});
    }

  }

  async copyLink() {
    const shareURL = environment.web.url + '/organization/create/' + this.plan.id;
    this.clipboard.copyFromContent(shareURL);

    const toast = await this.toastController.create({
      message: 'Plan link successfully copied to clipboard',
      duration: 2000
    });
    toast.present();
  }

  async save() {
    this.processing = true;
    await this.plan.save();
    this.processing = false;
    await this.router.navigate(['superadmin', 'plan'])
  }

}
