import { DatePipe } from '@angular/common';
import { TimeAgoPipe } from 'ngx-moment';
import { Component, Input, OnInit } from '@angular/core';
import { FeedPost } from 'src/app/core/models/feed-post';
import { Role } from 'src/app/core/models/role.model';
import { User } from 'src/app/core/models/user.model';
import { Player } from 'src/app/core/models/player.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'post-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class PostMessageComponent implements OnInit {

  @Input()
  post: FeedPost;

  @Input()
  public roles: Role[];

  @Input()
  public players: Player[];

  @Input()
  public users: User[];

  role: Role;
  player: Player;
  user: User;
  title: string;
  timeString: string;

  message: string;

  constructor(
    private amAgoPipe: TimeAgoPipe,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.user = this.users.find(user => user.ref.path === this.post.userRef.path);
    this.player = this.players.find(player => player.userRef.path === this.user.ref.path);
    this.role = this.roles.find(role => role.ref.path === this.player.roleRef.path);

    this.title = this.user.isMe
      ? this.translate.instant('playing/feed/message/me')
      : this.translate.instant('playing/feed/message/other', {user: this.user.displayName, role: this.role.title});
    this.timeString = this.getTimeString();
    this.message = this.post.text;
  }

  private getTimeString() {
    const time = this.post.createdAt.toDate();
    const oneDayAgo = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
    // day hour  min  sec  msec
    if (oneDayAgo < time.getTime()) {
      return this.amAgoPipe.transform(time);
    } else {
      return this.datePipe.transform(time, 'short');
    }
  }

}
