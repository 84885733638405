import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ModelsModule } from 'src/app/core/models/models.module';
import { TeamBoxComponent } from './team-box.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MemberChipModule } from '../../member-chip/member-chip.module';
import { ContextMenuModule } from 'src/app/core/context-menu/context-menu.module';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { FormsModule } from '@angular/forms';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { IonIconTitleModule } from 'src/app/core/ion-icon-title/ion-icon-title.module';



@NgModule({
  declarations: [
    TeamBoxComponent,
    DeleteConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ModelsModule,
    PipesModule,
    TranslateModule.forChild(),
    MemberChipModule,
    ContextMenuModule,
    AppComponentsModule,
    FormsModule,
    MatIconModule,
    IonIconTitleModule
  ],
  exports: [
    TeamBoxComponent,
  ],
  entryComponents: [
    DeleteConfirmationDialogComponent
  ]
})
export class TeamBoxModule { }
