import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

@Injectable()
export class HeaderAnalyticsService {
  constructor(
    private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
    // this.analyticsService.setCurrentScreen('App update popup');
  }


  onLogoClicked() {
    this.analyticsService.event('logo clicked', '', {}, 'Top menu');
  }

  onScriptsButtonClicked() {
    this.analyticsService.event(
      'games button clicked',
      this.translateService.instant('top-menu/design/title'),
      {}, 'Top menu'
    );
  }

  onPlaysButtonClicled() {
    this.analyticsService.event(
      'plays button clicked',
      this.translateService.instant('top-menu/organize/title'),
      {}, 'Top menu'
    );
  }

  onPlayButtonClicked(isRedirect: boolean) {
    this.analyticsService.event(
      'play button clicked',
      this.translateService.instant('top-menu/play/title'),
      { isRedirect: isRedirect },
      'Top menu'
    );
  }

  onProfileIconClicked() {
    this.analyticsService.event(
      'profile icon clicked',
      '',
      {},
      'Top menu'
    );
  }

  onOrganizationIconClicked() {
    this.analyticsService.event(
      'organization icon clicked',
      '',
      {},
      'Top menu'
    );
  }

}
