import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { Play } from 'src/app/core/models/play.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';
import { Script } from 'src/app/core/models/script.model';

@Injectable({
  providedIn: 'root'
})
export class PlayEditAnalyticsService {

  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) { }

  setScreen(play: Play, script: Script, scriptVersion: ScriptVersion) {
    this.analyticsService.setCurrentScreen('Organise play', {
      'play-id': play.id,
      'play-isNew': play.isNew,
      'game-id': script.id,
      'game-title': scriptVersion.title,
    });
  }

  saveClicked() {
    this.analyticsService.event('Save button clicked'); 
  }

  startClicked() {
    this.analyticsService.event('Start Game button clicked');
  }
}
