import { Component, OnInit, Input } from '@angular/core';
import { Stripe } from 'stripe';

@Component({
  selector: 'organization-invoice-list',
  templateUrl: './organization-invoice-list.component.html',
  styleUrls: ['./organization-invoice-list.component.scss'],
})
export class OrganizationInvoiceListComponent implements OnInit {

  @Input()
  invoices: Stripe.Invoice[];

  constructor() { }

  ngOnInit() {}

}
