import { NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { IonContent, IonInfiniteScroll, Platform } from '@ionic/angular';
import { MaskPipe, MaskService } from 'ngx-mask';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, first, tap, debounceTime, shareReplay } from 'rxjs/operators';
import { Feed } from 'src/app/core/models/feed';
import { FeedPost } from 'src/app/core/models/feed-post';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { QuoteLayoutItem } from 'src/app/core/models/layout-item-quote';
import { Play } from 'src/app/core/models/play.model';
import { Player } from 'src/app/core/models/player.model';
import { Response } from 'src/app/core/models/response';
import { Role } from 'src/app/core/models/role.model';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { Task } from 'src/app/core/models/task.model';
import { Team } from 'src/app/core/models/team.model';
import { User } from 'src/app/core/models/user.model';
import { PlayPage } from '../play.page';
import { PlayService } from '../play.service';

import 'leader-line';
declare let LeaderLine: any;

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent implements OnInit, OnDestroy {

  @ViewChild('codeInputTarget', { read: ElementRef }) codeInputTarget: ElementRef<HTMLTextAreaElement>;

  team$: Observable<Team>;
  play$: Observable<Play>;
  players$: Observable<Player[]>;
  responses$: Observable<Response[]>;
  users$: Observable<User[]>;
  tasks$: Observable<Task[]>;
  feed$: Observable<Feed>;
  roles$: Observable<Role[]>;
  messagePosts$: Observable<FeedPost[]>;
  roleByUserID$: Observable<Map<string, Role>>;
  actualTasks$: Observable<Task[]>;
  completedTasks$: Observable<Task[]>;
  myPlayer$: Observable<Player>;

  // todoPosts$: Observable<FeedPost[]>;
  // feedItems$: Observable<FeedPost[]>;
  initReady$ = new BehaviorSubject(false);

  inviteRequired$: Observable<Play>;
  completedPosts$: Observable<Array<FeedPost | Task>>;

  users: User[] = [];
  tasks: Task[] = [];
  play: Play;
  // roleByUserID: Map<string, Role> = new Map();
  players: Player[];
  myPlayer: Player;
  roles: Role[] = [];
  feed: Feed;
  // todoPosts: FeedPost[] = [];
  posts: FeedPost[] = [];
  responses: Response[];

  @ViewChild('header') header: NgTemplateOutlet;
  @ViewChild('content') content: IonContent;
  @ViewChildren('feedPost') feedPost: QueryList<FeedPost>;


  public scrollTop = 0;
  onWindowScroll(e: CustomEvent) {
    this.scrollTop = e.detail.scrollTop;
    console.log(this.scrollTop);
  }

  @HostBinding('class.entering') entering;
  @HostBinding('class.leaving') leaving;

  scrollElement: HTMLElement;

  async ionViewWillEnter() {
    this.playPage.content.scrollToPoint(0, this.scrollTop, 100);
    this.playPage.showTabbar = true;
    this.entering = true;
    const node = Array.from((this.content as any).el.shadowRoot.childNodes).find((_node: Node) => _node.nodeName === "MAIN");
    (node as any).style.position = 'relative';
    // if (this.platform.is('cordova')) {
      // console.log('ionViewWillEnter ', this.scrollTop)
    // }

    // (this.content as any).el.shadowRoot.firstChild.style.position = 'relative';
  }

  async ionViewDidEnter() {
    this.entering = false;
    this.playPage.content.scrollToPoint(0, this.scrollTop, 100);
    // this.subscriptions.push(
    //   this.playPage.content.ionScroll.subscribe(this.onWindowScroll)
    // )

    // this.playPage.content.scrollToPoint(0, this.scrollTop, 500);
    // this.playPage.content.ionScrollEnd.subscribe(e => console.log(this.playPage.content.ionScroll, e));
    // combineLatest([this.playService.play$, this.playService.myPlayer$, this.playService.scriptVersion$])
    // .pipe(first())
    // .subscribe(async ([play, myPlayer, scriptVersion]) => {
    //   await this.analytics.setScreen(play, myPlayer, scriptVersion.scriptRef, scriptVersion);
    // })
  }

  async ionViewWillLeave() {
    this.scrollTop = this.scrollElement.scrollTop;
    this.renderer.addClass(this.element.nativeElement, 'leaving');
    // console.log('taskList - ionViewWillLeave');
    this.leaving = true;
    // const scrollElement = await this.playPage.content.getScrollElement();
    // console.dir(this.scrollElement);
    // console.log('ionViewWillLeave',this.scrollTop);
  }

  async ionViewDidLeave() {
    this.renderer.removeClass(this.element.nativeElement, 'leaving');
    // console.log('taskList - ionViewDidLeave');
    this.leaving = false;
  }

  private subscriptions: Subscription[] = [];

  constructor(
    public playService: PlayService,
    public modelProvider: ModelProvider,
    @Inject(PlayPage) public playPage: PlayPage,
    private element: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private maskPipe: MaskPipe,
    private platform: Platform
  ) { }

  async ngAfterViewInit() {
    this.scrollElement = await this.playPage.content.getScrollElement();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    // this.roleByUserID$
  }

  async ngOnInit() {
    await this.playService.afterInit$.pipe(first()).toPromise();
    this.play$ = this.playService.play$.pipe(
      tap(play => this.play = play),
      shareReplay(1)
    );;
    this.team$ = this.playService.myTeam$;
    this.players$ = this.playService.myTeamPlayers$.pipe(
      tap(players => this.players = players),
      shareReplay(1)
    );
    this.myPlayer$ = this.playService.myPlayer$.pipe(
      tap(player => this.myPlayer = player),
      shareReplay(1)
    );
    this.tasks$ = this.playService.tasks$.pipe(
      tap(tasks => this.tasks = tasks),
      shareReplay(1)
    );
    this.roles$ = this.playService.roles$.pipe(
      tap(roles => this.roles = roles),
      shareReplay(1)
    )
    this.users$ = this.players$.pipe(
      switchMap(players => combineLatest(players.map(player => player.user$))),
      tap(users => this.users = users),
      shareReplay(1)
    );
    this.responses$ = this.players$.pipe(
      debounceTime(200),
      map(players => {
        return [].concat.apply([], players.map(player => Object.values(player.responses.items)))
      }),
      tap(responses => this.responses = responses),
      shareReplay(1)
    );
    this.completedTasks$ = this.playService.myTasksDone$;

    // this.roleByUserID$ = combineLatest([this.users$, this.players$, this.roles$]).pipe(map(
    //   ([users, players, roles]) => {
    //     for (const user of users) {
    //       if (!this.roleByUserID.has(user.id)) {
    //         const player = players.find(player => player.userRef.isEqual(user.ref));
    //         this.roleByUserID.set(user.id, roles.find(role => role.ref.isEqual(player.roleRef)));
    //       }
    //     }
    //     return this.roleByUserID;
    //   }
    // ), shareReplay(1));
    // this.subscriptions.push(this.roleByUserID$.subscribe());

    this.feed$ = this.team$.pipe(
      switchMap(team => this.modelProvider.feed.findByTeam(team)),
      tap(feed => this.feed = feed),
      shareReplay(1)
    );

    this.messagePosts$ = this.feed$.pipe(switchMap(feed => feed ? feed.posts$ : of([])));

    this.actualTasks$ = this.playService.myTasksActual$;

    this.inviteRequired$ = this.play$.pipe(
      switchMap(play =>
        (play.organizeType === 'invite-game' && play.slots.available > 0) ? this.play$ : of(null)
      )
    );

    this.completedPosts$ = combineLatest([this.messagePosts$, this.completedTasks$, this.myPlayer$]).pipe(
      map(([messagePosts, completedTasks, myPlayer]) => {
        // console.log(messagePosts, completedTasks, myPlayer);
        return ([] as Array<FeedPost | Task>)
          .concat(messagePosts)
          .concat(completedTasks)
          .sort((a, b) => (this.getTime(a, myPlayer) > this.getTime(b, myPlayer) ? -1 : 1));
      }),
      tap(c => console.log('completed', c))
      ,
      // map(
      //   items => items.sort((a, b) => (a.createdAt.toMillis() > b.createdAt.toMillis() ? -1 : 1))
      // ),
      shareReplay(1)
    );

    await combineLatest([
      this.play$, this.team$, this.players$, this.users$,
      this.responses$, this.messagePosts$, this.tasks$, this.roles$,
      this.actualTasks$, this.myPlayer$
    ]).pipe(
      first(),
      tap(i => console.log(i)),
      tap(i => this.initReady$.next(true))
    ).toPromise();
    // console.log('6', new Date());
  }

  // getResponseAsFeedItem(player: Player, response: Response, feedChannelPath: string) {
  //   const feedItem = new FeedItem(feedChannelPath + '/' + response.id, {
  //     type: 'response',
  //     userRef: player.userRef,
  //     text: response.text
  //   }, {}, null);
  //   return feedItem;
  // }

  async clickPost(post: FeedPost) {
    if (['task-upcoming', 'response'].includes(post.type)) {

      const task: Task = null;// = this.tasks.get(post.referTo.id);//(post.referTo as Task);
      if (!task) { return }
      const myPlayer = await this.myPlayer$.pipe(first()).toPromise();
      const roles = await this.roles$.pipe(first()).toPromise();
      const myRole = roles.find(role => role.ref.path === myPlayer.roleRef.path);

      const isMine = task.roleRef.path === myRole.ref.path;
      if (isMine) {
        return await this.router.navigate(['../../', 'task', 'view'], { queryParams: { id: task.id }, relativeTo: this.route });
      }
    }
    if (post.type === 'invite') {
      this.playPage.playStatus.invite(this.codeInputTarget.nativeElement.value, this.codeInputTarget.nativeElement)
    }
  }

  trackByPath(index, item: FeedPost) {
    return item.ref.path;
  }

  limit = 5;
  infiniteScrollEnabled = true;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  async onInfiniteScrollScroll(event) {
    // const items = await this.feedItems$.pipe(first()).toPromise();
    // if (items.length >= this.limit) {
    //   this.limit += 5;
    // }
    event.target.complete();
  }

  getType(model: FeedPost | Task) {
    if(model instanceof Task) {
      return 'task';
    } 

    if(model instanceof FeedPost) {
      return 'post';
    }
  }

  private getTime(model: FeedPost | Task, player: Player) {
    if (model instanceof FeedPost) {
      return model.createdAt.toDate().getTime();
    }

    // if(model instanceof Task) {
    return model.getResponseTime(player).getTime();
    // }
  }

}
