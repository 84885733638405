<mat-icon (click)="onCancel()" class="modal-close-icon" slot="start">close</mat-icon>

<div class="modal-content ion-padding">
  <form #deleteForm="ngForm" (ngSubmit)="onSubmit(deleteForm)" autocomplete="off">
    <h2 class="modal-title">{{'organization/plan-details/cancel-subscription/confirm/title' | translate: {planTitle: plan.title} }}</h2>
    <p class="">{{'organization/plan-details/cancel-subscription/confirm/message' | translate: {planTitle: plan.title} }}</p>

    <div class="what-get success p-0 mb-5">
      <ul *ngIf="plan">
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>Collaborate with
            <ng-container *ngIf="plan.features.designerLimitPerWorkspace < 0">unlimited</ng-container>
            <ng-container *ngIf="plan.features.designerLimitPerWorkspace >= 0">
              {{plan.features.designerLimitPerWorkspace * plan.features.workspaceLimit}}</ng-container>
            game creators
            <ng-container *ngIf="plan.features.designerLimitPerWorkspace >= 0">
              ({{plan.features.designerLimitPerWorkspace}} creators per workspace)
            </ng-container>
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>
            <ng-container *ngIf="plan.features.workspaceLimit < 0">Unlimited</ng-container>
            <ng-container *ngIf="plan.features.workspaceLimit >= 0">{{plan.features.workspaceLimit}}</ng-container>
            Team Workspaces
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>
            <ng-container *ngIf="plan.features.scriptPerWorkspaceLimit < 0">Unlimited games</ng-container>
            <ng-container *ngIf="plan.features.scriptPerWorkspaceLimit >= 0">
              Create up to
              {{plan.features.scriptPerWorkspaceLimit * plan.features.workspaceLimit}}
              games
              ({{plan.features.scriptPerWorkspaceLimit}} games per workspace)
            </ng-container>
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>
            <ng-container *ngIf="plan.features.playerLimit < 0">Unlimited</ng-container>
            <ng-container *ngIf="plan.features.playerLimit >= 0">Invite up to {{plan.features.playerLimit}}
            </ng-container>
            learners per workspace
          </span>
        </li>
        <li>
          <ion-icon name="checkmark"></ion-icon>
          <span>Advanced game analytics </span>
        </li>
      </ul>
    </div>

    <div class="alert alert-warning mt-3" role="alert">
      <p class="my-0">{{'organization/plan-details/cancel-subscription/confirm/note' | translate: {planTitle: plan.title, endDate: (organization.plan.currentPeriodEnd?.toDate() | date: 'short')} }}</p>
    </div>
    <div class="form-group my-4">
      <label for="confirm-input" [innerHTML]="'organization/plan-details/cancel-subscription/confirm/confirm-input/label' | translate">
      </label>
      <input name="confirm" [(ngModel)]="confirm" type="text" required id="confirm-input">
    </div>
    <div class="mt-5 text-right">
      <ion-button (click)="onCancel()"
                  class="mr-3 action"
                  color="secondary"
                  shape="round">
        {{'organization/plan-details/cancel-subscription/confirm/cancel' | translate}}
      </ion-button>
      <ion-button type="submit"
                  color="warning"
                  class="action"
                  shape="round"
                  [disabled]="processing || !deleteForm.valid || (deleteForm.value.confirm !== 'CANCEL' && deleteForm.value.confirm !== 'cancel')">
        <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
        {{'organization/plan-details/cancel-subscription/confirm/ok' | translate}}</ion-button>
    </div>
  </form>
</div>