<ng-template #header>
  <ion-back-button defaultHref="playing/browse/play-list" slot="start" mode="ios"></ion-back-button>
  <ion-title><span class="rtl-text"
      [headerTitleSize]="playService.play?.title">{{playService.play?.title}}</span></ion-title>
</ng-template>
<ion-content #content [scrollEvents]="false" (ionScroll)="onWindowScroll($event)" [scrollY]="false" class="static"
  [class.entering]="entering" [class.leaving]="leaving">
  <!-- <ion-content #content [scrollEvents]="true" (ionScroll)="onWindowScroll($event)" [class.entering]="entering" [class.leaving]="leaving" [scrollY]="true">   -->
  <!-- <ion-content #content [scrollEvents]="false" (ionScroll)="onWindowScroll($event)" [scrollY]="false" class="static" [class.entering]="entering" [class.leaving]="leaving">   -->

  <ng-container *ngIf="initReady$ | async; else loading">
    <div class="feed-send-post container px-0 px-sm-2">
      <feed-send-post *ngIf="(play$ | async)?.feed?.postEnabled; else noPost" [feed]="feed"></feed-send-post>
      <ng-template #noPost>
        <div class="py-3"></div>
      </ng-template>
    </div>
    <div class="container px-sm-2 pb-5">
      <!-- ; trackBy: trackByID -->
      <ng-container *ngIf="inviteRequired$ | async">
        <post-invite></post-invite>
      </ng-container>
      <ng-container *ngFor="let actualTask of (actualTasks$ | async); trackBy: trackByID">
        <post-task-upcoming [task]="actualTask" [users]="users" [tasks]="tasks" [play]="play" [players]="players"
          [roles]="roles" [myPlayer]="myPlayer"></post-task-upcoming>
          <!-- [responses]="responses" -->
      </ng-container>
      <!-- <ng-container *ngFor="let post of (messagePosts$ | async)">
        <post-message [post]="post" [users]="users" [players]="players" [roles]="roles"></post-message>
      </ng-container> -->
      
      <!-- <h5 class="mt-5">Completed</h5> -->
      <!-- <ng-container *ngFor="let completedTask of (completedTasks$ | async); trackBy: trackByID">
        <post-task-completed class="my-5" [task]="completedTask" [users]="users" [tasks]="tasks" [players]="players"
          [roles]="roles" [myPlayer]="myPlayer" [responses]="responses"></post-task-completed>
      </ng-container> -->
      <ng-container *ngIf="(completedPosts$ | async); let completedPosts">
        <div *ngIf="completedPosts.length > 0"class="divider"><span>Completed</span></div>
        <ng-container *ngFor="let completedPost of completedPosts; trackBy: trackByID">
          <ng-container [ngSwitch]="getType(completedPost)">
            <post-task-completed *ngSwitchCase="'task'" class="my-5" [task]="completedPost" [users]="users" [tasks]="tasks" [players]="players"
            [roles]="roles" [myPlayer]="myPlayer" [responses]="responses"></post-task-completed>
            <post-message *ngSwitchCase="'post'" [post]="completedPost" [users]="users" [players]="players" [roles]="roles"></post-message>
          </ng-container>
        </ng-container>
      </ng-container>

    </div>
  </ng-container>
  <ng-template #loading>
    <div class="my-3 text-center">
      <ion-spinner></ion-spinner>
    </div>
  </ng-template>
  <ng-container *ngIf="play$ | async; let play">
    <textarea class="copy" #codeInputTarget>
      {{'playing/play-status/invite/message' | translate : { name: playService?.meUser?.displayName, game: playService?.scriptVersion?.title, code: (play.share.code | mask: 'AAA-AAA'), url: (play?.shareURL) } }}
    </textarea>
  </ng-container>
</ion-content>
<!-- <ion-infinite-scroll [disabled]="!infiniteScrollEnabled" (ionInfinite)="onInfiniteScrollScroll($event)">
  <ion-infinite-scroll-content></ion-infinite-scroll-content>
</ion-infinite-scroll> -->