import { NgModule } from '@angular/core';
import { FacilitatorOrderPipe } from './last-facilitator-order.pipe';
import { TaskStatusPipe } from '../../designer/simulator/task-status.pipe';
import { SizePipe } from './size.pipe';
import { DateShortPipe } from './date-short.pipe';
import { CommonModule } from '@angular/common';
import { WorkspaceOrderPipe } from './workspace-order.pipe';
import { AuthModule } from 'src/app/auth/auth.module';
import { TaskOrderPipe } from './task-order.pipe';
import { TeamPipe } from './team.pipe';
import { SkipFacilitatorPipe } from './skip-facilitator.pipe';
import { TeamOrderPipe } from './team-order.pipe';
import { SafePipeModule } from 'safe-pipe';
@NgModule({
	declarations: [
		FacilitatorOrderPipe,
		TaskStatusPipe,
		SizePipe,
		DateShortPipe,
		WorkspaceOrderPipe,
		TaskOrderPipe,
		TeamPipe,
		SkipFacilitatorPipe,
		TeamOrderPipe,
	],
	imports: [
		CommonModule,
		AuthModule,
		SafePipeModule
	],
	exports: [
		FacilitatorOrderPipe,
		TaskStatusPipe,
		SizePipe,
		DateShortPipe,
		WorkspaceOrderPipe,
		TaskOrderPipe,
		TeamPipe,
		SkipFacilitatorPipe,
		TeamOrderPipe,
		SafePipeModule
	]

})
export class PipesModule { }
