import { Component, OnInit } from '@angular/core';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Play } from 'src/app/core/models/play.model';
import { Script } from 'src/app/core/models/script.model';
// import { Timestamp } from '@firebase/firestore-types';
import { combineAll, concatMap, concatMapTo, first, flatMap, map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.page.html',
  styleUrls: ['./sessions.page.scss'],
})
export class SupeardminAnalyticsSessionsPage implements OnInit {

  private subscriptions: Subscription[] = [];

  plays: Play[];
  scripts: Script[];

  dataFilter$: ReplaySubject<{
    start: Date,
    end: Date,
  }> = new ReplaySubject(1);

  viewFilter$: ReplaySubject<{
    game: null | Play;
    type: null | 'Random' | 'Invite',
    status: null | 'on-going' | 'running' | 'finished'
  }> = new ReplaySubject(1);

  scrollCompleted = false;
  limit: 10;

  constructor(
    public modelProvider: ModelProvider,
    private auth: AuthService,
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async ionViewWillEnter() {
    await this.auth.authState().pipe(first()).toPromise();
    const isSuperAdmin = await this.auth.isSuperAdmin();
    if(!isSuperAdmin) {
      await this.auth.navigateToHome();
      return;
    }
    // this.users = await this.modelProvider.user.findAllBy(ref => ref.orderBy('lastLoginAt', 'desc').limit(this.usersLimit)).pipe(first()).toPromise();
    // this.users = await this.modelProvider.user.findAllBy(ref => ref.orderBy('lastLoginAt', 'desc').limit(this.usersLimit)).pipe(first()).toPromise();
  }

  async ngOnInit() {
    const dataSub$ = this.dataFilter$.pipe(
      flatMap(filter => {
        // console.log(filter);
        this.plays = null;
        this.scripts = null;

        return this.modelProvider.play.findAllBy(ref => ref
          .where('createdAt', '>=', filter.start)
          .where('createdAt', '<=', filter.end)
          .orderBy('createdAt', 'desc')
          // .limit(3)
        )
      }),
      // concatMap(plays => [plays, this.viewFilter]),
      // combineLatest(plays => [plays, this.viewFilter]),
      // combineAll(plays => [plays, this.viewFilter]),
      map(plays => {
        return this.viewFilter$.pipe(map(viewFilter => {
          return plays
            .filter(play => {
              if (viewFilter.type) {
                return viewFilter.type === play.organizeTypeForAnalytics;
              }

              return true;
            })
            .filter(play => {
              if (viewFilter.status) {
                return viewFilter.status === play.status;
              }
              return true;

            })
            .filter(play => {
              if (viewFilter.game) {
                return viewFilter.game.ref.isEqual(play.scriptRef);
              }
              return true;

            })
        }))
      }),
      flatMap(plays$ => plays$),
      tap(plays => {
        // console.log('p2',plays);
        this.plays = plays;
      }),
      tap(async plays => {
        // console.log(plays);
        const scripts: Script[] = [];
        for (const play of this.plays) {
          const script = scripts.find(_script => _script.ref.isEqual(play.scriptRef));
          if (!script) {
            scripts.push(await play.script$.pipe(first()).toPromise());
          }
          // if (!this.getScript(play)) {

          // }
        }

        this.scripts = scripts;
      }),
    )

    this.subscriptions.push(dataSub$.subscribe(plays => console.log));


    //Get today's date using the JavaScript Date object.
    // const ourDate = new Date();
    //Change it so that it is 7 days in the past.
    // const pastDate = ourDate.getDate() - 7;
    // ourDate.setDate(pastDate);

    const start = new Date();
    start.setDate(start.getDate() - 7);
    const end = new Date();
    this.dataFilter$.next({
      start: start,
      end: end
    });

    this.viewFilter$.next({
      game: null,
      type: null,
      status: null
    });


    // this.plays = (await this.modelProvider.play.findAllBy(
    //   ref => ref.where('createdAt', '>=',
    //     // Timestamp.fromDate(new Date('2021-10-01'))
    //     new Date('2021-09-26')
    //   )
    //     .orderBy('createdAt', 'desc')
    //   // .limit(3)
    // ).pipe(first()).toPromise()).filter(play => {
    //   // const playerRoleIDs = Object.keys(play.slots.roles).filter(roleID => roleID.includes('facilitator'))
    //   return (play.status === Play.STATUS_ONGOING || play.status === Play.STATUS_FINISHED)
    //     && play.type === 'public-game' && play.accessLevel === 'private'
    // });

    // const scripts = [];
    // for (const play of this.plays) {
    //   if (!this.getScript(play)) {
    //     scripts.push(await play.script$.pipe(first()).toPromise());
    //   }
    // }

    // this.scripts = scripts;
  }

  getScript(play: Play) {
    return this.scripts && this.scripts.find(script => play.scriptRef.isEqual(script.ref));
  }

  async setTypeFilter($event: CustomEvent<null | 'Random' | 'Invite'>) {
    // console.log($event);
    this.viewFilter$.next(
      { ...await this.viewFilter$.pipe(first()).toPromise(), type: ($event.detail as any).value }
    );
  }

  async setStatusFilter($event: CustomEvent<null | 'on-going' | 'running' | 'finished'>) {
    // console.log($event);
    this.viewFilter$.next(
      { ...await this.viewFilter$.pipe(first()).toPromise(), status: ($event.detail as any).value }
    );
  }

  async setGameFilter($event: CustomEvent<Script>) {
    this.viewFilter$.next(
      { ...await this.viewFilter$.pipe(first()).toPromise(), game: ($event.detail as any).value }
    );
  }

  async setStartDate($event: CustomEvent<any>) {
    // console.log('start', new Date(($event.detail as any).value));
    this.dataFilter$.next(
      { ...await this.dataFilter$.pipe(first()).toPromise(), start: new Date(($event.detail as any).value) }
    );
  }

  async setEndDate($event: CustomEvent<any>) {
    // console.log('end', new Date(($event.detail as any).value));
    this.dataFilter$.next(
      { ...await this.dataFilter$.pipe(first()).toPromise(), end: new Date(($event.detail as any).value) }
    );
  }

  public onScroll(event): void {
    if (this.limit < this.plays.length) {
      this.limit += 10;
    } else {
      event.target.complete();
    }

  }

}
