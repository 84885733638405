export const PRIVILEGES = {
  DESIGN_PAGE_VIEW: 'design-page.view',
  DESIGN_EDIT: 'design.edit',
  MONITORING_VIEW: 'monitoring.view',
  ORGANIZE_PAGE_VIEW: 'organize-page.view',
  PLAY_LIST_VIEW: 'play-list.view',
  PLAY_EDIT: 'play.edit',
  PLAY_VIEW: 'play.view',
  ROLES_EDIT: 'roles.edit',
  TASK_EDIT: 'task.edit',
  TASK_VIEW: 'task.view',
  WORKSPACE_EDIT: 'workspace.edit',
  WORKSPACE_VIEW: 'workspace.view'
};
