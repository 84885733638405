<app-header>
  <!-- <ion-title>{{'organization/title' | translate}}</ion-title> -->
</app-header>
<app-team-sidebar></app-team-sidebar>
<ion-content>
  <content-container *ngIf="!sent && plan" [back-button]="'organization/create'">
    <h1 class="mb-3">{{'organization/enterprise/title' | translate: {planTitle: plan.title} }}</h1>
    <p class="mt-0 mb-4">{{'organization/enterprise/lead' | translate}}</p>
    <div class="row">
      <form #form="ngForm" class="form col-sm-6">
        <div class="form-group">
          <label>{{'organization/enterprise/full-name/label' | translate}}</label>
          <input [(ngModel)]="name" name="name" type="text" required />
        </div>

        <div class="form-group">
          <label>{{'organization/enterprise/email/label' | translate}}</label>
          <input [(ngModel)]="email" name="email" type="email" required />
        </div>

        <div class="form-group">
          <label>{{'organization/enterprise/company/label' | translate}}</label>
          <input [(ngModel)]="company" name="company" type="text" required />
        </div>

        <div class="form-group">
          <label>{{'organization/enterprise/phone/label' | translate}}</label>
          <input [(ngModel)]="phone" name="phone" type="text" />
        </div>

        <div class="form-group">
          <label>{{'organization/enterprise/number-of-users/label' | translate}}</label>
          <select [(ngModel)]="numberOfUsers" name="numberOfUsers" class="form-control w-auto pr-5" required>
            <option value="1-50">1-50</option>
            <option value="51-250">51-250</option>
            <option value="251-500">251-500</option>
            <option value="500+">500+</option>
          </select>
        </div>


        <div class="form-group">
          <label>{{'organization/enterprise/message/label' | translate}}</label>
          <ion-textarea [(ngModel)]="message" name="message" [autoGrow]="true"></ion-textarea>
        </div>

        <div class="form-group mt-5">
          <ion-button (click)="submit()" [disabled]="!form.form.valid || processing" type="submit" color="primary"
            shape="round">
            <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
            {{'general/submit' | translate}}
          </ion-button>
        </div>
      </form>
    </div>
  </content-container>

  <content-container *ngIf="sent" [back-button]="'organization/create'">
    <h1 class="mb-5">{{'organization/enterprise-confirmation/title' | translate: {planTitle: plan.title} }}</h1>

    <form #form="ngForm" class="form">
      <div class="form-group">
        <label>{{'organization/enterprise/full-name/label' | translate}}</label>
        <p class="mt-0">{{name}}</p>
        <!-- <input [(ngModel)]="name" name="name" type="text" required /> -->
      </div>

      <div class="form-group">
        <label>{{'organization/enterprise/email/label' | translate}}</label>
        <p class="mt-0">{{email}}</p>
        <!-- <input [(ngModel)]="email" name="email" type="email" required /> -->
      </div>

      <div class="form-group">
        <label>{{'organization/enterprise/company/label' | translate}}</label>
        <p class="mt-0">{{company}}</p>
        <!-- <input [(ngModel)]="company" name="company" type="text" required /> -->
      </div>

      <div *ngIf="phone" class="form-group">
        <label>{{'organization/enterprise/phone/label' | translate}}</label>
        <p class="mt-0">{{phone}}</p>
        <!-- <input [(ngModel)]="phone" name="phone" type="text" /> -->
      </div>

      <div class="form-group">
        <label>{{'organization/enterprise/number-of-users/label' | translate}}</label>
        <p class="mt-0">{{numberOfUsers}}</p>
      </div>

      <div *ngIf="message" class="form-group">
        <label>{{'organization/enterprise/message/label' | translate}}</label>
        <pre>{{message}}</pre>
      </div>
    </form>

    <!-- <h4>
      Name
    </h4>
    <p>{{'organization/enterprise/full-name/label' | translate}}</p>
    <h4>Email</h4>
    <p>{{email}}</p>
    <h4>Phone</h4>
    <p>{{phone}}</p>
    <h4>Company</h4>
    <p>{{company}}</p>
    <h4>Number of users</h4>
    <p>{{numberOfUsers}}</p>
    <h4>Message</h4>
    <pre>{{message}}</pre> -->
    <p class="mb-3 mt-5 font-weight-bold" [innerHTML]="'organization/enterprise-confirmation/paragraph-1' | translate">
    </p>

  </content-container>
</ion-content>