import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AppLauncher } from '@ionic-native/app-launcher/ngx';


@NgModule({
  declarations: [],
  imports: [
    IonicModule,
    CommonModule
  ],
  providers: [
    AppLauncher
  ]
})
export class EmailClientsModule { }
