import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-logout-required-modal',
    templateUrl: './logout-required-modal.component.html',
    styleUrls: ['./logout-required-modal.component.scss'],
})

export class LogoutRequiredModalComponent {
    constructor(
        private modalController: ModalController
    ) { }

    async logout() {
        await this.modalController.dismiss({ action: "logout" });
    }

    async cancel() {
        await this.modalController.dismiss({ action: "cancel" });
    }
}
