import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Plan } from 'src/app/core/models/plan';

@Component({
  selector: 'organization-enterprise',
  templateUrl: './organization-enterprise.component.html',
  styleUrls: ['./organization-enterprise.component.scss'],
})
export class OrganizationEnterpriseComponent implements OnInit {

  name: string;
  email: string;
  phone: string;
  company: string;
  numberOfUsers: string = '251-500';
  message: string;

  processing = false;

  sent = false;

  public plan: Plan;

  constructor(
    private modelProvider: ModelProvider,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    const user = await this.modelProvider.user.getMe();
    this.name = user.displayName;
    this.email = user.email;
    this.sent = false;
    this.plan = await this.modelProvider.plan.findByID(
      this.route.snapshot.paramMap.get('id')
    ).pipe(first()).toPromise()
  }

  async submit() {
    this.processing = true;
    const callable = this.modelProvider.functions.httpsCallable('organizationEnterprise');
    const data = await callable({
      planPath: this.plan.ref.path,
      name: this.name,
      email: this.email,
      phone: this.phone,
      company: this.company,
      numberOfUsers: this.numberOfUsers,
      message: this.message,
    }).toPromise();

    if (data.result === 'ok') {
      this.sent = true;
    }

    this.processing = false;
  }

}
