import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import firebase from "firebase/app"
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions, PaymentMethod, SetupIntent, StripeError, PaymentIntent } from '@stripe/stripe-js';
import { months } from 'moment';
import { Price, Plan } from 'src/app/core/models/plan';
import { AlertController, ModalController } from '@ionic/angular';
import { OrganizationService } from '../organization.service';
import { ContentContainerComponent } from 'src/app/core/content-container/content-container.component';
import { Organization } from 'src/app/core/models/organization.model';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { Stripe } from 'stripe';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationAnalyticsService } from '../organization.analytics.service';

type paymentMethod = 'card' | 'invoice';
type billingType = 'monthly' | 'yearly';


@Component({
  selector: 'organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss'],
})
export class OrganizationCreateComponent implements OnInit {

  // sticky = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  @ViewChild('contentContainer', { read: ElementRef }) contentContainer: ElementRef;

  public sticky = true;
  onWindowScroll(e: CustomEvent) {
    if (this.contentContainer) {
      const scrollBottom = (this.contentContainer.nativeElement.offsetHeight + 120 + 39) - (window.innerHeight + e.detail.scrollTop);
      this.sticky = (scrollBottom > (60 + 39));
    }
  }

  public sent = false;
  public invoiceLinkURL: SafeUrl;
  public invoiceLinkHtml: SafeHtml;
  public organization: Organization;

  // @Input()
  // planID: string;

  // https://stripe.com/docs/billing/customer/tax-ids
  // https://quaderno.io/stripe-vat-invoicing/
  countries = {
    AE: {
      label: 'United Arab Emirates',
      vatLabel: 'United Arab Emirates TRN',
      vatPercent: 0,
      vatMask: '000000000000000',
      vatPrefix: '',
      vatType: 'ae_trn',
      isEU: false
    },
    AU: {
      label: 'Australia',
      vatLabel: 'Australian Business Number (AU ABN)',
      vatPercent: 0,
      vatMask: '00000000000',
      vatPrefix: '',
      vatType: 'au_abn',
      isEU: false
    },
    BR: {
      label: 'Brazil',
      vatLabel: 'Brazilian CNPJ number',
      vatPercent: 0,
      vatMask: '00.000.000/0000-00',
      vatPrefix: '',
      vatType: 'br_cnpj',
      isEU: false
    },
    CA: {
      label: 'Canada',
      vatLabel: 'Canadian BN',
      vatPercent: 0,
      vatMask: '000000000',
      vatPrefix: '',
      vatType: 'ca_bn',
      isEU: false
    },
    CH: {
      label: 'Switzerland',
      vatLabel: 'Switzerland VAT number',
      vatPercent: 0,
      vatMask: '000.000.000',
      vatPrefix: 'CHE-',
      vatSuffix: ' MWST',
      vatType: 'ch_vat',
      isEU: false
    },
    CL: {
      label: 'Chile',
      vatLabel: 'Chilean TIN',
      vatPercent: 0,
      vatMask: "00.000.000",
      vatPrefix: '',
      vatSuffix: '-K',
      vatType: 'cl_tin',
      isEU: false
    },
    HK: {
      label: 'Hong Kong',
      vatLabel: 'Hong Kong BR number',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'hk_br',
      isEU: false
    },
    ID: {
      label: 'Indonesia',
      vatLabel: 'Indonesian NPWP number',
      vatPercent: 0,
      vatMask: "00.000.000.0-000.000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'id_npwp',
      isEU: false
    },
    IN: {
      label: 'India',
      vatLabel: 'Indian GST number',
      vatPercent: 0,
      vatMask: "00SSSSS0000S0S0",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'in_gst',
      isEU: false
    },
    JP: {
      label: 'Japan',
      vatLabel: 'Japanese Corporate Number (Hōjin Bangō)',
      vatPercent: 0,
      vatMask: "0000000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'jp_cn',
      isEU: false
    },
    KR: {
      label: 'Republic of	Korea',
      vatLabel: 'Korean BRN',
      vatPercent: 0,
      vatMask: "000-00-00000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'kr_brn',
      isEU: false
    },
    LI: {
      label: 'Liechtenstein',
      vatLabel: 'Liechtensteinian UID number',
      vatPercent: 0,
      vatMask: "000000000",
      vatPrefix: 'CHE',
      vatSuffix: '',
      vatType: 'li_uid',
      isEU: false
    },
    MX: {
      label: 'Mexico',
      vatLabel: 'Mexican RFC number',
      vatPercent: 0,
      vatMask: "SSS000000SS0",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'mx_rfc',
      isEU: false
    },
    MY: {
      label: 'Malaysia',
      vatLabel: 'Malaysian FRP number',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'my_frp',
      isEU: false
    },
    NO: {
      label: 'Norway',
      vatLabel: 'Norwegian VAT number',
      vatPercent: 0,
      vatMask: "000000000",
      vatPrefix: '',
      vatSuffix: 'MVA',
      vatType: 'no_vat',
      isEU: false
    },
    NZ: {
      label: 'New Zealand',
      vatLabel: 'New Zealand GST number',
      vatPercent: 0,
      vatMask: "000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'nz_gst',
      isEU: false
    },
    RU: {
      label: 'Russian Federation',
      vatLabel: 'Russian INN',
      vatPercent: 0,
      vatMask: "0000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'ru_inn',
      isEU: false
    },
    SA: {
      label: 'Saudi Arabia',
      vatLabel: 'Saudi Arabia VAT',
      vatPercent: 0,
      vatMask: "000000000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'sa_vat',
      isEU: false
    },
    SG: {
      label: 'Saudi Arabia',
      vatLabel: 'Singaporean GST',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: 'M',
      vatSuffix: 'X',
      vatType: 'sg_gst',
      isEU: false
    },
    TH: {
      label: 'Thailand',
      vatLabel: 'Thai VAT',
      vatPercent: 0,
      vatMask: "0000000000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'th_vat',
      isEU: false
    },
    TW: {
      label: 'Taiwan',
      vatLabel: 'Taiwanese VAT',
      vatPercent: 0,
      vatMask: "00000000",
      vatPrefix: '',
      vatSuffix: '',
      vatType: 'tw_vat',
      isEU: false
    },
    US: {
      label: 'United States',
      vatLabel: 'United States EIN',
      vatPercent: 0,
      vatMask: '00-00000000',
      vatPrefix: '',
      vatType: 'us_ein',
      isEU: false,
    },
    ZA: {
      label: 'South Africa',
      vatLabel: 'South African VAT number',
      vatPercent: 0,
      vatMask: '000000000',
      vatPrefix: '4',
      vatType: 'za_vat',
      isEU: false,
    },


    AT: {
      label: 'Austria',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '00000000',
      vatPrefix: 'ATU',
      vatType: 'eu_vat',
      isEU: true
    },
    BE: {
      label: 'Belgium',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '000000000',
      vatPrefix: 'BE',
      vatType: 'eu_vat',
      isEU: true
    },
    BG: {
      label: 'Bulgaria',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '000000000',
      vatPrefix: 'BG',
      vatType: 'eu_vat',
      isEU: true
    },
    HR: {
      label: 'Croatia',
      vatLabel: 'European VAT number',
      vatPercent: 25,
      vatMask: '00000000000',
      vatPrefix: 'HR',
      vatType: 'eu_vat',
      isEU: true
    },
    CY: {
      label: 'Cyprus',
      vatLabel: 'European VAT number',
      vatPercent: 19,
      vatMask: '00000000A',
      vatPrefix: 'CY',
      vatType: 'eu_vat',
      isEU: true
    },
    CZ: {
      label: 'Czech Republic',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '0000000000',
      vatPrefix: 'CZ',
      vatType: 'eu_vat',
      isEU: true
    },
    DK: {
      label: 'Denmark',
      vatLabel: 'European VAT number',
      vatPercent: 25,
      vatMask: '00000000',
      vatPrefix: 'DK',
      vatType: 'eu_vat',
      isEU: true
    },
    EE: {
      label: 'Estonia',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '000000000',
      vatPrefix: 'EE',
      vatType: 'eu_vat',
      isEU: true
    },
    FI: {
      label: 'Finland',
      vatLabel: 'European VAT number',
      vatPercent: 24,
      vatMask: '00000000',
      vatPrefix: 'FI',
      vatType: 'eu_vat',
      isEU: true
    },
    FR: {
      label: 'France',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: 'AA000000000',
      vatPrefix: 'FR',
      vatType: 'eu_vat',
      isEU: true
    },
    DE: {
      label: 'Germany',
      vatLabel: 'European VAT number',
      vatPercent: 16,
      vatMask: '000000000',
      vatPrefix: 'DE',
      vatType: 'eu_vat',
      isEU: true
    },
    GR: {
      label: 'Greece',
      vatLabel: 'European VAT number',
      vatPercent: 24,
      vatMask: '000000000',
      vatPrefix: 'EL',
      vatType: 'eu_vat',
      isEU: true
    },
    HU: {
      label: 'Hungary',
      vatLabel: 'European VAT number',
      vatPercent: 27,
      vatMask: '00000000',
      vatPrefix: 'HU',
      vatType: 'eu_vat',
      isEU: true
    },
    IT: {
      label: 'Italy',
      vatLabel: 'European VAT number',
      vatPercent: 22,
      vatMask: '00000000000',
      vatPrefix: 'IT',
      vatType: 'eu_vat',
      isEU: true
    },
    JO: {
      label: 'Jordan',
      vatLabel: 'Tax Identification Number (TIN)',
      vatPercent: 0,
      vatMask: '0000000',
      vatPrefix: '',
      vatType: false,
      isEU: false
    },
    LV: {
      label: 'Latvia',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '00000000000',
      vatPrefix: 'LV',
      vatType: 'eu_vat',
      isEU: true
    },
    LT: {
      label: 'Lithuania',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '000000000000',
      vatPrefix: 'LT',
      vatType: 'eu_vat',
      isEU: true
    },
    LU: {
      label: 'Luxembourg',
      vatLabel: 'European VAT number',
      vatPercent: 17,
      vatMask: '00000000',
      vatPrefix: 'LU',
      vatType: 'eu_vat',
      isEU: true
    },
    MT: {
      label: 'Malta',
      vatLabel: 'European VAT number',
      vatPercent: 18,
      vatMask: '00000000',
      vatPrefix: 'MT',
      vatType: 'eu_vat',
      isEU: true
    },
    NL: {
      label: 'Netherlands',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: '000000000A00',
      vatPrefix: 'NL',
      vatType: 'eu_vat',
      isEU: true
    },
    PL: {
      label: 'Poland',
      vatLabel: 'European VAT number',
      vatPercent: 23,
      vatMask: '0000000000',
      vatPrefix: 'PL',
      vatType: 'eu_vat',
      isEU: true
    },
    PT: {
      label: 'Portugal',
      vatLabel: 'European VAT number',
      vatPercent: 23,
      vatMask: '000000000',
      vatPrefix: 'PT',
      vatType: 'eu_vat',
      isEU: true
    },
    RO: {
      label: 'Romania',
      vatLabel: 'European VAT number',
      vatPercent: 19,
      vatMask: '0000000000',
      vatPrefix: 'RO',
      vatType: 'eu_vat',
      isEU: true
    },
    SK: {
      label: 'Slovakia',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '0000000000',
      vatPrefix: 'SK',
      vatType: 'eu_vat',
      isEU: true
    },
    SI: {
      label: 'Slovenia',
      vatLabel: 'European VAT number',
      vatPercent: 22,
      vatMask: '00000000',
      vatPrefix: 'SI',
      vatType: 'eu_vat',
      isEU: true
    },
    ES: {
      label: 'Spain',
      vatLabel: 'European VAT number',
      vatPercent: 21,
      vatMask: 'A00000000A',
      vatPrefix: 'ES',
      vatType: 'eu_vat',
      isEU: true
    },
    SE: {
      label: 'Sweden',
      vatLabel: 'European VAT number',
      vatPercent: 25,
      vatMask: '000000000000',
      vatPrefix: 'SE',
      vatType: 'eu_vat',
      isEU: true
    },
    GB: {
      label: 'United Kingdom',
      vatLabel: 'European VAT number',
      vatPercent: 20,
      vatMask: '000000000',
      vatPrefix: 'GB',
      vatType: 'eu_vat',
      isEU: true
    },
  }

  plan: Plan;

  currency = 'gbp';

  monthly: Price;
  yearly: Price;

  price: Price;

  organizationName: string;
  billingType: billingType;
  paymentMethod: paymentMethod = 'card';

  billingName: string;
  city: string;
  address: string;
  state: string;
  zip: string;
  country: string = 'GB';
  taxID: string;

  total = 0;
  vat = 0;
  vatPercent = 0;
  sourceCountry = 'GB';

  terms = false;
  processing = false;


  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    },
    hidePostalCode: true
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  constructor(
    private modelProvider: ModelProvider,
    private router: Router,
    private route: ActivatedRoute,
    private stripeService: StripeService,
    private modalController: ModalController,
    private organizationService: OrganizationService,
    private domSanitizer: DomSanitizer,
    public alertController: AlertController,
    private translate: TranslateService,
    private organizationAnalytics: OrganizationAnalyticsService
  ) { }

  async ngOnInit() {
    const planID = this.route.snapshot.paramMap.get('id');
    this.plan = await this.modelProvider.plan.findByID(planID).pipe(first()).toPromise();

    this.country = 'GB';
    this.yearly = this.plan.pricing['yearly'];
    this.monthly = this.plan.pricing['monthly'];

    this.billingType = (this.yearly) ? 'yearly' : 'monthly';
    this.price = this[this.billingType];
    this.calculateTotal();
  }

  ngAfterViewInit(): void {
    this.organizationAnalytics.openPage('Purchase');
  }

  calculateTotal() {
    const netPrice = this.price.currencies[this.currency].amount;
    const countryItem = this.countries[this.country];

    this.vatPercent = countryItem.vatPercent;
    this.vat = netPrice * (1 + this.vatPercent / 100) - netPrice;

    if(countryItem.vatType === false) {
      this.taxID = '';
    }

    if (this.taxID && this.taxID.length > 0 && countryItem.isEU && this.sourceCountry !== this.country) {
      this.vatPercent = 0;
      this.vat = 0;
    }

    this.total = netPrice + this.vat;
  }

  onSelectBillingType(event: CustomEvent<{ value: billingType }>) {
    this.billingType = event.detail.value;
    this.price = this[this.billingType];
    this.calculateTotal();
  }

  onSelectPaymentMethod(event: CustomEvent<{ value: paymentMethod }>) {
    this.paymentMethod = event.detail.value;

    if (this.paymentMethod === 'invoice' && this.yearly) {
      this.price = this.yearly;
      this.billingType = 'yearly';
    }
    this.calculateTotal();
  }

  async save() {
    try {
      this.processing = true;

      let stripePaymentMethodID: string;
      if (this.paymentMethod === 'card') {
        stripePaymentMethodID = await this.createStripePayment();
        // stripePaymentMethodID = paymentMethodResult.payment_method;
      }

      // if (!this.countries[this.country].isEU) {
      //   this.taxID = '';
      // }

      const createData = await this.modelProvider.functions.httpsCallable('organizationCreate')({
        planPath: this.plan.ref.path,
        organizationName: this.organizationName,
        billingType: this.billingType,
        paymentMethod: this.paymentMethod,
        stripePaymentMethod: stripePaymentMethodID,
        stripePriceID: this.plan.pricing[this.billingType].currencies[this.currency].stripeID,
        billingDetails: {
          address: {
            city: this.city,
            postal_code: this.zip,
            line1: this.address,
            state: this.state,
            country: this.country
          },
          name: this.billingName
        },
        taxID: (this.taxID && this.taxID.length > 0)
          ? this.countries[this.country].vatPrefix + this.taxID + ((this.countries[this.country].vatSuffix) ? this.countries[this.country].vatSuffix : '')
          : null,
        // ...additionalData
      }).pipe(first()).toPromise();

      if (createData.error) {
        throw new Error(createData.error);
      }

      // if (createData.error && createData.error === 'stripe-payment-intent-error') {
      //   if (['requires_confirmation', 'requires_action'].includes(createData.paymentIntentStatus)) {
      //     await this.confirmStripePayment(createData.paymentIntentClientSecret, stripePaymentMethodID);
      //   }
      //   return;
      // }

      if (createData && createData.result === true) {

        const invoice = ((createData.stripeInvoice as Stripe.Invoice));
        const paymentIntent = invoice.payment_intent as Stripe.PaymentIntent;
        if (paymentIntent && paymentIntent.status === 'requires_action') {
          await this.confirmStripePayment(paymentIntent.client_secret, stripePaymentMethodID);
        }

        const organizationID = firebase.firestore().doc(createData.organizationPath).id;
        const organization = await this.modelProvider.organization.findByRef('organizations/' + organizationID).pipe(first()).toPromise();
        this.organization = organization;
        this.organizationService.setOrganization(organization);

        this.invoiceLinkURL = this.domSanitizer.bypassSecurityTrustUrl(createData.invoiceLink);
        this.invoiceLinkHtml = this.domSanitizer.bypassSecurityTrustHtml(createData.invoiceLink);

        this.sent = true;
        this.organizationAnalytics.openPage('Purchase confirmation');
      }
    } catch (error) {
      console.error(error);
      await this.showErrorMessage(error.message || error);

    } finally {
      this.processing = false;
    }
  }

  async confirmStripePayment(stripePaymentIntent_ClientSecret: string, stripePaymentMethodID: string) {
    const isPaymentIntent = stripePaymentIntent_ClientSecret.startsWith('pi_');

    let result: { setupIntent?: SetupIntent; error?: StripeError; } | { paymentIntent?: PaymentIntent; error?: StripeError; }

    if (isPaymentIntent) {
      result = await this.stripeService.confirmCardPayment(
        stripePaymentIntent_ClientSecret,
        {
          payment_method: stripePaymentMethodID
        }
      ).pipe(first()).toPromise();
    } else {
      result = await this.stripeService.confirmCardSetup(
        stripePaymentIntent_ClientSecret,
        {
          payment_method: stripePaymentMethodID
        }
      ).pipe(first()).toPromise();
    }

    if (result.error) {
      console.error(result.error);
      if (result.error.code === 'payment_intent_authentication_failure') {
        return await this.confirmStripePayment(stripePaymentIntent_ClientSecret, stripePaymentMethodID);
      }
      throw new Error(result.error.message);
    }

    return result;
  }

  async createStripePayment() {
    // const result = await this.stripeService.confirmCardPayment(
    //   'pi_1HWMbwCnWdWlNRlbUEvdbEYn_secret_POcIje9ao7wa9fyv4PH2DQrpQ',
    //   // {
    //   //   payment_method: {
    //   //     card: this.card.element,
    //   //     billing_details: {
    //   //       name: 'Jenny Rosen',
    //   //     },
    //   //   }
    //   // }
    // ).pipe(first()).toPromise();

    // console.log(result);

    // throw new Error('koa');

    // return;

    const createPaymentMethodResult = await this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: {
        address: {
          city: this.city,
          postal_code: this.zip,
          line1: this.address,
          state: this.state,
          country: this.country
        },
        name: this.billingName
      }
    }).pipe(first()).toPromise();

    if (createPaymentMethodResult.error) {
      throw new Error(createPaymentMethodResult.error.message);
    }

    // console.log('createPaymentMethodResult.paymentMethod.id', createPaymentMethodResult.paymentMethod.id)

    // const intentResponse: {intent: Stripe.SetupIntent, customer: Stripe.Customer} = await this.modelProvider.functions.httpsCallable('organizationCreateIntent')({
    //   stripePaymentMethodID: createPaymentMethodResult.paymentMethod.id
    // }).pipe(first()).toPromise();

    // console.log('intent', intentResponse)

    // await this.confirmStripePayment(intentResponse.intent.client_secret, createPaymentMethodResult.paymentMethod.id)

    return createPaymentMethodResult.paymentMethod.id;
  }

  async goToOrganizationAccount() {
    await this.router.navigateByUrl('organization/' + this.organization.id);
  }

  async showErrorMessage(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant('error-alert/title'),
      message: message,
      buttons: [
        {
          text: this.translate.instant('general/ok'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }
}