import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrganizationMember } from 'src/app/core/models/organization-members.model';

@Component({
  selector: 'app-organization-member-delete-modal',
  templateUrl: './organization-member-delete-modal.component.html',
  styleUrls: ['./organization-member-delete-modal.component.scss'],
})
export class OrganizationMemberDeleteModalComponent implements OnInit {

  @Input()
  member: OrganizationMember;

  isDeleteFromWorkspaces = true;

  processing: string;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  public async delete() {
    this.processing = 'delete';
    await this.member.deactivate(this.isDeleteFromWorkspaces);
    await this.modalController.dismiss();
    this.processing = null;
  }

  public async cancel(source: string) {
    await this.modalController.dismiss();
  }


}
