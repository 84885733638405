<context-menu-icon>
  <context-item [label]="'script-details/action/play' | translate" (click)="openPlayModal()"></context-item>
  <context-item class="divider" label="{{'script-list/menu/view-details' | translate}}" (click)="openDetails($event)"></context-item>
  <context-item label="{{'script-details/action/edit/title' | translate}}" (click)="editDesign($event)"></context-item>
  <context-item class="divider" label="{{'script-details/action/make-a-copy/title' | translate}}">
    <context-group>
      <ng-container *ngFor="let workspace of workspaces; let i = index">
        <context-item *ngIf="workspaces[i - 1]?.ownerOrganizationRef?.path !== workspace?.ownerOrganizationRef?.path"
        [label]="(workspace.ownerOrganization$ | async)?.title" class="divider" [disabled]="true"
        ></context-item>

        <context-item *ngIf="workspace?.myMember?.level !== 'player'" [label]="workspace.title" [hideOnClick]="false" #duplicateItem
        (click)="copyTo(workspace, $event)"></context-item>
      </ng-container>
    </context-group>
  </context-item>
  <context-item label="{{'script-details/action/edit/move' | translate}}">
    <context-group>
      <ng-container *ngFor="let workspace of workspaces; let i = index">
        <ng-container *ngIf="workspace.ownerOrganizationRef?.path === (organizationService.activeOrganization$ | async).ref.path">
          <context-item *ngIf="workspaces[i - 1]?.ownerOrganizationRef?.path !== workspace?.ownerOrganizationRef?.path"
          [label]="(workspace.ownerOrganization$ | async)?.title" class="divider" [disabled]="true"
          ></context-item>
          <context-item *ngIf="workspace.id !== (workspaceService.activatedWorkspace | async)?.id && workspace?.myMember?.level !== 'player'"
            [label]="workspace.title" [hideOnClick]="false" #duplicateItem (click)="moveTo(workspace, $event)">
          </context-item>
        </ng-container>
      </ng-container>
    </context-group>
  </context-item>
  <!-- <context-item [disabled]="!script.isPublished" class="divider" [label]="'script-list/menu/organize' | translate" (click)="openOrganize($event)"></context-item>
  <context-item class="divider" label="{{'script-list/menu/publish' | translate}}" (click)="openPublish($event)"></context-item>
  <context-item label="{{'script-list/menu/inviteURL' | translate}}" (click)="copyInviteURL($event)"
    [disabled]="!script.isPublished"></context-item> -->
  <context-item class="divider highlighted" label="{{'script-details/action/edit/delete' | translate}}" (click)="delete($event)">
  </context-item>
</context-menu-icon>
