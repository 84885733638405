export const demoAiJSON = {
    "game": {
        "title": "Preventing and reporting sexual harassment: an interactive learning experience",
        "description": "A step-by-step game to increase awareness on sexual harassment in the workplace through practical examples of what is not acceptable and develop positive behaviours to intervene as a bystander, and to learn how to practise a reporting conversation through a virtual role-play exercise."
    },
    "roles": [
        {
            "id": "Abel",
            "title": "International Project Manager"
        },
        {
            "id": "Sara",
            "title": "Project Officer"
        },
        {
            "id": "Sam",
            "title": "International project Officer"
        }
    ],
    "tasks": [
        {
            "id": "task1",
            "roleID": "Abel",
            "title": "Defining Sexual Harassment",
            "text": "What is the definition of sexual harassment in the workplace?",
            "inputType": "input-choice",
            "inputInstruction": "Choose the correct answer",
            "inputChoiceOptions": [
                "a) Physical touching such as unwelcome touching",
                "b) Verbal communication such as inappropriate jokes",
                "c) Displaying sexually suggestive objects, pictures or materials",
                "d) All of the above"
            ],
            "dependency": null,
            "point": 1,
            "terminate": false
        },
        {
            "id": "task2",
            "roleID": "Abel",
            "title": "Defining Examples of Sexual Harassment",
            "text": "Identify four examples of sexual harassment in the workplace and explain why each example is not acceptable.",
            "inputType": "input-text",
            "inputInstruction": "Write your response here.",
            "inputChoiceOptions": null,
            "dependency": {
                "condition": "AND",
                "rules": [
                    {
                        "roleID": "Abel",
                        "taskID": "task1",
                        "value": 3
                    }
                ]
            },
            "point": 2,
            "terminate": false
        },
        {
            "id": "task3",
            "roleID": "Sara",
            "title": "Intervene as a Bystander",
            "text": "Learn some strategies to intervene as a bystander, and reflect on which strategies could apply to the scenario.",
            "inputType": "input-text",
            "inputInstruction": "Write your response here.",
            "inputChoiceOptions": null,
            "dependency": {
                "condition": "AND",
                "rules": [
                    {
                        "roleID": "Abel",
                        "taskID": "task2",
                        "value": "completed"
                    }
                ]
            },
            "point": 2,
            "terminate": false
        },
        {
            "id": "task4",
            "roleID": "Sam",
            "title": "A Bad Example of Reporting",
            "text": "Read the bad example of reporting between a bystander and a manager, reflect on what went wrong and identify the correct response as a manager.",
            "inputType": "input-choice",
            "inputInstruction": "Choose the correct response as a manager.",
            "inputChoiceOptions": [
                "a) Thank you for sharing this with me, I will investigate this matter and make sure appropriate actions are taken.",
                "b) Are you sure this is what happened? Maybe it was just a misunderstanding.",
                "c) Why did you wait so long to bring this up? It's your fault for not coming forward earlier.",
                "d) I'm really busy now, can we talk about this later?"
            ],
            "dependency": null,
            "point": 1,
            "terminate": false
        },
        {
            "id": "task5",
            "roleID": "Sara",
            "title": "Practice and Record the Reporting Conversation",
            "text": "Practice the reporting conversation with the bystander as a manager and record the entire conversation to self-assess and receive feedback.",
            "inputType": "input-video",
            "inputInstruction": "Record your response here",
            "inputChoiceOptions": null,
            "dependency": {
                "condition": "OR",
                "rules": [
                    {
                        "roleID": "Sam",
                        "taskID": "task4",
                        "value": 1
                    },
                    {
                        "roleID": "Sara",
                        "taskID": "task5",
                        "value": "completed"
                    }
                ]
            },
            "point": 3,
            "terminate": true
        }
    ]
}