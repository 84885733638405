import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';
import { first } from 'rxjs/operators';
import { Role } from 'src/app/core/models/role.model';
import { Script } from 'src/app/core/models/script.model';
import { ScriptVersion } from 'src/app/core/models/script-version.model';

@Injectable()
export class AppUpdateAnalyticsService {
  constructor(
    private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
    this.analyticsService.setCurrentScreen('App update popup');
  }

  onUpdateButtonClicked(): void {
    this.analyticsService.event(
      ['update button clicked'],
      'Ok'
    );
  }

}
