<ion-header>
  <ion-toolbar>
    <!-- <ion-buttons slot="start">
      <ion-button (click)="logout()"><ion-icon name="arrow-back-outline"></ion-icon></ion-button>
    </ion-buttons> -->
    <ion-buttons slot="start">
      <img src="assets/layout/header/logo_horizontal_black.png" class="logo pl-3 d-block" />
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button *ngIf="auth.user$ | async; let user" class="profile" (click)="showProfile()"
        [style.background-image]="'url(' + user?.photoURL + ')'">
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="row align-items-center h-100">
    <div class="col">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="segment code">
            <img src="/assets/pages/designer-onboard/designer-onboard-1.jpg" alt="" class="mb-2"/>
            <form #form="ngForm" class="form" (ngSubmit)="joinWithCode($event)">
              <div class="top row align-items-center px-4">
                <div class="col-auto px-0">
                  <mat-icon>groups</mat-icon>
                </div>
                <div class="col pl-3">
                  <h5>{{'organization/player/not-found/join/title' | translate}}</h5>
                </div>
              </div>
              <div>
                <p>{{'organization/player/not-found/join/lead' | translate}}</p>
                <input type="text" class="form-control2 w-50 text-center text-uppercase d-block mx-auto"
                  #joinCodeRef="ngModel" name="joinCode" [(ngModel)]="joinCode"
                  [placeholder]="'join-code/input/placeholder' | translate" required mask="AAA-AAA"
                  autocapitalize="characters" autocomplete="off" />
                <ion-text *ngIf="joinCodeRef.getError('unverified-code')" color="warning"
                  class="d-block text-center">{{'join-code/error/no-play-found' | translate}}</ion-text>
              </div>
              <div class="call-to-action mt-4">
                <ion-button [disabled]="form?.invalid || processing" type="submit" expand="block" color="warning"
                  shape="round">
                  <ion-spinner *ngIf="processing === 'code'"></ion-spinner>
                  {{'organization/player/not-found/join/button' | translate}}
                </ion-button>
              </div>
            </form>
          </div>
        </div>

        <div class="col-12 col-sm-6">
          <div class="segment demo">
            <img src="/assets/pages/designer-onboard/designer-onboard-3.jpg" alt="" class="mb-2"/>
            <div class="top row align-items-center px-4">
              <div class="col-auto px-0">
                <mat-icon>play_arrow</mat-icon>
              </div>
              <div class="col pl-3">
                <h5>{{'organization/player/not-found/demo/title' | translate}}</h5>
              </div>
            </div>
            <div>
              <p>{{'organization/player/not-found/demo/lead' | translate}}</p>
            </div>
            <div class="call-to-action mt-4">
              <ion-button (click)="joinGamoteca()" [disabled]="processing" type="submit" expand="block" color="primary"
                shape="round">
                <ion-spinner *ngIf="processing === 'gamoteca'"></ion-spinner>
                {{'organization/player/not-found/demo/button' | translate}}
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ion-content>