import { Injectable, SkipSelf } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Play } from '../play.model';
import { ModelProvider } from '../general/model.provider';
import { first } from 'rxjs/operators';
import { WorkspaceService } from '../../workspace/workspace.service';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class PlayResolver implements Resolve<Play> {
    constructor(
        private modelProvider: ModelProvider,
        private workspaceService: WorkspaceService,
        private router: Router,
    ) { }

    private async setWorkspace(play$: Observable<Play>) {
        const _play = await play$.pipe(first()).toPromise();
        if(_play) {
            this.workspaceService.setActiveWorkspaceByRef(_play.workspaceRef);
        }
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Play> | Observable<never> {
        const id = route.paramMap.get('playID');

        return Observable.create((observer) => {
            const play$ = this.modelProvider.play.findByRef('plays/' + id);
            let play: Play = null;

            this.setWorkspace(play$);
            
            play$.subscribe(_play => {
                if (!play) {
                    play = _play;
                } else {
                    play.setFields(_play.toData());
                }
                observer.next(play);
                observer.complete();
            }, error => {
                console.error(error);
                this.router.navigateByUrl(environment.homeURL);
            });

            route.data = {
                ...route.data,
                play$: play$
            };
        });
    }
}
