import { Pipe, PipeTransform } from '@angular/core';
import { Player } from '../models/player.model';
import { DocumentReference } from '@angular/fire/firestore';

@Pipe({
  name: 'team',
})
export class TeamPipe implements PipeTransform {

  transform(players: Array<Player>, teamRef: DocumentReference, ...args: any[]): Array<Player> {
    // if( Array.isArray(player) ) {
        return players.filter(player => teamRef.isEqual(player.teamRef));
    // }
  }
}