import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SessionsPageRoutingModule } from './sessions-routing.module';

import { SupeardminAnalyticsSessionsPage } from './sessions.page';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { ModelsModule } from 'src/app/core/models/models.module';
import { SuperadminAnalyticsSessionItemComponent } from './session-item/session-item.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AuthModule } from 'src/app/auth/auth.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SessionsPageRoutingModule,
    AppComponentsModule,
    ModelsModule,
    TranslateModule.forChild(),
    MatIconModule,
    PipesModule,
    AuthModule,
    // AngularFirestoreModule.
  ],
  declarations: [
    SupeardminAnalyticsSessionsPage,
    SuperadminAnalyticsSessionItemComponent
  ]
})
export class SuperadminAnalyticsSessionsPageModule {}
