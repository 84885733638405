import { LayoutItem } from './task-layout-item';
import { Task } from './task.model';

export class Video360LayoutItem extends LayoutItem {
    public type: string = LayoutItem.TYPE_VIDEO_360;
    public order = 40;

    public properties: {
        sizes?: {
            original: {
                ref?: string;
                url: string;
            }
        },
    };

    public get url() {
        if (this.properties && this.properties.sizes && this.properties.sizes.original && this.properties.sizes.original.url) {
            return this.properties.sizes.original.url;
        }
        return undefined;
    }

    public setVideo(url: string, ref: string = null) {
        if (!this.properties) { this.properties = {}; }
        // if (this.properties && this.properties.sizes && this.properties.sizes.original && this.properties.sizes.original.url) {
        this.properties.sizes = {
            original: {
                url: url,
                ref: ref,
            }
        };
        // }
    }

    public uploadFile(category: string, file: File, metadata?: any) {
        const path = (this.documentInstance as Task).getDocument().ref.path + '/' + this.type + '/' + file.name;
        const reference = this.modelProvider.fsStorage.ref(path);
        return { ref: reference, uploadTask: reference.put(file, metadata) };
    }
}
