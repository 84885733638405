import { FirestoreField } from '../firebase/firestore-field';
import { Timestamp } from '@firebase/firestore-types';
import { DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { Player } from './player.model';
import { Play } from './play.model';
import { Task } from './task.model';
import { Subject, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { AnalyticsProvider } from '../analytics/analytics';
import { FirestoreModel } from '../firebase/firestore-model';
import { FeedPost, QuoteInfo } from './feed-post';

export class Response extends FirestoreField {
    id: string;
    // taskID: string;
    // taskRef: DocumentReference;
    text?: string;
    // videoRef?: string;  // Legacy
    // videoURL?: string;  // Legacy
    // photoRef?: string;  // Legacy
    // photoURL?: string;  // Legacy
    photo?: {
        sm?: {
            ref: string,
            url: string,
            mimeType: string
        },
        md?: {
            ref: string,
            url: string,
            mimeType: string
        },
        lg?: {
            ref: string,
            url: string,
            mimeType: string
        },
        xlg?: {
            ref: string,
            url: string,
            mimeType: string
        },
        original?: {
            ref: string,
            url: string,
            mimeType: string
        }
    };
    video?: {
        original?: {
            ref: string,
            url: string,
            mimeType: string,
        },
        transcoded?: {
            ref: string,
            url: string,
            mimeType: string,
        },
    };
    choice?: string;
    done: boolean;
    point: number;
    createdAt: Timestamp;

    public rules() {
        return {
            id: {},
            text: {},
            video: {},
            photo: {},
            choice: {},
            done: {},
            point: {},
            createdAt: {},
        };
    }

    // public toData() {
    //     const data = super.toData();
    //     delete data['taskID'];
    //     delete data['taskRef'];
    //     return data;
    // }

    public get photoURL() {
        if (this.modelProvider.platform.is('desktop') && this.photo && this.photo.md && this.photo.md.url) {
            return this.photo.md.url;
        }

        if (!this.modelProvider.platform.is('desktop') && this.photo && this.photo.sm && this.photo.sm.url) {
            return this.photo.sm.url;
        }

        if (this.photo && this.photo.original && this.photo.original.url) {
            return this.photo.original.url;
        }

        return undefined;
    }

    public get videoURL() {
        if (this.video && this.video.transcoded && this.video.transcoded.url) {
            return this.video.transcoded.url;
        }

        if (this.video && this.video.original && this.video.original.url) {
            return this.video.original.url;
        }

        return undefined;
    }

    // tslint:disable-next-line: member-ordering
    private _filesToUpload: Array<{
        type: 'photo' | 'video',
        file: File,
        transcoded?: boolean
    }> = [];

    public get mediaType() {
        if(this.photoURL) { return 'image'; }
        if(this.videoURL) { return 'video'; }
        if(this.choice) { return 'choice'; }
        if(this.text) { return 'text'; }
        return null;
    }

    public setPhoto(file: File) {
        const existingPhotoIndex = this._filesToUpload.findIndex(({ type }) => type === 'photo');
        this._filesToUpload.splice(existingPhotoIndex, 1);
        if (file) {

            (file as any).extraMetadata = {
                fsDocPath: (this.documentInstance as Player).ref.path,
                fsDocField: 'responses.' + this.id + '.photo',
                maxSizes: JSON.stringify(['sm', 'md', 'lg', 'xlg']),
                isResize: 'true'
            };

            this.photo = {};
            this.photo.original = {
                ref: '',
                url: URL.createObjectURL(file),
                mimeType: file.type,
            };
            this._filesToUpload.push({
                type: 'photo',
                file: file
            });
        }
    }

    public setVideo(file: File) {
        const existingVideoIndex = this._filesToUpload.findIndex(({ type }) => type === 'video');
        this._filesToUpload.splice(existingVideoIndex, 1);

        if (file) {
            const size = this.isTranscoded(file) ? 'transcoded' : 'original';
            this.video = {};
            this.video[size] = {
                ref: '',
                url: URL.createObjectURL(file),
                mimeType: file.type,
            };
            this._filesToUpload.push({
                type: 'video',
                file: file,
                transcoded: this.isTranscoded(file) ? true : false
            });
        }
    }

    public removeVideo() {
        const videoIndex = this._filesToUpload.findIndex(file => file.type === 'video');
        this._filesToUpload.splice(videoIndex, 1);
        delete this['video'];
        // this.video = undefined;
    }

    public getVideoCacheKey(playerID: string) {
        return playerID + '-' + this.id + '-video-file-path'
    }

    public async getLocalVideoFile(playerID: string) {
        return await this.modelProvider.storage.get(this.getVideoCacheKey(playerID));
    }

    private async deleteLocal(playerID: string) {
        await this.modelProvider.storage.remove(this.getVideoCacheKey(playerID));
        // await this.modelProvider.storage.remove(play.id + '-' + this.id + '-meta');
    }

    private async storeLocal(fileItem: {
        type: "photo" | "video";
        file: File;
        transcoded?: boolean;
    }, playerID: string
    ) {
        if ((fileItem.file as any).filePath && this.modelProvider.platform.is('cordova')) {
            await this.modelProvider.storage.set(
                this.getVideoCacheKey(playerID),
                (fileItem.file as any).filePath
            );
        }
        // await this.modelProvider.storage.set(play.id + '-' + this.id + '-meta', {
        //     type: fileItem.type,
        //     transcoded: fileItem.transcoded
        // });
    }

    public uploadPercent$ = new ReplaySubject<number>(1);
    public async upload(task: Task, player: Player, play: Play, analytics?: { analyticsService: AnalyticsProvider }) {
        for (const fileItem of this._filesToUpload) {
            if (fileItem.type === 'video') {
                await this.storeLocal(fileItem, player.id);
            }

            analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - 0', task.title, {
                fileName: fileItem.file.name,
                fileType: fileItem.file.type,
                transcoded: fileItem.transcoded,
                size: fileItem.file.size
            });
            const uploadItem = this.uploadFile(fileItem.file, task, player, play);
            // this.uploadPercent$ = uploadItem.uploadTask.percentageChanges();
            const transcodePercents = [25, 50, 75, 100];
            const transcodePercentProgress = {
                25: false,
                50: false,
                75: false,
                100: false,
            };
            const percentSubscription = uploadItem.uploadTask.percentageChanges().subscribe(percent => {

                const percentRound = Math.ceil(percent);
                for (const percent of transcodePercents) {
                    if (percentRound >= percent && transcodePercentProgress[percent] === false) {
                        transcodePercentProgress[percent] = true;
                        analytics.analyticsService.event('Task Response - upload - progress - ' + percent + '%');
                    }
                }

                this.uploadPercent$.next(percent);
                // percent$.next(percent);
            });
            analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - 1');
            const finished = await uploadItem.uploadTask.then(snapshot => snapshot).catch(error => {
                analytics.analyticsService.event('Task Response - upload - error');

                analytics.analyticsService.event(JSON.stringify(error));
                analytics.analyticsService.event(JSON.stringify(uploadItem.uploadTask.task.snapshot));
                throw new Error(error);
            });
            analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - 2');
            // const downloadURL = finished.downloadURL;
            // console.log(downloadURL);



            percentSubscription.unsubscribe();

            switch (fileItem.type) {
                case 'photo':
                    this.photo.original.ref = uploadItem.path;
                    analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - photo - 3');
                    this.photo.original.url = await uploadItem.reference.getDownloadURL().pipe(take(1)).toPromise();
                    analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - photo - 4');
                    break;
                case 'video':
                    const size = fileItem.transcoded ? 'transcoded' : 'original';
                    this.video[size].ref = uploadItem.path;
                    analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - video - 3');
                    this.video[size].url = await uploadItem.reference.getDownloadURL().pipe(take(1)).toPromise();
                    analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - video - 4');
                    break;
                default:
                    break;
            }
        }
        analytics.analyticsService.event('Task Response - upload - ' + task.id + ' - video - 5');

        await this.deleteLocal(player.id);
        return true;
    }

    public hasFileToUpload() {
        return this._filesToUpload.length > 0;
    }

    private isTranscoded(file: File) {
        return (file.name.indexOf('transcoded') >= 0) ? true : false;
    }

    private uploadFile(file: File, task: Task, player: Player, play: Play) {
        const pathRegexp = /(?:\.([^.]+))?$/;
        const extension = pathRegexp.exec(file.name)[1];

        const extraMetadata = (file as any).extraMetadata || {};

        // tslint:disable-next-line: max-line-length
        const filePath = player.getReference().path +
            '/' + task.id + '-capture-' + Math.round(new Date().getTime() / 1000) + '.' + extension;
        const storageRef = this.modelProvider.fsStorage.ref(filePath);
        // console.log(typeof this.responseImagesToUpload[0]);
        const uploadTask = this.modelProvider.fsStorage.upload(filePath, file, {
            customMetadata: {
                type: 'response',
                playPath: play.getReference().path,
                playerPath: player.getReference().path,
                taskPath: task.getReference().path,
                scriptVersionPath: player.scriptVersionRef.path,
                scriptPath: player.scriptRef.path,
                playID: play.id,
                playerID: player.id,
                taskID: task.id,
                transcoded: this.isTranscoded(file) ? 'true' : null,
                ...extraMetadata
            }
        });

        return {
            uploadTask: uploadTask,
            path: filePath,
            reference: storageRef
        };
    }

    public get isNew() {
        return this.createdAt ? false : true;
    }

    public getTimeSpent(task: Task, play: Play, teamPlayers: Player[], tasks: Task[]) {
        if (!this.done) { return false; }

        // const task = tasks.find(_task => _task.id === this.id);

        // console.log('task', task, this.id, tasks);

        const dependentTasks = tasks.filter(_task => task.isDependent(_task));

        let diffFrom = play.startedAt.toDate();

        if (task.dependency.condition === 'OR') {
            diffFrom = new Date('2999-01-01');
        }

        if (dependentTasks.length > 0) {
            dependentTasks.forEach((dependentTask) => {
                const dependentTaskPlayer = teamPlayers.find(teamPlayer => teamPlayer.responses.items[dependentTask.id] && teamPlayer.responses.items[dependentTask.id].done === true);

                if (dependentTaskPlayer && dependentTaskPlayer.responses) {
                    const responseTime = dependentTaskPlayer.responses.items[dependentTask.id].createdAt.toDate();

                    if (task.dependency.condition === 'AND') {
                        if (responseTime.getTime() > diffFrom.getTime()) {
                            diffFrom = responseTime;
                        }
                    }
                    if (task.dependency.condition === 'OR') {
                        if (responseTime.getTime() < diffFrom.getTime()) {
                            diffFrom = responseTime;
                        }
                    }


                }

            });
        }

        // if (task.id === "LH1ubOu05PIEaz3fGoZY") {
        //     console.log(teamPlayers.length)
        //     console.log(play.startedAt.toDate());
        //     console.log(diffFrom);
        //     console.log(this.createdAt.toDate());
        // }


        const x = moment(diffFrom);
        const y = moment(this.createdAt.toDate());
        const difference = moment.duration(y.diff(x))

        // return difference.humanize();
        // this.duration = Math.floor(difference.asHours()) + 'h' + difference.minutes() + 'm';
        const asDays = Math.floor(difference.asDays());
        const hours = difference.hours();
        const minutes = difference.minutes();
        const seconds = difference.seconds();
        return (asDays ? asDays + 'd ' : '') + (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm ' : '') + (seconds ? seconds + 's' : '');
    }

    // tslint:disable-next-line: member-ordering
    static instantiate(data, documentInstance, { }, modelProvider) {
        return new Response(data, documentInstance, {}, modelProvider);
    }

    private _taskID: string;
    get taskID(): string {
        if (this._taskID) { return this._taskID };
        const responses = (this.documentInstance as Player).responses.items;
        for (const taskID in responses) {
            if (Object.prototype.hasOwnProperty.call(responses, taskID)) {
                const response = responses[taskID];

                if (response === this) {
                    return taskID;
                }
            }
        }
        // return (this.documentInstance as Player).responses.
    }

    get playerRef() {
        return (this.documentInstance as FirestoreModel<Player>).ref;
    }

    get playRef() {
        return this.playerRef.parent.parent;
    }

    private _asFeedItem: FeedPost;
    asFeedItem(player: Player, task: Task, options?: { className?: string, quote?: QuoteInfo }) {
        if (this._asFeedItem) { return this._asFeedItem };
        this._asFeedItem = this.modelProvider.feedItem.createFromResponse(this, player, task, options);

        return this._asFeedItem;
    }


}
