import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BrowserRecommendationModalComponent } from './browser-recommendation-modal/browser-recommendation-modal.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    BrowserRecommendationModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  entryComponents: [
    BrowserRecommendationModalComponent
  ]
})
export class BrowserRecommendationModule { }
