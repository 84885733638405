<div class="ion-padding modal-content">
  <script-context-menu #scriptContextMenu [script]="script" class="d-none"></script-context-menu>
  <h2 class="modal-title mt-0">{{'use-template/page-title' | translate }}</h2>
  <p [innerHTML]="'use-template/paragraph-1' | translate: { title: script?.draft?.title }"></p>
  <form class="p-0 mb-0">
    <div class="form-group float-left mb-0">
      <select [(ngModel)]="selectedWorkspace" (change)="onWorkspaceChange(selectedWorkspace)" name="workspace" class="form-control float-left">
        <option *ngFor="let workspace of workspaces" [ngValue]="workspace">{{workspace.title}}</option>
      </select>
    </div>
  </form>
  <div class="clearfix"></div>
  <p [innerHTML]="'use-template/paragraph-2' | translate"></p>
  <ion-footer mode="ios">
    <div class="text-right">
      <ion-button (click)="onCancel()" class="mr-2" color="secondary" shape="round">
        {{'general/cancel' | translate}}
      </ion-button>
      <ion-button (click)="onSave()" [disabled]="processing || !selectedWorkspace" type="submit" color="primary" shape="round">
        <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
        {{'use-template/submit-button/label' | translate}}
      </ion-button>
    </div>
  </ion-footer>
</div>