import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'task-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TaskTextComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  format: 'plain' | 'html';

  subscriptions: Subscription[] = [];

  constructor(
    private iab: InAppBrowser,
    private platform: Platform
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.platform.is('cordova')) {
      document.querySelectorAll('task-text a').forEach((element) => {
        this.subscriptions.push(
          fromEvent(element, 'click').subscribe(event => {
            event.preventDefault();
            event.stopPropagation();
            let url = (element as any).href;
            if (url.indexOf(':') === -1) { url = 'https://' + url; }
            this.iab.create(url, '_system');
          })
        )
      })
    }
  }

  ngOnDestory() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // https://stackoverflow.com/questions/15458876/check-if-a-string-is-html-or-not/15458987


}
