import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayingRoutingModule } from './playing-routing.module';
import { BrowsePageModule } from './browse/browse.module';
import { PlayPageModule } from './play/play.module';
import { PlayLandingModule } from './landing/play-landing.module';
import { OrganizationNotFoundModule } from '../organization/organization-not-found/organization-not-found.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowsePageModule,
    PlayPageModule,
    PlayingRoutingModule,
    PlayLandingModule,
    OrganizationNotFoundModule
  ],
  exports: [
    PlayingRoutingModule,
  ]
})
export class PlayingModule { }
