<a-scene *ngIf="url" embedded class="d-block w-100" vr-mode-ui="enabled: false">
  <!-- Wait for the video to load. -->
  <a-assets>
    <!-- Single source video. -->
    <video id="video" style="display:none" autoplay loop crossorigin="anonymous" playsinline webkit-playsinline>
      <!-- MP4 video source. -->
      <source type="video/mp4" [attr.src]="url" />
    </video>
  </a-assets>
  <!-- The original example also has this 180 degree rotation, to appear to be going forward. -->
  <a-videosphere rotation="0 180 0" src="#video" play-on-window-click play-on-vrdisplay activate-or-enter-vr>
  </a-videosphere>
</a-scene>