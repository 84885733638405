import { DatePipe } from '@angular/common';
import { TimeAgoPipe } from 'ngx-moment';
import { Component, Input, OnInit } from '@angular/core';
import { Player } from 'src/app/core/models/player.model';
import { Role } from 'src/app/core/models/role.model';
import { User } from 'src/app/core/models/user.model';
import { Task } from 'src/app/core/models/task.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'post-task-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class PostTaskQuoteComponent implements OnInit {

  @Input()
  task: Task;

  @Input()
  response: Response;

  @Input()
  player: Player;

  @Input()
  role: Role;

  @Input()
  user: User;

  title: string;
  timeString: string;

  constructor(
    private amAgoPipe: TimeAgoPipe,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.title = this.user.isMe
    ? this.translate.instant('playing/feed/post/quote/mine')
    : this.translate.instant('playing/feed/post/quote/other', {user: this.user.displayName, role: this.role.title});
    this.timeString = this.getTimeString();
  }

  private getTimeString() {
    const time = this.task.getResponseTime(this.player);
    const oneDayAgo = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
    // day hour  min  sec  msec
    if (oneDayAgo < time.getTime()) {
      return this.amAgoPipe.transform(time);
    } else {
      return this.datePipe.transform(time, 'short');
    }
  }


}
