<mat-icon (click)="onCancel('icon')" class="modal-close-icon" slot="start"
  >close</mat-icon
>

<div class="modal-content ion-padding">
  <h2 class="modal-title">
    {{ title | translate }}
  </h2>
  <p class="confirmation-description">
    {{ confirmQuestion | translate }}
  </p>
  <p class="confirmation-description">
    {{ confirmDescription | translate }}
  </p>
  <ion-footer mode="ios">
    <div class="text-right">
      <ion-button
        (click)="onCancel('button')"
        class="mr-2"
        color="secondary"
        shape="round"
      >
        {{ cancelBtnText | translate }}
      </ion-button>

      <ion-button
        (click)="onArchive()"
        type="submit"
        color="primary"
        shape="round"
      >
        {{ confirmBtnText | translate }}</ion-button
      >
    </div>
  </ion-footer>
</div>
