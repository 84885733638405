<div class="script-url-editor-container">
  <div class="row">
    <div class="col-1 offset-10 close-icon-container">
      <mat-icon (click)="dismissModal('icon')"
                class="close-icon"
                slot="start">close</mat-icon>
    </div>
  </div>
  <div class="row title-container">
    <span class="col-md-6 url-editor-title">{{'script-designer/url-editor/title' | translate}}</span>
  </div>
  <div class="row">
    <form class="form-group url-input-form">
      <div class="col-md-12 url-input-container">
        <input [(ngModel)]="url"
               (focusin)="analytics.createURLFieldClickedEvent()"
               [placeholder]="'script-designer/url-editor/edit-url-placeholder' | translate"
               type="text"
               name="name"
               id="teaserURL">
      </div>
    </form>
  </div>
  <div class="row actions-row">
    <div class="col-md-3 offset-md-5 actions-container">
      <ion-button (click)="dismissModal('close button')"
                  class="mr-2"
                  color="secondary"
                  shape="round">
        {{'general/cancel' | translate}}
      </ion-button>
    </div>
    <div class="col-md-3 pr-2 actions-container">
      <ion-button (click)="saveUrl()"
                  color="primary"
                  shape="round">
        {{'general/save' | translate}}
      </ion-button>
    </div>
  </div>
</div>
