import { NgModule } from '@angular/core';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ModelProvider } from './general/model.provider';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalizeModule } from '../localize/localize.module';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    AngularFirestoreModule,//.enablePersistence({ synchronizeTabs: true }),
    AngularFireStorageModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    LocalizeModule,
    TranslateModule.forChild(),
    IonicModule
  ],
  providers: [
    ModelProvider
  ]
})
export class ModelsModule { }
