import { Injectable, SkipSelf } from '@angular/core';
import { first } from 'rxjs/operators';
import { Workspace } from 'src/app/core/models/workspace.model';
import { OrganizationService } from 'src/app/organization/organization.service';
import { AnalyticsProvider } from '../../core/analytics/analytics';
import { ScriptCardAnalyticsService } from './script-card/script-card-analytics-service';

@Injectable()
export class ScripListAnalyticsService extends ScriptCardAnalyticsService {
  constructor(
    @SkipSelf() analyticsService: AnalyticsProvider,
    private organizationService: OrganizationService
  ) {
    super(analyticsService);
  }

  setScreen() {
    this.analyticsService.setCurrentScreen('Game list');
  }

  async createAddNewScriptClickedEvent(workspace: Workspace) {
    this.analyticsService.event('new design button clicked', null, {
      'workspace-id': workspace.id,
      isPersonal: workspace.isPersonal,
      ...await this.organizationService.getAnalyticsParams()
    });
  }

  async templateButtonClicked(workspace: Workspace) {
    this.analyticsService.event('use template button clicked', null, {
      'workspace-id': workspace.id,
      isPersonal: workspace.isPersonal,
      ...await this.organizationService.getAnalyticsParams()
    });
  }
}
