import { Injectable } from '@angular/core';
import { ModelProvider } from '../models/general/model.provider';
import { WorkspaceService } from '../workspace/workspace.service';
import { ConfigPrivilege } from '../models/config.model';
import { first } from 'rxjs/operators';
import { WorkspaceMember } from '../models/workspace-member';

@Injectable({ providedIn: 'root' })
export class PrivilegeService {
  private privilegeConfig: ConfigPrivilege;

  constructor(
    private modelProvider: ModelProvider,
    private workspaceService: WorkspaceService
  ) { }

  async hasAccess(feature: string, member?: WorkspaceMember): Promise<boolean> {
    const activeWorkspace = await this.workspaceService.activatedWorkspace.pipe(first()).toPromise();
    const currentWorkspaceMember = member || await activeWorkspace.getMyMember();
    
    if (!this.privilegeConfig) {
      this.privilegeConfig = await this.modelProvider.config.privilege$.pipe(first()).toPromise();
    }

    if (currentWorkspaceMember) {
      return this.privilegeConfig.hasLevelAccessToFeature(currentWorkspaceMember.level, feature);
    }

    return true;
  }
}
