import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperModule } from 'ngx-popper';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { PlayModalComponent } from './play-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { WorkspaceModule } from 'src/app/core/workspace/workspace.module';
import { ModelsModule } from 'src/app/core/models/models.module';
import { PlayModalAnalyticsService } from './play-modal-analytics.service';

@NgModule({
  declarations: [
    PlayModalComponent
  ],
  exports: [
    PlayModalComponent
  ],
  entryComponents: [
    PlayModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    PipesModule,
    MatIconModule,
    NgxPopperModule,
    ClipboardModule,
    WorkspaceModule,//.forChild()
    ModelsModule
  ],
  providers: [
    PlayModalAnalyticsService
  ]
})
export class PlayModalModule { }
