import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-teaser-modal",
  templateUrl: "./teaser-modal.page.html",
  styleUrls: ["./teaser-modal.page.scss"],
})
export class TeaserModalPage implements OnInit {
  @Input() externalVideoUrl: string;
  public videoUrl: SafeResourceUrl;

  constructor(
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.externalVideoUrl
    );
  }

  onClose() {
    this.modalCtrl.dismiss();
  }
}
