import { Component, OnInit, Input } from '@angular/core';
import { Organization } from 'src/app/core/models/organization.model';
import { Workspace } from 'src/app/core/models/workspace.model';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Plan } from 'src/app/core/models/plan';

@Component({
  selector: 'organization-workspaces',
  templateUrl: './organization-workspaces.component.html',
  styleUrls: ['./organization-workspaces.component.scss'],
})
export class OrganizationWorkspacesComponent implements OnInit {

  @Input()
  plan: Plan;

  @Input()
  organization: Organization

  workspaces: Workspace[];

  subscriptions: Subscription[] = [];

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    const workspaceSub = this.organization.workspaces$.subscribe(workspaces => this.workspaces = workspaces);
    this.subscriptions.push(workspaceSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getWorkspaceLogo(workspace: Workspace): SafeStyle {
    const url = workspace && workspace.photoURL ? workspace.photoURL : '/assets/images/img-placeholder.png';
    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
  }

}
