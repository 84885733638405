import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { resolve } from 'dns';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor(
    private platform: Platform
  ) { }

  public async show() {
    await (window as any).Intercom('show');
    if (this.platform.is('cordova')) {
      await new Promise((resolve, reject) => setTimeout(() => resolve(true), 1000));
      let doc = (document.querySelector('iframe[name="intercom-messenger-frame"]') as any).contentDocument;
      doc.querySelector('div[aria-label="Close"]').style.top = '20px';
      const topDiv = doc.querySelector('div.intercom-home-screen > div');
      topDiv.style.paddingTop = (parseInt(topDiv.style.paddingTop) + 30) + 'px';
    }
  }

  public hide() {
    (window as any).Intercom('hide');
  }

  public update() {
    (window as any).Intercom('update');
  }

  // private onHide$ = new Subject();
  get onHide$() {
    const onHide$ = new Subject();
    (window as any).Intercom('onHide', () => {
      onHide$.next(true);
    });
    return onHide$;
  }
}
