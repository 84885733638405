<ion-header>
  <ion-toolbar color="dark">
    <ion-title>{{"playing/browse/header/title" | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button class="intercom-show d-block d-sm-none" (click)="intercom.show()">
        <mat-icon slot="icon-only">help</mat-icon>
      </ion-button>
      <ion-button (click)="showProfile()" color="light">
        <mat-icon>people</mat-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-tabs>
    <ion-tab-bar class="d-none" slot="top">
      <ion-tab-button tab="play-list">
        <ion-label>{{"playing/browse/tab/plays/title" | translate}}</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="design-list-overview">
        <ion-label>{{"playing/browse/tab/scripts/title" | translate}}</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
