import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
// import { Angulartics2Module } from 'angulartics2';
import { AnalyticsProvider } from './analytics';
// import { ENV } from '@env';
import { AnalyticsOnDirective } from './analytics-on.directive';
import { AnalyticsOnBackButtonDirective } from './analytics-on-back-button.directive';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { AngularFireAnalytics, AngularFireAnalyticsModule, APP_NAME, CONFIG } from '@angular/fire/analytics';
// import { AnalyticsOnBackButtonDirective } from './analyticsOnBackButton';
const GA_LOCAL_STORAGE_KEY = 'GA_LOCAL_STORAGE_KEY';
(<any>window).mixpanel.init(environment.analytics.mixpanel.trackID);
(<any>window).ga('create', environment.analytics.googleAnalytics.trackID, 'auto', {
  'storage': 'none',
  'clientId': localStorage.getItem(GA_LOCAL_STORAGE_KEY),
  allowLinker: true
});
(<any>window).ga('require', 'linker');
(<any>window).ga('linker:autoLink', ['gamoteca.com','www.gamoteca.com', 'play.gamoteca.com', 'app.gamoteca.com']);
(<any>window).ga((tracker) => {
  localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
});
(window as any).ga('set', 'checkProtocolTask', null);
if((environment?.analytics as any)?.googleAds?.trackID) {
  (window as any).gtag('config', (environment?.analytics as any)?.googleAds?.trackID);
}
// (window as any).ga('set', 'transportUrl', 'https://www.google-analytics.com/collect');
(<any>window).Intercom('boot', { app_id: environment.analytics.intercom.trackID });
// (<any>window).gtag('config', 'G-DNV6PC4KZD');

@NgModule({
  declarations: [
    AnalyticsOnDirective,
    AnalyticsOnBackButtonDirective
  ],
  imports: [
    AngularFireAnalyticsModule,
    Angulartics2RouterlessModule.forRoot({
      pageTracking: {
        excludedRoutes: [
          new RegExp('/.*/')
          // /.*/,
        ],
      }
    })
  ],
  exports: [
    AnalyticsOnDirective,
    AnalyticsOnBackButtonDirective
  ]

  // providers: [
  //   FirebaseAnalytics,
  //   Angulartics2GoogleAnalytics,
  //   Angulartics2GoogleTagManager,
  //   Angulartics2Mixpanel,
  //   AnalyticsProvider
  // ],
})
export class AnalyticsModule {
  static forChild(): ModuleWithProviders<AnalyticsModule> {
    return { ngModule: AnalyticsModule
        // , providers: [WorkspaceService]
    };
}
  
  static forRoot(): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        FirebaseAnalytics,
        Angulartics2GoogleAnalytics,
        Angulartics2GoogleTagManager,
        Angulartics2Mixpanel,
        {
          provide: CONFIG, useValue: {
            send_page_view: false,
            // allow_ad_personalization_signals: false,
            // anonymize_ip: true
          }
        },
        // { provide: AUTOMATICALLY_TRACK_USER_IDENTIFIER, useValue: false },
        // { provide: APP_VERSION, useValue:  },
        { provide: APP_NAME, useValue: environment.app },
        AnalyticsProvider,
        Platform
      ]
    };
  }
}
