import { Component, OnInit, Input, Output, EventEmitter, ContentChild, ElementRef, ViewChild, HostBinding } from '@angular/core';

@Component({
  selector: 'inline-editor',
  templateUrl: './inline-editor.component.html',
  styleUrls: ['./inline-editor.component.scss'],
})
export class InlineEditorComponent implements OnInit {

  // @Input()
  // type: 'input' | 'textarea';
  // @HostBinding('class.editing') get ed () { return this.editing; } 
  
  input: HTMLInputElement;

  @HostBinding('class.editing')
  @Input()
  editing = false;

  @Input() disabled = false;
  @Output() ok = new EventEmitter<string>();

  @Input()
  iconTitle = false;

  // onChangeListener

  // @ContentChild('[slot="input"]', { static: true, read: ElementRef  }) input: ElementRef;
  // @ViewChild('input', {static: true}) input2: ElementRef;
  // ElementRef;

  // @Output() editClick = new EventEmitter<any>();


  // @Input() value: string;
  // @Output() valueChange = new EventEmitter<string>();

  // @Input()
  // name = '';

  constructor(
    public elementRef: ElementRef
  ) { }

  onEditButtonClick() {
    this.editing = true;
    setTimeout(() => { this.input.focus(); }, 100);
  }

  onButtonClick() {
    this.ok.next(this.input.value);
    this.editing = false;
  }

  ngOnInit() {
    // this.type = 'input';
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.input =  this.elementRef.nativeElement.querySelector('input, texarea') as HTMLInputElement;
  }

  ngAfterViewInit(): void {
    // input.onchange = (value) => {

    // };

    // console.log(input, this.input, this.input2);
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    
  }

  onSubmit() {

  }

}
