import { FirestoreModel } from '../firebase/firestore-model';
import { Observable } from 'rxjs';

export class ConfigGeneral extends FirestoreModel<ConfigGeneral> {
  public 'minimum-version': {
    android: string, ios: string, pwa: string
  };

  protected rules() {
    return {
      'minimum-version': {}
    };
  }

  // tslint:disable-next-line: member-ordering
  private _get$: Observable<ConfigGeneral>;
  public get get$(): Observable<ConfigGeneral> {
    if (this._get$ !== undefined) {
      return this._get$;
    }

    this._get$ = this.findByRef('configs/general');
    return this._get$;
  }

  protected instantiate(path, data, options?: any) {
    return new ConfigGeneral(path, data, options, this.modelProvider);
  }
}

export class ConfigMinimumVersion extends FirestoreModel<ConfigMinimumVersion> {
  public designer: {
    android: string, ios: string, pwa: string
  };

  public player: {
    android: string, ios: string, pwa: string
  };

  protected rules() {
    return {
      designer: {},
      player: {}
    };
  }

  // tslint:disable-next-line: member-ordering
  private _get$: Observable<ConfigMinimumVersion>;
  public get get$(): Observable<ConfigMinimumVersion> {
    if (this._get$ !== undefined) {
      return this._get$;
    }

    this._get$ = this.findByRef('configs/minimum-version');
    return this._get$;
  }

  protected instantiate(path, data, options?: any) {
    return new ConfigMinimumVersion(path, data, options, this.modelProvider);
  }
}

export class ConfigAuth extends FirestoreModel<ConfigAuth> {
  public alternateProviders: [
    {
      id: string;
      logoURL: string;
      title: string;
      type: string;
      url: string;
    }
  ];

  // public player: {
  //   android: string, ios: string, pwa: string
  // };

  protected rules() {
    return {
      alternateProviders: {}
    };
  }

  // tslint:disable-next-line: member-ordering
  private _get$: Observable<ConfigAuth>;
  public get get$(): Observable<ConfigAuth> {
    if (this._get$ !== undefined) {
      return this._get$;
    }

    this._get$ = this.findByRef('configs/auth');
    return this._get$;
  }

  protected instantiate(path, data, options?: any) {
    return new ConfigAuth(path, data, options, this.modelProvider);
  }
}

export class ConfigPrivilege extends FirestoreModel<ConfigPrivilege> {
  public privilege: {
    admin: [], editor: [], buy: [], player: [],
  };

  public privilegeOrder = [
    'admin',
    'editor',
    'buy',
    'player',
  ];

  public hasLevelAccessToFeature(level: string, feature: string) {
    let currentLevelIndex = this.privilegeOrder.findIndex(privilegeLevel => privilegeLevel === level);
    let currentLevel = this.privilegeOrder[currentLevelIndex];
    while (currentLevel) {
      if (this.privilege[currentLevel].includes(feature)) {
        return true;
      }

      ++currentLevelIndex;
      currentLevel = this.privilegeOrder[currentLevelIndex];
    }

    return false;
  }

  protected rules() {
    return {
      'privilege': {}
    };
  }

  // tslint:disable-next-line: member-ordering
  private _get$: Observable<ConfigPrivilege>;
  public get get$(): Observable<ConfigPrivilege> {
    if (this._get$ !== undefined) {
      return this._get$;
    }

    this._get$ = this.findByRef('configs/privilege');
    return this._get$;
  }

  protected instantiate(path, data, options?: any) {
    return new ConfigPrivilege(path, data, options, this.modelProvider);
  }

  setFields(data: any) {
    if (!this.privilege) {
      this.privilege = {
        admin: [],
        editor: [],
        buy: [],
        player: []
      };
    }

    if (data) {
      const admin = data.admin || [];
      const editor = data.editor || [];
      const buy = data.buy || [];
      const player = data.player || [];

      this.privilege.admin = admin.concat(editor).concat(player);
      this.privilege.editor = editor.concat(player);
      this.privilege.buy = buy;
      this.privilege.player = player;
    }
  }
}

export class Config extends FirestoreModel<Config> {

  protected rules() {
    return {};
  }

  // tslint:disable-next-line: member-ordering
  private _general$: Observable<ConfigGeneral>;
  public get general$(): Observable<ConfigGeneral> {
    if (this._general$ !== undefined) {
      return this._general$;
    }

    const general = new ConfigGeneral('', {}, {}, this.modelProvider);
    this._general$ = general.get$;
    return this._general$;
  }

  // tslint:disable-next-line: member-ordering
  private _privilege$: Observable<ConfigPrivilege>;
  public get privilege$(): Observable<ConfigPrivilege> {
    if (this._privilege$ !== undefined) {
      return this._privilege$;
    }

    const privilege = new ConfigPrivilege('', {}, {}, this.modelProvider);
    this._privilege$ = privilege.get$;
    return this._privilege$;
  }

  // tslint:disable-next-line: member-ordering
  private _minimumVersion$: Observable<ConfigMinimumVersion>;
  public get minimumVersion$(): Observable<ConfigMinimumVersion> {
    if (this._minimumVersion$ !== undefined) {
      return this._minimumVersion$;
    }

    const minimumVersion = new ConfigMinimumVersion('', {}, {}, this.modelProvider);
    this._minimumVersion$ = minimumVersion.get$;
    return this._minimumVersion$;
  }

  // tslint:disable-next-line: member-ordering
  private _auth$: Observable<ConfigAuth>;
  public get auth$(): Observable<ConfigAuth> {
    if (this._auth$ !== undefined) {
      return this._auth$;
    }

    const auth = new ConfigAuth('', {}, {}, this.modelProvider);
    this._auth$ = auth.get$;
    return this._auth$;
  }

}
