import { ModelProvider } from '../models/general/model.provider';
import { Model } from '../models/general/model';
import { FirestoreModel } from './firestore-model';

export class FirestoreField extends Model {
    public constructor(
        data: any,
        protected documentInstance?: Model,
        protected options?: any,
        protected modelProvider?: ModelProvider
    ) {
        super(data, { skipSetFields: true });

        // To reach model provider
        if (!options || !options.skipSetFields) {
            this.setFields(data);
        }
    }
}
