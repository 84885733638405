<div class="container">
  <div *ngIf="user" class="container-fluid">
    <!-- <div class="col-12"> -->
    <div class="row top pb-0 align-items-center">
      <div class="col-auto pl-0 avatar-container">
        <img class="profile" [src]="user?.photoURL" [alt]="user.displayName" />
      </div>
      <div class="col pl-2 pr-0">
        <div>
          <p class="font-bold" class="title my-0">{{title}}</p>
          <p class="text-muted time my-0">
            <small>{{timeString}}</small>
          </p>
        </div>
      </div>
      <div class="col-auto pr-0 call-to-action text-right">
        <ion-button (click)="action()" class="call-to-action" size="small" shape="round" color="warning">{{'playing/feed/post/invite/call-to-action' | translate}}</ion-button>
      </div>
    </div>
    <div class="row task mt-5 mb-3">
      <div class="col col-auto px-0 icon">
        <span>
          <mat-icon>group_add</mat-icon>
        </span>
      </div>
      <div class="col pl-0 mt-1">
        <p class="my-0">{{taskLabel}}</p>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
<!-- <ng-container *ngIf="play$ | async; let play"> -->
  <textarea class="copy" #codeInputTarget>
    {{'playing/play-status/invite/message' | translate : { name: playService?.meUser?.displayName, game: playService?.scriptVersion?.title, code: (play.share.code | mask: 'AAA-AAA'), url: (play?.shareURL) } }}
  </textarea>
<!-- </ng-container> -->