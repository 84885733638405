import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';

@Component({
  selector: 'app-organization-create-modal',
  templateUrl: './organization-create-modal.component.html',
  styleUrls: ['./organization-create-modal.component.scss'],
})
export class OrganizationCreateModalComponent implements OnInit {

  @Input()
  email: string;

  teamName: string;
  processing = false;

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthService,
    private modelProvider: ModelProvider,
    private workspaceService: WorkspaceService,
  ) {
  }

  async ngOnInit() { }

  onCancel(closeType: string) {
    this.modalCtrl.dismiss({ action: 'cancel' });
  }

  async onSubmit(createOrgForm: NgForm) {
    if (createOrgForm.valid) {
      this.processing = true;
      const result = await this.modelProvider.organization.createFreeOrganization(this.teamName, this.email)
      if (result.result === true) {
        await new Promise(resolve => setTimeout(resolve, 500));
        this.workspaceService.setActiveWorkspaceByRef(this.modelProvider.fsDB.doc(result.workspaceRefPath).ref);
        this.modalCtrl.dismiss({
          action: 'success'
        });
      } else {
        this.modalCtrl.dismiss({
          action: 'failure'
        });
      }
      this.processing = false;
    }
  }

}
