import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Organization } from 'src/app/core/models/organization.model';
import { Plan } from 'src/app/core/models/plan';

@Component({
  selector: 'app-cancel-subscription-confirm-modal',
  templateUrl: './cancel-subscription-confirm-modal.component.html',
  styleUrls: ['./cancel-subscription-confirm-modal.component.scss'],
})
export class CancelSubscriptionConfirmModalComponent implements OnInit {
  @Input()
  organization: Organization;


  @Input()
  plan: Plan;

  confirm: string;

  processing = false;

  constructor(
    private modelProvider: ModelProvider,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private translate: TranslateService
  ) { }

  ngOnInit() {}

  public async onSubmit(form: NgForm, submittedBySaveButton = true) {
    if (form.invalid) {
      return;
    }

    if((form.value.confirm as string).toUpperCase() !== 'CANCEL') {
      return;
    }

    this.processing = true;

    await this.doCancel();
    // const result = await this.modelProvider.functions.httpsCallable('deleteAccount')({}).pipe(first()).toPromise();
    this.modalCtrl.dismiss();
    // console.log(result);
    this.processing = false;

  }

  private async doCancel() {
    try {
      // if (this.processing === 'cancel-subscription') { return; }
      
      // this.processing = 'cancel-subscription';
      await this.organization.cancelSubscription();
      const toast = await this.toastController.create({
        message: this.translate.instant('organization/plan-details/cancel-subscription/confirm/success'),
        duration: 3500,
        color: 'primary',
        cssClass: 'text-center mt-5',
        position: 'top'
      });
      await toast.present();
    } catch (error) {

    } finally {
      this.processing = null;
    }
  }

  public onCancel() {
    this.modalCtrl.dismiss({ action: 'canceled' });
  }

}
