<!-- Generated template for the VideoMobileComponent component -->
<div>
    <div *ngIf="activeAlternate" class="alternateContainer pb-3">
      <div class="info text-center d-block">
          <ion-button color="warning" (click)="setActiveAlternate(false)" fill="clear">
              <mat-icon slot="start">videocam</mat-icon> {{'media-capture/video/alternate/back-to-video/label' | translate}}
          </ion-button>
      </div>
      <task-input-text (onTextTyped)="onTextTyped.emit($event)" class="no-border px-2 mt-2 d-block" *ngIf="activeAlternate === 'text'"></task-input-text>
    </div>
    <div *ngIf="!activeAlternate"  class="cameraContainer {{status}}">
      <div *ngIf="hasInfo" class="info visible-inactive">
        <div class="row mx-0 align-items-center">
            <div class="col-1 px-0 text-center">
                <mat-icon class="mx-auto">info</mat-icon>
            </div>
            <div class="col pl-0">
                <p class="pre rtl-text">
                    {{layoutItem?.getInstructionText()}}
                </p>
            </div>
        </div>
      </div>
      <!-- <mat-icon class="startCamera visible-inactive" (click)="capture()" center>camera_alt</mat-icon> -->
      <div class="start-camera-container" [class.has-info]="hasInfo" [class.has-alternate]="alternateOptions?.length > 0">
        <div class="startCamera visible-inactive text-center">
          <ion-button color="warning" size="small" (click)="capture()" shape="round">
            <mat-icon slot="start">videocam</mat-icon>
            {{'media-capture/video/capture-button/label' | translate}}
          </ion-button>
          <ng-container *ngIf="alternateOptions?.length > 0">
            <div class="clearfix"></div>
            <!-- <ion-button color="secondary" fill="clear" size="small"
                [context-menu]="contextGroup"
                popperAppendTo="body"
                >
              <mat-icon slot="icon-only">more_horiz</mat-icon>
            </ion-button> -->
            <p class="or">{{'general/or' | translate}}</p>
            <ion-button *ngIf="!showAlternates" (click)="onShowAlternates()" color="secondary" fill="clear" size="small">
              <mat-icon slot="icon-only">more_horiz</mat-icon>
            </ion-button>
            <!-- <ion-button *ngIf="showAlternates" class="alternate-option" color="secondary" fill="clear" size="small">
                Send text feedback instead
            </ion-button> -->
            <ion-button *ngIf="showAlternates" (click)="setActiveAlternate('text')" class="mt-3" color="secondary" shape="round" size="small">
                <mat-icon>subject</mat-icon> {{'media-capture/video/alternate/text/label' | translate}}
            </ion-button>
            
          </ng-container>
        </div>

      <!-- <button ion-button class="visible-success" (click)="cancel()">
          <mat-icon>undo</mat-icon>
      </button> -->
      <ion-button ion-button color="secondary" size="small" shape="round" class="visible-success retake" (click)="retake()">
        <mat-icon>videocam</mat-icon> {{'media-capture/video/retake-button/label' | translate}}
      </ion-button>
      <video #captureVideo class="visible-inactive visible-active visible-recording"></video>
      <video #previewVideo controls playsinline class="visible-success"></video>
      <!-- <ng-container [hidden]="!(progressPercent | async)"> -->
        <div [hidden]="!(progressPercent | async)" class="transcode-progress">
          Processing ... {{progressPercent | async | number:'1.0-0'}}%
            <!-- <circle-progress [percent]="(progressPercent | async)" [radius]="40"
            [animation]="true" [animationDuration]="300" subtitle="Processing"></circle-progress> -->
        </div>

      <!-- </ng-container> -->
      <!-- <circle-progress [hidden]="!(progressPercent | async)" [percent]="(progressPercent | async)" [radius]="40"
      [animation]="true" [animationDuration]="300" ></circle-progress> -->
      <!-- <circle-progress [hidden]="!transcodeProgress" [percent]="transcodeProgress" [radius]="40"
      [animation]="true" [animationDuration]="300"></circle-progress> -->
    </div>
    </div>
</div>
