import { Injectable, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

@Injectable()
export class PublishModalAnalyticsService {
  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
    // this.analyticsService.setCurrentScreen('Game edit / Teaser Video');
  }

  createPublishTypeSelectedEvent(value: boolean): void {
    this.analyticsService.event(
      ['Publish popup', 'publish type selected'],
      (value) ? this.translateService.instant('publish-settings/option/public/title') : this.translateService.instant('publish-settings/option/unlisted/title'),
      { value: value }
    );
  }

  createSaveButtonClickedEvent(value: boolean): void {
    this.analyticsService.event(
      ['Publish popup', 'save button clicked'],
      (value) ? this.translateService.instant('publish-settings/option/public/title') : this.translateService.instant('publish-settings/option/unlisted/title'),
      { value: value }
    );
  }

  createCancelButtonClickedEvent(): void {
    this.analyticsService.event(
      ['Publish popup', 'cancel button clicked'],
      this.translateService.instant('publish-settings/cancel-button/title')
    );
  }

  createCloseIconClickedEvent(): void {
    this.analyticsService.event(
      ['Publish popup', 'cancel icon clicked']
    );
  }

  createBackdropClickedEvent(): void {
    this.analyticsService.event(
      ['Publish popup', 'backdrop clicked']
    );
  }

}
