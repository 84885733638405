<div class="ion-padding modal-content">
  <h2 class="modal-title mt-0">{{'publish-settings/page-title' | translate }}</h2>
  <p *ngIf="script" [innerHTML]="'publish-settings/page-subtitle' | translate:{title: script.draft.title}"></p>
  <ion-radio-group class="choice" [(ngModel)]="isPublic" (ionChange)="onSelect($event)">
    <ion-item class="p-0 m-0 py-2" mode="md" lines="none">
      <ion-label class="m-0 p-0 ion-text-wrap">{{'publish-settings/option/unlisted/title' | translate}}</ion-label>
      <!-- <ion-radio [value]="false" checked slot="start" color="dark" class="m-0 mr-3 p-0" [value]="choice.key"
        slot="start"></ion-radio> -->
    </ion-item>
    <ion-item class="p-0 m-0 py-2" mode="md" lines="none">
      <ion-label class="m-0 p-0 ion-text-wrap">{{'publish-settings/option/public/title' | translate}}</ion-label>
      <!-- <ion-radio [value]="true" checked slot="start" color="dark" class="m-0 mr-3 p-0" [value]="choice.key"
        slot="start"></ion-radio> -->
    </ion-item>
  </ion-radio-group>
  <p [innerHTML]="'publish-settings/option/unlisted/description' | translate"></p>
  <p [innerHTML]="'publish-settings/option/public/description' | translate"></p>
  <p>{{'publish-settings/option/public/hint' | translate}}</p>
  <p [innerHTML]="'publish-settings/organize/invite/description' | translate"></p>
  <form class="p-0">
    <div class="organize">
      <div class="form-check">
        <input type="checkbox" [(ngModel)]="autoJoinEnabled" name="autoJoinEnabled" class="form-check-input"
          id="autoJoinEnabled" [disabled]="!isPublic" [attr.disabled]="(!isPublic)?'disabled':null">
        <label class="note form-check-label"
          for="autoJoinEnabled" [innerHTML]="'publish-settings/organize/auto-join-enabled/label' | translate"></label>
          <mat-icon
          [popper]="showResponseInfoTextPopper"
          [popperTrigger]="'hover'"
          [popperPlacement]="'right'"
          [popperApplyClass]="'info-text-popper'">help</mat-icon>
          <popper-content popperApplyClass="auto-join-help"
                          #showResponseInfoTextPopper>
            {{ 'publish-settings/organize/auto-join-enabled/description' | translate }}
          </popper-content>
      </div>
    </div>
  </form>

  <p *ngIf="script.isPublished && !isPublishing" class="info">
    <i>
      <ng-container *ngIf="!(script.isPublicDiffers$ | async)">
        {{'publish-settings/info/published-sync' | translate: {dateString: (script.public.publishedAt.toDate() | dateShort)} }}
      </ng-container>
      <ng-container *ngIf="script.isPublicDiffers$ | async">
        <span
          color="warning">{{'publish-settings/info/published-unsync/difference' | translate: {dateString: (script.draft.updatedAt.toDate() | dateShort)} }}</span><br />
        <span
          class="text-muted">{{'publish-settings/info/published-unsync/publish-time' | translate: {dateString: (script.public.publishedAt.toDate() | dateShort)} }}</span>
      </ng-container>
    </i>
  </p>
  <div *ngIf="!(organizationService.activeOrganization$ | async).isActive" class="alert alert-warning mb-5 mt-3" role="alert">
    <p class="my-0">
      {{'publish-settings/inactive-subscription-alert' | translate}}
    </p>
  </div>
  <ion-footer mode="ios">
    <div class="text-right">
      <ion-button (click)="onCancel()" class="mr-2" color="secondary" shape="round">
        {{'publish-settings/cancel-button/title' | translate}}
      </ion-button>
      <ion-button (click)="onSave()" [disabled]="isPublishing || !(organizationService.activeOrganization$ | async).isActive" type="submit" color="primary" shape="round">
        <ion-spinner *ngIf="isPublishing" name="lines-small"></ion-spinner>
        <ng-container *ngIf="!(script.isPublicDiffers$ | async)">
          {{'publish-settings/save-button/unpublished/title' | translate}}
        </ng-container>
        <ng-container *ngIf="(script.isPublicDiffers$ | async)">
          {{'publish-settings/save-button/published/title' | translate}}
        </ng-container>
      </ion-button>
    </div>
  </ion-footer>
</div>