<h1 class="page-title"
     *ngIf="pageTitle">
  {{pageTitle}}
</h1>

<div class="content">
  <a *ngIf="backButton"
  class="back-button"
  (click)="backClicked()"
  [ngClass]="{'back-button-disabled': navigationIsDisabled}">
      <mat-icon>arrow_back</mat-icon>
  </a>
  <ng-content></ng-content>

</div>
