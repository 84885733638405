import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgxPopperModule } from 'ngx-popper';
import { ContextMenuItemComponent } from './context-menu-item/context-menu-item.component';
import { ContextMenuGroupComponent } from './context-menu-group/context-menu-group.component';
import { ContextMenuTriggerDirective } from './context-menu-trigger/context-menu-trigger.directive';
import { ContextMenuIconComponent } from './context-menu-icon/context-menu-icon.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    ContextMenuItemComponent,
    ContextMenuGroupComponent,
    ContextMenuTriggerDirective,
    ContextMenuIconComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgxPopperModule,
    MatIconModule
  ],
  exports: [
    ContextMenuItemComponent,
    ContextMenuGroupComponent,
    ContextMenuTriggerDirective,
    ContextMenuIconComponent
  ]
})
export class ContextMenuModule { }
