<mat-icon (click)="onCancel('icon')"
          class="modal-close-icon"
          slot="start">close</mat-icon>

<div class="modal-content ion-padding">
  <h2 class="modal-title">{{'manage-teams/add-to-team-again/confirmation-title' | translate}}</h2>
  <p class="confirmation-description">
    {{'manage-teams/add-to-team-again/confirmation-question' | translate}}
  </p>
  <ion-footer mode="ios">
    <div class="text-right">
      <ion-button (click)="onCancel('button')"
                  class="mr-2"
                  color="secondary"
                  shape="round">
        {{'general/cancel' | translate}}
      </ion-button>

      <ion-button (click)="onReactivate()"
                  [disabled]="processing"
                  type="submit"
                  color="primary"
                  shape="round">
                  <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
        {{'manage-teams/add-to-team-again/confirm-button/label' | translate}}</ion-button>
    </div>
  </ion-footer>
</div>
