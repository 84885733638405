import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { Task } from '../task.model';
import { Role } from '../role.model';
import { Play } from '../play.model';
import { Team } from '../team.model';
import { Player } from '../player.model';
import { User } from '../user.model';
import { Script } from '../script.model';
import { LocalizeService } from '../../localize/localize.service';
import { ScriptVersion } from '../script-version.model';
import { TranslateService } from '@ngx-translate/core';
import { Workspace } from '../workspace.model';
import { Config } from '../config.model';
import { FirebaseCache } from '../../firebase/firebase-cache';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AlertController, Platform } from '@ionic/angular';
import { Organization } from '../organization.model';
import { OrganizationMember } from '../organization-members.model';
import { Plan } from '../plan';
import { Storage } from '@ionic/storage';
import { WorkspaceMember } from '../workspace-member';
import { FeedPost } from '../feed-post';
import { Feed } from '../feed';

@Injectable()
export class ModelProvider {
    get script(): Script {
        return new Script('', {}, {}, this);
    }

    get scriptVersion(): ScriptVersion {
        return new ScriptVersion('', {}, {}, this);
    }

    get task(): Task {
        return new Task('', {}, {}, this);
    }

    get role(): Role {
        return new Role('', {}, {}, this);
    }

    get play(): Play {
        return new Play('', {}, {}, this);
    }

    get team(): Team {
        return new Team('', {}, {}, this);
    }

    get player(): Player {
        return new Player('', {}, {}, this);
    }

    get user(): User {
        return new User('', {}, {}, this);
    }

    get workspace(): Workspace {
        return new Workspace('', {}, {}, this);
    }

    get workspaceMember(): WorkspaceMember {
        return new WorkspaceMember('', {}, {}, this);
    }

    get organization(): Organization {
        return new Organization('', {}, {}, this);
    }

    get organizationMember(): OrganizationMember {
        return new OrganizationMember('', {}, {}, this);
    }

    get config(): Config {
        return new Config('', {}, {}, this);
    }

    get plan(): Plan {
        return new Plan('', {}, {}, this);
    }

    get feed(): Feed {
        return new Feed('', {}, {}, this);
    }

    get feedItem(): FeedPost {
        return new FeedPost('', {}, {}, this);
    }

    public constructor(
        public fsDB: AngularFirestore,
        public fsStorage: AngularFireStorage,
        public functions: AngularFireFunctions,
        public fsAuth: AngularFireAuth,
        public localize: LocalizeService,
        public translate: TranslateService,
        public cache: FirebaseCache,
        public alertController: AlertController,
        public storage: Storage,
        public platform: Platform
    ) {

    }

    async alertLog(message: string) {
        const alert = await this.alertController.create({
            //   header: message,
            subHeader: new Date().toString(),
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    public async asyncFilter<T>(arr: Array<T>, predicate) {
        return Promise.all(arr.map(predicate))
            .then((results) => arr.filter((_v, index) => results[index]));
    }

    trackById(index, item) {
        return item.id;
    }
}
