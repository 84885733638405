<app-header active="superadmin"></app-header>

<ion-content>
  <content-container class="py-5 px-3" [back-button]="'/superadmin/dashboard'" pageTitle="Teams">
    <ion-button class="top-right" shape="round" (click)="createTeam()">Create Team</ion-button>
    <div class="row head mt-5 text-uppercase">
      <div class="col-4">Team</div>
      <div class="col-2">Plan</div>
      <div class="col-2">Status</div>
      <div class="col-2">Created at</div>
      <div class="col-2"></div>
    </div>
    <div *ngFor="let organization of organizations" class="row my-3 mb-5">
      <ng-container *ngIf="(organization?.plan$ | async); let plan">
        <div class="col-4">{{organization?.title}}</div>
        <div class="col-2">{{plan?.title}}</div>
        <div class="col-2">
          <ng-container *ngIf="organization.plan.status">
            {{'organization/plan/status/' + organization.plan.status | translate}}
          </ng-container>
        </div>
        <div class="col-2">{{organization?.createdAt?.toDate() | date:'shortDate'}}</div>
        <div class="col-2 text-center">
          <context-menu-icon>      
            <context-item label="Edit plan" (click)="editOrganization(organization)"></context-item>
            <context-item label="Settings as user" (click)="settingsOrganization(organization)"></context-item>
          </context-menu-icon>
        </div>
      </ng-container>
    </div>
  </content-container>
</ion-content>