import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Team } from 'src/app/core/models/team.model';
import { Player } from 'src/app/core/models/player.model';
import { Role } from 'src/app/core/models/role.model';
import { ModalController } from '@ionic/angular';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'play-team-box',
  templateUrl: './team-box.component.html',
  styleUrls: ['./team-box.component.scss'],
})
export class TeamBoxComponent implements OnInit {

  @Input()
  team: Team;

  private _players: Player[];
  public get players(): Player[] {
    return this._players;
  }
  @Input()
  public set players(players: Player[]) {
    console.log('set players init', players);
    // this._players = players;
    this._players = players.filter(player => this.team.ref.isEqual(player.teamRef));
    console.log('set players 1', this._players);
  }

  @Input()
  roles: Role[];

  @Input()
  teams: Team[];

  constructor(
    private changeRef: ChangeDetectorRef,
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  moveToTeam(player: Player, team: Team, $event) {
    player.teamRef = team.ref;
    player.save();
  }

  moveToRole(player: Player, role: Role, $event) {
    player.roleRef= role.ref;
    player.save();
  }

  remove(player: Player, $event) {
    player.remove();
    // player.roleRef= role.ref;
  }

  renameTeam(teamName: string) {
    this.team.title = teamName;
    this.team.save();
  }

  async deleteTeam() {
    await this.team.remove();
  }

  public showDeleteConfirmationDialog() {
    return new Promise<boolean>(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: DeleteConfirmationDialogComponent,
        componentProps: {
          team: this.team
        },
        cssClass: [
          'auto-height',
          'sm-modal'
        ]
      });
      modal.onDidDismiss().then(async (dismissed) => {
        if (dismissed.data && dismissed.data.action) {
          if (dismissed.role === 'backdrop') { }
          switch (dismissed.data.action) {
            case 'cancel':
              break;

            case 'delete':
              await this.team.remove();
              resolve(true);
              break;

            default:
              break;
          }
        }
      });
      await modal.present();
    });
  }

}
