import { Component, Input, OnInit } from '@angular/core';
import { Feed } from 'src/app/core/models/feed';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'feed-send-post',
  templateUrl: './send-post.component.html',
  styleUrls: ['./send-post.component.scss'],
})
export class SendPostComponent implements OnInit {

  @Input()
  feed: Feed;

  @Input()
  user: User;

  text: string;

  processing: string = null;

  constructor(
    public modelProvider: ModelProvider,
  ) { }

  ngOnInit() { }

  async submit() {
    if (this.text.trim() === '') { return };

    this.processing = 'submit';

    // await this.

    try {
      if (!this.user) {
        this.user = await this.modelProvider.user.getMe();
      }
      const post = this.modelProvider.feedItem.create(this.feed.ref.path + '/posts', {
        type: 'message',
        text: this.text,
        userRef: this.user.ref
      });
      // console.log(post);
      await post.save();
      this.text = '';
    } catch (error) {
      throw error;
    } finally {
      this.processing = null;
    }

  }
}
