import { LocalizePipe } from './localize.pipe';
import { LocalizeService } from './localize.service';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
      IonicModule,
      CommonModule
    ],
    exports: [
        LocalizePipe,
    ],
    declarations: [
        LocalizePipe
    ],
    providers: [
        LocalizeService
    ]
  })
export class LocalizeModule {}
