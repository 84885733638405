import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { ScriptResolver } from './script/script.resolver';

const routes: Routes = [
  { path: '', redirectTo: environment.homeURL, pathMatch: 'full' },
  { path: 'welcome',      loadChildren: './welcome/welcome.module#WelcomePageModule' },
  { path: 'auth/org-not-found', loadChildren: './organization/organization-not-found/organization-not-found.module#OrganizationNotFoundModule' },
  { path: 'auth/login',   loadChildren: './auth/login/login.module#LoginPageModule' },
  { path: 'auth/sign-up', loadChildren: './auth/sign-up/sign-up.module#SignUpPageModule' },
  {
    path: 'auth/verification-required',
    loadChildren: './auth/verification-required/verification-required.module#VerificationRequiredPageModule'
  },
  {
    path: 'analytics',
    loadChildren: './analytics/analytics.module#AnalyticsPageModule'
  },
  {
    path: 'analytics/:scriptID/reporting',
    loadChildren: './reporting/script-report/script-report.module#ScriptReportPageModule',
    resolve: { script: ScriptResolver }
  },

 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
