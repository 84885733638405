<!-- <ion-content mode="md"> -->
<div class="modal-content">
  <form #form="ngForm" (ngSubmit)="submit()">

    <ion-list class="py-0" mode="md" >
      <ion-radio-group [(ngModel)]="selectedRole" name="selectedRole" [allowEmptySelection]="false" required mode="md" class="no-override">
        <ion-list-header>
          <h2>{{'playing/play-details/role-select/title' | translate}}</h2>
          <!-- <ion-label>
            {{'playing/play-details/role-select/title' | translate}}
          </ion-label> -->
        </ion-list-header>
        <ion-item *ngFor="let role of (roles | facilitatorOrder); let i = index" [lines]="(i === (roles.length - 1)) ? 'full' : 'none'">
            <!-- <ion-label>{{role.title}}</ion-label> -->
            <ion-radio [value]="role" slot="start"
              required class="mr-4" mode="md"></ion-radio>
              <ion-label class="ion-text-wrap">
                <strong>{{role.title}}</strong>
                <ng-container *ngIf="mode === 'random'">
                  <br />
                  <span *ngIf="script.getRandomSessionAvailableSlot(role); let slot">
                    {{'playing/play-details/role-select/random-mode/available' | translate:{available: slot} }}
                  </span>
                  <span *ngIf="!script.getRandomSessionAvailableSlot(role); let slot">{{'playing/play-details/role-select/random-mode/not-available' | translate}}</span>
                </ng-container>
              </ion-label>
            
        </ion-item>
      </ion-radio-group>
      <!-- <ion-item lines="none" mode="md">
        <ion-label for="addToWorkspaceApproved">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum magna eget metus semper, ut blandit felis faucibus. Integer ut maximus metus.
        </ion-label>
        <ion-toggle [(ngModel)]="addToWorkspaceApproved" id="addToWorkspaceApproved" name="addToWorkspaceApproved" slot="end" required></ion-toggle>
      </ion-item> -->
    </ion-list>
  </form>
<!-- </ion-content> -->
</div>
<ion-footer class="ion-no-border">
  <ion-toolbar class="px-3 text-right">
    <!-- <p class="text-right"> -->
    <ion-button (click)="dismiss()" fill="clear" color="warning">{{'general/cancel' |
      translate | uppercase}}</ion-button>
    <ion-button (click)="form.ngSubmit.emit()" [disabled]="!form.form.valid" fill="clear"
      color="primary">
      {{'playing/play-details/role-select/ok' | translate | uppercase}}</ion-button>
    <!-- </p> -->
  </ion-toolbar>
  <ion-footer>