import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ModelsModule } from '../models/models.module';
import { WorkspaceService } from './workspace.service';
// import { PrivilegeModule } from '../privilege/privilege.module';
// import { PrivilegeService } from '../privilege/privilege.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule,
    ModelsModule,
    TranslateModule.forChild(),
    // PrivilegeModule
  ]
})
export class WorkspaceModule {
  // constructor(@Optional() @SkipSelf() parentModule: WorkspaceModule) {
  //   if (parentModule) {
  //     throw new Error(
  //       'WorkspaceModule is already loaded. Import it in the AppModule only');
  //   }
  // }

  // constructor(@Optional() @SkipSelf() parentModule: WorkspaceModule) {
//   if (parentModule) {
//     throw new Error(
//       'WorkspaceModule is already loaded. Import it in the AppModule only');
//   }
// }
static forChild(): ModuleWithProviders<WorkspaceModule> {
    return { ngModule: WorkspaceModule
        // , providers: [WorkspaceService]
    };
}

  static forRoot(
      service?: WorkspaceService
    ): ModuleWithProviders<WorkspaceModule> {
    return {
      ngModule: WorkspaceModule,
      providers: [
        WorkspaceService,
        // PrivilegeService,
        // { provide: WorkspaceService, useClass: WorkspaceService }
      ]
    };
  }
}
