import { Component, OnInit } from '@angular/core';
import { Plan } from 'src/app/core/models/plan';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Subscription } from 'rxjs';
import { Team } from 'src/app/core/models/team.model';
import { Organization } from 'src/app/core/models/organization.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-superadmin-plan-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class SuperadminPlanListComponent implements OnInit {

  plans: Plan[];

  plansOrganizations: Map<Plan, Organization[]>;

  subscriptions: Subscription[] = [];

  constructor(
    private modelProvider: ModelProvider,
    private router: Router
    ) { }

  async ngOnInit() {
    const plansSub = this.modelProvider.plan.findAllBy().subscribe(plans => {
      this.plans = plans;

      // for (const plan of this.plans) {
      //   const organizations = this.modelProvider.organization.findAllBy((ref) =>
      //     ref.where('plan.status', '==', 'active').where('plan.planRef', '==', plan.ref)
      //   ).subscribe(organizations => {
      //     this.plansOrganizations.set(plan, organizations);
      //   });
      // }

    });


    this.subscriptions.push(plansSub);
  }

  create() {
    this.router.navigate(['superadmin/plan', 'create'])
  }

  viewPlan(plan: Plan) {
    this.router.navigate(['superadmin/plan/edit/', plan.id])
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
