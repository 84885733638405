<!-- <ion-header>
  <ion-toolbar>
    <ion-title>organization</ion-title>
  </ion-toolbar>
</ion-header> -->
<app-header>
  <!-- <ion-title>{{'organization/title' | translate}}</ion-title> -->
</app-header>

<ion-content>
  <h1 class="page-title-with-submenu">
    {{ 'manage-organization/title' | translate }}
  </h1>
  <div class="submenu">
    <div class="submenu-item" [class.active-submenu]="activeTab === 'profile'" [class.disabled]="member?.level === 'billing'" (click)="setActiveTab('profile')">
      {{ 'manage-organization/settings/title' | translate }}
    </div>
    <div class="submenu-item" [class.active-submenu]="activeTab === 'members'" [class.disabled]="member?.level === 'billing'" (click)="setActiveTab('members')">
      {{ 'manage-organization/members/title' | translate }}
    </div>
    <div class="submenu-item" [class.active-submenu]="activeTab === 'players'" [class.disabled]="member?.level === 'billing'" (click)="setActiveTab('players')">
      {{ 'manage-organization/players/title' | translate }}
    </div>
    <div class="submenu-item" [class.active-submenu]="activeTab === 'package'" (click)="setActiveTab('package')">
      {{ 'manage-organization/package/title' | translate }}
    </div>
    <div *ngIf="organization?.stripe?.customerID && (organization?.plan$ | async)?.stripeID" class="submenu-item" [class.active-submenu]="activeTab === 'billing'" (click)="setActiveTab('billing')">
      {{ 'manage-organization/billing/title' | translate }}
    </div>
    <div [class.disabled]="member?.level === 'billing'" class="submenu-item" [class.active-submenu]="activeTab === 'integrations'" (click)="setActiveTab('integrations')">
      {{ 'manage-organization/integrations/title' | translate }}
    </div>
  </div>
  <content-container *ngIf="organization && activeTab !== 'billing'" [back-button]="false">
    <organization-profile *ngIf="activeTab === 'profile'" [organization]="organization"></organization-profile>
    <organization-current-package *ngIf="activeTab === 'package'" [organization]="organization" (goToInvoice)="setActiveTab('billing')"></organization-current-package>
    <!-- <organization-package *ngIf="activeTab === 'package'" [organization]="organization"></organization-package> -->
    <organization-members *ngIf="activeTab === 'members'" [organization]="organization" activeTab="members"></organization-members>
    <organization-members *ngIf="activeTab === 'players'" [organization]="organization" activeTab="players"></organization-members>
    <organization-integrations *ngIf="activeTab === 'integrations'" [organization]="organization"></organization-integrations>
    <!-- <organization-billing *ngIf="activeTab === 'billing'" #organizationBilling [organization]="organization">
    </organization-billing> -->
    <!-- <organization-invoice-list *ngIf="activeTab === 'billing' && organizationBilling?.invoices" [invoices]="organizationBilling.invoices"></organization-invoice-list> -->
    <!-- <organization-workspaces></organization-workspaces> -->
  </content-container>
  <ng-container *ngIf="activeTab === 'billing' && organization">
    <content-container [back-button]="false">
      <organization-billing #organizationBilling [organization]="organization">
      </organization-billing>
    </content-container>
    <content-container *ngIf="organizationBilling?.invoices" [back-button]="false">
      <organization-invoice-list [invoices]="organizationBilling.invoices"></organization-invoice-list>
    </content-container>
  </ng-container>
  <!-- <content-container [back-button]="false">
    <h1>Package</h1>
    
  </content-container> -->
  <!-- <content-container [back-button]="false">
    <organization-members></organization-members>
  </content-container> -->
</ion-content>