import { Component, OnInit, Input } from '@angular/core';
import { Response } from 'src/app/core/models/response';
import { QuoteLayoutItem } from 'src/app/core/models/layout-item-quote';
import { Task } from 'src/app/core/models/task.model';
import { combineLatest } from 'rxjs';
import { LayoutItem } from 'src/app/core/models/task-layout-item';
import { Player } from 'src/app/core/models/player.model';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Role } from 'src/app/core/models/role.model';
import { DatePipe } from '@angular/common';
import { TimeAgoPipe } from 'ngx-moment';
import { User } from 'src/app/core/models/user.model';
import { Timestamp } from '@firebase/firestore-types';
import firebase from "firebase/app";


@Component({
  selector: 'task-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements OnInit {

  // private _response: Response;
  // public get response(): Response {
  //   return this._response;
  // }
  public response: Response;
  public responseTask: Task;

  @Input()
  public tasks: Task[];

  @Input()
  public roles: Role[];

  @Input()

  private _players: Player[];
  @Input()
  set players(players: Player[]) {
    this._players = players;
    this.updateResponse().then();
  }
  get players(): Player[] {
    return this._players;
  }

  @Input()
  public layoutItem: QuoteLayoutItem;

  public responsePlayer: Player;
  public responseUser: User;


  // private _layoutItem: QuoteLayoutItem;
  // modelProvider: any;
  // @Input()
  // public set layoutItem(item: QuoteLayoutItem) {
  //   this._layoutItem = item;
  //   // if (this._layoutItem) {
  //   //   combineLatest(this._layoutItem.defaultResponse$, this._layoutItem.task$)
  //   //   // this._layoutItem.defaultResponse$.pipe(combineLatest(this._layoutItem.task$))
  //   //   .subscribe(([response, task]) => {
  //   //     this.response = response;
  //   //     this.responseTask = task;
  //   //   });
  //   // }
  // }
  // public get layoutItem() {
  //   return this._layoutItem;
  // }
  LayoutItem = LayoutItem;
  task: Task;
  role: Role;
  title: string;
  timeString: string;

  constructor(
    private translateService: TranslateService,
    private modelProvider: ModelProvider,
    private translate: TranslateService,
    private amAgoPipe: TimeAgoPipe,
    private datePipe: DatePipe,
  ) { }

  async ngOnInit() {
    if (this.tasks) {
      this.responseTask = this.tasks.find(task => task.getReference().isEqual(this.layoutItem.properties.taskRef));
    } else {
      this.responseTask = await this.layoutItem.task$.pipe(first()).toPromise();
    }
    if (this.players && this.responseTask) {
      const respondedPlayer = this.players.find(player => player.responses.getByID(this.responseTask.id));
      if (respondedPlayer) {
        this.responsePlayer = respondedPlayer;
        const respondedUser = await respondedPlayer.user$.pipe(first()).toPromise();
        this.responseUser = respondedUser;
        if (respondedUser?.deleted?.is) {
          this.response = this.deletedUserResponse
        } else {
          this.response = respondedPlayer.responses.getByID(this.responseTask.id);
        }
      }
    } else {
      // Preview
      this.response = this.defaultResponse;
      const player = this.modelProvider.player.create('player', {
        roleRef: this.roles.find(role => role.ref.path === this.responseTask.roleRef.path)?.ref
      });
      player.userRef = this.modelProvider.user.facilitatorRef;
      // console.log(this.task);
      player.addResponse(this.responseTask, this.response);
      this.responsePlayer = player;
      this.responseUser = await this.responsePlayer.user$.pipe(first()).toPromise();
    }

    // await new Promise(resolve => setTimeout(resolve, 100));

    // console.dir(this.responsePlayer.roleRef, this.responsePlayer.roleRef.path);


    this.task = this.responseTask;
    this.role = this.roles.find(role => {
      // console.log(role.ref.path, this.responsePlayer.roleRef.path, role.ref.path === this.responsePlayer.roleRef.path)
      return role.ref.path === this.responsePlayer.roleRef.path
    });//await this.responsePlayer.role$.pipe(first()).toPromise();

    // console.log(this.players, this.responseTask, this.responsePlayer, this.role, this.roles);
    this.title = this.responseUser.isMe
      ? this.translate.instant('playing/feed/post/quote/mine')
      : this.translate.instant('playing/feed/post/quote/other', { user: this.responseUser.displayName, role: this.role.title });

    if (this.responseUser.isFacilitator) {
      this.title = this.translate.instant('playing/feed/post/quote/facilitator', { user: this.role.title })
    }
    this.timeString = this.getTimeString(this.task, this.responsePlayer);
  }

  private async updateResponse() {
    if (this.players && this.responseTask) {
      const respondedPlayer = this.players.find(player => player.responses.getByID(this.responseTask.id));
      if (respondedPlayer) {
        this.responsePlayer = respondedPlayer;
        const respondedUser = await respondedPlayer.user$.pipe(first()).toPromise();
        this.responseUser = respondedUser;
        if (respondedUser?.deleted?.is) {
          this.response = this.deletedUserResponse
        } else {
          this.response = respondedPlayer.responses.getByID(this.responseTask.id);
        }
        // this.response = respondedPlayer.responses.getByID(this.responseTask.id);
      }
    } else {
      // this.response = this.defaultResponse;
    }
  }

  private get deletedUserResponse(): Response {
    const task = this.responseTask;

    const response = new Response({}, null, null, this.modelProvider);
    if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_TEXT)) {
      response.text = this.translateService.instant('script-details/quote/deleted/text');
    }
    if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO) || task.layout.getItemByType(LayoutItem.TYPE_INPUT_VIDEO)) {
      response.photo = {
        original: {
          ref: '',
          // tslint:disable-next-line: max-line-length
          url: 'https://firebasestorage.googleapis.com/v0/b/gamoteca-161414.appspot.com/o/auth%2Fno-image-available.png?alt=media&token=93640884-6ae6-482c-862d-405fb1294c6f',
          mimeType: 'image/jpeg'
        }
      };
    }
    if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_CHOICE)) {
      const item = (task.layout.choice);
      response.choice = Object.values(item.choices)[0].id;
    }
    return response;
  }

  private getTimeString(task: Task, player: Player) {
    const time = task.getResponseTime(player);
    const oneDayAgo = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
    // day hour  min  sec  msec
    if (oneDayAgo < time.getTime()) {
      return this.amAgoPipe.transform(time);
    } else {
      return this.datePipe.transform(time, 'short');
    }
  }

  private get defaultResponse(): Response {
    const task = this.responseTask;

    const response = new Response({}, null, null, this.modelProvider);
    if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_TEXT)) {
      response.text = this.translateService.instant('script-details/quote/default/text');
    }
    if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO) || task.layout.getItemByType(LayoutItem.TYPE_INPUT_VIDEO)) {
      response.photo = {
        original: {
          ref: '',
          // tslint:disable-next-line: max-line-length
          url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGO6BwAA5ADhV3yGqAAAAABJRU5ErkJggg==',
          mimeType: 'image/png'
        }
      };
    }
    if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_CHOICE)) {
      const item = (task.layout.choice);
      response.choice = Object.values(item.choices)[0].id;
    }
    response.createdAt = firebase.firestore.Timestamp.now()
    return response;
  }

  // // tslint:disable-next-line: member-ordering
  // private _defaultResponse$: ReplaySubject<Response>;
  // public get defaultResponse$(): ReplaySubject<Response> {
  //   if (this._defaultResponse$ !== undefined) { return this._defaultResponse$; }

  //   this._defaultResponse$ = new ReplaySubject<Response>(1);
  //   this.task$.pipe(map((task) => {
  //     const response = new Response({});
  //     if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_TEXT)) {
  //       response.text = this.modelProvider.translate.instant('script-details/quote/default/text');
  //     }
  //     if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO) || task.layout.getItemByType(LayoutItem.TYPE_INPUT_VIDEO)) {
  //       response.photo = {
  //         original: {
  //           ref: '',
  //           // tslint:disable-next-line: max-line-length
  //           url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGO6BwAA5ADhV3yGqAAAAABJRU5ErkJggg==',
  //           mimeType: 'image/png'
  //         }
  //       };
  //     }
  //     if (task.layout.getItemByType(LayoutItem.TYPE_INPUT_CHOICE)) {
  //       const item = (task.layout.choice);
  //       response.choice = Object.values(item.choices)[0].id;
  //     }
  //     return response;
  //   })).subscribe(this._defaultResponse$);

  //   return this._defaultResponse$;
  // }

}
