<div class="ion-padding modal-content">
<h1 class="m-0 mt-5" [innerHTML]="'script-list/empty/title' | translate">Welcome to the Gamoteca creator!</h1>
<div  class="row align-items-center">
  <div class="col-6">
    <div>
      <p class="lead" [innerHTML]="'script-list/empty/create/text' | translate">
        Get started with creating your own human-connected, game-based learning in a few easy steps. This video shows you how to get started with creating your own design from scratch.
      </p>
      <!-- <mat-icon class="expand">expand_more</mat-icon> -->
      <!-- <p class="instructions">Try out our template games on our Player app. To play or join a game, download the Gamoteca Player app available on the</p> -->
      <p class="buttons">
        <!-- <ion-button color="primary" shape="round" class="mr-3">
          <mat-icon slot="start">public</mat-icon>
          Use Template
        </ion-button> -->
        <ion-button (click)="create()" [disabled]="processing" color="warning" shape="round">
          <ion-spinner *ngIf="processing === 'create'" name="lines-small"></ion-spinner>
          <mat-icon *ngIf="processing !== 'create'" slot="start">add</mat-icon>
          <span [innerHTML]="'script-list/empty/create/button' | translate">New Design</span>
        </ion-button>
      </p>
    </div>
  </div>
  <div class="col-6">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7dVdkikv_I4?rel=0" allowfullscreen></iframe>
    </div>
  </div>
</div>
<hr />
<div class="row align-items-center">
  <div class="col-6">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/clW3z2nVaDc?rel=0" allowfullscreen></iframe>
    </div>
  </div>
  <div class="col-6">
    <div>
      <!-- <p class="lead mb-0"><strong>Need some ideas and inspiration?</strong></p> -->
      <p class="lead" [innerHTML]="'script-list/empty/template/text' | translate">
        Need some ideas and inspiration for your learning experiences? We have created a number of templates that can be easily adapted for your training requirements. This video shows you how to get started with templates.
      </p>
      <p class="buttons">
        <ion-button (click)="goToTemplate()" color="primary" shape="round" class="mr-3">
          <mat-icon slot="start">public</mat-icon>
          <span [innerHTML]="'script-list/empty/template/button' | translate">View Templates</span>
        </ion-button>
      </p>
    </div>
  </div>
</div>
</div>