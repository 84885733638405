import { Pipe, PipeTransform } from '@angular/core';
import { Team } from '../models/team.model';

@Pipe({
  name: 'teamOrder'
})
export class TeamOrderPipe implements PipeTransform {

  transform(teams: Team[], ...args: unknown[]): unknown {
    return teams.sort((a, b) => {
      if (a.order && b.order) {
        return (a.order > b.order) ? 1 : -1;
      }

      return 0;
    });
  }

}
