<div class="my-4 p-2 text-center">
  <!-- ORGANIZED GAME -->
  <ng-container *ngIf="type === 'organized-game'">
    <ng-container *ngIf="play.status === 'pre-start'">
      <!-- Joined -->
      <ng-container *ngIf="player">
        <ion-button *ngIf="hasFreeSlot" class="disable-hover" color="warning" fill="clear" size="large">
          {{'playing/play-status/custom/pre-start/has-free-slot' | translate}}
        </ion-button>
        <ion-button *ngIf="!hasFreeSlot" class="disable-hover" color="warning" fill="clear" size="large">
          {{'playing/play-status/custom/pre-start/has-not-free-slot' | translate}}
        </ion-button>
      </ng-container>
      <!-- Not Joined -->
      <ng-container *ngIf="!player">
        <!-- Locked -->
        <ion-button *ngIf="play.accessLevel === 'private'" class="disable-hover" color="warning" fill="clear"
          size="large">
          The game session is locked. Please contact the event organiser for more details.
        </ion-button>
        <!-- Unlocked -->
        <ion-button *ngIf="play.accessLevel === 'unlisted' || play.accessLevel === 'public'" class="ion-text-uppercase"
          color="warning" shape="round" size="large" (click)="joinClicked()" [disabled]="processing === 'join'">
          <ion-spinner *ngIf="processing  === 'join'" name="lines-small"></ion-spinner>
          {{'playing/play-details/join-game-button/organized-game/label' | translate}}
        </ion-button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="play.status === 'on-going'">
      <ng-container *ngIf="!player">
        <ion-button *ngIf="play.accessLevel === 'private'" class="disable-hover" color="warning" fill="clear" size="large">
          The game session is already started. Please contact the event organiser for more details.
        </ion-button>
        <ion-button *ngIf="play.accessLevel === 'unlisted' || play.accessLevel === 'public'" class="ion-text-uppercase"
        color="warning" shape="round" size="large" (click)="joinClicked()" [disabled]="processing === 'join'">
          <ion-spinner *ngIf="processing  === 'join'" name="lines-small"></ion-spinner>
          {{'playing/play-details/join-game-button/organized-game/label' | translate}}
        </ion-button>
      </ng-container>

      <ng-container *ngIf="player && player.status == 'active'">
        <ng-container *ngIf="(playService?.myTasksActual$ | async); let myActualTasks">
          <ng-container *ngIf="player.status !== 'finished' && myActualTasks?.length === 0; else elseHaveTask">
            <ion-item lines="none" class="no-task-left mx-auto">
              <ion-icon slot="start" name="cafe" size="large" color="warning"></ion-icon>
              <ion-label color="warning"><strong>{{'playing/play-status/waiting-for-response/title'| translate}}</strong><br />{{'playing/play-status/waiting-for-response/text' | translate}}</ion-label>
            </ion-item>
            <ng-container *ngIf="!platform.is('cordova') && !(isNotified$ | async)">
              <div class="clearfix"></div>
              <ion-button class="ion-text-uppercase" color="primary" shape="round" size="large" (click)="getNotified()">
                <ion-spinner *ngIf="processing  === 'notification'" name="lines-small"></ion-spinner>
                {{'playing/play-status/get-notified' | translate}}
              </ion-button>
            </ng-container>
          </ng-container>
          <ng-template #elseHaveTask>
            <ion-button *ngIf="player.responses?.isEmpty" class="ion-text-uppercase" color="warning" shape="round"
              size="large" (click)="playClicked()">
              {{'playing/play-status/on-going/start' | translate}}
            </ion-button>
            <ion-button *ngIf="!player.responses?.isEmpty" class="ion-text-uppercase" color="warning" shape="round"
              size="large" (click)="playClicked()">
              {{'playing/play-status/on-going/resume' | translate}}
            </ion-button>
          </ng-template>

        </ng-container>
      </ng-container>
    </ng-container>

    <ion-item *ngIf="player?.status === 'finished'" class="finished" lines="none">
      <ion-icon class="m-0 mx-3" slot="start" size="large" name="checkmark-circle-outline" mode="ios" color="primary">
      </ion-icon>
      <ion-label color="primary">
        <strong>{{'playing/play-status/finished/title' | translate}}</strong> <span [innerHTML]="'playing/play-status/finished/text' | translate"></span>
      </ion-label>
    </ion-item>
  </ng-container>



  <!-- INSTANT GAME -->
  <ng-container *ngIf="type === 'instant-game'">
    <ng-container *ngIf="play.status === 'pre-start'">
      <!-- Joined -->
      <ng-container *ngIf="player">
        <ion-button *ngIf="hasFreeSlot" class="disable-hover" color="warning" fill="clear" size="large">
          Waiting for players to join...
        </ion-button>
      </ng-container>
      <!-- Not Joined -->
      <ng-container *ngIf="!player">
        <ion-button class="disable-hover" color="warning" fill="clear" size="large" [disabled]="processing === 'join'">
          <ion-spinner *ngIf="processing  === 'join'" name="lines-small"></ion-spinner>
          {{'playing/play-status/joining' | translate}}
        </ion-button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="play.status === 'on-going'">
      <ng-container *ngIf="player && player.status == 'active'">
        <ng-container *ngIf="(playService?.myTasksActual$ | async); let myActualTasks">
          <ng-container *ngIf="player.status !== 'finished' && myActualTasks?.length === 0; else elseHaveTask">
            <ng-container *ngIf="hasFreeSlot">
              <ion-item lines="none" class="no-task-left mx-auto">
                <ion-icon slot="start" name="cafe" size="large" color="warning"></ion-icon>
                <ion-label color="warning"><strong>{{'playing/play-status/random/waiting-for-player/title' | translate}}</strong>
                  <br />{{'playing/play-status/random/waiting-for-player/text' | translate }}
                </ion-label>
              </ion-item>
            </ng-container>
            <ng-container *ngIf="!hasFreeSlot">
              <ion-item lines="none" class="no-task-left mx-auto">
                <ion-icon slot="start" name="cafe" size="large" color="warning"></ion-icon>
                <ion-label color="warning"><strong>{{'playing/play-status/waiting-for-response/title'| translate}}</strong><br />{{'playing/play-status/waiting-for-response/text' | translate}}</ion-label>
              </ion-item>
              <ng-container *ngIf="!platform.is('cordova') && !(isNotified$ | async)">
                <div class="clearfix"></div>
                <ion-button class="ion-text-uppercase" color="primary" shape="round" size="large" (click)="getNotified()">
                  <ion-spinner *ngIf="processing  === 'notification'" name="lines-small"></ion-spinner>
                  {{'playing/play-status/get-notified' | translate}}
                </ion-button>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #elseHaveTask>
            <ion-button *ngIf="player.responses?.isEmpty" class="ion-text-uppercase" color="warning" shape="round"
              size="large" (click)="playClicked()">
              {{'playing/play-status/on-going/start' | translate}}
            </ion-button>
            <ion-button *ngIf="!player.responses?.isEmpty" class="ion-text-uppercase" color="warning" shape="round"
              size="large" (click)="playClicked()">
              {{'playing/play-status/on-going/resume' | translate}}
            </ion-button>
          </ng-template>

        </ng-container>
      </ng-container>
    </ng-container>

    <ion-item *ngIf="player?.status === 'finished'" class="finished" lines="none">
      <ion-icon class="m-0 mx-3" slot="start" size="large" name="checkmark-circle-outline" mode="ios" color="primary">
      </ion-icon>
      <ion-label color="primary">
        <strong>{{'playing/play-status/finished/title' | translate}}</strong> <span [innerHTML]="'playing/play-status/finished/text' | translate"></span>
      </ion-label>
    </ion-item>
  </ng-container>



  <!-- INVITE GAME -->
  <ng-container *ngIf="type === 'invite-game'">
    <ng-container *ngIf="play.status === 'pre-start'">
      <!-- Joined -->
      <ng-container *ngIf="player">
        <!-- Organizer -->
        <ng-container *ngIf="play?.createdBy?.path === playService?.modelProvider?.user?.meReference.path">
          <!-- <ng-container *ngIf="!platform.is('cordova')">
            <textarea class="copy" #inputTarget>{{play?.shareURL}}</textarea>
            <ion-button *ngIf="playerRoles?.length > 1" (click)="invite(inputTarget.value, inputTarget)" shape="round"
              size="large" color="warning" class="ion-text-uppercase">
              Invite players
            </ion-button>
          </ng-container> -->
          <!-- <ng-container *ngIf="platform.is('cordova')"> -->
          <textarea class="copy" #inputTarget>{{'playing/play-status/invite/message' | translate : { name: playService?.meUser?.displayName, game: playService?.scriptVersion?.title, code: (play.share.code | mask: 'AAA-AAA'), url: (play?.shareURL) } }}</textarea>
          <ion-button *ngIf="playerRoles?.length > 1" (click)="invite(inputTarget.value, inputTarget)" shape="round"
            size="large" color="warning" class="ion-text-uppercase">
            {{'playing/play-status/invite/button/label' | translate}}
          </ion-button>
          <div class="clearfix"></div>
          <textarea class="copy" #codeInputTarget>You have been invited by {{playService?.meUser?.displayName}} to join and play "{{playService?.scriptVersion?.title}}" on Gamoteca.
Please use Game Code: {{play.share.code | mask: 'AAA-AAA'}} on the app, or
click on the URL to join the game:
{{play?.shareURL}}</textarea>
          <p *ngIf="playerRoles?.length > 1" class="text-muted cursor-pointer"
            (click)="inviteCode(codeInputTarget.value, codeInputTarget)">or <span>share game code: {{play.share.code |
              mask: 'AAA-AAA'}}</span></p>
          <!-- </ng-container> -->
          <ion-button *ngIf="playerRoles?.length === 1" class="disable-hover" color="warning" fill="clear" size="large"
            [disabled]="processing === 'join'">
            <ion-spinner *ngIf="processing  === 'join'" name="lines-small"></ion-spinner>
            {{'playing/play-status/joining' | translate}}
          </ion-button>
        </ng-container>
        <!-- Not organizer -->
        <ng-container *ngIf="play?.createdBy?.path !== playService?.modelProvider?.user?.meReference.path">
          <ion-button class="disable-hover" color="warning" fill="clear" size="large">
            Waiting for players to join...
          </ion-button>
        </ng-container>
      </ng-container>
      <!-- Not Joined -->
      <ng-container *ngIf="!player">
        <ion-button *ngIf="hasFreeSlot" class="ion-text-uppercase" color="warning" shape="round" size="large"
          (click)="joinClicked()" [disabled]="processing === 'join'">
          <ion-spinner *ngIf="processing  === 'join'" name="lines-small"></ion-spinner>
          {{'playing/play-details/join-game-button/public-game/label' | translate}}
        </ion-button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="play.status === 'on-going'">
      <ng-container *ngIf="!player">
        <ng-container *ngIf="hasFreeSlot">
          <ion-button class="ion-text-uppercase" color="warning" shape="round" size="large" (click)="joinClicked()"
            [disabled]="processing === 'join'">
            <ion-spinner *ngIf="processing  === 'join'" name="lines-small"></ion-spinner>
            {{'playing/play-details/join-game-button/public-game/label' | translate}}
          </ion-button>
        </ng-container>
        <ng-container *ngIf="!hasFreeSlot">
          <ion-item lines="none" class="no-task-left mx-auto">
            <ion-icon slot="start" name="alert-circle-outline" size="large" color="warning"></ion-icon>
            <ion-label color="warning"><strong>The game session is already started.</strong>
              <br />Please contact the event organiser for more details.
            </ion-label>
          </ion-item>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="player && player.status == 'active'">
        <ng-container *ngIf="(playService?.myTasksActual$ | async); let myActualTasks">
          <ng-container *ngIf="player.status !== 'finished' && myActualTasks?.length === 0; else elseHaveTask">
            <ng-container *ngIf="hasFreeSlot">
              <ion-item lines="none" class="no-task-left mx-auto">
                <ion-icon slot="start" name="people-circle-outline" size="large" color="warning"></ion-icon>
                <ion-label color="warning"><strong>{{'playing/play-status/invite/waiting-for-player/title' | translate}}</strong>
                  <br />{{'playing/play-status/invite/waiting-for-player/text' | translate}}
                </ion-label>
              </ion-item>
              <div>
                <textarea class="copy" #inputTarget>{{'playing/play-status/invite/message' | translate : { name: playService?.meUser?.displayName, game: playService?.scriptVersion?.title, code: (play.share.code | mask: 'AAA-AAA'), url: (play?.shareURL) } }}</textarea>
                <ion-button *ngIf="playerRoles?.length > 1" (click)="invite(inputTarget.value, inputTarget)"
                  shape="round" size="large" color="warning" class="ion-text-uppercase">
                  {{'playing/play-status/invite/button/label' | translate}}
                </ion-button>
              </div>
            </ng-container>
            <ng-container *ngIf="!hasFreeSlot">
              <ion-item lines="none" class="no-task-left mx-auto">
                <ion-icon slot="start" name="cafe" size="large" color="warning"></ion-icon>
                <ion-label color="warning"><strong>{{'playing/play-status/waiting-for-response/title'| translate}}</strong><br />{{'playing/play-status/waiting-for-response/text' | translate}}</ion-label>
              </ion-item>
              <ng-container *ngIf="!platform.is('cordova') && !(isNotified$ | async)">
                <div class="clearfix"></div>
                <ion-button class="ion-text-uppercase" color="primary" shape="round" size="large" (click)="getNotified()">
                  <ion-spinner *ngIf="processing  === 'notification'" name="lines-small"></ion-spinner>
                  {{'playing/play-status/get-notified' | translate}}
                </ion-button>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #elseHaveTask>
            <ion-button *ngIf="player.responses?.isEmpty" class="ion-text-uppercase" color="warning" shape="round"
              size="large" (click)="playClicked()">
              {{'playing/play-status/on-going/start' | translate}}
            </ion-button>
            <ion-button *ngIf="!player.responses?.isEmpty" class="ion-text-uppercase" color="warning" shape="round"
              size="large" (click)="playClicked()">
              {{'playing/play-status/on-going/resume' | translate}}
            </ion-button>
          </ng-template>

        </ng-container>
      </ng-container>
    </ng-container>

    <ion-item *ngIf="player?.status === 'finished'" class="finished" lines="none">
      <ion-icon class="m-0 mx-3" slot="start" size="large" name="checkmark-circle-outline" mode="ios" color="primary">
      </ion-icon>
      <ion-label color="primary">
        <strong>{{'playing/play-status/finished/title' | translate}}</strong> <span [innerHTML]="'playing/play-status/finished/text' | translate"></span></ion-label>
    </ion-item>
  </ng-container>
</div>