import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UseTemplateModalComponent } from './use-template-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ScriptContextMenuModule } from '../script-context-menu/script-context-menu.module';
import { UseTemplateAnalyticsService } from './use-template.analytics.service';



@NgModule({
  declarations: [
    UseTemplateModalComponent
  ],
  exports: [
    UseTemplateModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ScriptContextMenuModule,
    TranslateModule.forChild()
  ],
  providers: [
    UseTemplateAnalyticsService
  ]
})
export class UseTemplateModalModule { }
