
import { FirestoreField } from './firestore-field';
// import { FirestoreModel } from './firestore-model';
import { Model } from '../models/general/model';
import { ModelProvider } from '../models/general/model.provider';
import { FirestoreModel } from './firestore-model';

export class FirestoreMapField<T> extends FirestoreField implements Iterable<T> {

    public items: {
        [id: string]: T
    };

    // public constructor(
    //     data: any,
    //     protected documentInstance?: Model,
    //     protected options?: { skipSetFields?: true },
    //     protected modelProvider?: ModelProvider
    // ) {
    //     super(data, documentInstance, options, modelProvider);
    //     // // To reach model provider
    //     // if (!options || !options.skipSetFields) {
    //     //     this.setFields(data);
    //     // }
    // }

    public add(id: string, item: T) {
        this.items[id] = item;
        this.items = { ...this.items };
    }

    public getByID(id: string) {
        return this.items[id];
    }

    public get values() {
        return Object.values(this.items);
    }

    public get isEmpty() {
        return Object.values(this.items).length === 0;
    }

    public setFields(data: object) {
        this.items = {};

        for (const id in data) {
            if (data.hasOwnProperty(id)) {
                const item = data[id];
                this.items[id] = (this.options as any).mapToClass.instantiate(
                    {...item, id: id},
                    this.documentInstance,
                    this.options,
                    this.modelProvider
                );
            }
        }
    }

    public toData() {
        const data = {};
        for (const id in this.items) {
            if (this.items.hasOwnProperty(id)) {
                const item = this.items[id];
                data[id] = (item as any).toData();
            }
        }


        return data;
    }

    [Symbol.iterator]() {
        let pointer = 0;
        const items = Object.values(this.items);

        return {
            next(): IteratorResult<T> {
                if (pointer < items.length) {
                    return {
                        done: false,
                        value: items[pointer++]
                    };
                } else {
                    return {
                        done: true,
                        value: null
                    };
                }
            }
        };
    }

    // tslint:disable-next-line: member-ordering
    public static instantiate(data: any, model: FirestoreModel<any>, options: any, modelProvider: ModelProvider) {
        return new FirestoreMapField(data, model, options, modelProvider);
    }
}
