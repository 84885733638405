import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SessionArchiveConfirmationDialogComponent } from './session-archive-confirmation-dialog.component';
import { SessionArchiveConfirmationDialogAnalyticsService } from './session-archive-confirmation-dialog.analytics.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SessionArchiveConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    MatIconModule
  ],
  exports: [
    SessionArchiveConfirmationDialogComponent
  ],
  providers: [
    SessionArchiveConfirmationDialogAnalyticsService
  ],
  entryComponents: [
    SessionArchiveConfirmationDialogComponent
  ]
})
export class SessionArchiveConfirmationDialogModule { }
