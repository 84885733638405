<app-header active="design">
  <ion-title>{{'script-list/title' | translate}}</ion-title>
</app-header>

<app-team-sidebar></app-team-sidebar>

<ion-content>
  <div *ngIf="scripts.length <= 0" class="container py-3">
    <script-empty-list [listPage]="this" (onCreate)="addNewScript()"></script-empty-list>
  </div>
  <div *ngIf="workspace && scripts.length > 0" class="container py-3 script-list">
    <div class="top row title-row">
      <div class="col-6">
        <h2 *ngIf="workspace?.isPersonal">{{'workspace/personal/default-title' | translate}}</h2>
        <h2 *ngIf="!workspace?.isPersonal">{{workspace.title}}</h2>
      </div>
      <div class="col-6">
        <div *ngIf="!readonly" class="actions float-right">
          <ion-button shape="round"
                      (click)="addNewScript()"
                      [disabled]="!workspace || processing === 'new-script'"
                      type="submit"
                      id="btn-add-new-script">
            <ion-spinner *ngIf="processing === 'new-script'" name="lines-small"></ion-spinner>
            <mat-icon *ngIf="processing !== 'new-script'">add</mat-icon>
            {{'script-list/new-design' | translate}}
          </ion-button>
          <ion-button id="btn-show-tutorial" (click)="showEmpty()" class="create-info" fill="clear" color="secondary">
            <mat-icon>power_settings_new</mat-icon>
          </ion-button>
        </div>
      </div>

    </div>
    <div *ngIf="processing === 'loading'" class="text-center">
      <ion-spinner name="lines-small"></ion-spinner>
    </div>
    <div *ngIf="processing !== 'loading'" class="row">
            <div *ngFor="let script of scripts; trackBy:trackById"
                 class="col-lg-6">
              <script-card [routerLink]="['/design', script.id]"
                           [script]="script"
                           [version]="script.draft$ | async"></script-card>
            </div>
      <!-- <div *ngFor="let script of scripts" class="col-sm-6">
        <script-card [routerLink]="['/design', script.id]" [script]="script" [version]="script.draft" [user]="user"></script-card>
      </div> -->
    </div>

    <ion-infinite-scroll *ngIf="!scrollCompleted" threshold="80%" (ionInfinite)="onScroll($event)">
      <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</ion-content>
