import { DocumentReference, QueryFn } from "@angular/fire/firestore";
import { FirestoreModel } from "../firebase/firestore-model";
import { Model } from "./general/model";
import { Player } from "./player.model";
import { Response } from "./response";
import firebase from "firebase/app"
import { Observable } from "rxjs";
import { Feed } from "./feed";
import { Task } from "./task.model";
import { LayoutItem } from "./task-layout-item";
import { ImageLayoutItem } from "./layout-item-image";
import { Play } from "./play.model";
import { QuoteLayoutItem } from "./layout-item-quote";
import { Role } from "./role.model";
import { User } from "./user.model";
import { VideoLayoutItem } from "./layout-item-video";

export type QuoteInfo = {
  response: Response,
  task: Task,
  player: Player,
  role: Role,
  user: User
}

export class FeedPost extends FirestoreModel<FeedPost>{
  type: 'message' | 'invite' | 'task-upcoming' | 'response';
  text: string;
  userRef: DocumentReference;
  createdAt: firebase.firestore.Timestamp;
  imageURL: string;
  videoURL: string;

  taskRef: DocumentReference;

  referTo: DocumentReference;
  className: string;

  quote: QuoteInfo

  protected rules() {
    return {
      text: {
        [Model.RULE_DEFAULT]: ''
      },
      imageURL: {},
      videoURL: {},
      //   title: {},
      //   photoURL: {},
      // _image: { 
      //   [Model.RULE_ALIAS]: 'image',
      //   [Model.RULE_CLASS]: ImageWithSizes,
      //   [Model.RULE_DEFAULT]: () => new ImageWithSizes({}, this, {}, this.modelProvider)
      // },
      type: {
        [Model.RULE_DEFAULT]: 'message'
      },
      userRef: {},
      //   stripe: {
      //     [Model.RULE_DEFAULT]: {}
      //   },
      //   integrations: {}
    };
  }

  public findAllByFeed(feed: Feed, queryFn?: QueryFn): Observable<FeedPost[]> {
    return this.findAllBy(null, feed.getReference().collection('posts'));
  }

  protected instantiate(path: string, data, options?: any) {
    return new FeedPost(path, data, options, this.modelProvider);
  }

  icon = 'chat';

  createFromResponse(response: Response, player: Player, task: Task, options?: { className?: string, quote?: QuoteInfo }) {
    const quoteInfo = options?.quote;

    // console.log(response.id, response, (task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO, false), response.photoURL, (task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO, false) as ImageLayoutItem)?.url);
    // response.photoURL || (task.layout.getItemByType(LayoutItem.TYPE_INPUT_PHOTO, false) as ImageLayoutItem)?.url
    // console.log('4.2.3.0.1',new Date());
    const text = this.modelProvider.translate.instant('playing/feed/post/response/title/prefix') + ': ' + task.title;
    // console.log('4.2.3.0.2',new Date());
    const imageURL = response.photoURL; //|| (task.layout.getItemByType(LayoutItem.TYPE_IMAGE, false) as ImageLayoutItem)?.url;
    const videoURL = response.videoURL;
    // console.log('4.2.3.0.3',new Date());
    const post = this.instantiate(player.playRef.path + '/feeds/team' + player.teamRef.id + '/responses/' + response.id, {
      type: 'response',
      userRef: player.userRef,
      imageURL: imageURL,
      videoURL: videoURL,
      text: text,
      createdAt: response.createdAt,
    }, {
      isNew: true
    });
    // console.log('4.2.3.0.4',new Date());
    post.referTo = task.ref;
    post.icon = task.inputTypeIcon;
    post.className = options?.className;
    if (quoteInfo) {
      post.quote = quoteInfo;
    }
    return post;
  }

  createFromUpcomingTask(player: Player, task: Task, options?: {
    quote: {
      response: Response,
      task: Task,
      player: Player,
      role: Role,
      user: User
    }
  }) {
    // const response = player.responses.getByID(task.id);
    // const quoteInfo = this.getQuoteInfo(task, tasks, players);
    const quoteInfo = options?.quote;

    const post = this.instantiate(player.playRef.path + '/feeds/team' + player.teamRef.id + '/tasks/' + task.id, {
      type: 'task-upcoming',
      userRef: player.userRef,
      imageURL: quoteInfo?.response?.photoURL ? quoteInfo.response.photoURL : null,//(task.layout.getItemByType(LayoutItem.TYPE_IMAGE, false) as ImageLayoutItem)?.url,
      videoURL: quoteInfo?.response?.videoURL ? quoteInfo.response.videoURL : null,//(task.layout.getItemByType(LayoutItem.TYPE_VIDEO, false) as VideoLayoutItem)?.url,
      // text: this.modelProvider.translate.instant('playing/feed/post/task-upcoming/title/prefix') + ': ' + task.title,
      text: quoteInfo ? quoteInfo.role.title + ' sent you: ' + task.title : this.modelProvider.translate.instant('playing/feed/post/task-upcoming/title/prefix') + ': ' + task.title,
      createdAt: firebase.firestore.Timestamp.now(),
    }, {
      isNew: true
    });
    post.referTo = task.ref;
    post.icon = 'play_circle';

    // if (quoteInfo) {
    //   post.quote = quoteInfo;
    // }
    return post;
  }

  createInvitePost(play: Play, player: Player, code: string) {
    const post = this.instantiate(player.playRef.path + '/feeds/team' + player.teamRef.id + '/invite/' + player.id, {
      type: 'invite',
      userRef: player.userRef,
      // imageURL: (task.layout.getItemByType(LayoutItem.TYPE_IMAGE, false) as ImageLayoutItem)?.url,
      text: this.modelProvider.translate.instant('playing/feed/post/invite/title/prefix') + ' ' + code,
      createdAt: firebase.firestore.Timestamp.now(),
    }, {
      isNew: true
    });
    // post.referTo = task;
    post.icon = 'group_add';
    return post;
  }

  public static getQuote(task: Task, tasks: Task[], players: Player[], roles: Role[], users: User[]) {
    // const responseTaskQuotedTask = tasks.find(task => task.id === response.taskID && task.layout.getItemByType(LayoutItem.TYPE_QUOTE, false));
    const quotedTask = tasks.find(_task => _task.ref.path === (task.layout.getItemByType(LayoutItem.TYPE_QUOTE, false) as QuoteLayoutItem)?.properties?.taskRef?.path);
    if (quotedTask) {
      // const quotedTask = tasks.find(task => task.ref.path === (responseTaskQuotedTask.layout.getItemByType(LayoutItem.TYPE_QUOTE, false) as QuoteLayoutItem).properties.taskRef.path);
      const quotedPlayer = players.find(player => player.responses.getByID(quotedTask.id)?.done);
      if (quotedPlayer) {
        const quotedResponse = quotedPlayer.responses.getByID(quotedTask.id);
        const role = roles.find(role => role.ref.path === quotedPlayer.roleRef.path);
        const user = users.find(user => user.ref.path === quotedPlayer.userRef.path)
        if (quotedResponse) {
          return {
            response: quotedResponse,
            player: quotedPlayer,
            task: quotedTask,
            role: role,
            user: user
          }
        }
      }
    }
    return null;
  }

  get userCategory() {
    if (this.userRef.isEqual(this.modelProvider.user.meReference)) {
      return 'me';
    }

    if (this.userRef.isEqual(this.modelProvider.user.facilitatorRef)) {
      return 'facilitator';
    }

    return 'player';
  }

  // get timeString() {
  //   this.modelProvider.
  // }

  // getAsFeedItem() {
  //   FeedItem.
  //   const feedItem = new FeedItem(feedChannelPath + '/' + response.id, {
  //     type: 'response',
  //     userRef: player.userRef,
  //     text: response.text
  //   }, {}, null);
  //   return feedItem;
  // }
}
