import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ContextMenuClickEvent } from 'src/app/core/context-menu/context-menu-events';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { Script } from 'src/app/core/models/script.model';
import { WorkspaceService } from 'src/app/core/workspace/workspace.service';
import { OrganizationService } from 'src/app/organization/organization.service';
import { PlayModalAnalyticsService } from './play-modal-analytics.service';

@Component({
  selector: 'app-play-modal',
  templateUrl: './play-modal.component.html',
  styleUrls: ['./play-modal.component.scss'],
})
export class PlayModalComponent implements OnInit {

  @Input() script: Script;

  feedPostEnabled: boolean = false;
  autoJoinEnabled: boolean = false;
  playerDisplay: boolean = false;
  error: string;

  processing: string = null;
  subscriptions: Subscription[] = [];

  constructor(
    private modalCtrl: ModalController,
    private translateService: TranslateService,
    public organizationService: OrganizationService,
    public workspaceService: WorkspaceService,
    private clipboard: ClipboardService,
    private toastController: ToastController,
    private router: Router,
    private modelProvider: ModelProvider,
    public analytics: PlayModalAnalyticsService,
    public alertController: AlertController
  ) { }

  async ngOnInit() {
    this.autoJoinEnabled = (this.script.public && this.script.public.autoJoinEnabled) || false;
    this.playerDisplay = (this.script.public && this.script.public.displayForPlayer) || false;
    this.feedPostEnabled = (this.script.public && this.script.public?.feed?.postEnabled) || false;

    const scriptSub = this.modelProvider.script.findByRef(this.script.ref).subscribe(script => {
      this.script = script;
      this.autoJoinEnabled = (this.script.public && this.script.public.autoJoinEnabled) || false;
      this.playerDisplay = (this.script.public && this.script.public.displayForPlayer) || false;
      this.feedPostEnabled = (this.script.public && this.script.public?.feed?.postEnabled) || false;
    });
    this.subscriptions.push(scriptSub);

    await this.modelProvider.script.findByRef(this.script.ref).pipe(first()).toPromise();
    const draft = await this.script.draft$.pipe(first()).toPromise();
    this.analytics.setScreen(this.script.id, draft.title);
  }

  async ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    await this.analytics.close();
  }

  public async publish() {
    const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();

    if (!this.script.isPublished) {
      const roles = await this.script.draft$.pipe(switchMap(version => version.roles$), first()).toPromise();
      if (roles.length > 1) {
        await this.toggleFeedPost(true);
      }
    }

    if (!['active', 'trialing'].includes(organization.plan.status)) {
      await this.modalCtrl.dismiss({ action: 'canceled' });
      await this.organizationService.showWarning(this.translateService.instant('publish-settings/page-title'));
      return false;
    }

    try {
      if (!this.script.isPublished) {
        this.analytics.firstPublish();
      } else {
        this.analytics.publish();
      }

      this.processing = 'publish';

      if (!this.script.isPublished || await this.script.isPublicDiffers()) {
        await this.script.publish();
        this.script = await this.modelProvider.script.findByRef(this.script.ref).pipe(first()).toPromise();
      }
    } catch (error) {
      if (error && error.message === 'video-transcoding') {
        this.error = this.translateService.instant('publish-settings/error/video-transcoding');
      }
    } finally {
      this.processing = null;
    }
  }

  public async toggleRandomJoin($event: any) {
    this.analytics.toggleRandomPlayerMode(!!$event);

    this.script.public = {
      ...this.script.public,
      autoJoinEnabled: !!$event
    };
    await this.script.save();
  }

  public async togglePlayerDisplay($event: any){
    this.analytics.togglePlayerDisplay(!!$event);

    this.script.public = {
      ...this.script.public,
      displayForPlayer: !!$event
    };
    await this.script.save();
  }

  public async toggleFeedPost($event: any) {
    this.analytics.toggleFeedPost(!!$event);

    this.script.public = {
      ...this.script.public,
      feed: {
        postEnabled: !!$event
      }
    };
    await this.script.save();
  }

  public close() {
    this.modalCtrl.dismiss({ action: 'canceled' });
  }

  async openOrganize() {
    try {
      this.analytics.createOrganizedSession();

      const organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
      if (!organization.isActive) {
        throw new Error('plan-status-invalid');
      }

      this.router.navigateByUrl('/organize/create/' + this.script.id);
      this.modalCtrl.dismiss();
    } catch (error) {
      if (error.message === 'plan-status-invalid') {
        await this.organizationService.showWarning(this.translateService.instant('script-details/action/edit/move'));
      }
    } 
  }

  async copyInviteURL() {
    this.analytics.copyInstantSessionURL();

    if (this.script.isPublished) {
      const url = 'https://' + environment.dynamicLinkDomainPlayer + '/?link=' +
        encodeURIComponent(environment.web.playUrl + '/playing/game/' + this.script.id) +
        '&apn=' + environment.android.playPackageName + '&isi=' + environment.iOS.playAppId + '&ibi=' + environment.iOS.playBundleId;

      this.clipboard.copyFromContent(url);

      const toast = await this.toastController.create({
        message: this.translateService.instant('play-modal/invite-event/call-to-action/success'),
        duration: 2000
      });
      toast.present();

      return false;
    }
  }

}
