import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationMemberLevel } from 'src/app/core/models/organization-members.model';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-organization-invite-modal',
  templateUrl: './organization-invite-modal.component.html',
  styleUrls: ['./organization-invite-modal.component.scss'],
})
export class OrganizationInviteModalComponent implements OnInit {

  @Input()
  organization: Organization;

  email: string;
  memberRoleLevel: OrganizationMemberLevel = 'admin';
  isSendEmail = true;

  isSuperadmin = false;

  processing = false;

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthService
  ) {
  }

  async ngOnInit() {
    this.isSuperadmin = await this.authService.isSuperAdmin();
  }

  onCancel(closeType: string) {
    this.modalCtrl.dismiss({ action: 'cancel' });
  }

  async onSubmit(inviteForm: NgForm) {
    if (inviteForm.valid) {
      this.processing = true;
      const result = await this.organization.inviteMember(this.email, this.memberRoleLevel, this.isSendEmail);
      if(result.success){
        this.modalCtrl.dismiss({
          action: 'submit',
          mail: this.email,
          level: this.memberRoleLevel,
          isSendEmail: this.isSendEmail
        });
      }else{
        this.modalCtrl.dismiss({
          action: 'submit',
          mail: this.email,
          error: result.error,
          level: this.memberRoleLevel,
          isSendEmail: this.isSendEmail
        });
      }
      this.processing = false;
    }
  }

}
