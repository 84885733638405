import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginAsPage } from './login-as/login-as.page';
import { SetSuperadminPage } from './set-superadmin/set-superadmin.page';
import { DashboardPage } from './dashboard/dashboard.page';
import { EmailVerificationPage } from './email-verification/email-verification.page';
import { SuperadminPlanListComponent } from './plan/list/list.component';
import { SuperadminPlanEditComponent } from './plan/edit/edit.component';
import { SuperadminOrganizationListComponent } from './organization/list/list.component';
import { SuperadminOrganizationEditComponent } from './organization/edit/edit.component';
import { SuperadminGenerateUrlPage } from './generate-url/generate-url.page';
import { SupeardminAnalyticsSessionsPage } from './analytics/sessions/sessions.page';


const routes: Routes = [
  {
    path: 'superadmin/login-as',
    component: LoginAsPage
  },
  {
    path: 'superadmin/set-superadmin',
    component: SetSuperadminPage
  },
  {
    path: 'superadmin/dashboard',
    component: DashboardPage
  },
  {
    path: 'superadmin/email-verification',
    component: EmailVerificationPage
  },
  {
    path: 'superadmin/plan',
    component: SuperadminPlanListComponent
  },
  {
    path: 'superadmin/plan/create',
    component: SuperadminPlanEditComponent
  },
  {
    path: 'superadmin/plan/edit/:id',
    component: SuperadminPlanEditComponent
  },
  {
    path: 'superadmin/organization',
    component: SuperadminOrganizationListComponent
  },
  {
    path: 'superadmin/organization/create',
    component: SuperadminOrganizationEditComponent
  },
  {
    path: 'superadmin/organization/edit/:id',
    component: SuperadminOrganizationEditComponent
  },
  {
    path: 'superadmin/generate-url',
    component: SuperadminGenerateUrlPage
  },
  {
    path: 'superadmin/analytics/sessions',
    component: SupeardminAnalyticsSessionsPage
    // loadChildren: () => import('./analytics/sessions/sessions.module').then( m => m.SessionsPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperadminRoutingModule {}
