<div *ngIf="version && (version?.roles$ | async); else loadVersion" class="row card" [dir]="direction">
  <div class="img-container">
    <img *ngIf="version && version.imageUrl" [src]="version?._image | size : 'sm' : 'sm'" [alt]="version?.title" class="img" />
  </div>
  <div class="col-12 text-container">
    <div class="row">
      <div class="col-12">
        <div *ngIf="((PRIVILEGES.DESIGN_EDIT| privilege) | async)" class="actions">
          <script-context-menu [script]="script"></script-context-menu>
        </div>

        <div class="details">
          <div class="title">
            <h3 class="rtl-text">{{version?.title}}</h3>
          </div>
          <div *ngIf="version?.roles$ | async; let roles; else elseRoles" class="roles">
            <mat-icon>people</mat-icon>
            <!-- <span class="rtl-text">
              <ng-container *ngFor="let role of roles; let roleIndex = index">
                {{role.title}}<ng-container *ngIf="roleIndex < roles.length - 1">,</ng-container>
              </ng-container>
            </span> -->
            <span class="rtl-text">{{version?.rolesAsString}}</span>
          </div>
          <ng-template #elseRoles>
            <div class="roles">
              <mat-icon>people</mat-icon>
              <ion-skeleton-text [animated]="true" style="width: 100px; height: 15px"></ion-skeleton-text>
            </div>
          </ng-template>
          <!-- <div *ngIf="user"
               class="user">
            <img [src]="user.photoURL"
                 [alt]="user.displayName">
            {{user.displayName}}
          </div> -->
        </div>
      </div>
    </div>

    <div class="info-bar justify-content-between" [class.published]="script.isPublished"
      [class.sync]="!(script.isPublicDiffers$ | async)" [class.unsync]="script.isPublicDiffers$ | async">
      <div *ngIf="((PRIVILEGES.DESIGN_EDIT| privilege) | async)" class="main-actions d-flex align-items-center">
        <ion-button (click)="edit($event)" shape="round" color="warning" [title]="'script-details/action/edit/title' | translate">
          <mat-icon slot="icon-only">create</mat-icon>
        </ion-button>
        <ion-button (click)="play($event)" shape="round" color="primary" [title]="'script-details/action/play' | translate">
          <mat-icon slot="icon-only" class="play">play_arrow</mat-icon>
        </ion-button>
      </div>
      <ng-container *ngIf="!script.isPublished">
        <div class="date">
          {{ 'script-card/unpublished/status' | translate }}
        </div>
        <!-- <div class="status">
          {{ 'script-card/unpublished/status' | translate | uppercase }}
        </div> -->
      </ng-container>
      <ng-container *ngIf="script.isPublished">
        <div class="date">
          <ng-container *ngIf="!(script.isPublicDiffers$ | async)">
            {{ 'script-card/published/sync/date' | translate }} {{script?.public?.publishedAt.toDate() | dateShort}}
          </ng-container>
          <ng-container *ngIf="script.isPublicDiffers$ | async">
            {{ 'script-card/published/unsync/date' | translate }} {{script.updatedAt.toDate() | dateShort}}
          </ng-container>
        </div>
        <!-- <div class="status d-flex align-items-center">
          <mat-icon>flash_on</mat-icon>
          {{ 'script-card/published/status' | translate | uppercase }}
        </div> -->
      </ng-container>
    </div>
  </div>
</div>
<ng-template #loadVersion>
  <div class="row card" [dir]="direction">
  <div class="img-container">
    <ion-skeleton-text [animated]="true" style="width:150px; height: 150px;" class="img"></ion-skeleton-text>
  </div>
  <div class="col-12 text-container">
    <div class="row">
      <div class="col-12">
        <div class="details">
          <div class="title">
            <h3 class="rtl-text"><ion-skeleton-text [animated]="true" style="width:100%; height: 20px;"></ion-skeleton-text></h3>
          </div>
          <div class="roles">
            <!-- <mat-icon>people</mat-icon> -->
            <ion-skeleton-text [animated]="true" style="width: 100px; height: 15px"></ion-skeleton-text>
          </div>
      </div>
    </div>

    <div class="info-bar justify-content-between" class="main-actions d-flex align-items-center">
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 15px"></ion-skeleton-text>
      </div>
    </div>
  </div>
</div>
</ng-template>