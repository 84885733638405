import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ScriptResolver } from 'src/app/script/script.resolver';
import { PrivilegeGuard } from '../core/privilege/privilege.guard';
import { PRIVILEGES } from '../core/privilege/privilege-roles';

const routes: Routes = [
  {
    path: 'script',
    redirectTo: 'design'
  },
  {
    path: 'design',
    loadChildren: './list/script-list-page.module#ScriptListPageModule',
    canActivate: [AuthGuard, PrivilegeGuard],
    data: {privilege: PRIVILEGES.DESIGN_PAGE_VIEW, redirectTo: 'play-landing'}
  },
  {
    path: 'design/:scriptID/edit',
    loadChildren: './edit/script-edit-page.module#ScriptEditPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'design/:scriptID',
    loadChildren: './view/script-view-page.module#ScriptViewPageModule',
    canActivate: [AuthGuard],
    resolve: { script: ScriptResolver }
  },
  // { path: 'roles-edit', loadChildren: './designer/roles-edit/roles-edit.module#RolesEditPageModule' },
  // {
  //   path: 'design/:scriptID/task/:taskID/edit',
  //   loadChildren: './designer/task-edit/task-edit.module#TaskEditPageModule',
  //   canActivate: [AuthGuard],
  //   // resolve: {
  //   //   script: ScriptResolver,
  //   //   task: TaskResolver
  //   // }
  // },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [
    ScriptResolver
  ]
})
export class ScriptRoutingModule { }
