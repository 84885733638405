<ng-container *ngIf="response && layoutItem.task">
  <!-- <ion-item lines="none">
    <ion-avatar slot="start" class="mr-2">
      <img src="https://firebasestorage.googleapis.com/v0/b/gamoteca-161414.appspot.com/o/users%2Ffacilitator%2Favatar.png?alt=media&token=5265ea4d-525a-4212-873c-96aa13c9b649">
    </ion-avatar>
    <p>
      <strong class="role rtl-text" [style.color]="responseTask?.role?.color">
        <ng-container *ngIf="responseUser && responseUser?.id !== 'facilitator'">{{responseUser.displayName}} ({{responseTask?.role?.title}})</ng-container>
        <ng-container *ngIf="!responseUser">{{responseTask?.role?.title}}</ng-container>
      </strong>
      {{'script-details/quote/answered' | translate}}
      <strong class="rtl-text">{{responseTask?.title}}</strong>:</p>
  </ion-item>
  <div class="content">
    <pre *ngIf="response.text" class="rtl-text"><q>{{response.text}}</q></pre>
    <task-image *ngIf="response.photoURL" [response]="response"></task-image>
    <layout-item-video *ngIf="response.videoURL" [response]="response"></layout-item-video>
    <p *ngIf="response.choice" class="rtl-text"><strong>{{responseTask.layout.choice.choices[response.choice].text}}</strong></p>
  </div> -->

  <div class="container-fluid">
    <div class="row top pb-4 align-items-center">
      <div *ngIf="responseUser" class="col-auto pl-0 avatar-container">
        <img class="profile" [src]="responseUser?.photoURL" [alt]="responseUser.displayName" />
      </div>
      <div class="col pl-2 pr-0">
        <div>
          <p class="font-bold" class="title my-0">{{title}}</p>
          <p class="text-muted time my-0">
            <small>{{timeString}}</small>
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="response?.mediaType; let mediaType" class="row media position-relative">
      <ng-container *ngIf="response.mediaType === 'image'">
        <task-image *ngIf="response.photoURL" [response]="response"></task-image>
        <!-- <img [src]="response.photoURL" alt="" /> -->
      </ng-container>
      <ng-container *ngIf="response.mediaType === 'video'">
        <!-- <video [src]="response.videoURL+'#t=0.1'"></video> -->
        <layout-item-video *ngIf="response.videoURL" [response]="response"></layout-item-video>
        <!-- <mat-icon>play_circle_outline</mat-icon> -->
      </ng-container>
      <ng-container *ngIf="response.mediaType === 'choice'">
        <div class="col">
          <div class="row message mb-3 align-items-center">
            <div class="col col-auto px-0 icon">
              <span>
                <mat-icon>quiz</mat-icon>
              </span>
            </div>
            <div class="col pl-0 mt-1 text">
              <ion-radio-group *ngIf="this.task?.layout?.getItemByType(LayoutItem.TYPE_INPUT_CHOICE, false); let item"
              class="choice mb-0 py-1 px-3" [value]="response.choice">
              <ion-list-header class="p-0">
                <ion-label class="rtl-text mt-0"><i>{{item.question}}</i></ion-label>
              </ion-list-header>
              <ion-item *ngFor="let choice of item.choices | keyvalue | orderBy: 'value.order'" class="p-0 m-0 py-2" mode="md"
                lines="none">
                <ion-label class="m-0 p-0 ion-text-wrap rtl-text">{{choice.value.text}}</ion-label>
                <ion-radio [value]="choice.key" [disabled]="true" checked mode="md" slot="start" color="dark" class="m-0 mr-3 p-0"
                  [value]="choice.key" slot="start"></ion-radio>
              </ion-item>
            </ion-radio-group>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="response.mediaType === 'text'">
        <div class="col">
          <div class="row message mb-3 align-items-center">
            <div class="col col-auto px-0 icon">
              <span>
                <mat-icon>chat</mat-icon>
              </span>
            </div>
            <div class="col pl-0 mt-1 text">
              <p class="my-0 pre">{{response.text}}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</ng-container>