import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { LoginAsPage } from './login-as.page';
import { AppComponentsModule } from 'src/app/core/app-components.module';
import { AuthModule } from 'src/app/auth/auth.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperModule } from 'ngx-popper';
import { ModelsModule } from 'src/app/core/models/models.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AppComponentsModule,
    AuthModule,
    TranslateModule,
    NgxPopperModule,
    ModelsModule
  ],
  declarations: [LoginAsPage]
})
export class LoginAsPageModule {}
