import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PlayEditPageModule } from './edit/play-edit-page.module';
import { PlayViewPageModule } from './view/play-page-view.module';
import { PlayListPageModule } from './list/play-list-page.module';
import { PlayRoutingModule } from './play-routing.module';
import { WorkspaceModule } from '../core/workspace/workspace.module';

@NgModule({
  imports: [
    IonicModule,
    PlayListPageModule,
    PlayViewPageModule,
    PlayEditPageModule,
    PlayRoutingModule,
    WorkspaceModule
  ],
  exports: [
    PlayViewPageModule,
    PlayEditPageModule,
    PlayListPageModule,
    PlayRoutingModule,
  ]
})
export class PlayPageModule { }
