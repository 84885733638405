import { FirestoreField } from '../../firebase/firestore-field';
import { FirestoreModel } from '../../firebase/firestore-model';
import { ModelProvider } from './model.provider';
import { Model } from './model';

export class LocalizedText extends FirestoreField {
    public languages: {
        [language: string]: string;
    };

    public static instantiate(data: any, model: FirestoreModel<Model>, options: any, modelProvider: ModelProvider): LocalizedText {
        return new LocalizedText(data, model, options, modelProvider);
    }

    public static empty(model: FirestoreModel<Model>, modelProvider: ModelProvider): LocalizedText {
        const emptyData = {
            languages: {}
        };
        emptyData.languages[modelProvider.localize.language] = null;
        return new LocalizedText(emptyData, model, {}, modelProvider)
    }

    public getText() {
        return this.modelProvider.localize.getText(this);
    }

    public setText(value: string) {
        this.modelProvider.localize.setText(this, value);
    }

    protected rules() {
        return {
            languages: {}
        };
    }}
