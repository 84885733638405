import { Component, OnInit, Input } from '@angular/core';
import { Play } from 'src/app/core/models/play.model';
import { Subscription } from 'rxjs';
import { ModelProvider } from 'src/app/core/models/general/model.provider';

@Component({
  selector: 'play-stat',
  templateUrl: './play-stat.component.html',
  styleUrls: ['./play-stat.component.scss'],
})
export class PlayStatComponent implements OnInit {
  @Input()
  plays: Play[];

  totalCount: number;
  preStartedCount: number;
  ongoingCount: number;
  finishedCount: number;

  instantCount: number;
  organizedCount: number;

  data

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  cardColor: string = '#232837';

  private subplayions: Subscription[] = [];


  constructor(
    private modelProvider: ModelProvider
  ) { }

  ngOnInit() {
    const queryFn = ref => ref//.limit(10);
    // const userSub = this.modelProvider.play.findAllBy(queryFn).subscribe(plays => {
      // this.plays = plays;

      this.totalCount = this.plays.length;
      this.preStartedCount = this.plays.filter(play => play.status === Play.STATUS_PRESTART).length;
      this.ongoingCount = this.plays.filter(play => play.status === Play.STATUS_ONGOING).length;
      this.finishedCount = this.plays.filter(play => play.status === Play.STATUS_FINISHED).length;
      // this.updatedCount = this.plays.filter(user => user.roles && user.roles.designer && user.roles.designer.active).length;
      this.instantCount = this.plays.filter(play => play.type === Play.TYPE_INSTANT).length;
      this.organizedCount = this.plays.filter(play => play.type === Play.TYPE_ORGANIZED).length;
      this.data = this.getData();
    // });
    // this.subplayions.push(userSub);
  }

  private getData() {
    return [
      // {
      //   "name": "Total",
      //   "value": this.plays.length
      // },
      {
        "name": "Waiting to start",// (" + Math.round(this.preStartedCount / this.totalCount * 100) + "%)",
        "value": this.preStartedCount
      },
      {
        "name": "Running",// (" + Math.round(this.ongoingCount / this.totalCount * 100) + "%)",
        "value": this.ongoingCount
      },
      {
        "name": "Finished",// (" + Math.round(this.finishedCount / this.totalCount * 100) + "%)",
        "value": this.finishedCount
      },
    ];
  }

  ngOnDestroy(): void {
    this.subplayions.forEach(sub => sub.unsubscribe())
  }

}
