import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-set-superadmin',
  templateUrl: './set-superadmin.page.html',
  styleUrls: ['./set-superadmin.page.scss'],
})
export class SetSuperadminPage implements OnInit {

  public userID = '';

  constructor(
    private fns: AngularFireFunctions,
    public toastController: ToastController
  ) { }

  ngOnInit() {
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  submit() {
    const callable = this.fns.httpsCallable('setSuperadmin');
    callable({ userID: this.userID }).subscribe(data => {
      console.log(data);
      if(data) {
        this.presentToast('Successfully verified: ' + this.userID)
        this.userID = '';
      }
    })
  }

}
