<ng-container *ngIf="!contextMenuGroup">
  <li>
    <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
    {{label}}
    <!-- <ng-container *ngTemplateOutlet="other"></ng-container> -->
  </li>
</ng-container>
<ng-container *ngIf="contextMenuGroup">
  <li>
    <div [popper]="contextMenuGroup.popper"
         [popperPlacement]="'right-start'"
         [popperTrigger]="'hover'"
         [popperModifiers]="flipmodifier"
         [popperPreventOverflow]="true"
         [popperBoundaries]="'body'"
         class="menu-item-line">
      {{label}}
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
    <ng-content #contextGroup select="context-group" ngProjectAs="context-group"></ng-content>
  </li>
</ng-container>


<ng-template #other>
  <ng-content #otherContent></ng-content>
</ng-template>
