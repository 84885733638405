import { Component, OnInit, Input } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { Plan } from 'src/app/core/models/plan';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Organization } from 'src/app/core/models/organization.model';

@Component({
  selector: 'app-upgrade-required-modal',
  templateUrl: './upgrade-required-modal.component.html',
  styleUrls: ['./upgrade-required-modal.component.scss'],
})
export class UpgradeRequiredModalComponent implements OnInit {
  @Input()
  public feature: string;

  @Input()
  public title: string;

  @Input()
  public organization: Organization;

  public plan: Plan;
  public current: number;
  public limit: number;

  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    if (!this.organization) {
      this.organization = await this.organizationService.activeOrganization$.pipe(first()).toPromise();
    }

    // if(this.feature) {
    //   this.feature = this.type;
    // }

    this.plan = await this.organization.plan$.pipe(first()).toPromise();

    switch (this.feature) {
      case 'workspace':
        const workspaces = await this.organization.workspaces$.pipe(first()).toPromise();

        this.current = workspaces.length;
        this.limit = this.plan.features.workspaceLimit;
        break;

      default:
        break;
    }
  }

  async upgrade() {
    await this.router.navigateByUrl('organization/create');
    await this.modalController.dismiss();
  }

  async cancel() {
    await this.modalController.dismiss();
  }

}
