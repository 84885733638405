<ng-container *ngIf="task">
  <h2 class="rtl-text">{{task.title}}</h2>
  <div class="layout-item"
       col-12
       *ngFor="let item of (task?.layout?.items | layoutOrderPipe)">
    <div class="response"
         *ngIf="item.type == 'response'">
      <task-quote [layoutItem]="item"
                  [tasks]="tasks"
                  [players]="players"
                  [roles]="roles"></task-quote>
    </div>
    <div class="image"
         *ngIf="item.type == 'image'">
      <task-image [url]="item.url"></task-image>
    </div>
    <div class="video"
         *ngIf="item.type == 'video'">
      <layout-item-video [taskItem]="item"></layout-item-video>
    </div>
    <div class="image-360"
         *ngIf="item.type == 'image-360'">
      <image-vr [taskItem]="item"></image-vr>
    </div>
    <div class="video-360"
         *ngIf="item.type == 'video-360'">
      <video-vr [taskItem]="item"></video-vr>
    </div>
    <div class="text"
         *ngIf="item.type == 'text'">
         <!-- <p>{{item.text}}</p> -->
      <task-text [text]="item.text" [format]="item.format"></task-text>
    </div>
    <div class="input-choice"
         *ngIf="item.type == 'input-choice'">
      <!-- <ion-list class="choice p-0"> -->
      <ion-radio-group class="choice"
                      [value]="response?.choice"
                       (ionChange)="onChoiceSelected($event)">
        <ion-list-header class="p-0">
          <ion-label class="rtl-text">{{item.question}}</ion-label>
        </ion-list-header>
        <ion-item *ngFor="let choice of item.choices | keyvalue | orderBy: 'value.order'"
                  class="p-0 m-0 py-2"
                  mode="md"
                  lines="none">
          <ion-label class="m-0 p-0 ion-text-wrap rtl-text">{{choice.value.text}}</ion-label>
          <ion-radio [value]="choice.key"
                    [disabled]="!response.isNew"
                     checked
                     mode="md"
                     slot="start"
                     color="dark"
                     class="m-0 mr-3 p-0"
                     [value]="choice.key"
                     slot="start"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <!-- </ion-list> -->
      <!-- <ion-item text-wrap>
          <h2>{{item.question}}</h2>
        </ion-item>
        <ion-list mode="md" radio-group [disabled]="member.status == 'finished'" [(ngModel)]="response.choice">
          <ion-item *ngFor="let option of item.value | orderBy: 'order' | keyvalue" mode="md" text-wrap>
            <ion-label mode="md">{{option.value.text}}</ion-label>
            <ion-radio [value]="option.key" item-start mode="md"></ion-radio>
          </ion-item>
        </ion-list> -->
    </div>
    <div class="input-text"
         *ngIf="item.type == 'input-text'">
      <p class="rtl-text"><i>{{item.question}}</i></p>
      <task-input-text *ngIf="response.isNew || isSubmiting" (onTextTyped)="onTextTyped($event)"></task-input-text>
      <!-- <ion-textarea *ngIf="response.isNew || isSubmiting"
                    (ionChange)="onTextTyped($event)"
                    rows="5"
                    placeholder="Enter your feedback"
                    [autoGrow]="true"></ion-textarea> -->
      <q *ngIf="!response.isNew && !isSubmiting"
         class="pre rtl-text"><i>{{response.text}}</i> </q>
    </div>
    <div class="input-photo"
         *ngIf="item.type == 'input-photo'">
      <ng-container *ngIf="response.isNew || isSubmiting">
        <camera-mobile *ngIf="platform.is('cordova')"
                       (file)="setResponseImage($event)"></camera-mobile>
        <camera-desktop *ngIf="!platform.is('cordova')"
                        #cameraDesktop
                        (file)="setResponseImage($event)"></camera-desktop>
      </ng-container>
      <task-image *ngIf="!response.isNew && !isSubmiting"
                  [response]="response"></task-image>
    </div>
    <div class="input-video"
         *ngIf="item.type == 'input-video'">
      <ng-container *ngIf="response.isNew || isSubmiting">
        <video-record-mobile *ngIf="platform.is('cordova')"
                              [cacheKey]="response.getVideoCacheKey(player.id)"
                              [layoutItem]="item"
                              (onTextTyped)="onTextTyped($event)"
                              (file)="setResponseVideo($event)"></video-record-mobile>
        <video-record-desktop *ngIf="!platform.is('cordova')"
                              #videoRecordDesktop
                              [layoutItem]="item"
                              (onTextTyped)="onTextTyped($event)"
                              (file)="setResponseVideo($event)"></video-record-desktop>
      </ng-container>
      <pre><q *ngIf="!response.isNew && !isSubmiting && response.text" class="pre rtl-text"><i>{{response.text}}</i> </q></pre>
      <layout-item-video *ngIf="!response.isNew && !isSubmiting && response.video"
                         [response]="response"></layout-item-video>
    </div>
  </div>
  <div class="text-center submit">
    <ion-button *ngIf="response.isNew || isSubmiting"
                [class.complete]="task.inputType === 'complete'"
                (click)="onSubmit()"
                [disabled]="isSendDisabled()"
                color="primary"
                shape="round">
      <ion-spinner *ngIf="isSubmiting"
                   name="lines-small"></ion-spinner>
      <ng-container *ngIf="task.inputType !== 'complete'">
        <!-- <ion-icon *ngIf="!isSubmiting" name="send" slot="start"></ion-icon> -->
        <mat-icon *ngIf="!isSubmiting" slot="start" class="mr-2">send</mat-icon>
          {{'playing/task-view/submit-button/has-input/label' | translate}}
      </ng-container>
      <ng-container *ngIf="task.inputType === 'complete'">
        <!-- <ion-icon *ngIf="!isSubmiting" name="checkmark-circle" slot="start"></ion-icon> -->
        <mat-icon *ngIf="!isSubmiting" slot="start" class="mr-2">play_circle_outline</mat-icon>
        <!-- <mat-icon *ngIf="!isSubmiting" slot="start" class="mr-2">assignment_turned_in</mat-icon> -->
        
        <!-- <mat-icon *ngIf="!isSubmiting" slot="start" class="mr-2">task_alt</mat-icon> -->
          {{'playing/task-view/submit-button/no-input/label' | translate}}
      </ng-container>
      <ng-container *ngIf="(response.uploadPercent$ | async); let uploadPercent">
          <span class="ml-1"
          *ngIf="uploadPercent > 0 && uploadPercent < 100">{{uploadPercent | number:'1.0-0'}} %</span>
      </ng-container>
    </ion-button>
    <ion-button *ngIf="!response.isNew && !isSubmiting"
                color="primary"
                fill="clear">
      {{'task/preview/completed/label' | translate}}
      <mat-icon class="ml-3">check</mat-icon>
    </ion-button>
  </div>
  <!-- <ion-row>
  <ion-col class="layout-item" col-12 *ngFor="let item of (task.layout | layoutOrderPipe)">
  </ion-col>
</ion-row> -->
</ng-container>
