import { FirestoreModel } from '../firebase/firestore-model';
import { Model } from './general/model';

export type Price = {
  currencies: {
    [name: string]: {
      amount: number,
      stripeID: string,
    }
  },
  trialPeriodDays: number,
  invoiceDaysUntilDue: number,
}

export class Plan extends FirestoreModel<Plan> {
  public COLLECTION_NAME = 'plans';

  title: string;
  pricing: {
    monthly?: Price,
    yearly?: Price
  };
  role: string;
  stripeID: string;
  features: {
    workspaceLimit: number,
    scriptPerWorkspaceLimit: number,
    designerLimitPerWorkspace: number,
    playerLimit: number,
    sso?: boolean,
    lti?: boolean,
  };

  protected rules() {
    return {
      title: {
        [Model.RULE_DEFAULT]: ""
      },
      pricing: {
        [Model.RULE_DEFAULT]: {}
      },
      role: {},
      stripeID: {},
      features: {
        [Model.RULE_DEFAULT]: {
          workspaceLimit: 0,
          scriptPerWorkspaceLimit: 0,
          designerLimitPerWorkspace: 0,
          playerLimit: 0,
        }
      },
    };
  }

  protected instantiate(path, data, options?: any) {
    return new Plan(path, data, options, this.modelProvider);
  }

  addPrice(period: 'monthly' | 'yearly', currency: string) {
    this.pricing = {
      ...this.pricing,
      [period]: {
        currencies: {
          [currency]: {
            amount: 0,
            stripeID: null,
          }
        },
        trialPeriodDays: 0,
        invoiceDaysUntilDue: 0,
      }
    }
  }
}