import { FirestoreModel } from '../firebase/firestore-model';

import { map } from 'rxjs/operators';
import { Model } from './general/model';
import { LocalizedText } from './general/localized-text';
import { ScriptVersion } from './script-version.model';
import firebase from "firebase/app"

export class Role extends FirestoreModel<Role> {
  public _title: LocalizedText;
  public get title(): string {
    return this._title.getText();
  }
  public set title(text: string) {
    this._title.setText(text);
  }
  public color?: string;
  public order?: number;
  public legacy?: {
    roleID?: string;
  };
  public type?: string;//'facilitator' | 'player';

  protected rules() {
    return {
      _title: { [Model.RULE_ALIAS]: 'title', [Model.RULE_CLASS]: LocalizedText },
      order: {},
      color: {},
      legacy: {},
      type: {},
    };
  }

  public async save() {
    await super.save();
    // Update script version update time
    await this.getReference().parent.parent.update('updatedAt', firebase.firestore.Timestamp.now());
  }

  public findAllByVersion(scriptVersion: ScriptVersion) {
    return this.findAllBy(null, scriptVersion.ref.collection('roles'));
  }

  public findByID(id: string, scriptId?: string, versionId?: string) {
    if (scriptId && versionId) {
      return this.findByRef('scripts/' + scriptId + '/versions/' + versionId + '/roles/' + id);
    } else {
      return super.findByID(id);
    }
  }

  protected instantiate(path, data, options?: any) {
    return new Role(path, data, options, this.modelProvider);
  }

  public async createDefaultRolesForVersion(scriptVersion: ScriptVersion) {
    const roles: Array<Role> = [];
    const player1 = this.createDefaultRole('Player 1', '#ff0000', scriptVersion, roles);
    const player2 = this.createDefaultRole('Player 2', '#0099dd', scriptVersion, roles);
    roles.push(player1);
    roles.push(player2);
    for (const role of roles) {
      await role.save();
    }
    // roles.forEach(role => {
    //   role.save();
    // });
  }

  private createDefaultRole(title: string, color: string, scriptVersion: ScriptVersion, roles: Array<Role>) {
    return this.modelProvider.role.create(scriptVersion.getDocument().ref.path + '/roles', {
      title: title,
      color: color,
      order: roles.length + 1,
      legacy: {},
      type: 'player'
    });
  }
}
