import { Component, OnInit } from '@angular/core';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss'],
})
export class DeleteAccountModalComponent implements OnInit {

  confirm: string;

  processing = false;

  constructor(
    private router: Router,
    private modelProvider: ModelProvider,
    private modalCtrl: ModalController,
    private authService: AuthService
  ) { }

  ngOnInit() {}

  public async onSubmit(form: NgForm, submittedBySaveButton = true) {
    if (form.invalid) {
      return;
    }

    if(form.value.confirm !== 'DELETE') {
      return;
    }

    this.processing = true;

    const result = await this.modelProvider.functions.httpsCallable('deleteAccount')({}).pipe(first()).toPromise();
    console.log(result);
    this.processing = false;
    this.authService.signOut();
    await this.router.navigateByUrl('/welcome');
    window.location.reload(true);

    // .subscribe(data => {
    //   console.log(data);
    //   if(data) {
    //     this.presentToast('Successfully verified: ' + this.userID)
    //     this.userID = '';
    //   }
    // })

  }

  public onCancel() {
    this.modalCtrl.dismiss({ action: 'canceled' });
  }

}
