import { Script } from '../../../core/models/script.model';
import { ScriptVersion } from '../../../core/models/script-version.model';
import { AnalyticsService } from '../../../core/analytics/analytics.service';
import { AnalyticsProvider } from '../../../core/analytics/analytics';
import { SkipSelf } from '@angular/core';
import { Workspace } from 'src/app/core/models/workspace.model';

export abstract class ScriptCardAnalyticsService implements AnalyticsService {

  constructor(
    @SkipSelf() protected analyticsService: AnalyticsProvider
    ) {
  }

  createContextMenuItemClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Context menu clicked'
                                ],
                                version.title,
                                {
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  };

  createViewDesignButtonClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'View design button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  }

  createEditDesignButtonClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Edit design button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  }

  createOrganizeButtonClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Organize button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  }

  createEditDetailsButtonClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Edit details button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  }

  createMoveToButtonClickedEvent(script: Script, version: ScriptVersion, workspace: Workspace): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Move to button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id,
                                  'workspace-title': workspace.title,
                                  'workspace-id': workspace.id
                                });
  }

  createDuplicateScriptButtonClickedEvent(script: Script, version: ScriptVersion, workspace: Workspace): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Duplicate button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'game-id': script.id,
                                  'workpace-title': workspace.title,
                                  'workpace-id': workspace.id,
                                });
  }

  createInviteURLButtonClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Invite URL button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  }

  createPublishButtonClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Publish button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  }

  createDeleteButtonClickedEvent(script: Script, version: ScriptVersion): void {
    this.analyticsService.event([
                                  'Game card',
                                  'Delete button clicked'
                                ],
                                version.title,
                                {
                                  type: 'button',
                                  'version-id': version.id,
                                  'game-id': script.id
                                });
  }
}