import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BrowserRecommendationModalComponent } from './browser-recommendation-modal/browser-recommendation-modal.component';
import * as Bowser from "bowser"; // TypeScript

@Injectable({
  providedIn: 'root'
})
export class BrowserRecommendationService {

  modal: HTMLIonModalElement;

  constructor(
    private modalController: ModalController,
    public platform: Platform
  ) { }

  async showModal() {
    this.modal = await this.modalController.create({
      component: BrowserRecommendationModalComponent,
      componentProps: {},
      cssClass: 'auto-height browser-recommendation'
    });
    this.modal.present();
    const data = await this.modal.onDidDismiss();
    return data;
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  browser() {
    return Bowser.getParser(window.navigator.userAgent).getBrowser();
  }

  browserSpecs() {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
      M.splice(1, 1, tem[1]);
    return { name: M[0], version: M[1] };
  }

  isIE() {
    return /*@cc_on!@*/false || !!(document as any).documentMode;
  }

  isFirefox() {
    return typeof (window as any).InstallTrigger !== 'undefined';
  }

  isChrome() {
    return !!(window as any).chrome && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/OPR/.test(navigator.userAgent);
  }

  isEdge() {
    return !this.isIE() && !!(window as any).StyleMedia;
  }

  isOpera() {
    return (!!(window as any).opr && !!(window as any).opr.addons) || !!(window as any).opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  }
}
