import { Injectable, SkipSelf } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

// @Injectable({
//   providedIn: 'root'
// }
@Injectable()
export class PlayModalAnalyticsService {

  private previousScreen: string;
  private previousScreenProperties: {};

  constructor(
    @SkipSelf() private analyticsService: AnalyticsProvider,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
  ) { }

  setScreen(scriptID: string, scriptTitle: string) {
    this.previousScreen = this.analyticsService.currentScreen;
    this.previousScreenProperties = this.analyticsService.screenProperties;
    this.analyticsService.setCurrentScreen('Play options', { 'game-id': scriptID, 'game-title': scriptTitle });
  }

  async firstPublish() {
    await this.analyticsService.event('Publish / first update button clicked');
  }

  async publish() {
    await this.analyticsService.event('Publish / update button clicked');
  }

  async createOrganizedSession() {
    await this.analyticsService.event('Organized session / create button clicked');
  }

  async copyInstantSessionURL() {
    await this.analyticsService.event('Instant session / copy url button clicked');
  }

  async toggleRandomPlayerMode(isTurnedOn: boolean) {
    await this.analyticsService.event('Random player mode / toggle', this.translateService.instant('play-modal/invite-event/auto-join-enabled/label'), { status: isTurnedOn });
  }

  async togglePlayerDisplay(isTurnedOn: boolean) {
    await this.analyticsService.event('Player Display / toggle', this.translateService.instant('play-modal/invite-event/player-display/label'), { status: isTurnedOn });
  }

  async toggleFeedPost(isTurnedOn: boolean) {
    await this.analyticsService.event('Feed post / toggle', this.translateService.instant('play-modal/feed-post/label'), { status: isTurnedOn });
  }
  

  async close() {
    await this.analyticsService.event('close');
    this.analyticsService.currentScreen = this.previousScreen;
    this.analyticsService.screenProperties = this.previousScreenProperties;
  }

}
